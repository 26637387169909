import React, { memo, useRef, useState, useMemo } from "react";
import Cropper from "react-cropper";
import { ColorRing } from "react-loader-spinner";
import "cropperjs/dist/cropper.css";

import UploadIcon from "../../../assets/Icons/UploadIcon";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./FileUpload.module.scss";
import { Modal } from "../../atoms/Modal/Modal";
import { ModelHeader } from "../../atoms/Modal/ModelHeader/ModelHeader";
import { ModalBody } from "../../atoms/Modal/ModalBody/ModalBody";
import { Button, ButtonModifier } from "../../atoms/Button/Button";
import { dataURLtoFile } from "../../../utils/common";
import { CircularAvatar } from "../CircularAvatar/CircularAvatar";

import NoImageIcon from "../../../assets/Icons/NoImageIcon";
import toastify from "../../../utils/toast";
import closeIcon from "../../../assets/images/closeicon.svg";

const cx = createModuleStyleExtractor(styles);

const FileUpload = ({
  onSelectSuccess, // dispatch data up to parent
  onRemoveSuccess, // dispatch data up to parent
  className = "", // main wrapper class
  maxFileSize = 5, // default maximumFileSize in MB
  allowedFiles = ["image/png", "image/jpg", "image/jpeg"],
  withPreview = true, // show preview image on selection if true
  previewContainerClass = "", /// override class for image preview
  defaultBackgroundImage = "https://www.static-contents.youth4work.com/y4w/Images/Users/20200217103803.png?v=20200217103803",
  error,
  userName = "",
  loader = false,
  cropperAlign = "center",
  ...extraProps
}) => {
  const inputFile = useRef(null);
  const cropperRef = useRef(null);

  const [showCropperModal, setShowCropperModal] = useState(false);
  const [cropper, setCropper] = useState();
  const [profile, setProfile] = useState({
    preview: "",
    data: "",
  });

  /**
   * Handle Image Change callback
   * @returns
   */
  const onImageChange = (e) => {
    const image = e.target?.files[0];
    if (!image) {
      toastify("Oops! something went wrong. Please try again");
      return;
    }

    const { size, type } = image;
    const fileSize = size / 1024 / 1024; // in MiB
    if (fileSize > maxFileSize) {
      toastify(`Please select a file less than ${maxFileSize} MB`);
      return;
    }
    if (!allowedFiles.includes(type)) {
      toastify("Please select a valid image (png, jpg, jpeg)");
      return;
    }

    // Update State
    setProfile({
      preview: URL.createObjectURL(image),
      data: image,
    });
    // Open Modal
    setShowCropperModal(true);
  };

  /**
   * Handle File button click
   */
  const onImageChooseButtonClick = (e) => {
    e?.preventDefault && e.preventDefault();
    if (inputFile) {
      inputFile.current?.click();
    }
  };

  // Get cropped image
  const getCropData = () => {
    if (typeof cropper !== "undefined" && cropper.getCroppedCanvas()) {
      let base64Url = cropper.getCroppedCanvas().toDataURL();
      const getObjectFile = dataURLtoFile(base64Url, "hello.txt");

      // Update State
      setProfile({
        preview: base64Url,
        data: getObjectFile,
      });

      // Close modal
      setShowCropperModal(false);
      // Reset file input field
      inputFile.current.value = "";

      onSelectSuccess(getObjectFile); /// when file selected successfully
    }
  };

  const handleClose = () => {
    // Close modal
    setShowCropperModal(false);
    // Reset Image
    setProfile({
      preview: "",
      data: "",
    });
    // Reset file input field
    inputFile.current.value = "";
  };

  const handleRemoveFile = () => {
    // Reset Image
    setProfile({
      preview: "",
      data: null,
    });
    onRemoveSuccess(true);
  };

  // Image optimization
  const displayBackgroundImage = useMemo(() => {
    if (typeof defaultBackgroundImage === "string") {
      return defaultBackgroundImage;
    } else {
      return URL.createObjectURL(defaultBackgroundImage);
    }
  }, [defaultBackgroundImage]);

  return (
    <>
      <input
        {...extraProps}
        id="_upload-file_"
        type="file"
        ref={inputFile}
        onChange={onImageChange}
        accept="image/*"
        className={cx("hidden-input")}
      />
      <div
        className={cx([
          "a-fileupload-container",
          ...(className ? [className] : []),
        ])}
      >
        {withPreview && (
          <div
            className={cx([
              "a-fileupload-container__preview",
              ...(previewContainerClass ? [previewContainerClass] : []),
            ])}
          >
            {defaultBackgroundImage ? (
              <div
                className={cx("picture")}
                style={{ backgroundImage: `url(${displayBackgroundImage})` }}
              />
            ) : userName ? (
              <div>
                <CircularAvatar name={userName} round size={66} />
              </div>
            ) : (
              <NoImageIcon />
            )}
          </div>
        )}
        <div
          className={cx("a-fileupload-container__button")}
          onClick={onImageChooseButtonClick}
        >
          <div className={cx("a-fileupload-container__button-icon")}>
            <UploadIcon />
          </div>
          <div className={cx("a-fileupload-container__button-text")}>
            Upload
          </div>
        </div>
        {defaultBackgroundImage !== "" && loader && (
          <div style={{ marginLeft: "5px", marginTop: "5px" }}>
            <ColorRing
              visible={true}
              height="38"
              width="38"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
              on
            />
          </div>
        )}
        {defaultBackgroundImage !== "" && !loader && (
          <div className={cx("a-fileupload-container__close_button")}>
            <img src={closeIcon} alt="close" onClick={handleRemoveFile} />
          </div>
        )}
      </div>
      {error && <span className="p-error mb-20">{error}</span>}

      {/* Cropper Modal */}
      <Modal
        show={showCropperModal}
        onClose={handleClose}
        centerAlign={cropperAlign === "start" ? false : true}
      >
        <ModelHeader title={"Upload a profile picture"} />
        <ModalBody>
          <div className={cx("a-fileupload-cropper-modal-wrapper")}>
            <div className={cx("a-fileupload-cropper-modal-img-wrapper")}>
              <Cropper
                style={{ height: 220, width: "100%" }}
                aspectRatio={1}
                src={profile.preview}
                viewMode={1}
                center={false}
                cropBoxResizable={false}
                dragCrop={false}
                dragMode={"move"}
                guides={false}
                toggleDragModeOnDblclick={false}
                background={false}
                cropBoxMovable={false}
                checkOrientation={false}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                ref={cropperRef}
              />
            </div>
            <div className={cx("a-fileupload-cropper-modal-button-wrapper")}>
              <Button
                modifier={ButtonModifier.PROFILE_UPLOAD}
                onClick={() => {
                  getCropData();
                }}
                type="button"
              >
                Crop image
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default memo(FileUpload);
