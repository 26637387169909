import React, { useState } from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./CalculatorInfoCard.module.scss";
import Card, { CardModifier } from "../../atoms/Card/Card";
import { getCapitalizedHeader, getUSDAmount } from "../../../utils/common";

const cx = createModuleStyleExtractor(styles);

export default function CalculatorInfoCard({ data = {} }) {
  const [showLink, setShowLink] = useState(false);

  const MAX_DESCRIPTION_LENGTH = 200;

  return (
    <div className={cx("m-calculator-info-card")}>
      <Card
        modifier={CardModifier.FULL_WIDTH}
        onClick={() => {
          if (data.url) window.open(data.url);
        }}
      >
        <div className={cx("m-calculator-info-card__header")}>
          {data.title}{" "}
          <span
            className={cx([
              "m-calculator-info-card__header__tag",
              `m-calculator-info-card__header__tag--${
                data.funding_type === "scholarship" ? "yellow" : "green"
              }`,
            ])}
          >
            {getCapitalizedHeader(data.funding_type?.replace("_", " ") ?? "")}
          </span>
        </div>
        {/* <div className={cx("m-calculator-info-card__subtitles")}>
          {data.subtitles.map((subtitle) => (
            <div className={cx("m-calculator-info-card__subtitles__item")}>
              {subtitle}
            </div>
          ))}
        </div> */}
        <div className={cx("m-calculator-info-card__details")}>
          {["amount", "degree"].map((key) => (
            <>
              {data[key] && (
                <div className={cx("m-calculator-info-card__details__item")}>
                  <div
                    className={cx(
                      "m-calculator-info-card__details__item__title"
                    )}
                  >
                    {getCapitalizedHeader(key ?? "")}
                  </div>
                  <div
                    className={cx(
                      "m-calculator-info-card__details__item__value"
                    )}
                  >
                    {key === "amount" ? getUSDAmount(data[key]) : data[key]}
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
        <div className={cx("m-calculator-info-card__description")}>
          {data.description?.length > MAX_DESCRIPTION_LENGTH ? (
            <>
              {showLink
                ? data.description
                : `${data.description.slice(0, MAX_DESCRIPTION_LENGTH)}...`}
              <div
                className={cx("m-calculator-info-card__description__link")}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowLink(!showLink);
                }}
              >
                {showLink ? "See less" : "See more"}
              </div>
            </>
          ) : (
            <>{data.description}</>
          )}
        </div>
      </Card>
    </div>
  );
}
