import React from "react";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./NoChatsPreview.module.scss";
const cx = createModuleStyleExtractor(styles);
export const NoChatsPreview = () => {
  return (
    <div className={cx("m-card-container")}>
      <p className={cx("m-card-container__heading")}>
        Looks like you don’t have any messages! Get started now.
      </p>
      <p className={cx("m-card-container__description")}>
        Click on Chat to start a new conversation.
      </p>
    </div>
  );
};
