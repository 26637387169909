import React, { memo } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { noop } from "../../../utils/noop";
import "./PhoneNumberSelect.scss";

const PhoneNumberSelect = ({
  onChange = noop,
  onBlur = noop,
  onMount = noop,
  name,
  value,
  placeholder,
  label = "",
  isDisabled = false,
  error = false,
  autoFocus = false,
}) => {
  return (
    <div className={"a-phone-number-select"}>
      <div className="field-group">
        {label && <label>{label}</label>}
        <PhoneInput
          enableSearch
          disableSearchIcon
          countryCodeEditable={false}
          country={"us"}
          value={value}
          onChange={(phone_number, { dialCode }) => {
            onChange(phone_number, dialCode);
          }}
          onMount={(value, data, formattedValue) => {
            onMount(formattedValue);
          }}
          onBlur={onBlur}
          placeholder={placeholder}
          disabled={isDisabled}
          inputProps={{
            name,
            autoFocus,
            required: true,
          }}
          searchPlaceholder="Search for countries"
          searchNotFound="No countries to show"
          dropdownClass="phone-number-select-dropdown"
          inputClass="phone-number-select-input"
        />
        {error && <span className="p-error">{error}</span>}
      </div>
    </div>
  );
};

export default memo(PhoneNumberSelect);
