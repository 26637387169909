import React from "react";
import moment from "moment";

import Card from "../../atoms/Card/Card";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./ResourceCard.module.scss";

import StarIcon from "../../../assets/Icons/StarIconFacelift";
import xlsIcon from "../../../assets/svg/xlsIcon.svg";
import docIcon from "../../../assets/svg/docIcon.svg";
import BigPlayIcon from "../../../assets/images/BigPlayIcon.png";
import Missing_Video_Icon from "../../../assets/images/youtube_big.png";
import NewDocIcon from "../../../assets/svg/newDocIcon.svg";
import NewWebIcon from "../../../assets/svg/newWebIcon.svg";
import NewPlayIcon from "../../../assets/svg/newPlayIcon.svg";
import { INTERSTRIDE_EMBLEM } from "../../../utils/constants";

const cx = createModuleStyleExtractor(styles);

export default function ResourceCard({
  resource,
  index,
  isResourceFavorite,
  categoryName = "",
  handleFavorite = () => {},
  onClick = () => {},
}) {
  return (
    <div className={cx("m-resource-card")} key={index}>
      <a href className={cx("m-resource-card__wrapper")} onClick={onClick}>
        <Card>
          <div className={cx("m-resource-card__wrapper__content")}>
            <div className={cx("m-resource-card__wrapper__content__image")}>
              {resource.link_type === "Interstride Video" ? (
                <>
                  <img
                    className={cx(
                      "m-resource-card__wrapper__content__image__primary"
                    )}
                    src={resource.interstride_video_thumb_image}
                    alt="Video thumbnail"
                  />
                  <div
                    className={cx(
                      "m-resource-card__wrapper__content__image__secondary"
                    )}
                  >
                    <img src={BigPlayIcon} alt="play-icon" />
                  </div>
                </>
              ) : resource.link_type === "YouTube Video" && resource.image ? (
                <img src={resource.image} alt="play-icon" />
              ) : resource.link_type === "YouTube Video" && !resource.image ? (
                <img
                  style={{
                    width: "72px",
                    height: "72px",
                  }}
                  src={NewPlayIcon}
                  alt="play-icon"
                />
              ) : resource.link_type === "Image" &&
                resource.image &&
                resource.image !== "" ? (
                <img src={resource.image} alt={resource.link_type} />
              ) : resource.link_type === "Website Link" && resource.image ? (
                <img src={resource.image} alt={resource.link_type} />
              ) : resource.link_type === "Website Link" && !resource.image ? (
                <img
                  style={{
                    width: "72px",
                    height: "72px",
                  }}
                  src={NewWebIcon}
                  alt={resource.link_type}
                />
              ) : resource?.link_type === "PDF" &&
                resource?.interstride_video_thumb_image ? (
                <img
                  src={resource.interstride_video_thumb_image}
                  alt={resource.link_type}
                />
              ) : (
                <img
                  style={{
                    width: "72px",
                    height: "72px",
                  }}
                  alt={resource.link_type}
                  src={
                    resource.link_type === "PDF"
                      ? NewDocIcon
                      : resource.link_type === "Document"
                      ? docIcon
                      : resource.link_type === "Excel Sheet"
                      ? xlsIcon
                      : Missing_Video_Icon
                  }
                />
              )}
            </div>
            <div className={cx("m-resource-card__wrapper__content__title")}>
              <span>{resource.title}</span>
            </div>
            <div
              className={cx("m-resource-card__wrapper__content__description")}
            >
              <span>{resource.description}</span>
            </div>
            <div className={cx("m-resource-card__wrapper__content__school")}>
              {resource?.posted_by_super_admin ? (
                <div
                  className={cx(
                    "m-resource-card__wrapper__content__school__super-admin"
                  )}
                >
                  <img src={INTERSTRIDE_EMBLEM} alt="Interstride" />
                  <span>Interstride upload</span>
                </div>
              ) : (
                <div
                  className={cx(
                    "m-resource-card__wrapper__content__school__school-admin"
                  )}
                >
                  School upload
                </div>
              )}
            </div>
            <div className={cx("m-resource-card__wrapper__content__footer")}>
              <div
                className={cx(
                  "m-resource-card__wrapper__content__footer__date"
                )}
              >
                <span>{moment(resource.date).format("MMMM DD, YYYY")}</span>
              </div>
              <div
                className={cx(
                  "m-resource-card__wrapper__content__footer__type"
                )}
              >
                {resource.link_type}
              </div>
            </div>
          </div>
        </Card>
      </a>
      <div
        className={cx([
          "m-resource-card__star",
          isResourceFavorite ? "m-resource-card__star--active" : "",
        ])}
      >
        {isResourceFavorite ? (
          <StarIcon
            aria-label={`Remove ${resource.title} from saved resources`}
            variant="filled"
            onClick={() => handleFavorite(resource.id)}
          />
        ) : (
          <StarIcon
            aria-label={`Add ${resource.title} to saved resources`}
            onClick={() => handleFavorite(resource.id)}
          />
        )}
      </div>
      {categoryName && (
        <div className={cx("m-resource-card__label")}>
          <span>{categoryName}</span>
        </div>
      )}
    </div>
  );
}
