import React from "react";

import { Modal } from "../../../atoms/Modal/Modal";
import { ModalBody } from "../../../atoms/Modal/ModalBody/ModalBody";
import { Button, ButtonModifier } from "../../../atoms/Button/Button";
import { ModelHeader } from "../../../atoms/Modal/ModelHeader/ModelHeader";
import { createModuleStyleExtractor } from "../../../../utils/css";
import styles from "./SignOutModal.module.scss";

const cx = createModuleStyleExtractor(styles);

export default function SignOutModal({
  showModal,
  closeModal,
  handleOnSignout,
}) {
  return (
    <Modal show={showModal} onClose={closeModal} maxWidth={480}>
      <ModelHeader title={"Are you sure you want to sign out?"} />
      <ModalBody>
        <div className={cx("o-sign-out-modal")}>
          <div className={cx("o-sign-out-modal__submit-button")}>
            <Button
              onClick={handleOnSignout}
              modifier={ButtonModifier.PROFILE_SUBMIT}
            >
              Sign Out
            </Button>
          </div>

          <a
            href="/cancel"
            className={cx("o-sign-out-modal__cancel-link")}
            onClick={(e) => {
              e?.preventDefault && e.preventDefault();
              closeModal();
            }}
          >
            Cancel
          </a>
        </div>
      </ModalBody>
    </Modal>
  );
}
