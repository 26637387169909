import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteChat } from "../api/chat";
import {
  removeConversationByConversationID,
  removeUserFromFreshChat,
  setReloadChats,
} from "../redux/reducers/chat/reducers";

export const useDeleteChat = (confirmed, conversationId, callback) => {
  const chatStore = useSelector((store) => store.chatStore);
  const dispatch = useDispatch();
  const { freshChat, activeConversation } = chatStore;

  const [processed, setIsProcessed] = useState([]);
  const [processing, setIsProcessing] = useState(false);

  const handleDeleteOperation = async () => {
    setIsProcessing(true);
    const isFreshChat = freshChat.includes(activeConversation);
    const handleDelete = () => {
      dispatch(
        removeConversationByConversationID({
          conversation_id: activeConversation,
        })
      );
      dispatch(setReloadChats(true));
      setIsProcessed([...processed, conversationId]);
      setIsProcessing(false);
      callback();
    };
    if (isFreshChat) {
      dispatch(removeUserFromFreshChat({ id: activeConversation }));
      handleDelete();
      return;
    }
    const response = await deleteChat(activeConversation);
    const { success } = response;
    if (success) {
      handleDelete();
    }
  };
  useEffect(() => {
    if (activeConversation !== conversationId) return;
    if (!conversationId || processing || !confirmed) return;
    if (processed.includes(conversationId)) return;
    handleDeleteOperation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmed, conversationId]);

  return processed.includes(conversationId);
};
