import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { createModuleStyleExtractor } from "../../../utils/css";
import { getConversationByConversationID } from "../../../redux/reducers/chat/selectors";
import {
  openInNewTab,
  generateDisplayName,
  extractFirstName,
} from "../../../utils/common";
import { CircularAvatar } from "../CircularAvatar/CircularAvatar";
import { Button, ButtonModifier } from "../Button";
import { bulkUpdate } from "../../../redux/reducers/chat/reducers";
import { validateAttribute } from "../../../utils/validations";
import styles from "./UserProfileCard.module.scss";

import Linkedin from "../../../assets/Icons/Linkedin";
import ChevronLeft from "../../../assets/Icons/ChevronLeft";

const cx = createModuleStyleExtractor(styles);

export const UserProfileCard = ({
  handleRequestMeeting = () => {},
  isUserDeactivated = false,
}) => {
  const dispatch = useDispatch();

  const { permissions = {} } = useSelector((state) => state.userState);
  const conversation = useSelector((state) =>
    getConversationByConversationID(state.chatStore)
  );
  const { user_info: user } = conversation || {};

  // Override full name if alumni or student ambassador
  let { full_name, user_type } = user || {};
  const isStudentAmbassador =
    user_type && (user_type.includes("Ambassador") || user_type === "Student");
  const isAlumniAmbassador =
    user_type && (user_type.includes("Ambassador") || user_type === "Alumni");

  if (isStudentAmbassador || isAlumniAmbassador) {
    full_name = extractFirstName(user?.full_name);
  }

  // Selected School Profile Field Settings
  const onboardingSettings = permissions?.custom_onboarding_field;
  const nationalitySetting = onboardingSettings?.country_of_origin;
  const majorSetting = onboardingSettings?.major;
  const enrollmentYearSetting = onboardingSettings?.graduation_year;

  const handleBackOperation = () => {
    dispatch(
      bulkUpdate({
        isChatPreview: false,
        isConversationView: !false,
        isProfileView: !true,
      })
    );
  };

  return (
    <div className={cx("a-user-profile-card")}>
      {isUserDeactivated ? (
        <h3 className={cx("a-user-profile-card__deactivated")}>
          User profile currently unavailable
        </h3>
      ) : user ? (
        <div className={cx("a-user-profile-card__content")}>
          <button className={cx("a-user-profile-card__content__close")}>
            <ChevronLeft
              style={{ width: "25px" }}
              onClick={handleBackOperation}
            />
          </button>
          <div className={cx("a-user-profile-card__content__image")}>
            <CircularAvatar
              src={user.user_profile}
              name={full_name}
              size={70}
              round
            />
          </div>
          <div className={cx("a-user-profile-card__content__name")}>
            <h3>{generateDisplayName(full_name)}</h3>
          </div>
          {user.account_type ? (
            <div className={cx("a-user-profile-card__content__tag")}>
              <span>
                {user.account_type === "super_admin_user" ||
                user.account_type === "school_admin_user"
                  ? "Admin"
                  : user.account_type === "prospect_user"
                  ? "Prospect"
                  : user.account_type === "parent_user"
                  ? "Parent"
                  : user.account_type}
              </span>
            </div>
          ) : user.user_type ? (
            <div className={cx("a-user-profile-card__content__tag")}>
              <span>
                {isStudentAmbassador
                  ? "Student"
                  : isAlumniAmbassador
                  ? "Alumni"
                  : user.user_type === "parent_user"
                  ? "Parent"
                  : user.user_type}
              </span>
            </div>
          ) : (
            <></>
          )}
          {user.account_type === "prospect_user" &&
          (!majorSetting?.is_visible || !enrollmentYearSetting?.is_visible) ? (
            <></>
          ) : (
            <div className={cx("a-user-profile-card__content__subject")}>
              <h3>
                {user.major &&
                  user.graduation_year &&
                  user.major + ", " + user.graduation_year}
              </h3>
            </div>
          )}
          {user.account_type === "prospect_user" &&
          !nationalitySetting?.is_visible ? (
            <></>
          ) : user?.country_image ? (
            <div className={cx("a-user-profile-card__content__flag")}>
              <div>
                <span>
                  <img src={user?.country_image} alt="flag" />
                </span>
                {user?.country_of_origin && `From ${user.country_of_origin}`}
              </div>
            </div>
          ) : (
            <></>
          )}
          {user && (
            <div className={cx("a-user-profile-card__content__description")}>
              {validateAttribute(user.intro) ? (
                <p>{user.intro}</p>
              ) : validateAttribute(user.user_intro) ? (
                <p>{user.user_intro}</p>
              ) : user.account_type === "super_admin_user" ||
                user.account_type === "school_admin_user" ? (
                <p>University Admin at {user.school_name}</p>
              ) : (isStudentAmbassador || isAlumniAmbassador) &&
                validateAttribute(user.major) &&
                validateAttribute(user.degree) &&
                validateAttribute(user.school_name) ? (
                <p>
                  {`I ${
                    isAlumniAmbassador
                      ? "have completed"
                      : "am currently persuing my"
                  } ${user.degree} in ${user.major} at ${user.school_name}`}
                </p>
              ) : user.user_type === "Staff" ? (
                <p>University Admin at {user.school_name}</p>
              ) : (
                <></>
              )}
            </div>
          )}
          {user?.linkedin_url && (
            <div className={cx("a-user-profile-card__content__social")}>
              <span>
                <Linkedin onClick={() => openInNewTab(user.linkedin_url)} />
              </span>
              LinkedIn
            </div>
          )}

          {user?.request_meeting && permissions["request_meeting"] && (
            <Button
              modifier={ButtonModifier.USER_PROFILE_REQUEST_MEETING}
              onClick={handleRequestMeeting}
            >
              Request a meeting
            </Button>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
