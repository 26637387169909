import React, { memo } from "react";

function WidgetResourcesIcon() {
  return (
    <svg
      width="39"
      height="38"
      viewBox="0 0 39 38"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke={"var(--primary-font-color)"}
        strokeWidth="1.2"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M26.356 31.615H5.076a1.037 1.037 0 0 1-1.044-1.031V7.894c0-.492.407-.894.904-.894h21.558c.497 0 .905.402.905.894v22.69c0 .57-.467 1.031-1.043 1.031" />
        <path d="M18.898 19.308c0 1.738-1.425 3.146-3.183 3.146s-3.182-1.408-3.182-3.146 1.425-3.148 3.182-3.148c1.758 0 3.183 1.409 3.183 3.147" />
        <path d="M24.697 19.308s-4.02 4.96-8.982 4.96c-4.96 0-8.982-4.96-8.982-4.96s4.022-4.96 8.982-4.96c4.961 0 8.982 4.96 8.982 4.96m2.702-2.767 6.269-2.666V24.74l-6.27-1.897" />
      </g>
    </svg>
  );
}

export default memo(WidgetResourcesIcon);
