import React, { memo, useState, useEffect } from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { useSelector } from "react-redux";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./DiscoverTopic.module.scss";
import { TopicCard } from "../../atoms/TopicCard/TopicCard";
import { getDiscoverTopics } from "../../../api/topics";
import EmptyDiscoverTopicsIcon from "../../../assets/Icons/EmptyDiscoverTopicsIcon";

const cx = createModuleStyleExtractor(styles);

const DiscoverTopic = ({
  recommendedTopics,
  setRecommendedTopics,
  isFollowingTopic = false,
}) => {
  const [isFetchingTopics, setIsFetchingTopics] = useState(true);

  const userState = useSelector((state) => state.userState);
  const selectedSchool = userState.selectedSchool;

  const fetchDiscoverTopics = async () => {
    try {
      setIsFetchingTopics(true);
      const response = await getDiscoverTopics(selectedSchool.id);
      if (response.success) {
        setIsFetchingTopics(false);
        setRecommendedTopics(response.data.recommended_topics);
      } else {
        setIsFetchingTopics(false);
      }
    } catch (error) {
      setIsFetchingTopics(false);
    }
  };

  // Get TOPICS data via API upon DOM load
  useEffect(() => {
    fetchDiscoverTopics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isFetchingTopics ? (
        [...Array(10)].map((item, index) => (
          <ShimmerThumbnail
            key={index}
            height={130}
            width={360}
            rounded
            className={cx("o-topic-body-shimmer")}
          />
        ))
      ) : recommendedTopics.length > 0 ? (
        recommendedTopics.map((topic, index) => (
          <div key={index} className={cx("o-topics-body-card-wrapper")}>
            <TopicCard
              key={topic.id}
              topic={topic}
              fetchDiscoverTopics={fetchDiscoverTopics}
            />
          </div>
        ))
      ) : (
        <div className={cx("o-topic-body-empty-list-card-title")}>
          <h2>
            <br />
            {isFollowingTopic
              ? "You're following all the Communities!"
              : "Looks like there are no Communities."}
          </h2>
          <EmptyDiscoverTopicsIcon />
        </div>
      )}
    </>
  );
};

export default memo(DiscoverTopic);
