import { ApiWrapper, REQUEST } from "./index";

export const getResourceCategories = async (searchKeywords, school_id) => {
  return await ApiWrapper({
    url: `videos/video_categories?school_id=${school_id}${
      searchKeywords ? `&search=${searchKeywords}` : ""
    }`,
    method: REQUEST.GET,
  });
};

export const getResourcesByCategoryAndSortType = async (
  school_id,
  video_category_id,
  sort_by,
  group_by
) => {
  return await ApiWrapper({
    url: `videos?school_id=${school_id}&video_category_id=${video_category_id}&sort_by=${sort_by}&group_by=${group_by}`,
    method: REQUEST.GET,
  });
};

export const getSavedResourcesList = async (
  school_id,
  group_by,
  search = ""
) => {
  return await ApiWrapper({
    url: `videos/saved_resources_list?school_id=${school_id}&group_by=${group_by}&search=${search}`,
    method: REQUEST.GET,
  });
};

export const saveResource = async (resourceId) => {
  const payload = { resource_id: resourceId };
  return await ApiWrapper({
    url: `videos/save_resource`,
    method: REQUEST.POST,
    data: payload,
  });
};
