import React from "react";
import "../../../assets/styles/global.scss";
export const Row = ({ reverse, children, styles, className }) => {
  const classes = [
    ...(reverse ? ["reverse"] : []),
    ...(className ? [className] : []),
  ].join(" ");
  return (
    <div style={styles} className={`row ${classes}`}>
      {children}
    </div>
  );
};
