import React from "react";
import { ClickAwayListener } from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";

import { CircularAvatar } from "../../../atoms/CircularAvatar/CircularAvatar";
import LinkContent from "../../../atoms/LinkContent/LinkContent";

import topicMenuOpen from "../../../../assets/svg/community-menu-open.svg";
import topicMenuClose from "../../../../assets/svg/community-menu-close.svg";

const Comments = ({
  data = {},
  fetchReplies = () => {},
  loadingReplies = false,
  onContactInfoUserClick = () => {},
  topic = {},
  commentMenuState = {},
  setCommentMenuState = () => {},
  INITIAL_COMMENT_MENU_STATE = {},
}) => {
  const { activeTopic = {} } = useSelector((state) => state.communityState);
  const { user = {} } = useSelector((state) => state.userState);

  const renderComments = (item, index) => {
    return (
      <div className="item" key={index}>
        {index !== 0 && <div className="item__saperator" />}
        <div className="item__title">
          <div
            className="item__title__avtar"
            onClick={() =>
              !topic?.anonymously &&
              item?.commented_by?.id &&
              onContactInfoUserClick(item.commented_by.id)
            }
            style={{
              cursor: topic?.anonymously ? "initial" : "pointer",
            }}
          >
            <CircularAvatar
              round={true}
              size={40}
              name={
                item?.commented_by?.first_name +
                " " +
                item?.commented_by?.last_name
              }
              alt={`${
                item?.commented_by?.first_name +
                " " +
                item?.commented_by?.last_name
              }'s profile picture`}
              index={index}
              src={item?.commented_by?.image}
            />
          </div>
          <h3
            className="item__title__name"
            onClick={() =>
              !topic?.anonymously &&
              item?.commented_by?.id &&
              onContactInfoUserClick(item.commented_by.id)
            }
            style={{
              cursor: topic?.anonymously ? "initial" : "pointer",
            }}
          >
            {item?.commented_by?.first_name +
              " " +
              item?.commented_by?.last_name}
          </h3>
          {item?.user_type && (
            <div
              className={`item__title__user-type ${
                item?.user_type === "Mentor"
                  ? "item__title__user-type--mentor"
                  : ""
              }`}
            >
              {item?.user_type}
            </div>
          )}
          {item?.commented_by?.id === activeTopic?.created_by_id && (
            <div className="item__title__community-creator">
              Community Creator
            </div>
          )}
          <h4 className="item__title__time">
            {moment(item?.created_at).calendar(null, {
              sameDay: "[Today at] hh:mm A",
              lastDay: "[Yesterday at] hh:mm A",
              sameElse: "DD/MM/YYYY",
            })}
          </h4>
          {item?.commented_by?.id === user?.id && (
            <div className="item__title__options">
              <img
                className="item__title__options__menu-icon"
                alt="menu-icon"
                src={
                  commentMenuState.open &&
                  commentMenuState.comment.id === item.id
                    ? topicMenuClose
                    : topicMenuOpen
                }
                onClick={() =>
                  setCommentMenuState(() => ({
                    menuOpen: true,
                    comment: item,
                    apiLoading: false,
                    editModalOpen: false,
                    deleteModalOpen: false,
                  }))
                }
              />
              {commentMenuState.menuOpen &&
                commentMenuState?.comment?.id === item.id && (
                  <ClickAwayListener
                    onClickAway={() =>
                      setCommentMenuState(INITIAL_COMMENT_MENU_STATE)
                    }
                  >
                    <div className="item__title__options__menu">
                      <span
                        onClick={() =>
                          setCommentMenuState(() => ({
                            menuOpen: false,
                            comment: item,
                            editModalOpen: true,
                            deleteModalOpen: false,
                          }))
                        }
                      >
                        Edit
                      </span>
                      <span
                        onClick={() =>
                          setCommentMenuState(() => ({
                            menuOpen: false,
                            comment: item,
                            editModalOpen: false,
                            deleteModalOpen: true,
                          }))
                        }
                      >
                        Delete
                      </span>
                    </div>
                  </ClickAwayListener>
                )}
            </div>
          )}
        </div>
        <p className="item__comment">
          <LinkContent>{item?.comment || ""}</LinkContent>
        </p>
      </div>
    );
  };

  return (
    <>
      <div className="comment-list">
        {data?.comments?.map((item, index) => renderComments(item, index))}
      </div>
      {data?.current_page < data?.total_pages &&
        data?.total_pages > 0 &&
        !loadingReplies && (
          <div className="comment-load-more">
            <span onClick={() => fetchReplies(data.current_page + 1)}>
              Show more replies
            </span>
          </div>
        )}
    </>
  );
};

export default Comments;
