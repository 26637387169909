import React, { memo } from "react";
import Slider from "react-slick";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./DashboardPostSlider.module.scss";

const cx = createModuleStyleExtractor(styles);

const DashboardPostSlider = ({ files = [], handleClick = () => {} }) => {
  let settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const preventClick = (e) => {
    e?.stopPropagation && e.stopPropagation();
    e?.preventDefault && e.preventDefault();
  };

  return (
    <div className="dashboard-post-slider-container" onClick={preventClick}>
      <Slider {...settings}>
        {files.map((file, index) => (
          <div
            className={cx("m-dashboard-post-slider")}
            key={`dashboard-post-preview-${file.id ?? index}`}
          >
            {file.file_content_type.includes("image") ? (
              <div className={cx("m-dashboard-post-slider__image")}>
                <img
                  src={file.file_url}
                  key={file.file_url}
                  alt="preview"
                  onClick={handleClick}
                />
              </div>
            ) : file.file_content_type.includes("video") ? (
              <div className={cx("m-dashboard-post-slider__video")}>
                <video
                  src={file.file_url}
                  controls
                  poster={file.thumb_file_url}
                  onClick={handleClick}
                />
              </div>
            ) : null}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default memo(DashboardPostSlider);
