import React from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import { CircularAvatar } from "../CircularAvatar/CircularAvatar";
import styles from "./GroupParticipant.module.scss";

const cx = createModuleStyleExtractor(styles);

export const GroupParticipant = ({ name, admin, image, accountType }) => {
  // Name override in case of alumni or student
  if (accountType === "student" || accountType === "alumni") {
    name = name?.split(" ")[0];
  }
  return (
    <div className={cx("a-participant-container")}>
      <CircularAvatar src={image} round size={30} name={name} />
      <div className={cx("a-participant-container__content")}>
        <div className={cx("a-participant-container__content__title")}>
          <span>
            {name ? name.split(" ").slice(0, 2).join(" ") : "Unknown User"}
          </span>
        </div>
        {admin && (
          <div className={cx("a-participant-container__content__tag")}>
            <span> Group Admin</span>
          </div>
        )}
      </div>
    </div>
  );
};
