import React, { memo } from "react";

import { Modal } from "../../atoms/Modal/Modal";
import { ModelHeader } from "../../atoms/Modal/ModelHeader/ModelHeader";
import { ModalBody } from "../../atoms/Modal/ModalBody/ModalBody";
import { Button } from "../../atoms/Button/Button";
import styles from "./MuteChat.module.scss";
import { createModuleStyleExtractor } from "../../../utils/css";

const cx = createModuleStyleExtractor(styles);

const MuteChat = ({ show, onClose, onSuccess, isMute, loading }) => {
  return (
    <Modal show={show} onClose={onClose}>
      <ModelHeader
        title={`Are you sure you want to ${
          isMute ? "Unmute" : "Mute"
        } this chat?`}
      />
      <ModalBody>
        <div className={cx("button-wrapper")}>
          <Button onClick={onSuccess} isSubmitting={loading}>
            {isMute ? "Unmute" : "Mute"}
          </Button>
        </div>
        <p className={cx("button-cancel")} onClick={onClose}>
          <span>Cancel</span>
        </p>
      </ModalBody>
    </Modal>
  );
};

export default memo(MuteChat);
