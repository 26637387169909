import React from "react";

export default function GoogleMapWebIcon({ onClick = () => {} }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="34"
      viewBox="0 0 35 34"
      fill="none"
      style={{ cursor: "pointer" }}
      onClick={onClick}
    >
      <circle cx="17.5" cy="17" r="17" fill="#F1F4F7" />
      <path
        d="M24.063 17.75a.75.75 0 0 0 0-1.5zM23.75 17a6.25 6.25 0 0 1-6.25 6.25v1.5A7.75 7.75 0 0 0 25.25 17zm-6.25 6.25A6.25 6.25 0 0 1 11.25 17h-1.5a7.75 7.75 0 0 0 7.75 7.75zM11.25 17a6.25 6.25 0 0 1 6.25-6.25v-1.5A7.75 7.75 0 0 0 9.75 17zm6.25-6.25A6.25 6.25 0 0 1 23.75 17h1.5a7.75 7.75 0 0 0-7.75-7.75zm0 12.5c-.175 0-.4-.078-.67-.34-.275-.264-.556-.684-.808-1.26-.504-1.152-.834-2.795-.834-4.65h-1.5c0 2.011.355 3.868.96 5.25.301.69.68 1.296 1.139 1.74.462.446 1.042.76 1.713.76zM15.188 17c0-1.855.33-3.498.834-4.65.252-.576.533-.996.807-1.26.27-.262.496-.34.671-.34v-1.5c-.671 0-1.251.314-1.713.76-.459.444-.838 1.05-1.14 1.74-.604 1.382-.96 3.239-.96 5.25zm2.312 7.75c.671 0 1.251-.314 1.713-.76.459-.444.838-1.05 1.14-1.74.604-1.382.96-3.239.96-5.25h-1.5c0 1.855-.33 3.498-.835 4.65-.252.576-.533.996-.807 1.26-.27.262-.496.34-.671.34zM21.313 17c0-2.011-.356-3.868-.96-5.25-.302-.69-.681-1.296-1.14-1.74-.462-.446-1.042-.76-1.713-.76v1.5c.175 0 .4.078.67.34.275.264.556.684.808 1.26.504 1.152.834 2.795.834 4.65zm-10.813.75h13.563v-1.5H10.5z"
        fill="var(--primary-color)"
      />
    </svg>
  );
}
