import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchChatsList } from "../api/chat";
import {
  setChatsList,
  bulkUpdate,
  setCurrentConversationId,
} from "../redux/reducers/chat/reducers";
import { initialState } from "../redux/reducers/chat/state";

export const useChatsList = () => {
  const { id } = useSelector((state) => state?.userState?.selectedSchool);
  const { reloadChats, activeConversation } = useSelector(
    (state) => state?.chatStore
  );

  const dispatch = useDispatch();

  const loadChats = async () => {
    try {
      // Set chat state loader to true
      dispatch(
        bulkUpdate({
          ...initialState,
          isChatsLoading: true,
        })
      );

      // Call API
      const response = await fetchChatsList(id);

      if (response?.success) {
        // Update chats in store
        dispatch(
          setChatsList({
            chats: response.data.direct_chats,
          })
        );

        if (response?.data?.direct_chats?.length > 0) {
          // Get persisted active chat conversation id
          const persistedChatConversationID = parseInt(
            localStorage.getItem("INTERSTRIDE_ACTIVE_CHAT_ID") || -1
          );

          if (persistedChatConversationID !== -1) {
            // Check if persisted chat conversation present in chats list from API
            const persistedConversationInChats =
              !!response?.data?.direct_chats?.find(
                (chat) => chat?.conversation_id === persistedChatConversationID
              );
            dispatch(
              setCurrentConversationId(
                persistedConversationInChats
                  ? persistedChatConversationID
                  : response?.data?.direct_chats[0]?.conversation_id
              )
            );
          } else if (activeConversation !== -1) {
            // Check if current active conversation present in chats list from API
            const currentConversationInChats =
              !!response?.data?.direct_chats?.find(
                (chat) => chat?.conversation_id === activeConversation
              );

            // If current conversation not present select the first chat
            dispatch(
              setCurrentConversationId(
                currentConversationInChats
                  ? activeConversation
                  : response?.data?.direct_chats[0]?.conversation_id
              )
            );
          }
        }
      }
      dispatch(
        bulkUpdate({
          isChatsLoaded: true,
          isChatsLoading: false,
          reloadChats: false,
        })
      );
    } catch (error) {
      dispatch(
        bulkUpdate({
          isChatsLoaded: true,
          isChatsLoading: false,
          reloadChats: false,
        })
      );
    }
  };

  useEffect(() => {
    if (id && reloadChats) {
      loadChats();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reloadChats]);
};
