import React, { memo } from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import AmbassadorIllustration from "../../../assets/images/AmbassadorIllustration.svg";
import styles from "./AmbassadorPerspective.module.scss";
import PerspectiveCheckIcon from "../../../assets/Icons/PerspectiveCheckIcon";

const cx = createModuleStyleExtractor(styles);

const AmbassadorPerspective = () => {
  const benefitsList = [
    "Grow your leadership and communication skills",
    "Support, inspire, and connect with international prospective students and applicants",
    "Gain valuable volunteer experience",
  ];

  return (
    <div className={cx("m-ambassador-perspective")}>
      <div className={cx("m-ambassador-perspective__content")}>
        <img src={AmbassadorIllustration} alt="ambassador illustration" />
        <h1>Become a Student or Alumni Ambassador</h1>
        <div className={cx("m-ambassador-perspective__content__middle")}>
          <div
            className={cx("m-ambassador-perspective__content__middle__title")}
          >
            This is your chance to represent your university and make an impact.
            As an international student or alum, you know firsthand what rich
            experiences and opportunities live at the university. By becoming an
            ambassador, you get to:
          </div>
          <div
            className={cx(
              "m-ambassador-perspective__content__middle__benefits"
            )}
          >
            {benefitsList.map((item, index) => (
              <div
                className={cx(
                  "m-ambassador-perspective__content__middle__benefits__item"
                )}
                key={`benefit-${index}`}
              >
                <div
                  className={cx(
                    "m-ambassador-perspective__content__middle__benefits__item__image"
                  )}
                >
                  <PerspectiveCheckIcon />
                </div>
                {item}
              </div>
            ))}
          </div>
        </div>
        <h2>Join your university's ambassador program today.</h2>
      </div>
    </div>
  );
};

export default memo(AmbassadorPerspective);
