import React, { useEffect } from "react";
import { useFormik } from "formik";

import { Modal } from "../../../atoms/Modal/Modal";
import { ModelHeader } from "../../../atoms/Modal/ModelHeader/ModelHeader";
import { ModalBody } from "../../../atoms/Modal/ModalBody/ModalBody";
import { createModuleStyleExtractor } from "../../../../utils/css";
import MuiTextbox from "../../../atoms/MuiTextbox/MuiTextbox";
import { Button, ButtonModifier } from "../../../atoms/Button/Button";
import { updateUserPassword } from "../../../../api/profile";
import toastify from "../../../../utils/toast";
import {
  validatePassword,
  validateString,
} from "../../../../utils/validations";
import styles from "./EditPasswordModal.module.scss";

const cx = createModuleStyleExtractor(styles);

export default function EditPasswordModal({
  showChangePasswordModal,
  setShowChangePasswordModal,
}) {
  // Validation function for profile fields
  const validate = (values) => {
    const errors = {};

    if (validateString(values.password)) {
      errors.password = "Password is required";
    } else if (values.password?.length < 8) {
      errors.password = "Password must have at least 8 characters";
    } else if (validatePassword(values.password)) {
      errors.password =
        "Password should have both upper and lowercase characters";
    }

    if (validateString(values.repeatPassword)) {
      errors.repeatPassword = "Please enter repeat password";
    } else if (values.password !== values.repeatPassword) {
      errors.repeatPassword = "Passwords do not match";
    }

    return errors;
  };

  const handleSave = async (values) => {
    // Call API
    try {
      const response = await updateUserPassword(
        values.password,
        values.repeatPassword
      );
      if (response.success) {
        setShowChangePasswordModal(false);
        toastify(response.message);
      } else {
        toastify(response.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Formik Hook
  const formik = useFormik({
    initialValues: {
      password: "",
      repeatPassword: "",
    },
    validate,
    onSubmit: handleSave,
  });

  // Reset form fields upon modal close
  useEffect(() => {
    if (!showChangePasswordModal) {
      formik.resetForm({ values: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showChangePasswordModal]);

  return (
    <form key="password-form">
      <Modal
        show={showChangePasswordModal}
        onClose={() => setShowChangePasswordModal(false)}
      >
        <ModelHeader title={"Change password"} />
        <ModalBody>
          <div className={cx("o-profile-change-password-modal-body")}>
            <div className={cx("o-profile-change-password-modal-body-input")}>
              <MuiTextbox
                onBlur={formik.handleBlur}
                type="password"
                value={formik.values.password}
                onChange={(e) =>
                  formik.setFieldValue("password", e.target.value)
                }
                name="password"
                label="New password*"
                placeholder={"Enter your new password"}
                error={
                  formik.touched.password && formik.errors.password
                    ? formik.errors.password
                    : ""
                }
              />
            </div>
            <br />
            <div className={cx("o-profile-change-password-modal-body-input")}>
              <MuiTextbox
                onBlur={formik.handleBlur}
                type="password"
                value={formik.values.repeatPassword}
                onChange={(e) =>
                  formik.setFieldValue("repeatPassword", e.target.value)
                }
                name="repeatPassword"
                label="Confirm password*"
                placeholder={"Confirm password"}
                error={
                  formik.touched.repeatPassword && formik.errors.repeatPassword
                    ? formik.errors.repeatPassword
                    : ""
                }
              />
            </div>
            <div
              className={cx(
                "o-profile-change-password-modal-body-submit-button"
              )}
            >
              <Button
                modifier={ButtonModifier.PROFILE_SUBMIT}
                type="button"
                onClick={formik.handleSubmit}
              >
                Apply changes
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </form>
  );
}
