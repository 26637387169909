import React, { useState, memo } from "react";
import { useSelector } from "react-redux";
import { ShimmerSocialPost } from "react-shimmer-effects";
import { ThreeDots } from "react-loader-spinner";
import ReactPlayer from "react-player";
import { TextField } from "@mui/material";
import { useDebouncedCallback } from "use-debounce";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./TopicPosts.module.scss";
import Card, { CardModifier } from "../../atoms/Card/Card";
import { Button, ButtonModifier } from "../../atoms/Button/Button";
import { isNull, validateString } from "../../../utils/validations";
import FileUploadIcon from "../../atoms/FileUploadIcon/FileUploadIcon";
import {
  createOrUpdatePost,
  getTopicPostPreview,
  deleteTopicPost,
  deleteTopicPostComment,
} from "../../../api/topics";
import toastify from "../../../utils/toast";
import SingleTopicPost from "../SingleTopicPost/SingleTopicPost";
import { Modal } from "../../atoms/Modal/Modal";
import { ModelHeader } from "../../atoms/Modal/ModelHeader/ModelHeader";
import { ModalBody } from "../../atoms/Modal/ModalBody/ModalBody";

import EmptyMyTopicsIcon from "../../../assets/Icons/EmptyMyTopicsIcon";

const cx = createModuleStyleExtractor(styles);
const MAX_LENGTH_TOPIC_POST = 1000;

const TopicPosts = ({
  topicDetails = {},
  followingTopics,
  setTopicDetails,
  isCallingTopicDetailsAPI,
}) => {
  const {
    permissions = {},
    isProspect,
    isParent,
    selectedSchool,
  } = useSelector((store) => store.userState);
  const [isSubmittingTopicPost, setIsSubmittingTopicPost] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [description, setDescription] = useState("");
  const [previewUrl, setPreviewUrl] = useState(null);
  const [previewData, setPreviewData] = useState(null);
  const [previewLoader, setPreviewLoader] = useState(false);
  const [deletePostModal, setDeletePostModal] = useState(false);
  const [callingTopicPostDeleteAPI, setCallingTopicPostDeleteAPI] =
    useState(false);
  const [topicPostDeleteId, setTopicPostDeleteId] = useState(null);
  const [deletePostCommentModal, setDeletePostCommentModal] = useState(false);
  const [
    callingTopicPostCommentDeleteAPI,
    setCallingTopicPostCommentDeleteAPI,
  ] = useState(false);
  const [topicPostCommentDeleteId, setTopicPostCommentDeleteId] =
    useState(null);

  const handleTopicTextChange = useDebouncedCallback(
    async (value) => {
      setPreviewLoader(true);
      const urldata = value.match(/\bhttps?:\/\/\S+/gi);
      if (urldata?.length) {
        setPreviewUrl(urldata[0]);
        const response = await getTopicPostPreview(urldata[0]);

        if (response?.success) {
          setPreviewData(JSON.parse(response.data));
          setPreviewLoader(false);
        } else {
          setPreviewUrl(null);
          setPreviewData(null);
          setPreviewLoader(false);
        }
      } else {
        setPreviewUrl(null);
        setPreviewData(null);
        setPreviewLoader(false);
      }
    },
    [3000]
  );

  const handleNewTopicPost = async () => {
    // Call post API
    try {
      setIsSubmittingTopicPost(true);

      let formData = new FormData();
      formData.append("topic_post[topic_id]", topicDetails.topic_info.id);
      formData.append("topic_post[content]", description);
      if (selectedFile && !isNull(selectedFile))
        formData.append("topic_post[image]", selectedFile);
      if (previewUrl && !isNull(previewUrl)) {
        if (previewData?.images[0]?.src) {
          formData.append("topic_post[image_url]", previewData.images[0].src);
          formData.append("topic_post[image_type]", "image");
        } else if (previewData?.videos[0]?.src) {
          formData.append("topic_post[image_url]", previewData.videos[0].src);
          formData.append("topic_post[image_type]", "video");
        }
        formData.append("topic_post[url_title]", previewData.title);
        formData.append("topic_post[url_description]", previewData.description);
      }
      // Need to pass selected school id
      formData.append("school_id", selectedSchool.id);

      const response = await createOrUpdatePost(formData);

      if (response.success) {
        setDescription("");
        setSelectedFile(null);
        setPreviewUrl(null);
        setPreviewData(null);
        toastify(response.message);

        // Update the UI - Without API call
        setTopicDetails((current) => {
          let _topic_posts_details = current.topic_posts_details;

          const new_topic_post = {
            topic_post: response.data.topic,
            topic_post_comments: [],
          };

          if (_topic_posts_details.length === 0) {
            _topic_posts_details.push(new_topic_post);
          } else {
            const array_new_topic_post = new Array(new_topic_post);
            _topic_posts_details =
              array_new_topic_post.concat(_topic_posts_details);
          }

          setIsSubmittingTopicPost(false);

          return {
            ...current,
            topic_posts_details: _topic_posts_details,
          };
        });
      }
    } catch (response) {
      setIsSubmittingTopicPost(false);
      toastify(response.message);
    }
  };

  const handleDeleteTopicPost = async () => {
    // Call API
    try {
      setCallingTopicPostDeleteAPI(true);
      const response = await deleteTopicPost(topicPostDeleteId);
      if (response && response.success) {
        // Close modal
        setDeletePostModal(false);
        // Show toast message
        toastify(response.message);
        // Update the UI - Without API call
        setTopicDetails((current) => {
          const _topic_posts_details = current.topic_posts_details.filter(
            (item) => item.topic_post.id !== topicPostDeleteId
          );
          return {
            ...current,
            topic_posts_details: _topic_posts_details,
          };
        });
      }
      setCallingTopicPostDeleteAPI(false);
    } catch (error) {
      setCallingTopicPostDeleteAPI(false);
      console.error(error);
    }
  };

  const handleDeleteTopicPostComment = async () => {
    // Call API
    try {
      setCallingTopicPostCommentDeleteAPI(true);
      const response = await deleteTopicPostComment(
        topicPostCommentDeleteId.id
      );
      if (response && response.success) {
        // Close modal
        setDeletePostCommentModal(false);
        // Show toast message
        toastify(response.message);
        // Update the UI - Without API call
        setTopicDetails((current) => {
          let index = current.topic_posts_details.findIndex(
            (item) =>
              item.topic_post.id === topicPostCommentDeleteId.topicPostId
          );
          current.topic_posts_details[index] = {
            ...current.topic_posts_details[index],
            topic_post_comments: current.topic_posts_details[
              index
            ].topic_post_comments.filter(
              (comment) => comment.id !== topicPostCommentDeleteId.id
            ),
          };
          return {
            ...current,
          };
        });
      }
      setCallingTopicPostCommentDeleteAPI(false);
    } catch (error) {
      setCallingTopicPostCommentDeleteAPI(false);
      console.error(error);
    }
  };

  const isWidgetUser = isProspect || isParent;

  // Comment permission is only applicable to prospect/parent role
  const allowAddPost =
    !isWidgetUser || (isWidgetUser && permissions["topic_posts"]);

  const handleCreatePostChange = (text = "") => {
    if (!text) {
      setPreviewData(null);
    }
    setDescription(text);
    handleTopicTextChange(text);
  };

  return (
    <>
      {allowAddPost && (
        <Card modifier={CardModifier.CREATE_TOPIC_POST}>
          <div
            className={cx([
              "m-my-topic-middle-post-wrapper",
              followingTopics.length === 0 &&
                "m-my-topic-middle-post-wrapper-disabled",
            ])}
          >
            <div className={cx("m-my-topic-middle-post-wrapper__text")}>
              <TextField
                variant="standard"
                multiline
                minRows={3}
                maxRows={15}
                placeholder="Post something"
                value={description}
                onChange={(e) => handleCreatePostChange(e.target.value)}
                InputProps={{
                  disableUnderline: true,
                }}
                inputProps={{ maxLength: MAX_LENGTH_TOPIC_POST }}
              />
            </div>

            <div className={cx("m-my-topic-middle-post-controls")}>
              <FileUploadIcon
                setSelectedFile={setSelectedFile}
                selectedFile={selectedFile}
              />
              <Button
                modifier={ButtonModifier.NOTIFICATIONS_DRAWER_VIEW}
                disabled={isSubmittingTopicPost || validateString(description)}
                onClick={handleNewTopicPost}
              >
                {isSubmittingTopicPost ? (
                  <ThreeDots color="#ffffff" height={25} width={25} />
                ) : (
                  <>Send</>
                )}
              </Button>
            </div>
          </div>
          {previewLoader && <div class="border-loader" />}
          {previewUrl && previewUrl !== null && previewData && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={previewData && previewData.url}
              className="preview-url-post"
            >
              <div className="preview-nbox-wrappe">
                {previewData && previewData.title && (
                  <h3>{previewData && previewData.title}</h3>
                )}
                {previewData && previewData.description && (
                  <h4>{previewData && previewData.description}</h4>
                )}
                {previewData &&
                previewData.videos &&
                previewData.videos.length ? (
                  <div className="topic-preview-video-section">
                    <ReactPlayer
                      url={previewData.videos[0].src}
                      controls={true}
                      playing={true}
                    />
                  </div>
                ) : (
                  <>
                    {previewData &&
                    previewData.images &&
                    previewData.images.length ? (
                      <div className="img-wrap title-align">
                        <img
                          src={
                            previewData &&
                            previewData.images &&
                            previewData.images[0].src
                          }
                          alt="Community post preview"
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            </a>
          )}
        </Card>
      )}

      {followingTopics.length === 0 ? (
        <></>
      ) : topicDetails === null || isCallingTopicDetailsAPI ? (
        <>
          <ShimmerSocialPost type="image" title />
          <ShimmerSocialPost type="image" title />
        </>
      ) : topicDetails?.topic_posts_details.length === 0 ? (
        <div className={cx("m-my-topic-middle-no-post")}>
          <EmptyMyTopicsIcon />
          <h2>Looks like no one has posted anything here yet.</h2>
          <h3>Be the first to post on this Community!</h3>
        </div>
      ) : (
        topicDetails.topic_posts_details.map((post, index) => (
          <SingleTopicPost
            key={`topic-post-${index}`}
            index={index}
            post={post}
            setTopicDetails={setTopicDetails}
            setDeletePostModal={setDeletePostModal}
            setTopicPostDeleteId={setTopicPostDeleteId}
            setDeletePostCommentModal={setDeletePostCommentModal}
            setTopicPostCommentDeleteId={setTopicPostCommentDeleteId}
          />
        ))
      )}
      {/* Delete topic post modal */}
      <Modal
        show={deletePostModal}
        marginTop={200}
        onClose={() => {
          setDeletePostModal(false);
          setTopicPostDeleteId(null);
        }}
      >
        <ModelHeader title="Are you sure you want to delete this post?" />
        <ModalBody>
          <div className={cx("delete-post-modal")}>
            <div className={cx("delete-post-modal__submit-button")}>
              <Button
                onClick={handleDeleteTopicPost}
                modifier={ButtonModifier.PROFILE_SUBMIT}
                isSubmitting={callingTopicPostDeleteAPI}
              >
                Yes
              </Button>
            </div>
            <span
              className={cx("delete-post-modal__cancel-link")}
              onClick={() => {
                setDeletePostModal(false);
                setTopicPostDeleteId(null);
              }}
            >
              Cancel
            </span>
          </div>
        </ModalBody>
      </Modal>
      {/* Delete topic post comment modal */}
      <Modal
        show={deletePostCommentModal}
        marginTop={200}
        onClose={() => {
          setDeletePostCommentModal(false);
          setTopicPostCommentDeleteId(null);
        }}
      >
        <ModelHeader title="Are you sure you want to delete your comment?" />
        <ModalBody>
          <div className={cx("delete-post-modal")}>
            <div className={cx("delete-post-modal__submit-button")}>
              <Button
                onClick={handleDeleteTopicPostComment}
                modifier={ButtonModifier.PROFILE_SUBMIT}
                isSubmitting={callingTopicPostCommentDeleteAPI}
              >
                Yes
              </Button>
            </div>
            <span
              className={cx("delete-post-modal__cancel-link")}
              onClick={() => {
                setDeletePostCommentModal(false);
                setTopicPostCommentDeleteId(null);
              }}
            >
              Cancel
            </span>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default memo(TopicPosts);
