import React, { useState, useEffect, useRef, memo } from "react";
import { useSelector } from "react-redux";
import Chart from "react-google-charts";

import { DEFAULT_PRIMARY_COLOR, GOOGLE_MAP_KEY } from "../../../config/config";
import { isEmpty } from "../../../utils/validations";

const MarkerWorldMap = ({
  height = 600,
  valueType = "count", // "list "| "count"
  data = [],
  categoryLabel = "",
  valueLabel = "",
  onClick,
  displayModeOption = "markers", // "regions" | "markers" | "text"
}) => {
  const preservedState = useSelector((state) => state.preservedState);
  const { themeColor = DEFAULT_PRIMARY_COLOR } = preservedState ?? {};

  const mapDataRef = useRef();
  const [mapData, setMapData] = useState([]);

  useEffect(() => {
    mapDataRef.current = mapData;
  }, [mapData]);

  useEffect(() => {
    if (data && !isEmpty(data)) {
      const labels = Object.keys(data);
      let fomattedData = [
        [
          categoryLabel,
          valueLabel,
          { type: "string", role: "tooltip", p: { html: true } },
        ],
      ];
      labels.forEach((key) => {
        // Extract number of users
        const value = valueType === "list" ? data[key]?.length : data[key];
        // Extract all user images, create html, and serialize them to string
        let imagesHtmlString = "";
        if (data[key]?.length > 0) {
          imagesHtmlString += `<div style="display:flex;gap:5px;margin-top:6px;">`;
          data[key]?.forEach((user) => {
            if (user?.user_profile?.length > 0) {
              imagesHtmlString += `<img src=${user?.user_profile} alt="User" style="width:32px;height:32px;border-radius:100%;"}/>`;
            }
          });
          imagesHtmlString += "</div>";
        }
        // Append data
        fomattedData.push([
          key,
          value,
          `<div style="font-weight:500;width:145px;overflow:hidden;">${value} ${valueLabel}${
            value > 1 && valueLabel !== "Alumni" ? "s" : ""
          }${imagesHtmlString}</div>`,
        ]);
      });
      setMapData([...fomattedData]);
    } else setMapData([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleMarkerClick = (selectedId) => {
    if (!mapDataRef.current || isEmpty(mapDataRef.current)) return;
    if (selectedId?.length) {
      const selectedRecord = mapDataRef?.current?.[selectedId[0].row + 1];
      if (selectedRecord?.[0]) onClick(selectedRecord?.[0]);
    }
  };

  return (
    <div className="a-marker-world-map">
      <div className="chart-wrapper">
        <Chart
          chartType="GeoChart"
          width="100%"
          height={`${height}px`}
          data={mapData}
          options={{
            colorAxis: {
              colors: [themeColor, themeColor, themeColor],
            },
            backgroundColor: "#FFF",
            datalessRegionColor: "#D1DDE5",
            defaultColor: "#D1DDE5",
            legend: false,
            displayMode: displayModeOption,
            tooltip: {
              isHtml: true,
              textStyle: {
                color: themeColor,
                fontName: "TTCommons-Regular",
                fontSize: 18,
                fontWeight: 500,
              },
            },
          }}
          mapsApiKey={GOOGLE_MAP_KEY}
          chartEvents={[
            {
              eventName: "select",
              callback({ chartWrapper }) {
                if (onClick)
                  handleMarkerClick(chartWrapper?.getChart()?.getSelection());
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

export default memo(MarkerWorldMap);
