import React from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./CalculatorInfo.module.scss";
import Card from "../../atoms/Card/Card";
import InfoIconPopup from "../../atoms/InfoIconPopup/InfoIconPopup";
import CalculatorInfoCard from "../CalculatorInfoCard/CalculatorInfoCard";

const cx = createModuleStyleExtractor(styles);

export default function CalculatorInfo({ scholarships = [] }) {
  return (
    <div className={cx("m-calculator-info")}>
      <div className={cx("m-calculator-info__funding")}>
        <h2>
          Data on institutional funding available{" "}
          <InfoIconPopup
            margin={"0"}
            popoverContent={<>Data on institutional funding available</>}
          />
        </h2>
        <Card>
          <div className={cx("m-calculator-info__funding__content")}>
            <h3>Need-based aid</h3>
            <p>Percent of international students receiving aid</p>
            <div className={cx("m-calculator-info__funding__content--tag")}>
              <span>24%</span>
            </div>
            <p>Average amount awarded</p>
            <div className={cx("m-calculator-info__funding__content--tag")}>
              <span>$12,400</span>
            </div>
            <h3>Merit-based aid</h3>
            <p>Unavailable</p>
          </div>
        </Card>
      </div>

      {scholarships.length > 0 ? (
        <div className={cx("m-calculator-info__scholarships")}>
          <h2>
            Scholarships and financial aid{" "}
            <InfoIconPopup
              margin={"0px 0px 0px 5px"}
              popoverContent={<>Scholarships and financial aid</>}
            />
          </h2>
          {scholarships.map((data) => (
            <CalculatorInfoCard data={data} />
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
