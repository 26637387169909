import React, { memo } from "react";
import { Link } from "react-router-dom";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./HeaderLogos.module.scss";
import { ROUTES } from "../../../routes";
import NoImageIcon from "../../../assets/Icons/NoImageIcon";
import { INTERSTRIDE_LOGO_URL } from "../../../utils/constants";

const cx = createModuleStyleExtractor(styles);

const HeaderLogos = ({ selectedSchool = {}, onClick = () => {} }) => (
  <div className={cx("a-header-logos")}>
    <Link to={ROUTES.DASHBOARD} onClick={onClick}>
      {selectedSchool && selectedSchool.logo_url ? (
        <img src={selectedSchool.logo_url} alt="University logo" />
      ) : (
        <NoImageIcon />
      )}
    </Link>
    <Link to={ROUTES.DASHBOARD} onClick={onClick}>
      <img src={INTERSTRIDE_LOGO_URL} alt="interstride logo" />
    </Link>
  </div>
);

export default memo(HeaderLogos);
