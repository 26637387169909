import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchConversation } from "../api/chat";
import { setConversationInformation } from "../redux/reducers/chat/reducers";
import { getConversationByConversationID } from "../redux/reducers/chat/selectors";
import { scrollToActiveConversation } from "../utils/common";

export const useFetchConversation = () => {
  const dispatch = useDispatch();
  const chatStore = useSelector((state) => state.chatStore);
  const {
    activeConversation,
    currentSearchMessageItem,
    reloadChats,
    freshChat,
  } = chatStore || {};
  const conversation = useSelector((state) =>
    getConversationByConversationID(state.chatStore, activeConversation)
  );
  const fresh = !conversation ? true : false;
  const [loading, setIsLoading] = useState(false);

  ///// in order to avoid same api call for same conversation consectivly
  const [apiCache, setApiCache] = useState({});

  const loadConversations = async () => {
    setIsLoading(true);
    try {
      const response = await fetchConversation(activeConversation);

      if (response?.success) {
        dispatch(
          setConversationInformation({
            conversation_id: activeConversation,
            conversation: response.data,
            replace: true,
          })
        );

        scrollToActiveConversation(activeConversation);

        setApiCache({
          ...apiCache,
          [activeConversation]: false,
        });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    // Monitor chat use case
    if (reloadChats) {
      (async () => {
        await loadConversations();
      })();
      return;
    }
    if (
      activeConversation === -1 ||
      activeConversation === currentSearchMessageItem?.conversation_id
    ) {
      return;
    }

    if (freshChat.includes(activeConversation)) {
      return;
    }
    if (apiCache[activeConversation]) {
      return;
    }

    (async () => {
      await loadConversations();
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeConversation, reloadChats]);

  return {
    loading,
    conversation,
    fresh,
  };
};
