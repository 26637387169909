import React, { memo, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Tooltip } from "@mui/material";
import { createModuleStyleExtractor } from "../../../utils/css";
import { Button, ButtonModifier } from "../Button";
import { CircularAvatar } from "../CircularAvatar/CircularAvatar";
import {
  createFreshChatRecord,
  setChatsList,
  setConversationInformation,
  setCurrentConversationId,
} from "../../../redux/reducers/chat/reducers";
import {
  createFreshChatPreview,
  extractFirstName,
  getCapitalizedHeader,
} from "../../../utils/common";
import styles from "./DiscoverNetworkCard.module.scss";
import { isEmpty, validateAttribute } from "../../../utils/validations";
import { ROUTES } from "../../../routes";
import { Mixpanel } from "../../../MixPanel/mixpanel";

import AccordionArrow from "../../../assets/Icons/AccordionArrow";
import arrowRight from "../../../assets/svg/arrow-right-network-card.svg";

const MAX_VISIBLE_CHARS = 88;
const cx = createModuleStyleExtractor(styles);

const DiscoverNetworkCard = ({
  user,
  selectedSchoolId,
  handleMeeting,
  index = null,
  variant = "chat", // possible values => [chat, widget]
  handleViewDetailsClick = () => {},
}) => {
  const [open, setOpen] = useState(false);

  const { permissions = {} } = useSelector((state) => state.userState);
  const {
    country_image,
    country_of_origin,
    user_profile,
    intro,
    user_type,
    major,
    graduation_year,
    degree,
    school_name,
    conversation_id,
    id,
    last_active_time,
    request_meeting,
    full_name,
  } = user;

  const processed_name = useMemo(() => {
    if (user_type === "Alumni" || user_type === "Student") {
      return getCapitalizedHeader(extractFirstName(full_name));
    } else {
      const names = full_name.split(" ");
      return (
        getCapitalizedHeader(names[0]) +
        " " +
        getCapitalizedHeader(names[1]?.slice(0, 1)) +
        "."
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [full_name]);

  // Student Staff Alumni
  const chatStore = useSelector((store) => store.chatStore);
  const { chats = [] } = chatStore || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    setOpen((current) => !current);
  };

  const handleOnClickMeeting = (e) => {
    handleMeeting(user);
  };
  const handleOnClickMessage = () => {
    // Check if conversation already present with selected user
    const conversationCheckIndex =
      chats && !isEmpty(chats)
        ? chats.findIndex((item) => item.receiver_id === id)
        : -1;
    if (conversationCheckIndex !== -1) {
      const conversationId =
        chatStore.chats[conversationCheckIndex].conversation_id;
      Mixpanel.track("Chat initiated", {
        schoolID: selectedSchoolId,
        objId: id,
        objClass: "User",
      });
      dispatch(setCurrentConversationId(conversationId));
      navigate(ROUTES.MESSAGES);
      return;
    }

    if (!conversation_id) {
      const randomConversationId = new Date().getTime();
      const { preview, conversation } = createFreshChatPreview(
        user,
        randomConversationId
      );
      Mixpanel.track("Chat initiated", {
        schoolID: selectedSchoolId,
        objId: id,
        objClass: "User",
      });
      dispatch(setChatsList({ chats: [preview, ...(chats || [])] }));
      dispatch(
        setConversationInformation({
          conversation,
          conversation_id: randomConversationId,
        })
      );
      dispatch(setCurrentConversationId(randomConversationId));
      dispatch(createFreshChatRecord({ id: randomConversationId }));
      navigate(ROUTES.MESSAGES);
    }
  };

  useEffect(() => {
    if (index === 0) {
      setOpen(true);
    }
  }, [index]);

  const studentAlumniDesc = `I ${
    user_type === "Alumni" ? "have completed" : "am currently pursuing my"
  } ${degree} in ${major} at ${school_name}`;

  return (
    <div
      className={cx([
        "a-discover-network-card",
        open
          ? "a-discover-network-card__open"
          : "a-discover-network-card__close",
        variant === "widget" ? "a-discover-network-card--widget" : "",
      ])}
    >
      <div className={cx("a-discover-network-card__profile")}>
        <CircularAvatar
          src={user_profile}
          name={processed_name}
          round={true}
          size={73}
          wrapperClass={cx("a-discover-network-card__profile__pic")}
        />
        <div className={cx("a-discover-network-card__profile__info")}>
          <div
            className={cx("a-discover-network-card__profile__info__line_one")}
          >
            <span>{processed_name}</span>
            <span>{user_type}</span>
          </div>
          <div
            className={cx("a-discover-network-card__profile__info__line_two")}
          >
            {user_type === "Staff" ? (
              <span>Admissions Team</span>
            ) : major && graduation_year && major.length > 21 ? (
              <Tooltip
                title={major + ", " + graduation_year}
                arrow
                placement="bottom-start"
                PopperProps={{
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -6],
                      },
                    },
                  ],
                }}
                enterTouchDelay={0}
              >
                <span>{major + ", " + graduation_year}</span>
              </Tooltip>
            ) : major && graduation_year && major.length <= 21 ? (
              <span>{major + ", " + graduation_year}</span>
            ) : (
              <span></span>
            )}
          </div>
          {country_image && country_of_origin && (
            <div
              className={cx(
                "a-discover-network-card__profile__info__line_three"
              )}
            >
              <img src={country_image} alt="flag" />
              <span>From {country_of_origin}</span>
            </div>
          )}
        </div>
      </div>
      <div
        className={cx([
          "a-discover-network-card__body",
          open
            ? "a-discover-network-card__body__open"
            : "a-discover-network-card__body__close",
        ])}
      >
        <div className={cx("a-discover-network-card__body__intro")}>
          {validateAttribute(intro) ? (
            <p>{intro}</p>
          ) : (user_type === "Student" || user_type === "Alumni") &&
            validateAttribute(major) &&
            validateAttribute(degree) &&
            validateAttribute(school_name) ? (
            <p>{studentAlumniDesc}</p>
          ) : user_type === "Staff" ? (
            <p>University Admin at {school_name}</p>
          ) : (
            <p> </p>
          )}

          <div className={cx("a-discover-network-card__body__intro__icon")}>
            {validateAttribute(intro) ? (
              <>
                {intro?.length > MAX_VISIBLE_CHARS ? (
                  <AccordionArrow
                    color="var(--primary-color)"
                    onClick={handleClick}
                  />
                ) : (
                  <></>
                )}
              </>
            ) : (
              (user_type === "Student" || user_type === "Alumni") &&
              validateAttribute(major) &&
              validateAttribute(degree) &&
              validateAttribute(school_name) && (
                <>
                  {studentAlumniDesc?.length > MAX_VISIBLE_CHARS ? (
                    <AccordionArrow
                      color="var(--primary-color)"
                      onClick={handleClick}
                    />
                  ) : (
                    <></>
                  )}
                </>
              )
            )}
          </div>
        </div>

        {last_active_time && (
          <div className={cx("a-discover-network-card__body__active-text")}>
            <div
              className={cx(
                "a-discover-network-card__body__active-text__bullet"
              )}
            />
            {last_active_time}
          </div>
        )}
      </div>
      <div className={cx("a-discover-network-card__bottom")}>
        {variant === "widget" ? (
          <div
            className={cx("a-discover-network-card__bottom__view-details")}
            onClick={() => handleViewDetailsClick(user.id)}
          >
            <span>View full profile</span>
            <img src={arrowRight} alt="arrow" />
          </div>
        ) : (
          <div
            className={cx([
              "a-discover-network-card__bottom__buttons",
              permissions["request_meeting"]
                ? ""
                : "a-discover-network-card__bottom__buttons--center",
            ])}
          >
            <Button
              modifier={ButtonModifier.NEW_CHAT_BUTTON_FROM_CARD}
              onClick={handleOnClickMessage}
            >
              Message
            </Button>

            {permissions["request_meeting"] && request_meeting && (
              <p
                className={cx(
                  "a-discover-network-card__bottom__buttons__request-meeting"
                )}
                onClick={handleOnClickMeeting}
              >
                Request a meeting
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default memo(DiscoverNetworkCard);
