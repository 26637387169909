import React, { memo } from "react";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./InterstridePageLoader.module.scss";
import { INTERSTRIDE_LOGO_URL } from "../../../utils/constants";

const cx = createModuleStyleExtractor(styles);

const InterstridePageLoader = () => {
  return (
    <div className={cx("a-interstride-page-loader")}>
      <div>
        <img src={INTERSTRIDE_LOGO_URL} alt="interstride logo" />
        <div className={cx("lds-ellipsis")}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};
export default memo(InterstridePageLoader);
