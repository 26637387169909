import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import Picker from "emoji-picker-react";
import {
  ClickAwayListener,
  Radio,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import { useDebouncedCallback } from "use-debounce";
import { CheckCircle } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { CircleSpinner } from "react-spinners-kit";

import { isNull } from "../../../../utils/validations";
import Card from "../../../atoms/Card/Card";
import toastify from "../../../../utils/toast";
import { Button } from "../../../atoms/Button";
import {
  createNewPostAPI,
  getTopicPreviewAPI,
} from "../../../../api/community";
import { setPreApiCallSavedTopic } from "../../../../redux/reducers/communityReducer";
import { createModuleStyleExtractor } from "../../../../utils/css";
import styles from "./CreatePost.module.scss";

import DeleteIcon from "../../../../assets/svg/f-delete-icon.svg";
import EmojiIcon from "../../../../assets/svg/community-emoji-icon.svg";
import AttachmentIcon from "../../../../assets/svg/community-attachment.svg";

const MAX_LENGTH_TOPIC_POST = 1000;
const cx = createModuleStyleExtractor(styles);

export default function CreatePost({
  editTopicPostId = null,
  handleCloseTopicEditModal = () => {},
  callGetTopicDetailsAPI = () => {},
}) {
  const [description, setDescription] = useState("");
  const [previewUrl, setPreviewUrl] = useState(null);
  const [previewData, setPreviewData] = useState(null);
  const [postFile, setFiles] = useState(null);
  const [previewLoader, setPreviewLoader] = useState(false);
  const [emojiContainer, setEmojiContainer] = useState(false);
  const [privatePost, setPrivatePost] = useState(false);

  const { activeTopicId, topicPosts, activeTopic } = useSelector(
    (state) => state.communityState
  );
  const { selectedSchool = {} } = useSelector((state) => state.userState);
  const selectedSchoolId = selectedSchool?.id || null;
  const dispatch = useDispatch();

  const selectedInputFile = useRef(null);

  const debouncedURLPreviewGenerate = useDebouncedCallback(
    async (text = "") => {
      setPreviewLoader(true);
      const urldata = text.match(/\bhttps?:\/\/\S+/gi);
      if (urldata?.length) {
        setPreviewUrl(urldata[0]);
        const response = await getTopicPreviewAPI(urldata[0]);
        if (response?.success) {
          setPreviewData(JSON.parse(response?.data));
          setPreviewLoader(false);
        } else {
          setPreviewUrl(null);
          setPreviewData(null);
          setPreviewLoader(false);
        }
      } else {
        setPreviewUrl(null);
        setPreviewData(null);
        setPreviewLoader(false);
      }
    },
    3000
  );

  const handleChange = async (text = "") => {
    setDescription(text);
    debouncedURLPreviewGenerate(text);
  };

  const handleCallback = (e = {}) => {
    const file = e.target.files[0];

    if (["image/png", "image/jpg", "image/jpeg"].includes(file.type)) {
      if (file.size <= 50000000) {
        setFiles(file);
      } else {
        toastify("We can't attach file > 50MB.", "error");
      }
    } else {
      toastify("Please select a valid image. (png, jpg, or jpeg)", "error");
    }
  };

  const createUpdatePost = async () => {
    let formData = new FormData();
    formData.append("topic_post[topic_id]", activeTopic.id);
    formData.append("topic_post[content]", description);
    if (postFile?.type) {
      formData.append("topic_post[image]", postFile);
    } else if (!postFile?.type && !postFile?.name && editTopicPostId) {
      formData.append("topic_post[topic_post_image_delete]", true);
    }
    if (previewUrl && !isNull(previewUrl)) {
      if (previewData?.images[0]?.src) {
        formData.append("topic_post[image_url]", previewData.images[0].src);
        formData.append("topic_post[image_type]", "image");
      } else if (previewData?.videos[0]?.src) {
        formData.append("topic_post[image_url]", previewData.videos[0].src);
        formData.append("topic_post[image_type]", "video");
      }
      formData.append("topic_post[url_title]", previewData.title);
      formData.append("topic_post[url_description]", previewData.description);
    }
    editTopicPostId &&
      formData.append("topic_post[topic_post_id]", editTopicPostId);
    formData.append("topic_post[anonymously]", privatePost);
    formData.append("topic_post[school_id]", selectedSchoolId);

    const response = await createNewPostAPI(formData);
    if (response.success && response?.data) {
      dispatch(setPreApiCallSavedTopic("topic"));
      callGetTopicDetailsAPI(activeTopicId);
      editTopicPostId && handleCloseTopicEditModal();
      setDescription("");
      setFiles(null);
      selectedInputFile.current.value = ""; // Clear Input
      setPreviewUrl(null);
      toastify(response?.message);
    } else {
      toastify(response?.message, "error");
    }
  };

  useEffect(() => {
    if (editTopicPostId) {
      const topic = topicPosts.posts.filter(
        (post) => post.id === editTopicPostId
      )[0];
      if (topic) {
        handleChange(topic?.content);
        topic?.image_name &&
          setFiles({
            name: topic?.image_name,
          });
        setPrivatePost(topic?.anonymously === true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editTopicPostId]);

  const removeUploadFile = () => {
    setFiles(null);
    selectedInputFile.current.value = ""; // Clear Input
  };

  const onEmojiClick = (emojiObject = {}) => {
    if (emojiObject?.emoji)
      setDescription((current) => current + emojiObject.emoji);
    setEmojiContainer(false);
  };

  const handleChangePrivatePost = (event) => {
    setPrivatePost(event.target.checked);
  };

  return (
    <Card>
      <div className={cx("create-post")}>
        <div className={cx("create-post__content")}>
          <div className={cx("create-post__content__text-area")}>
            <TextareaAutosize
              maxRows={4}
              placeholder="Ask a question, share a resource, or just say hi"
              value={description}
              onChange={(e) => handleChange(e.target.value)}
              maxLength={MAX_LENGTH_TOPIC_POST}
            />
          </div>
          <div className={cx("create-post__content__post-btn")}>
            <div className={cx("create-post__content__post-btn__left")}>
              {!previewData && (
                <>
                  <div
                    className={cx(
                      "create-post__content__post-btn__left__emoji"
                    )}
                  >
                    <img
                      src={EmojiIcon}
                      alt="emoji"
                      onClick={() => setEmojiContainer((current) => !current)}
                      className={cx(
                        "create-post__content__post-btn__left__emoji__icon"
                      )}
                    />
                    {emojiContainer && (
                      <ClickAwayListener
                        onClickAway={() => setEmojiContainer(false)}
                      >
                        <div
                          className={cx(
                            "create-post__content__post-btn__left__emoji__picker"
                          )}
                        >
                          <Picker
                            onEmojiClick={onEmojiClick}
                            skinTonesDisabled
                            width="100%"
                          />
                        </div>
                      </ClickAwayListener>
                    )}
                  </div>
                  <div
                    className={cx(
                      "create-post__content__post-btn__left__attachment"
                    )}
                  >
                    <input
                      id="_upload-file_"
                      type="file"
                      ref={selectedInputFile}
                      onChange={handleCallback}
                      className={cx(
                        "create-post__content__post-btn__left__attachment__hidden-input"
                      )}
                    />
                    <img
                      src={AttachmentIcon}
                      alt="attachment"
                      onClick={() =>
                        selectedInputFile?.current?.click &&
                        selectedInputFile.current.click()
                      }
                      className={cx(
                        "create-post__content__post-btn__left__attachment__add-icon"
                      )}
                    />
                    {postFile && (
                      <div
                        className={cx(
                          "create-post__content__post-btn__left__attachment__file"
                        )}
                      >
                        <span
                          className={cx(
                            "create-post__content__post-btn__left__attachment__file__icon"
                          )}
                          onClick={removeUploadFile}
                        >
                          <img src={DeleteIcon} alt="Delete Icon" />
                        </span>
                        <Tooltip title={postFile?.name}>
                          <span
                            className={cx(
                              "create-post__content__post-btn__left__attachment__file__name"
                            )}
                          >
                            {postFile?.name}
                          </span>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className={cx("create-post__content__post-btn__right")}>
              <div
                className={cx(
                  "create-post__content__post-btn__right__checkbox"
                )}
              >
                <Radio
                  type="checkbox"
                  checkedIcon={<CheckCircle fontSize="small" />}
                  color="primary"
                  size="small"
                  checked={privatePost}
                  onChange={handleChangePrivatePost}
                />
                <span
                  className={cx(
                    "create-post__content__post-btn__right__checkbox__text"
                  )}
                >
                  Post anonymously
                </span>
              </div>
              <Button
                disabled={description.trim() === ""}
                width="100"
                height="30"
                onClick={createUpdatePost}
              >
                Post
              </Button>
            </div>
          </div>
        </div>
        {previewLoader && (
          <div className={cx("create-post__preview-loader")}>
            <CircleSpinner
              color={
                getComputedStyle(
                  document?.getElementById("root")
                )?.getPropertyValue("--primary-color") || "#000c3d"
              }
              size={25}
            />
          </div>
        )}
        {previewUrl && !isNull(previewUrl) && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={previewData && previewData.url}
            className={cx("create-post__preview-url")}
            aria-label="Preview Box"
          >
            <div className={cx("create-post__preview-url__n-box-wrapper")}>
              {previewData && previewData.title && (
                <h3>{previewData && previewData.title}</h3>
              )}
              {previewData && previewData.description && (
                <h4>{previewData && previewData.description}</h4>
              )}
              {previewData &&
              previewData.videos &&
              previewData.videos.length ? (
                <div
                  className={cx(
                    "create-post__preview-url__n-box-wrapper__video"
                  )}
                >
                  <ReactPlayer
                    url={previewData.videos[0].src}
                    controls={true}
                    playing={true}
                    width={"auto"}
                  />
                </div>
              ) : (
                <>
                  {previewData &&
                  previewData.images &&
                  previewData.images.length ? (
                    <div
                      className={cx(
                        "create-post__preview-url__n-box-wrapper__image"
                      )}
                    >
                      <img
                        src={
                          previewData &&
                          previewData.images &&
                          previewData.images[0].src
                        }
                        alt="Preview Images"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          </a>
        )}
      </div>
    </Card>
  );
}
