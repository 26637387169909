import { ApiWrapper, REQUEST } from "./index";

export const getCategories = async (
  school_id = null,
  page = 1,
  per_page = 20
) => {
  return await ApiWrapper({
    url: "place_categories",
    method: REQUEST.GET,
    config: {
      params: {
        school_id,
        page,
        per_page,
      },
    },
  });
};

export const getCategoriesData = async (
  place_category_id = null,
  school_id = null
) => {
  return await ApiWrapper({
    url: "places",
    method: REQUEST.GET,
    config: {
      params: {
        ...(place_category_id ? { place_category_id } : {}),
        ...(school_id ? { school_id } : {}),
      },
    },
  });
};
