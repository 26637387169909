import React, { useEffect } from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";

import HorizontalBarChart from "../../atoms/HorizontalBarChart/HorizontalBarChart";
import MuiSelect from "../../atoms/MuiSelect/MuiSelect";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./TopEmployerGraph.module.scss";
import Card from "../../atoms/Card/Card";
import { getGraphData } from "../../../api/employmentDataHub";
import { initialStateTopEmployerGraph } from "../../organisms/EmploymentDataHub/EmploymentDataHubContants";

import NoDataImage from "../../../assets/images/chart_placeholder.png";
import FilterIcon from "../../../assets/Icons/FilterIcon";

const cx = createModuleStyleExtractor(styles);
const seedGraphWrapperHeight = 369;

export default function TopEmployerGraph({
  filters = {},
  state = {
    ...initialStateTopEmployerGraph,
  },
  setState,
  openFilter,
  toggleFilter,
  schoolId = null,
  processGraphClick,
}) {
  // Calls API upon component and/or selected school change
  useEffect(() => {
    (async () => {
      const response = await getGraphData(
        schoolId,
        "employer",
        state?.selectedFilters?.major?.value,
        state?.selectedFilters?.year?.value,
        state?.selectedFilters?.nationality?.value,
        state?.selectedFilters?.degree?.value,
        state?.selectedFilters?.employment_type?.value,
        state?.selectedFilters?.country?.value
      );

      if (response?.success && response?.data?.length > 0) {
        // Process data
        let _labels = [];
        let _data = [];
        let _graphHeight = seedGraphWrapperHeight;
        response?.data?.forEach((item, index) => {
          _labels.push(item?.company);
          _data?.push(item?.count);
          // Increase graph height per record dynamically if more than 10 records
          if (index > 9) _graphHeight += 45; // Pixels
        });
        setState((current) => ({
          ...current,
          loader: false,
          graphHeight: _graphHeight,
          data_set: {
            labels: _labels,
            data: _data,
          },
        }));
        return;
      }
      setState((current) => ({
        ...current,
        loader: false,
        graphHeight: seedGraphWrapperHeight,
        data_set: {
          labels: null,
          data: null,
        },
      }));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolId, state?.selectedFilters]);

  const handleClearAllFilter = () => {
    setState((current) => ({
      ...current,
      selectedFilters: initialStateTopEmployerGraph.selectedFilters,
    }));
  };

  //  CPT, OPT and STEM OPT are only relevant for US
  const onEmploymentTypeChange = (option) => {
    const isUSSpecific =
      option?.value &&
      (option.value === "CPT" ||
        option.value === "OPT" ||
        option.value === "STEM OPT");
    // clearing residue country value conditionally
    setState((current) => ({
      ...current,
      selectedFilters: {
        ...current.selectedFilters,
        country: isUSSpecific ? null : current.selectedFilters.country,
        employment_type: option,
      },
    }));
  };

  return (
    <div className={cx("m-top-emp")}>
      <div
        className={`collapsible-section ${
          openFilter ? "collapsible-section--open" : ""
        }`}
      >
        <div
          className={cx("m-top-emp__header")}
          id={"collapsible-section-header"}
        >
          <h2 className={cx("m-top-emp__header__title")}>
            Top employers for international graduates
          </h2>
          <a
            href="/#"
            onClick={(e) => {
              e?.preventDefault && e.preventDefault();
              toggleFilter(!openFilter);
            }}
            id="collapsible-section-header-icon"
          >
            <FilterIcon fill={openFilter} />
          </a>
          <div
            className={cx("m-top-emp__header__filters")}
            id="collapsible-section-search-filter"
          >
            <MuiSelect
              placeholder="Degree"
              justifyContent
              isClearable={true}
              isDisabled={state.loader}
              options={filters?.degree}
              value={state?.selectedFilters?.degree}
              onChange={(option) =>
                setState((current) => ({
                  ...current,
                  selectedFilters: {
                    ...current.selectedFilters,
                    degree: option,
                  },
                }))
              }
            />
            <MuiSelect
              placeholder="Major"
              justifyContent
              isClearable={true}
              isDisabled={state.loader}
              options={filters?.major}
              value={state?.selectedFilters?.major}
              onChange={(option) =>
                setState((current) => ({
                  ...current,
                  selectedFilters: {
                    ...current.selectedFilters,
                    major: option,
                  },
                }))
              }
            />
            <MuiSelect
              placeholder="Nationality"
              justifyContent
              isClearable={true}
              isDisabled={state.loader}
              options={filters?.nationality}
              value={state?.selectedFilters?.nationality}
              onChange={(option) =>
                setState((current) => ({
                  ...current,
                  selectedFilters: {
                    ...current.selectedFilters,
                    nationality: option,
                  },
                }))
              }
            />
            <MuiSelect
              placeholder="Year"
              justifyContent
              isClearable={true}
              isDisabled={state.loader}
              options={filters?.year}
              value={state?.selectedFilters?.year}
              onChange={(option) =>
                setState((current) => ({
                  ...current,
                  selectedFilters: {
                    ...current.selectedFilters,
                    year: option,
                  },
                }))
              }
            />
            <MuiSelect
              placeholder="Type"
              justifyContent
              isClearable={true}
              isDisabled={state.loader}
              options={filters?.employment_type}
              value={state?.selectedFilters?.employment_type}
              onChange={onEmploymentTypeChange}
            />
            {state?.selectedFilters?.employment_type?.value === "CPT" ||
            state?.selectedFilters?.employment_type?.value === "OPT" ||
            state?.selectedFilters?.employment_type?.value === "STEM OPT" ? (
              <></>
            ) : (
              <MuiSelect
                placeholder="Job Location"
                justifyContent
                isClearable={true}
                isDisabled={state.loader}
                options={filters?.country}
                value={state?.selectedFilters?.country}
                onChange={(option) =>
                  setState((current) => ({
                    ...current,
                    selectedFilters: {
                      ...current.selectedFilters,
                      country: option,
                    },
                  }))
                }
              />
            )}
            {(state?.selectedFilters?.degree !== null ||
              state?.selectedFilters?.major !== null ||
              state?.selectedFilters?.nationality !== null ||
              state?.selectedFilters?.year !== null ||
              state?.selectedFilters?.employment_type !== null ||
              state?.selectedFilters?.country !== null) && (
              <div className={cx("m-top-emp__header__filters__clear")}>
                <span onClick={handleClearAllFilter}>Clear all</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={cx("m-top-emp__body")}>
        <Card>
          <div className={cx("m-top-emp__body__card")}>
            {state.loader ? (
              <div className={cx("m-top-emp__body__card__loader")}>
                <ShimmerThumbnail rounded />
              </div>
            ) : !state.loader &&
              (state.data_set.labels === null ||
                state.data_set.data === null) ? (
              <div className={cx("m-top-emp__body__card__no-data")}>
                <img src={NoDataImage} alt="No Data Available" />
                <h3>No data available</h3>
              </div>
            ) : !state.loader &&
              state.data_set.labels?.length > 0 &&
              state.data_set.data?.length > 0 ? (
              <div className={cx("m-top-emp__body__card__graph")}>
                <div
                  // Dynamically adjust graph height. Helps with scroll
                  style={{ height: state?.graphHeight + "px" }}
                >
                  <HorizontalBarChart
                    labels={state.data_set.labels}
                    data={state.data_set.data}
                    enableCursorPointer
                    onClickHandler={(coordinates) =>
                      processGraphClick(coordinates, "TopEmployer")
                    }
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
}
