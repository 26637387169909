import React, { memo, useState, useEffect } from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./Topics.module.scss";
import Switch from "../../atoms/Switch/Switch";
import DiscoverTopic from "../../molecules/DiscoverTopic/DiscoverTopic";
import MyTopic from "../../molecules/MyTopic.jsx/MyTopic";
import { isNull } from "../../../utils/validations";

const cx = createModuleStyleExtractor(styles);

const Topics = () => {
  const [tab, setTab] = useState("right"); //Options -> left: My Topics and right: Discover
  const [recommendedTopics, setRecommendedTopics] = useState([]);
  const [followingTopics, setFolllowingTopics] = useState([]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("tab") === "explore") {
      setTab("right");
    }
  }, []);

  return (
    <div className={cx("o-topics-container")}>
      <div className={cx("o-topics-header")}>
        <div className={cx("o-topics-header-title")}>Community</div>
        {!isNull(tab) && (
          <div className={cx("o-topics-header-switch")}>
            <Switch
              activeSide={tab}
              setActiveSide={setTab}
              leftTitle="Following"
              rightTitle="Explore"
            />
          </div>
        )}
      </div>
      <div className={cx("o-topics-body")}>
        {tab === "left" ? (
          <MyTopic
            followingTopics={followingTopics}
            setFolllowingTopics={setFolllowingTopics}
            setTab={setTab}
          />
        ) : (
          <DiscoverTopic
            isFollowingTopic={
              followingTopics && followingTopics?.length > 0 ? true : false
            }
            recommendedTopics={recommendedTopics}
            setRecommendedTopics={setRecommendedTopics}
            setFolllowingTopics={setFolllowingTopics}
          />
        )}
      </div>
    </div>
  );
};

export default memo(Topics);
