import React, { memo, useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ShimmerThumbnail } from "react-shimmer-effects";

import ServiceCard from "../ServiceCard/ServiceCard";
import { saveService } from "../../../api/studentServices";
import toastify from "../../../utils/toast";
import styles from "./StudentServicesList.module.scss";
import { createModuleStyleExtractor } from "../../../utils/css";

import NoContentIllustration from "../../../assets/images/discover_no_people.svg";

const cx = createModuleStyleExtractor(styles);

const StudentServicesList = ({
  serviceList = [],
  savedServiceList = [],
  showLoader = false,
  currentPage,
  totalPages,
  refreshSavedServices = () => {},
  fetchMoreData = () => {},
}) => {
  const [favServiceIds, setFavSerciveIds] = useState([]);

  // Tracks saved services list and extracts ids
  useEffect(() => {
    if (savedServiceList.length === 0) {
      setFavSerciveIds([]);
      return;
    } else {
      let _favServiceIds = [];
      savedServiceList.forEach((item) => _favServiceIds.push(item.deal.id));
      setFavSerciveIds(_favServiceIds);
    }
  }, [savedServiceList]);

  // Handler for favorite and un favorite service
  const handleFavoriteClick = async (deal_id, like) => {
    try {
      const response = await saveService(deal_id, like);
      if (response.success) {
        toastify(response.message);
        refreshSavedServices();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={cx("m-service-middle")}>
      {showLoader ? (
        <div className={cx("m-service-list__loader-main")}>
          {[...Array(9)].map((_, index) => (
            <ShimmerThumbnail width={267} height={345} key={index} />
          ))}
        </div>
      ) : (
        <div className={cx("m-service-list__list")}>
          {serviceList && serviceList.length > 0 ? (
            <InfiniteScroll
              dataLength={serviceList.length}
              next={fetchMoreData}
              hasMore={currentPage !== totalPages}
              loader={
                <div className={cx("m-service-list__list__loader-secondary")}>
                  {[...Array(3)].map((_, index) => (
                    <ShimmerThumbnail width={267} height={345} key={index} />
                  ))}
                </div>
              }
              style={{ overflow: "none" }}
            >
              <div className={cx("m-service-list__list__item")}>
                {serviceList.map((data, index) => (
                  <ServiceCard
                    key={index}
                    data={data}
                    isFavourite={favServiceIds.includes(data.id)}
                    onClickStar={handleFavoriteClick}
                  />
                ))}
              </div>
            </InfiniteScroll>
          ) : (
            <div className={cx("m-service-list__list__placeholder")}>
              <img src={NoContentIllustration} alt="no-services" />
              <h2>Looks like there are no services with this search.</h2>
              <h3>Try expanding your search to discover other services!</h3>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default memo(StudentServicesList);
