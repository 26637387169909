import React from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./StarIcon.module.scss";

import Star from "../../../assets/svg/f-saved-star.svg";
import StarBorder from "../../../assets/svg/f-unclicked-normal-start.svg";

const cx = createModuleStyleExtractor(styles);

export default function StarIcon({
  variant = "",
  className = "",
  onClick = () => {},
}) {
  const handleClick = (e = {}) => {
    e.preventDefault && e.preventDefault();
    e.stopPropagation && e.stopPropagation();
    onClick();
  };

  return (
    <div
      className={cx([
        "star",
        variant === "filled" ? "star--filled" : "",
        className,
      ])}
    >
      <img
        onClick={handleClick}
        src={variant === "filled" ? Star : StarBorder}
        alt="Favourite Star"
      />
    </div>
  );
}
