import React, { memo } from "react";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./Link.module.scss";
const cx = createModuleStyleExtractor(styles);

const noop = (e) => {
  e?.preventDefault && e.preventDefault();
};
const Link = ({
  children,
  href = "",
  onClick = noop,
  className = "",
  target,
}) => {
  return (
    <a
      href={href}
      className={cx(className)}
      onClick={href ? () => {} : onClick}
      target={target ? target : "_self"}
    >
      {children}
    </a>
  );
};
export default memo(Link);
