import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  school_id: null,
  costValues: {},
  fundValues: {},
};

export const calculatorReducer = createSlice({
  name: "calculator",
  initialState,
  reducers: {
    setCostValues: (state, action) => {
      const { id, value } = action.payload;
      state.costValues[id] = value;
    },
    setFundValues: (state, action) => {
      const { id, value } = action.payload;
      state.fundValues[id] = value;
    },
    setInitialValues: (state, action) => {
      const {
        school_id = null,
        costValues = {},
        fundValues = {},
      } = action.payload;
      state.school_id = school_id;
      state.costValues = { ...costValues };
      state.fundValues = { ...fundValues };
    },
    resetCalculatorData: () => initialState,
  },
});

export const {
  setCostValues,
  setFundValues,
  setInitialValues,
  resetCalculatorData,
} = calculatorReducer.actions;

export default calculatorReducer.reducer;
