import React, { useState, useEffect } from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import GoogleMap from "../../atoms/GoogleMap/GoogleMap";
import Card from "../../atoms/Card/Card";
import { getCategories, getCategoriesData } from "../../../api/campusMap";
import { createModuleStyleExtractor } from "../../../utils/css";
import { ROUTES } from "../../../routes";
import styles from "./CampusMap.module.scss";
import { US_COORDIANTES } from "../../../utils/constants";

const cx = createModuleStyleExtractor(styles);
const INITIAL_STATE = {
  categories: [],
  loadingCategories: true,
  selectedCategoryId: null,
  selectedCategoryData: null,
  loadingSelectedCategoryData: true,
};

export default function CampusMap() {
  const { selectedSchool = {}, permissions = {} } = useSelector(
    (state) => state.userState
  );
  const navigate = useNavigate();

  const [state, setState] = useState(INITIAL_STATE);

  const fetchCampusMapCategories = async () => {
    setState((current) => ({
      ...current,
      loadingCategories: true,
      loadingSelectedCategoryData: true,
    }));
    const response = await getCategories(selectedSchool?.id);
    if (response.success && response?.data?.place_categories?.length > 0) {
      setState((current) => ({
        ...current,
        categories: response?.data?.place_categories,
        loadingCategories: false,
        selectedCategoryId: response?.data?.place_categories[0]?.id,
      }));
      // Call data on first selected category
      fetchCampusMapCategoriesData(
        response?.data?.place_categories[0]?.id,
        response?.data?.place_categories
      );
    } else {
      setState((current) => ({
        ...current,
        categories: [],
        loadingCategories: false,
        selectedCategoryId: null,
        selectedCategoryData: null,
        loadingSelectedCategoryData: false,
      }));
    }
  };

  const fetchCampusMapCategoriesData = async (
    selectedCategoryId = null,
    categories = []
  ) => {
    setState((current) => ({
      ...current,
      loadingSelectedCategoryData: true,
    }));
    const response = await getCategoriesData(
      selectedCategoryId,
      selectedSchool?.id
    );
    if (response?.success) {
      setState((current) => ({
        ...current,
        selectedCategoryData: {
          pois: response?.data?.places?.map((item) => ({
            key: item?.name,
            location: {
              lat: parseFloat(item?.latitude),
              lng: parseFloat(item?.longitude),
            },
            ...item,
            category: {
              ...response?.data?.place_category,
            },
          })),
          category: categories?.filter(
            (item) => item.id === selectedCategoryId
          )[0],
        },
        loadingSelectedCategoryData: false,
      }));
    } else {
      setState((current) => ({
        ...current,
        selectedCategoryData: null,
        loadingSelectedCategoryData: false,
      }));
    }
  };

  const handleCategoryClick = (id = null) => {
    if (id === state?.selectedCategoryId) return;
    setState((current) => ({
      ...current,
      selectedCategoryId: id,
    }));
    fetchCampusMapCategoriesData(id, state?.categories);
  };

  useEffect(() => {
    // Check permission of module
    if (permissions["campus_map"] && permissions["campus_map_data_uploaded"]) {
      fetchCampusMapCategories();
    } else {
      // Do not allow to access if feature permission is not granted
      setTimeout(() => {
        // Delay added to smoothly tranistion and avoid bug
        navigate(ROUTES.DASHBOARD);
      }, [1000]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSchool, permissions, navigate]);

  return (
    permissions["campus_map"] &&
    permissions["campus_map_data_uploaded"] && (
      <div className={cx("o-campus-map")}>
        <h1 className={cx("o-campus-map__title")}>Campus Map</h1>
        <div className={cx("o-campus-map__body")}>
          <div className={cx("o-campus-map__body__list")}>
            {state?.loadingCategories ? (
              <div className={cx("o-campus-map__body__list__loader")}>
                {[...Array(4)].map((item, index) => (
                  <ShimmerThumbnail
                    key={index}
                    className={cx("o-campus-map__body__list__loader__item")}
                    width={160}
                    height={50}
                    rounded
                  />
                ))}
              </div>
            ) : !state?.loadingCategories && state?.categories?.length > 0 ? (
              <div className={cx("o-campus-map__body__list__categories")}>
                {state?.categories?.map((category, index) => (
                  <div
                    key={index}
                    className={cx([
                      "o-campus-map__body__list__categories__item",
                      category?.id === state?.selectedCategoryId
                        ? "o-campus-map__body__list__categories__item--selected"
                        : "",
                    ])}
                  >
                    <Card onClick={() => handleCategoryClick(category.id)}>
                      <div
                        className={cx(
                          "o-campus-map__body__list__categories__item__body"
                        )}
                      >
                        <img src={category.icon_url} alt="Logo" />
                        <p>{category.name}</p>
                      </div>
                    </Card>
                  </div>
                ))}
              </div>
            ) : (
              <div className={cx("o-campus-map__body__list__no-categories")}>
                <Card>
                  <h3>There aren't any categories.</h3>
                </Card>
              </div>
            )}
          </div>
          <div className={cx("o-campus-map__body__map")}>
            <GoogleMap
              data={state?.selectedCategoryData}
              defaultCenter={US_COORDIANTES}
              defaultZoom={4}
            />
          </div>
        </div>
      </div>
    )
  );
}
