import React, { memo, useState } from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import { Button } from "../../atoms/Button";
import Card from "../../atoms/Card/Card";
import styles from "./LeaveFeedbackCard.module.scss";

import LeaveFeedbackModal from "../LeaveFeedbackModal/LeaveFeedbackModal";
import linkArrow from "../../../assets/images/f-arrow.svg";

const cx = createModuleStyleExtractor(styles);

const LeaveFeedbackCard = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className={cx("m-leave-feedback-card")}>
        <Card>
          <div className={cx("m-leave-feedback-card__title")}>
            <h3>Tell us what you think!</h3>
            <span>We’d love to hear your feedback.</span>
          </div>
          <div className={cx("m-leave-feedback-card__button")}>
            <Button onClick={() => setShowModal(true)}>
              <img src={linkArrow} width="18px" alt="linkArrow" />
            </Button>
          </div>
        </Card>
      </div>
      {showModal && <LeaveFeedbackModal setShowModal={setShowModal} />}
    </>
  );
};

export default memo(LeaveFeedbackCard);
