import React, { useEffect, useState } from "react";
import moment from "moment";
import { isNull } from "../../../utils/validations";
import { Tooltip } from "@mui/material";
import { find } from "lodash";
import { useSelector } from "react-redux";

import Card from "../../atoms/Card/Card";
import ReadMoreReadLess from "../../atoms/ReadMoreReadLess/ReadMoreReadLess";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./WebinarItem.module.scss";
import {
  calculateLocalEndTimeFromEpoch,
  getLocalTzAbbr,
} from "../../../utils/common";
import { Button, ButtonModifier } from "../../atoms/Button";

import HandshakeRssLogo from "../../../assets/images/handshake_rss_logo.png";
import WebinarIcon from "../../../assets/images/Webinar.png";
import userAvatar from "../../../assets/Icons/f-user-avatar.svg";
import circleCheck from "../../../assets/Icons/f-circle-check.svg";

const cx = createModuleStyleExtractor(styles);
const MAX_LINES_TOPIC_POST = 5;

export default function WebinarItem({ data = {}, type, myWebinars = [] }) {
  const [buttonState, setButtonState] = useState({
    text: "Register",
    disabled: false,
    showIcon: false,
    buttonClass: ButtonModifier.WEBINAR_BUTTON_UPCOMMING_PAST,
  });

  const { user_type } = useSelector((state) => state?.userState?.user);
  const isAdmin = user_type === "school_admin" || user_type === "school";

  useEffect(() => {
    if (type === "upcoming") {
      if (
        find(myWebinars, (o) => {
          return o.id === data.id;
        })
      ) {
        setButtonState({
          text: "Registered",
          disabled: true,
          showIcon: true,
          buttonClass: ButtonModifier.WEBINAR_BUTTON_REGISTERED,
        });
      } else {
        setButtonState({
          text: "Register",
          disabled:
            data?.webinar_category_name === "Admin Training" && !isAdmin
              ? true
              : false,
          showIcon: false,
          buttonClass: ButtonModifier.WEBINAR_BUTTON_UPCOMMING_PAST,
        });
      }
    } else {
      setButtonState({
        text: "View Recording",
        disabled: false,
        showIcon: false,
        buttonClass: ButtonModifier.WEBINAR_BUTTON_UPCOMMING_PAST,
      });
    }
  }, [data?.id, type, myWebinars, data?.webinar_category_name, isAdmin]);

  const handleClick = (link) => {
    window.open(link);
  };

  return (
    <div className={cx("m-webinar-item")}>
      <Card>
        <div className={cx("m-webinar-item__root")}>
          <div className={cx("m-webinar-item__root__image")}>
            {data.webinar_image && data.webinar_image.length > 0 ? (
              <img
                src={data.webinar_image}
                alt="Webinar Display"
                className={cx("m-webinar-item__root__image__webinar")}
              />
            ) : (
              <img
                src={WebinarIcon}
                alt="Webinar"
                className={cx("m-webinar-item__root__image__webinar")}
              />
            )}
            {data.webinar_category_name && (
              <div className={cx("m-webinar-item__root__image__tag")}>
                {data.webinar_category_name}
              </div>
            )}
          </div>
          <div className={cx("m-webinar-item__root__description")}>
            {data?.rss_feed_id != null && (
              <img
                src={HandshakeRssLogo}
                className={cx("m-webinar-item__root__description__rss")}
                alt="Webinar-Rss"
              />
            )}
            <div className={cx("m-webinar-item__root__description__title")}>
              {data.name}
            </div>
            {data?.webinar_category_name === "Admin Training" && (
              <Tooltip
                title={"Created for Admins only"}
                placement="bottom"
                enterTouchDelay={0}
              >
                <div
                  className={cx("m-webinar-item__root__description__admin-tag")}
                >
                  <span>For Admins Only</span>
                </div>
              </Tooltip>
            )}
            {data?.description?.length > 0 && (
              <div className={cx("m-webinar-item__root__description__summary")}>
                <p>
                  <span>
                    <ReadMoreReadLess
                      maxLines={MAX_LINES_TOPIC_POST}
                      moreComp={
                        <span
                          className={
                            "m-webinar-item__root__description__summary__text-control"
                          }
                        >
                          Read more
                        </span>
                      }
                      lessComp={
                        <span
                          className={
                            "m-webinar-item__root__description__summary__text-control"
                          }
                        >
                          Read less
                        </span>
                      }
                    >
                      {`${data.description} `}
                    </ReadMoreReadLess>
                  </span>
                </p>
              </div>
            )}
          </div>
          <div className={cx("m-webinar-item__root__info")}>
            <div className={cx("m-webinar-item__root__info__header")}>
              {moment
                .unix(data?.epoch_time)
                .local()
                .format("ddd MMMM Do, YYYY")}
            </div>
            <div className={cx("m-webinar-item__root__info__sub-header")}>
              {`${moment
                .unix(data?.epoch_time)
                .local()
                .format("hh:mm a")} - ${calculateLocalEndTimeFromEpoch(
                data?.epoch_time,
                data?.duration && !isNull(data.duration)
                  ? data.duration.split(" ")[0]
                  : 0,
                "minutes"
              )}`}{" "}
              ({getLocalTzAbbr()})
            </div>
            {data.presenter && data.presenter.length > 0 && (
              <div className={cx("m-webinar-item__root__info__presenter")}>
                <span>
                  <img src={userAvatar} alt="User avatar" />
                </span>
                {data.presenter}
              </div>
            )}

            {data?.attachment !== "" && (
              <div className={cx("m-webinar-item__root__info__attachment")}>
                <a
                  href={data.attachment}
                  download
                  target="blank"
                  aria-label="Open attachment"
                >
                  attachment
                </a>
              </div>
            )}
            <div className={cx("m-webinar-item__root__info__button")}>
              {type === "upcoming" && data.register_link !== "" && (
                <Button
                  modifier={buttonState.buttonClass}
                  onClick={() => handleClick(data.register_link)}
                  disabled={buttonState.disabled}
                  aria-label={`${buttonState.text} ${
                    buttonState.text && buttonState.text.includes("Register")
                      ? "for"
                      : "of"
                  } ${data.name}`}
                >
                  {buttonState.showIcon ? (
                    <div
                      className={cx(
                        "m-webinar-item__root__info__button__with-icon"
                      )}
                    >
                      <span>{buttonState.text}</span>

                      <img src={circleCheck} alt="check" />
                    </div>
                  ) : (
                    <div>{buttonState.text}</div>
                  )}
                </Button>
              )}
              {type === "past" && data.recording_link !== "" && (
                <Button
                  modifier={buttonState.buttonClass}
                  onClick={() => handleClick(data.recording_link)}
                  aria-label={`${buttonState.text} ${
                    buttonState.text && buttonState.text.includes("Register")
                      ? "for"
                      : "of"
                  } ${data.name}`}
                >
                  {buttonState.text}
                </Button>
              )}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
