import React, { memo } from "react";
import TextField from "@mui/material/TextField";

import { createModuleStyleExtractor } from "../../../utils/css";
import { noop } from "../../../utils/noop";
import styles from "./MuiTextbox.module.scss";
const cx = createModuleStyleExtractor(styles);

export const MuiTextboxModifier = {
  LEFT_ALIGN: "a-mui-textbox--left-align",
  CENTER_ALIGN: "a-mui-textbox--center-align",
};

const MuiTextbox = ({
  onClick = noop,
  onChange = noop,
  onBlur = noop,
  onKeyPress,
  onEnterKey,
  id,
  name,
  value,
  type = "text",
  placeholder,
  width = "100%",
  height = 60,
  label = "",
  isMultiline = false,
  marginBottom = 25,
  rows = 3,
  error = false,
  firstLogin = false,
  isDisable = false,
  maxLength = null,
  min,
  max,
  step,
  modifier,
  ...props
}) => (
  <div
    className={cx(
      "a-mui-textbox",
      modifier
        ? {
            [modifier]: true,
          }
        : {}
    )}
  >
    <div className={`field-group ${error ? "Mui-error" : ""}`}>
      {label && <label>{label}</label>}
      <TextField
        name={name}
        id={id ? id : name}
        type={type}
        value={value}
        variant="outlined"
        placeholder={placeholder}
        width={width}
        height={height}
        onClick={(e) => onClick(e)}
        onChange={(e) => onChange(e)}
        onBlur={(e) => onBlur(e)}
        onKeyPress={
          onKeyPress || onEnterKey
            ? (e) => {
                if (onKeyPress) onKeyPress(e);
                else if (e.key === "Enter" && onEnterKey) onEnterKey();
              }
            : () => {}
        }
        multiline={isMultiline}
        rows={rows}
        disabled={isDisable}
        FormHelperTextProps={{
          role: "alert",
          "aria-live": "assertive",
        }}
        {...(maxLength !== null
          ? {
              inputProps: {
                ...(step && { step }),
                ...(min && {
                  min,
                }),
                ...(max && {
                  max,
                }),
                maxLength,
                "aria-label": props["aria-label"]
                  ? props["aria-label"]
                  : placeholder,
                autoComplete: "new-password",
              },
            }
          : {
              inputProps: {
                "aria-label": props["aria-label"]
                  ? props["aria-label"]
                  : placeholder,
                autoComplete: "new-password",
              },
            })}
        {...props}
      />
      {error && <span className="p-error">{error}</span>}
    </div>
  </div>
);
export default memo(MuiTextbox);
