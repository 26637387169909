export const noop = () => {
  /* non operational function */
};

export const getSelectOptions = (options = []) => {
  return options.map((rec) => ({
    label: rec,
    value: rec,
  }));
};
