import React, { memo } from "react";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./EmptyResources.module.scss";

import NoContentIllustration from "../../../assets/images/discover_no_people.svg";

const cx = createModuleStyleExtractor(styles);

const EmptyResources = () => {
  return (
    <div className={cx("a-empty-resources")}>
      <img src={NoContentIllustration} alt="no Categories" />
      <h3 className={cx("a-empty-resources__heading")}>
        You haven't saved any resources yet.
      </h3>
    </div>
  );
};
export default memo(EmptyResources);
