import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { createModuleStyleExtractor } from "../../../utils/css";
import { CircularAvatar } from "../../atoms/CircularAvatar/CircularAvatar";
import styles from "./SingleMessageItemV2.module.scss";
import { deliveryTime } from "../../../utils/common";

const cx = createModuleStyleExtractor(styles);

export const SingleMessageItemV2 = ({ item, isRecent }) => {
  const { currentSearchMessageItem } = useSelector((state) => state.chatStore);
  const { userId } = useSelector((state) => state.userState);
  const {
    message,
    sender_image,
    sender_name,
    send_at,
    shared_file,
    file_name,
    id,
    sender_id,
    conversation_type,
  } = item ?? {};

  // Scroll into view the message upon search
  useEffect(() => {
    if (currentSearchMessageItem?.id === id) {
      const messageElement = document.getElementById(id);
      messageElement.scrollIntoView({ block: "end", behavior: "smooth" });
    }
  }, [currentSearchMessageItem, id]);

  return (
    <div className={cx("message")} id={id}>
      {sender_id === userId ? ( // Own Message
        <div
          className={cx("message__own")}
          style={{ marginTop: isRecent ? "-20px" : "0px" }}
        >
          <div className={cx("message__own__content")}>
            {!isRecent && (
              <div className={cx("message__own__content__title")}>
                <span>
                  <strong>You</strong> at {deliveryTime(send_at)}
                </span>
              </div>
            )}
            <div
              className={cx([
                "message__own__content__item",
                conversation_type === "system_generated"
                  ? "message__own__content__item__system"
                  : "",
              ])}
            >
              <span className={cx("message__own__content__item__message")}>
                {message}
              </span>
              {message === "" && shared_file && file_name && (
                <em
                  className={cx(
                    "message__own__content__item__no-message-attachment"
                  )}
                >
                  New attachment
                </em>
              )}
              {shared_file && file_name && (
                <a
                  className={cx("message__own__content__item__attachment")}
                  href={shared_file}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  {/* <DownloadFile /> */}
                  <span>{file_name}</span>
                </a>
              )}
            </div>
          </div>
          <div className={cx("message__own__info")}>
            <div
              className={cx("message__own__info__image")}
              style={{ visibility: isRecent ? "hidden" : "visible" }}
            >
              <CircularAvatar
                src={sender_image}
                round
                size={31}
                name={sender_name}
              />
            </div>
          </div>
        </div>
      ) : (
        // other message
        <div
          className={cx("message__other")}
          style={{ marginTop: isRecent ? "-20px" : "0px" }}
        >
          <div className={cx("message__other__info")}>
            <div
              className={cx("message__other__info__image")}
              style={{ visibility: isRecent ? "hidden" : "visible" }}
            >
              <CircularAvatar
                src={sender_image}
                round
                size={31}
                name={sender_name}
              />
            </div>
          </div>
          <div className={cx("message__other__content")}>
            {!isRecent && (
              <div className={cx("message__other__content__title")}>
                <span>
                  <strong>{sender_name?.split(" ")[0]}</strong> at{" "}
                  {deliveryTime(send_at)}
                </span>
              </div>
            )}
            <div
              className={cx([
                "message__other__content__item",
                conversation_type === "system_generated" ||
                message?.includes("This conversation is assigned")
                  ? "message__other__content__item__system"
                  : "",
              ])}
            >
              <span className={cx("message__other__content__item__message")}>
                {message}
              </span>
              {message === "" && shared_file && file_name && (
                <em
                  className={cx(
                    "message__other__content__item__no-message-attachment"
                  )}
                >
                  New attachment
                </em>
              )}
              {shared_file && file_name && (
                <a
                  className={cx("message__other__content__item__attachment")}
                  href={shared_file}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  {/* <DownloadFile /> */}
                  <span>{file_name}</span>
                </a>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
