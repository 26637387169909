import { useState } from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./TruncateText.module.scss";

const cx = createModuleStyleExtractor(styles);

/**
 * Component takes text and truncates it with option of Show Less and Show All
 * @param {String} text Text to be truncated
 * @param {Int} length Character length beyond which text is to be truncated
 * @returns JSX expression of controlled text
 */
export default function TruncateText({ text = "", length = 500 }) {
  const [expanded, setExpanded] = useState(false);

  if (text && text.length > length) {
    return (
      <p>
        {expanded ? (
          <>
            {text}
            <span
              className={cx("a-expand-toggle")}
              onClick={() => setExpanded(false)}
            >
              Show less
            </span>
          </>
        ) : (
          <>
            {text.slice(0, length)} . . .
            <span
              className={cx("a-expand-toggle")}
              onClick={() => setExpanded(true)}
            >
              Show all
            </span>
          </>
        )}
      </p>
    );
  } else return <div>{text}</div>;
}
