import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { requestMeeting } from "../../../api/chat";
import { createModuleStyleExtractor } from "../../../utils/css";
import { Button } from "../../atoms/Button";
import { CircularAvatar } from "../../atoms/CircularAvatar/CircularAvatar";
import { Modal } from "../../atoms/Modal/Modal";
import { ModalBody } from "../../atoms/Modal/ModalBody/ModalBody";
import { ModalFooter } from "../../atoms/Modal/ModalFooter/ModalFooter";
import MuiTextbox from "../../atoms/MuiTextbox/MuiTextbox";
import { validateString } from "../../../utils/validations";
import toastify from "../../../utils/toast";
import styles from "./RequestMeeting.module.scss";
import { Mixpanel } from "../../../MixPanel/mixpanel";
import { setReloadChats } from "../../../redux/reducers/chat/reducers";

const cx = createModuleStyleExtractor(styles);

export const RequestMeeting = ({
  show,
  handleClose,
  user,
  variant = "simple",
  meetingMessage = null,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user_profile, id, user_type } = user || {};
  let { full_name } = user || {};

  const dispatch = useDispatch();

  // Full Name Override
  full_name =
    user_type === "Alumni" ||
    user_type === "Student" ||
    user_type?.includes("Ambassador")
      ? full_name.split(" ")[0]
      : full_name;

  const { userState } = useSelector((store) => store);

  const defaultText = `Hi ${full_name},\n\nI would like to request a meeting with you to discuss my potential admission into ${userState.selectedSchool.name}.\n\nPlease let me know what time would suit you best.\n\nI look forward to meeting you.\n\n${userState.user.first_name} ${userState.user.last_name}`;

  const [notes, setNotes] = useState(defaultText);

  useEffect(() => {
    if (show)
      setNotes(
        variant === "messages" && meetingMessage?.length > 0
          ? meetingMessage
          : defaultText
      );
  }, [show, user, defaultText, meetingMessage, variant]);

  const handleSubmit = async () => {
    if (!isSubmitted) {
      if (validateString(notes)) {
        toastify("Please enter a meeting note");
        return;
      }
      const data = {
        receiver_id: id,
        email_body: notes,
        school_id: userState?.selectedSchool?.id,
      };
      setLoading(true);
      try {
        const response = await requestMeeting(data);
        setLoading(false);
        if (response?.success) {
          dispatch(setReloadChats(true)); // Reload chats
          handleClose();
          response?.message && toastify(response?.message);
          Mixpanel.track("Meeting initiated", {
            schoolID: userState.selectedSchool.id,
            objId: id,
            objClass: "User",
          });
          setIsSubmitted(true);
        } else {
          toastify("Oops! something went wrong. Please try again");
        }
      } catch (error) {
        setLoading(false);
        toastify("Oops! something went wrong. Please try again");
      }
    } else {
      setIsSubmitted(false);
      handleClose();
    }
  };

  // Reset modal upon close
  useEffect(() => {
    if (!show) {
      setNotes(defaultText);
      setIsSubmitted(false);
      setLoading(false);
    }
  }, [show, defaultText]);

  return (
    <div className={cx("m-request-meeting")}>
      <Modal
        show={show}
        onClose={handleClose}
        centerAlign={false}
        preserveBodyScroll
      >
        <h2 className={cx("m-request-meeting__header")}>
          {!isSubmitted ? "Request a meeting" : "Meeting request sent!"}
        </h2>
        <ModalBody>
          <div className={cx("m-request-meeting__user")}>
            <CircularAvatar
              round
              src={user_profile}
              name={full_name}
              size={100}
            />
          </div>
          <div className={cx("m-request-meeting__title")}>
            <span>{full_name}</span>
          </div>

          {!isSubmitted && variant !== "messages" ? (
            <div className={cx("m-request-meeting__textbox")}>
              <MuiTextbox
                isMultiline
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                maxLength={255}
              />
            </div>
          ) : !isSubmitted && variant === "messages" ? (
            <div className={cx("m-request-meeting__static-text")}>
              <textarea disabled value={notes} />
            </div>
          ) : (
            <div className={cx("m-request-meeting__text")}>
              <p>You will hear from {full_name} soon.</p>
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          <div className={cx("m-request-meeting__footer")}>
            {variant === "messages" ? (
              <Button onClick={() => handleClose()}>Got it</Button>
            ) : (
              <Button onClick={handleSubmit} isSubmitting={loading}>
                {!isSubmitted ? "Request meeting" : "Got it"}
              </Button>
            )}
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};
