import React, { memo, forwardRef } from "react";
import { NumericFormat } from "react-number-format";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./USDTextbox.module.scss";
import MuiTextbox, { MuiTextboxModifier } from "../MuiTextbox/MuiTextbox";

import AddIcon from "../../../assets/svg/calculator/Add.svg";
import MinusIcon from "../../../assets/svg/calculator/Minus.svg";

const cx = createModuleStyleExtractor(styles);

const NumericFormatCustom = forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange(values?.value);
      }}
      prefix="$"
      thousandSeparator
      valueIsNumericString={false}
      allowNegative={false}
      decimalScale={2}
    />
  );
});

const USDTextbox = ({ value, onChange, ...props }) => (
  <div className={cx("a-usd-textbox")}>
    <button
      className={cx("a-usd-textbox__button")}
      onClick={() => {
        if (value && parseFloat(value) >= 10) {
          onChange((parseFloat(value) - 10).toString());
        } else {
          onChange("0");
        }
      }}
    >
      <img src={MinusIcon} alt="Minus" />
    </button>

    <div className={cx("a-usd-textbox__input")}>
      <MuiTextbox
        {...props}
        value={value}
        onChange={onChange}
        maxLength={12}
        InputProps={{ inputComponent: NumericFormatCustom }}
        modifier={MuiTextboxModifier.CENTER_ALIGN}
      />
    </div>

    <button
      className={cx("a-usd-textbox__button")}
      onClick={() => {
        if (value && parseFloat(value) >= 0) {
          onChange((parseFloat(value) + 10).toString());
        } else {
          onChange("10");
        }
      }}
    >
      <img src={AddIcon} alt="Add" />
    </button>
  </div>
);
export default memo(USDTextbox);
