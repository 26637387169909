import React, { memo, useState, useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { ShimmerSocialPost } from "react-shimmer-effects";
import InfiniteScroll from "react-infinite-scroll-component";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./DashboardPostsList.module.scss";
import { getDashboardPosts } from "../../../api/dashboardPosts";
import DashboardPost from "../../molecules/DashboardPost/DashboardPost";
import Card from "../../atoms/Card/Card";

import EmptyPostIcon from "../../../assets/Icons/EmptyPostIcon";

const cx = createModuleStyleExtractor(styles);

const DashboardPostsList = ({ refreshSavedPosts = () => {} }) => {
  const [searchParams] = useSearchParams();
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loader, setLoader] = useState(true);

  const userState = useSelector((state) => state.userState);
  const selectedSchool = userState.selectedSchool;

  const notification_id = searchParams.getAll("notification_id")
    ? searchParams.get("notification_id")
    : false;

  const fetchDashboardPosts = async (page) => {
    const response = await getDashboardPosts(selectedSchool.id, page);
    if (response.success) {
      setPosts(
        page === 1
          ? [...response.data.user_posts]
          : [...posts, ...response.data.user_posts]
      );
      setTotalPages(response.data.total_pages);

      if (currentPage === 0) {
        // Scroll to the post of notification
        if (notification_id) {
          setTimeout(function () {
            const element = document.getElementById(
              `dashboard-post-${notification_id}`
            );
            if (element)
              element.scrollIntoView({ block: "start", behavior: "smooth" });
          }, 1000);
        }
        setCurrentPage(1);
      }
      setLoader(false);
    } else {
      setPosts([]);
      setLoader(false);
    }
  };

  useEffect(() => {
    setLoader(true);
    fetchDashboardPosts(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSchool]);

  useLayoutEffect(() => {
    if (notification_id) {
      setTimeout(function () {
        const element = document.getElementById(
          `dashboard-post-${notification_id}`
        );
        if (element)
          element.scrollIntoView({ block: "start", behavior: "smooth" });
      }, 1000);
    }
  }, [notification_id]);

  const fetchMoreData = () => {
    setCurrentPage(currentPage + 1);
    fetchDashboardPosts(currentPage + 1);
  };

  const PostsLoader = () => (
    <>
      {Array.from(Array(3).keys()).map((item, index) => (
        <ShimmerSocialPost type="image" key={index} />
      ))}
    </>
  );

  return (
    <div className={cx("m-dashboard-post-list-container")}>
      {loader ? (
        <PostsLoader />
      ) : (
        <>
          {posts.length === 0 ? (
            <Card>
              <div className={cx("m-dashboard-post-list-container__empty")}>
                <h2>Looks like no one has posted anything here yet.</h2>
                <EmptyPostIcon />
              </div>
            </Card>
          ) : (
            <InfiniteScroll
              dataLength={posts.length}
              next={fetchMoreData}
              hasMore={currentPage !== totalPages && totalPages > 0}
              loader={<PostsLoader />}
              style={{ overflow: "none" }}
              endMessage={
                <div>{currentPage === 0 ? <PostsLoader /> : <></>}</div>
              }
            >
              {posts.map((post, k) => (
                <DashboardPost
                  post={post}
                  key={`post-${k}`}
                  index={`post-${k}`}
                  refreshSavedPosts={refreshSavedPosts}
                />
              ))}
            </InfiniteScroll>
          )}
        </>
      )}
    </div>
  );
};
export default memo(DashboardPostsList);
