import React, { memo } from "react";
import { Link } from "react-router-dom";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./NotificationIcon.module.scss";

import notification from "../../../assets/images/f-notification.svg";

const cx = createModuleStyleExtractor(styles);

const NotificationIcon = ({ active = false, badge, onClick = () => {} }) => (
  <Link
    to="#"
    className={cx([
      "a-header-notification-icon",
      active ? "a-header-notification-icon--active" : "",
    ])}
    onClick={onClick}
  >
    <img src={notification} alt="Message" />
    {badge && (
      <span className={cx("a-header-notification-icon__badge")}>
        <span>{badge}</span>
      </span>
    )}
  </Link>
);

export default memo(NotificationIcon);
