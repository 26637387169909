import React from "react";

function BackIcon() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 60 60"
    >
      <g>
        <path
          class="st0"
          d="M38.7,8.1L21,30.4l17.6,22.3"
          fill="none"
          stroke="var(--primary-font-color)"
          strokeWidth="8.6349"
          strokeMiterlimit={10}
        />
      </g>
    </svg>
  );
}

export default React.memo(BackIcon);
