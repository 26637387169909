import React from "react";

function ChecklistLinkIcon() {
  return (
    <svg
      width="50px"
      height="50px"
      viewBox="0 0 50 50"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Asset-Artboard-Page"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Icon/Network/Off-Copy">
          <path
            d="M50,25 C50,38.8069679 38.8074455,50 25,50 C11.1930321,50 0,38.8069679 0,25 C0,11.1925545 11.1930321,0 25,0 C38.8074455,0 50,11.1925545 50,25"
            id="Fill-1"
            fill="#F1F4F7"
          ></path>
          <g
            id="Group"
            transform="translate(13.000000, 12.000000)"
            stroke="#0030B5"
            strokeWidth="1.35"
          >
            <path
              d="M9.77777778,5.4 L19.5555556,5.4"
              id="Stroke-2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M9.77777778,10.8 L19.5555556,10.8"
              id="Stroke-2-Copy"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <ellipse
              id="Oval"
              cx="5.77777778"
              cy="5.4"
              rx="1.33333333"
              ry="1.35"
            ></ellipse>
            <ellipse
              id="Oval-Copy"
              cx="5.77777778"
              cy="10.8"
              rx="1.33333333"
              ry="1.35"
            ></ellipse>
            <ellipse
              id="Oval-Copy-2"
              cx="5.77777778"
              cy="16.2"
              rx="1.33333333"
              ry="1.35"
            ></ellipse>
            <ellipse
              id="Oval-Copy-3"
              cx="5.77777778"
              cy="21.6"
              rx="1.33333333"
              ry="1.35"
            ></ellipse>
            <path
              d="M9.77777778,16.2 L19.5555556,16.2"
              id="Stroke-2-Copy-2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M9.77777778,21.6 L19.5555556,21.6"
              id="Stroke-2-Copy-3"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M0.675,26.325 L19.5,26.325 C21.6124892,26.325 23.325,24.6124892 23.325,22.5 L23.325,0.675 L4.5,0.675 C2.38751083,0.675 0.675,2.38751083 0.675,4.5 L0.675,26.325 Z"
              id="Rectangle"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default React.memo(ChecklistLinkIcon);
