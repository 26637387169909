import React, { Fragment, memo } from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./ChatOptions.module.scss";

const cx = createModuleStyleExtractor(styles);

const ChatOptions = ({
  chatActionOptions = [],
  handleChatAction = () => {},
}) => {
  return (
    <div className={cx("init-chat-options")}>
      {chatActionOptions.map((option, index) => (
        <Fragment key={`index_option_${index}`}>
          <h5
            className={cx("init-chat-options__item")}
            onClick={() => handleChatAction(option.id)}
          >
            <span>{option.title}</span>
          </h5>
        </Fragment>
      ))}
    </div>
  );
};
export default memo(ChatOptions);
