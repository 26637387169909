import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getChecklistTasks } from "../../../api/checklists";
import Card from "../../atoms/Card/Card";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./ChecklistsCategories.module.scss";

import FilterIcon from "../../../assets/Icons/FilterIcon";
// import DownArrowIcon from "../../../assets/svg/down-arrow.svg";

const cx = createModuleStyleExtractor(styles);

const ChecklistsCategories = ({
  checkListCategories = [],
  selectedCategory = 0,
  setSelectedCategory,
  setChecklistTasks,
  setChecklistTasksLoader,
}) => {
  const [openFilter, toggleFilter] = useState(false);

  const userState = useSelector((state) => state.userState);
  const selectedSchool = userState.selectedSchool;

  const getListByCategory = async (id) => {
    setChecklistTasksLoader(true);
    const response = await getChecklistTasks(id, selectedSchool.id);
    if (response.success && response.data)
      setChecklistTasks(response.data.tasks);
    else setChecklistTasks([]);
    setChecklistTasksLoader(false);
  };

  useEffect(() => {
    setChecklistTasksLoader(true);
    getListByCategory(checkListCategories[selectedCategory].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedCategory !== null) toggleFilter(false);
  }, [selectedCategory]);

  return (
    <>
      <div
        className={`collapsible-section ${
          openFilter ? "collapsible-section--open" : ""
        }`}
      >
        <div id={"collapsible-section-header"}>
          <h2>Categories</h2>
          <a
            href="/#"
            onClick={(e) => {
              e?.preventDefault && e.preventDefault();
              toggleFilter(!openFilter);
            }}
            id="collapsible-section-header-icon"
          >
            <FilterIcon fill={openFilter} />
          </a>
        </div>
        <div id="collapsible-section-search-filter">
          <div className={cx("m-checklist-categories")}>
            <Card>
              <div className={cx("m-checklist-categories__container")}>
                <ul>
                  {checkListCategories.map((item, category) => (
                    <li
                      key={category}
                      className={cx([
                        "m-checklist-categories__container__item",
                        ...(selectedCategory === category
                          ? ["m-checklist-categories__container__item--active"]
                          : []),
                      ])}
                      onClick={() => {
                        setSelectedCategory(category);
                        setChecklistTasksLoader(true);
                        setChecklistTasks([]);
                        getListByCategory(checkListCategories[category].id);
                      }}
                    >
                      <a
                        className="link"
                        href="/#"
                        aria-label={item.category_name}
                        onClick={(e) => {
                          e?.preventDefault && e.preventDefault();
                        }}
                      >
                        <span>{item.category_name}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(ChecklistsCategories);
