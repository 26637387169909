import React, { memo } from "react";

function WidgetStudentsIcon() {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon/Services/Search">
        <g id="Group">
          <path
            id="Stroke 10"
            d="M25.4901 24L29.9516 29.1014C30.5141 29.7446 30.4017 30.7322 29.709 31.2325V31.2325C29.1054 31.6683 28.2698 31.5766 27.7753 31.0201L23 25.6467"
            stroke="#000C3D"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Stroke 6"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M28 16.5C28 22.2988 23.2988 27 17.5 27C11.7008 27 7 22.2988 7 16.5C7 10.7012 11.7008 6 17.5 6C23.2988 6 28 10.7012 28 16.5Z"
            stroke="#000C3D"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Stroke 6_2"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M25 16.5C25 20.642 21.642 24 17.5 24C13.3577 24 10 20.642 10 16.5C10 12.358 13.3577 9 17.5 9C21.642 9 25 12.358 25 16.5Z"
            stroke="#000C3D"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </g>
    </svg>
  );
}

export default memo(WidgetStudentsIcon);
