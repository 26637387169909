import React, { useState, useEffect, memo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { ROUTES } from "../../../routes";
import toastify from "../../../utils/toast";
import { unsubscribeNotification } from "../../../api/notification";
import { Button, ButtonModifier } from "../../atoms/Button/Button";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./Unsubscribe.module.scss";
import { INTERSTRIDE_LOGO_URL } from "../../../utils/constants";

const cx = createModuleStyleExtractor(styles);

const Unsubscribe = () => {
  const { isLogin } = useSelector((state) => state.userState);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.getAll("token")
    ? searchParams.get("token")
    : false;

  const [unsubscribed, setUnsubscribed] = useState(false);
  const [failureCount, setFailureCount] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const FAIL_ATTEMPT_LIMIT = 3;

  useEffect(() => {
    if (!token) redirectToHomeScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectToHomeScreen = () => {
    navigate(isLogin ? ROUTES.DASHBOARD : ROUTES.LOGIN, { replace: true });
  };

  const handleUnsubscribeClick = async () => {
    setIsSubmitting(true);

    const response = await unsubscribeNotification(token);
    if (response.success) {
      toastify("You've successfully unsubscribed.");
      setUnsubscribed(true);
    } else {
      toastify(response.message);
      setFailureCount(failureCount + 1);
    }
    setIsSubmitting(false);
  };

  return (
    <div className={cx("m-unsubscribe-notifications")}>
      <div className={cx("m-unsubscribe-notifications__unlogo")}>
        <img src={INTERSTRIDE_LOGO_URL} alt="interstride logo" />
      </div>
      <h2>{unsubscribed ? "Success!" : "Unsubscribe email notifications"}</h2>
      <h5>
        {unsubscribed
          ? "You've successfully unsubscribed from all Interstride email notifications."
          : "Click below to unsubscribe from all Interstride email notifications."}
      </h5>
      {unsubscribed ? (
        <Button
          onClick={redirectToHomeScreen}
          modifier={ButtonModifier.NOTIFICATIONS_MANAGE}
        >
          {isLogin ? "Dashboard" : "Login"}
        </Button>
      ) : (
        <Button
          onClick={handleUnsubscribeClick}
          modifier={ButtonModifier.NOTIFICATIONS_MANAGE}
          disabled={failureCount >= FAIL_ATTEMPT_LIMIT}
          isSubmitting={isSubmitting}
        >
          Unsubscribe
        </Button>
      )}
    </div>
  );
};
export default memo(Unsubscribe);
