import { ApiWrapper, REQUEST } from ".";
import { NOTIFICATION_FILTERS } from "../utils/constants";

export const fetchAllNotificationCount = async () => {
  return await ApiWrapper({
    url: `notifications/all_notifications_count`,
    method: REQUEST.GET,
  });
};

export const getAllNotifications = async () => {
  let params = "";
  NOTIFICATION_FILTERS.forEach((filter, index) => {
    params += `${index === 0 ? "?" : "&"}filter_type[]=${filter.value}`;
  });

  return await ApiWrapper({
    url: `notifications${params}`,
    method: REQUEST.GET,
  });
};

export const fetchNotificationSettings = async (id) => {
  return await ApiWrapper({
    url: `notification_settings/${id}`,
    method: REQUEST.GET,
  });
};

export const updateNotificationSettings = async (data) => {
  return await ApiWrapper({
    url: `notification_settings`,
    method: REQUEST.POST,
    data,
  });
};

export const updateUnsubscribeNotification = async (data) => {
  return await ApiWrapper({
    url: `notification_settings/update_notifications`,
    method: REQUEST.PUT,
    data,
  });
};

export const removeNotification = async (id) => {
  return await ApiWrapper({
    url: `notifications/${id}/remove_notification`,
    method: REQUEST.GET,
  });
};

export const removeAllPastNotifications = async () => {
  return await ApiWrapper({
    url: `notifications/remove_all_past_notifications`,
    method: REQUEST.GET,
  });
};

export const readNotification = async (id) => {
  return await ApiWrapper({
    url: `notifications/${id}/read`,
    method: REQUEST.GET,
  });
};

export const readAllNotifications = async (id) => {
  return await ApiWrapper({
    url: `notifications/read_all_notification`,
    method: REQUEST.GET,
  });
};

export const readChatNotification = async (data) => {
  return await ApiWrapper({
    url: `notifications/read_chat_notifications`,
    method: REQUEST.POST,
    data,
  });
};

export const unsubscribeNotification = async (token) => {
  return await ApiWrapper({
    url: `notification_settings/unsubsrcibe_email_without_signup`,
    method: REQUEST.PUT,
    data: { token },
  });
};
