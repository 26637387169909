import React, { memo } from "react";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./GreyLink.module.scss";
const cx = createModuleStyleExtractor(styles);

const GreyLink = ({ children, href, onClick, className = "" }) => {
  return (
    <a
      href={href ? href : "/#"}
      className={cx[("a-grey-link", className)]}
      onClick={(e) => {
        if (!href) {
          e?.preventDefault && e.preventDefault();
          onClick && onClick();
        }
      }}
    >
      {children}
    </a>
  );
};
export default memo(GreyLink);
