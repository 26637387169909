import React, { memo } from "react";

function FilterIcon({ fill = false }) {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Action/Page/Filter/Deslected"
          transform="translate(-12.000000, -12.000000)"
          fill={fill ? "var(--primary-font-color)" : "#8094AB"}
          fillRule="nonzero"
        >
          <path
            d="M12.2116038,13.61 C14.2316038,16.2 17.9616038,21 17.9616038,21 L17.9616038,27 C17.9616038,27.55 18.4116038,28 18.9616038,28 L20.9616038,28 C21.5116038,28 21.9616038,27.55 21.9616038,27 L21.9616038,21 C21.9616038,21 25.6816038,16.2 27.7016038,13.61 C28.2116038,12.95 27.7416038,12 26.9116038,12 L13.0016038,12 C12.1716038,12 11.7016038,12.95 12.2116038,13.61 Z"
            id="Path"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default memo(FilterIcon);
