import React from "react";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./Categories.module.scss";
import Link from "../Link/Link";
import { CircularAvatar } from "../CircularAvatar/CircularAvatar";
import { isEmpty } from "../../../utils/validations";

const cx = createModuleStyleExtractor(styles);

export const CategoriesModifier = {
  COMMUNITY_CATEGORIES: "a-categories-flush--community",
};

export default function Categories({
  type = "",
  data = [],
  selectedCategoryId = null,
  onCategorySelect = () => {},
  toggleFilter = () => {},
  notificationCounts = {},
  modifier,
}) {
  const handleCategoryClick = (e, data) => {
    e?.preventDefault && e.preventDefault();
    toggleFilter(false);
    onCategorySelect(data);
  };

  return (
    <div
      className={cx(
        "a-categories-flush",
        modifier
          ? {
              [modifier]: true,
            }
          : {}
      )}
    >
      {type === "resource" && data.length === 0 ? (
        <div className={cx("a-categories-not-available")}>
          <h3>No categories are available.</h3>
        </div>
      ) : type === "resource" && data.length !== 0 ? (
        <ul className={cx("a-categories-flush__list_resource")}>
          {data.length > 0 &&
            data.map((resource, index) => (
              <li
                key={index}
                className={cx(
                  `${
                    resource.id === selectedCategoryId
                      ? `a-categories-flush__list__item__selected`
                      : ``
                  }`
                )}
              >
                <Link
                  className="a-categories-flush__list__item__link"
                  onClick={(e) => handleCategoryClick(e, resource)}
                >
                  {resource.category_name}
                </Link>
              </li>
            ))}
        </ul>
      ) : type === "notifications" && data.length !== 0 ? (
        <ul className={cx("a-categories-flush__list_notifications")}>
          {data.length > 0 &&
            data.map((item, index) => (
              <li
                key={index}
                className={cx(
                  `${
                    item.value === selectedCategoryId
                      ? `a-categories-flush__list__item__notifications_selected`
                      : ``
                  }`
                )}
              >
                <Link
                  className="a-categories-flush__list__item__link_notifications"
                  onClick={(e) => handleCategoryClick(e, item)}
                >
                  <div
                    className={cx(
                      "a-categories-flush__list_notifications_image"
                    )}
                  >
                    <img src={item.icon} alt="logo" />
                  </div>
                  <span>{item.category_name}</span>
                </Link>
              </li>
            ))}
        </ul>
      ) : type === "studentServices" && data.length === 0 ? (
        <div className={cx("a-categories-not-available")}>
          <h3>No categories are available.</h3>
        </div>
      ) : type === "studentServices" && data.length !== 0 ? (
        <ul className={cx("a-categories-flush__student-services")}>
          {data.length > 0 &&
            data.map((studentService, index) => (
              <li
                key={index}
                className={cx(
                  `${
                    studentService.id === selectedCategoryId
                      ? `a-categories-flush__list__item__selected`
                      : ``
                  }`
                )}
              >
                <Link
                  className="a-categories-flush__list__item__link"
                  onClick={(e) => handleCategoryClick(e, studentService)}
                >
                  <div
                    className={cx(
                      "a-categories-flush__student-services__image"
                    )}
                  >
                    <img src={studentService.logo_url} alt="logo" />
                  </div>
                  {studentService.name}
                </Link>
              </li>
            ))}
        </ul>
      ) : type === "myTopic" && data.length === 0 ? (
        <div className={cx("a-categories-not-available")}>
          <h3>Looks like you haven't followed any Topic.</h3>
        </div>
      ) : type === "myTopic" && data.length !== 0 ? (
        <ul className={cx("a-categories-flush__list_topics")}>
          {data.length > 0 &&
            data.map((topic, index) => (
              <li
                key={index}
                className={cx(
                  `${
                    topic.id === selectedCategoryId
                      ? `a-categories-flush__list_topics__selected`
                      : ``
                  }`
                )}
              >
                <Link
                  className="a-categories-flush__list_topics__link"
                  onClick={(e) => handleCategoryClick(e, topic.id)}
                >
                  <div className={cx("a-categories-flush__list_topics__icons")}>
                    <CircularAvatar
                      src={topic.topic_icon}
                      name={topic.topic_name}
                      alt="logo"
                      maxInitials={2}
                      round
                      size={50}
                    />
                  </div>
                  {topic.topic_name}
                  {!isEmpty(notificationCounts) &&
                    notificationCounts[topic.id] > 0 && (
                      <span
                        className={cx(
                          "a-categories-flush__list_topics__counts"
                        )}
                      >
                        {notificationCounts[topic.id]}
                      </span>
                    )}
                </Link>
              </li>
            ))}
        </ul>
      ) : type === "checklist" && data.length === 0 ? (
        <div className={cx("a-categories-not-available")}>
          <h3>No categories are available.</h3>
        </div>
      ) : type === "checklist" && data.length !== 0 ? (
        <ul className={cx("a-categories-flush__list_resource")}>
          {data.length > 0 &&
            data.map((checklist, index) => (
              <li
                key={index}
                className={cx(
                  `${
                    checklist.id === selectedCategoryId
                      ? `a-categories-flush__list__item__selected`
                      : ``
                  }`
                )}
              >
                <Link
                  className="a-categories-flush__list__item__link"
                  onClick={(e) => handleCategoryClick(e, checklist)}
                >
                  {checklist.category_name}
                </Link>
              </li>
            ))}
        </ul>
      ) : (
        <ul className={cx("a-categories-flush__list_resource")}>
          {data.length > 0 &&
            data.map((rec, index) => (
              <li
                key={index}
                className={cx(
                  `${
                    rec.id === selectedCategoryId
                      ? `a-categories-flush__list__item__selected`
                      : ``
                  }`
                )}
              >
                <Link
                  className="a-categories-flush__list__item__link"
                  onClick={(e) => handleCategoryClick(e, rec)}
                >
                  {rec.icon ? <img src={rec.icon} alt={rec.category} /> : null}
                  {rec.category}
                </Link>
              </li>
            ))}
        </ul>
      )}
    </div>
  );
}
