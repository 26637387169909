import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  newNotifications: [],
  pastNotifications: [],
  unreadNotifications: [],
  notificationSettings: {},
  unsubscribePush: false,
  unsubscribeEmail: false,

  /** Notification counts - start */
  unreadNotificationCount: 0,
  topicsNotificationCount: 0,
  topicSpecificNotificationCount: {},
  chatNotificationCount: 0,
  chatSpecificNotificationCount: {},
  dealNotificationCount: 0,
  webinarNotificationCount: 0,
  videoNotificationCount: 0,
  /** Notification counts - end */
};

export const notificationReducer = createSlice({
  name: "notification",
  initialState,
  reducers: {
    resetNotificationData: () => initialState,
    setAllNotificationCounts: (state, action) => {
      let topicCount = {};
      action.payload.topic_specific_notifcations_count.forEach(
        ([id, count]) => {
          topicCount[id] = count;
        }
      );

      let chatCount = {};
      action.payload.chat_specific_notifcations_count.forEach(([id, count]) => {
        chatCount[id] = count;
      });

      state.unreadNotificationCount = action.payload.unread_notification_count;
      state.topicsNotificationCount = action.payload.topics_notifcations_count;
      state.topicSpecificNotificationCount = {
        ...topicCount,
      };
      state.chatNotificationCount = action.payload.chat_notifcations_count;
      state.chatSpecificNotificationCount = { ...chatCount };
      state.dealNotificationCount = action.payload.deal_notifications_count;
      state.webinarNotificationCount =
        action.payload.webinar_notifications_count;
      state.videoNotificationCount = action.payload.video_notifcations_count;
    },
    setLiveTopicNotificationCounts: (state, action) => {
      let topicSpecificNotificationCount = {
        ...state.topicSpecificNotificationCount,
      };

      topicSpecificNotificationCount[action.payload.topic_id] =
        action.payload.topic_notifications_count;

      state.topicSpecificNotificationCount = {
        ...topicSpecificNotificationCount,
      };
      state.topicsNotificationCount =
        action.payload.total_topic_notifications_count;
      state.unreadNotificationCount = action.payload.total_notifications_count;
    },
    setLiveChatNotificationCounts: (state, action) => {
      let chatSpecificNotificationCount = {
        ...state.chatSpecificNotificationCount,
      };

      chatSpecificNotificationCount[action.payload.conversation_id] =
        action.payload.chat_notifications_count;

      state.chatSpecificNotificationCount = {
        ...chatSpecificNotificationCount,
      };
      state.chatNotificationCount =
        action.payload.total_chat_notifications_count;
      state.unreadNotificationCount = action.payload.total_notifications_count;
    },
    resetAllNotificationCounts: (state) => {
      state.unreadNotificationCount = 0;
      state.topicsNotificationCount = 0;
      state.topicSpecificNotificationCount = {};
      state.chatNotificationCount = 0;
      state.chatSpecificNotificationCount = {};
      state.dealNotificationCount = 0;
      state.webinarNotificationCount = 0;
      state.videoNotificationCount = 0;
      state.unreadNotifications = [];
    },
    setAllNotifications: (state, action) => {
      state.newNotifications = action.payload.new_notifications;
      state.pastNotifications = action.payload.past_notifications;
      state.unreadNotifications = action.payload.unread_notifications;
    },
    updateNewNotifications: (state, action) => {
      state.newNotifications = action.payload;
    },
    updatePastNotifications: (state, action) => {
      state.pastNotifications = action.payload;
    },
    setAllNotificationSettings: (state, action) => {
      state.notificationSettings = action.payload.notificationSettings;
      state.unsubscribePush =
        action.payload.pushNotifications.length > 0 ? false : true;
      state.unsubscribeEmail =
        action.payload.emailNotifications.length > 0 ? false : true;
    },
    setNotificationSettings: (state, action) => {
      state.notificationSettings = action.payload.notificationSettings;
    },
    unsubscribeNotificationUpdate: (state, action) => {
      state.notificationSettings = action.payload.notificationSettings;
      state.unsubscribePush = action.payload.unsubscribePush;
      state.unsubscribeEmail = action.payload.unsubscribeEmail;
    },
    readChatSpecificNotification: (state, action) => {
      state.chatSpecificNotificationCount[action.payload.conversation_id] = 0;
      state.chatNotificationCount = action.payload.chat_notifcations_count;
      state.unreadNotificationCount = action.payload.unread_notification_count;
    },
    decrementUnreadNotificationCount: (state) => {
      if (state.unreadNotificationCount > 0)
        state.unreadNotificationCount = state.unreadNotificationCount - 1;
    },
  },
});

export const {
  resetNotificationData,
  setAllNotificationCounts,
  resetAllNotificationCounts,
  setAllNotifications,
  setAllNotificationSettings,
  setNotificationSettings,
  unsubscribeNotificationUpdate,
  updateNewNotifications,
  updatePastNotifications,
  setLiveTopicNotificationCounts,
  setLiveChatNotificationCounts,
  readChatSpecificNotification,
  decrementUnreadNotificationCount,
} = notificationReducer.actions;

export default notificationReducer.reducer;
