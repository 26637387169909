import React, { memo } from "react";
import { Link } from "react-router-dom";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./DashboardChecklistLink.module.scss";
import Card from "../../atoms/Card/Card";
import { ROUTES } from "../../../routes";
import ChecklistLinkIcon from "../../../assets/Icons/ChecklistLinkIcon";
import linkArrow from "../../../assets/images/f-arrow.svg";

const cx = createModuleStyleExtractor(styles);

const DashboardChecklistLink = () => {
  return (
    <div className={cx("m-dashboard-checklist-link-card")}>
      <Card>
        <Link to={ROUTES.TOOLS.CHECKLISTS}>
          <div className={cx("m-dashboard-checklist-link-card__content")}>
            <ChecklistLinkIcon />
            <div className={cx("m-dashboard-checklist-link-card__title")}>
              University Checklist{" "}
            </div>
            <div className={cx("m-dashboard-checklist-link-card__link")}>
              <img src={linkArrow} width="18px" alt="linkArrow" />
            </div>
          </div>
        </Link>
      </Card>
    </div>
  );
};
export default memo(DashboardChecklistLink);
