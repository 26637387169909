import React from "react";
import Linkify from "linkify-react";

const LinkContent = ({ children, className = "" }) => {
  return (
    <Linkify
      options={{
        className: `link-content ${className}`,
        tagName: "a",
        target: "_blank",
        rel: "noopener",
      }}
    >
      {children}
    </Linkify>
  );
};

export default LinkContent;
