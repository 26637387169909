import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import styles from "./NotificationsDrawer.module.scss";
import { createModuleStyleExtractor } from "../../../utils/css";
import { ROUTES } from "../../../routes";
import { Button, ButtonModifier } from "../../atoms/Button/Button";
import { getAllNotifications } from "../../../api/notification";
import {
  setAllNotifications,
  decrementUnreadNotificationCount,
} from "../../../redux/reducers/notificationReducer";
import SettingsCogIcon from "../../../assets/Icons/SettingsCogIcon";
import {
  NotificationsList,
  NotificationsModifier,
} from "../NotificationsList/NotificationsList";

const cx = createModuleStyleExtractor(styles);

export default function NotificationsDrawer({ setShowNotificationsDrawer }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const notificationState = useSelector((state) => state.notificationState);
  const unreadNotifications = notificationState.unreadNotifications;
  const unreadNotificationCount = notificationState.unreadNotificationCount;

  const displayNotificationsCount = 5; // Denotes the numbers of notifications to show

  const getNotifications = async () => {
    const response = await getAllNotifications();

    if (response.success && response.data) {
      dispatch(setAllNotifications(response.data));
    }
  };

  useEffect(() => {
    getNotifications();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadNotificationCount]);

  const displayNotifications = unreadNotifications.slice(
    0,
    displayNotificationsCount
  );

  /**
   * Get the API responses' notification id and update notification store and close the drawer
   * @param {Object} id Notification ID from API: /admission_portal/notifications/{notification_id}/read
   */
  const handleDrawerNotificationView = (notification = {}) => {
    setShowNotificationsDrawer(false);
    if (notification.id) {
      // Call notification store method
      dispatch(decrementUnreadNotificationCount());
    }
  };

  return (
    <div className={cx("m-notifications__content_wrapper")}>
      <div className={cx("m-notifications__content_header")}>
        <div className={cx("m-notifications__content_header_left")}>
          <h2>New Notifications</h2>
        </div>
        <div className={cx("m-notifications__content_header_right")}>
          <SettingsCogIcon
            onClick={() => {
              setShowNotificationsDrawer(false);
              navigate(ROUTES.NOTIFICATIONS_SETTINGS);
            }}
          />
          <Button
            modifier={ButtonModifier.NOTIFICATIONS_DRAWER_HEADER}
            onClick={() => {
              setShowNotificationsDrawer(false);
              navigate(ROUTES.NOTIFICATIONS);
            }}
          >
            View all
          </Button>
        </div>
      </div>
      <div className={cx("m-notifications__content_items_wrapper")}>
        <NotificationsList
          modifier={NotificationsModifier.SMALL}
          notifications={displayNotifications}
          showDelete={false}
          onViewClick={handleDrawerNotificationView}
        />
        {unreadNotifications &&
          unreadNotifications.length > displayNotificationsCount && (
            <div
              className={cx("m-notifications__content_items_wrapper__see-more")}
            >
              <span
                onClick={() => {
                  setShowNotificationsDrawer(false);
                  navigate(ROUTES.NOTIFICATIONS);
                }}
              >
                View {unreadNotifications.length - displayNotifications.length}{" "}
                more
              </span>
            </div>
          )}
      </div>
    </div>
  );
}
