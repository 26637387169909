import React, { useState } from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";

import Card from "../../atoms/Card/Card";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./DashboardInviteFriend.module.scss";
import { Button, ButtonModifier } from "../../atoms/Button";
import { Modal } from "../../atoms/Modal/Modal";
import { ModelHeader } from "../../atoms/Modal/ModelHeader/ModelHeader";
import { ModalBody } from "../../atoms/Modal/ModalBody/ModalBody";
import { ModalFooter } from "../../atoms/Modal/ModalFooter/ModalFooter";
import MuiTextbox from "../../atoms/MuiTextbox/MuiTextbox";
import { validateString, validateEmail } from "../../../utils/validations";
import toastify from "../../../utils/toast";
import { inviteFriend } from "../../../api/profile";

const cx = createModuleStyleExtractor(styles);

export default function DashboardInviteFriend() {
  const [invitePopup, setInvitePopup] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { id } = useSelector((state) => state?.userState?.selectedSchool);

  const handleSave = async () => {
    if (!id) {
      // Fail safe in case id not present
      toastify("Please selected a school from header");
      return;
    }
    // Call API
    try {
      setIsSubmitting(true);
      let formData = new FormData();
      formData.append("school_id", id);
      formData.append("email", formik.values.email);

      const response = await inviteFriend(formData);
      setIsSubmitting(false);
      if (response.success) {
        formik.resetForm();
        setInvitePopup(false);
        toastify(response?.message || "Friend invited.");
      } else {
        toastify(response?.message || "Something went wrong!");
      }
    } catch (error) {
      setIsSubmitting(false);
      toastify("Something went wrong!");
    }
  };

  // Validation function for profile fields
  const validate = (values) => {
    const errors = {};

    if (validateString(values.email)) {
      errors.email = "Email is required";
    } else if (validateEmail(values.email)) {
      errors.email = "Email is not valid";
    }

    return errors;
  };

  // Formik Hook
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate,
    onSubmit: handleSave,
  });

  return (
    <div className={cx("m-invite-friend")}>
      <Card>
        <h3>Invite a friend</h3>
        <h4>You can invite your friend to this admissions portal.</h4>
        <div className={cx("m-invite-friend__button")}>
          <Button
            modifier={ButtonModifier.PROFILE_HEADER_PRIMARY}
            onClick={() => setInvitePopup(true)}
          >
            Send Invite
          </Button>
        </div>
      </Card>
      {invitePopup && (
        <div className={cx("m-invite-friend__modal")}>
          <Modal
            show={invitePopup}
            onClose={() => {
              formik.resetForm();
              setInvitePopup(false);
            }}
            maxWidth={500}
          >
            <ModelHeader title="Invite a friend" />
            <ModalBody>
              <h4>Please enter email of a friend to invite</h4>
              <div>
                <MuiTextbox
                  type="email"
                  value={formik.values.email}
                  onChange={(e) =>
                    formik.setFieldValue("email", e.target.value)
                  }
                  name={"email"}
                  label="Friend's email"
                  placeholder={"Enter your friend's email"}
                  error={formik.errors.email}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <div>
                <Button
                  modifier={ButtonModifier.PROFILE_SUBMIT}
                  type="button"
                  onClick={formik.handleSubmit}
                  isSubmitting={isSubmitting}
                  disabled={!formik?.values?.email || formik?.errors?.email}
                >
                  Submit
                </Button>
              </div>
            </ModalFooter>
          </Modal>
        </div>
      )}
    </div>
  );
}
