import React, { memo } from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./SingleSelectCard.module.scss";
import SelectiveCard from "../SelectiveCard/SelectiveCard";

const cx = createModuleStyleExtractor(styles);

const SingleSelectCard = ({
  onClick = () => {},
  options = [],
  value,
  header = "",
}) => {
  return (
    <div className={cx("a-single-select-card")}>
      <span className={cx("a-single-select-card__header")}>{header}</span>
      <div className={cx("a-single-select-card__options")}>
        {options.map((option, index) => (
          <SelectiveCard
            key={index}
            {...option}
            active={value !== null && value === option.value}
            onClick={() => onClick(option.value)}
          />
        ))}
      </div>
    </div>
  );
};

export default memo(SingleSelectCard);
