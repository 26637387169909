import React, { useState, useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"; // Dependency for using Bar component of "react-chartjs-2"
import ChartDataLabels from "chartjs-plugin-datalabels"; // Dependency for showing data labels in Bar component of "react-chartjs-2"
import { Bar, getElementAtEvent } from "react-chartjs-2";

// Dependency for using Bar component of "react-chartjs-2". This registers our plugins, options and elements of Bar Chart
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

/**
 *
 * @param {Array} lables Array containg data keys for chart
 * @param {Array} data Data values corresponding to data key labels for chart
 */
export default function VerticalBarChart({
  labels = [],
  data = [],
  backgroundColor = "#D1DDE5",
  enableCursorPointer = false,
  onClickHandler = null,
}) {
  const [isMobiile, setIsMobile] = useState(window.innerWidth <= 500);

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const chartRef = useRef(null);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  // Options to customize bar cart
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "x",
    layout: {
      padding: {
        top: 35,
      },
    },
    onHover: (event, chartElement) => {
      event.native.target.style.cursor =
        enableCursorPointer && chartElement[0] ? "pointer" : "default";
    },
    scales: {
      x: {
        ticks: {
          color: "#000C3D",
          font: {
            size: isMobiile ? 12 : 18,
            color: "#000C3D",
            family: "TTCommons-Regular",
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        color: "#000C3D",
        anchor: "end",
        align: "end",
        font: {
          family: "TTCommons-Regular",
          size: isMobiile ? 12 : 18,
        },
        // formatter: (value) => { // Commented spec for future use
        //   return value + "%";
        // },
      },
    },
  };

  const computeChartCoordinates = (event) => {
    const { current: chart } = chartRef;

    if (!chart || !onClickHandler) {
      return;
    }

    let element = getElementAtEvent(chart, event);
    if (!element.length) return;

    const { index } = element[0];

    onClickHandler({
      label: labels[index] || null,
      data: data[index] || null,
    });
  };

  return (
    <Bar
      ref={chartRef}
      options={options}
      onClick={computeChartCoordinates}
      data={{
        labels: isMobiile
          ? labels.map((label) =>
              label.length > 15 ? `${label.substring(0, 12)}...` : label
            )
          : labels,
        datasets: [
          {
            data: data,
            backgroundColor,
            maxBarThickness: 82,
          },
        ],
      }}
    />
  );
}
