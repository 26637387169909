import React, { memo } from "react";

const ChecklistTextbox = ({
  placeholder = "",
  value,
  onChange = () => {},
  ...props
}) => {
  return (
    <input
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      autoFocus={true}
      {...props}
    />
  );
};
export default memo(ChecklistTextbox);
