import React from "react";
import { Link } from "react-router-dom";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./SubMenuItem.module.scss";

const cx = createModuleStyleExtractor(styles);

export const SubMenuItemModifier = {
  ACTIVE: "a-sub-menu-item--active",
};

export const SubMenuItem = ({ subMenu = {}, onClick, modifier }) => (
  <li
    className={cx(
      "a-sub-menu-item",
      modifier
        ? {
            [modifier]: true,
          }
        : {}
    )}
  >
    <Link
      className={cx("a-sub-menu-item__link")}
      to={subMenu.route}
      onClick={onClick}
    >
      <img src={subMenu.icon} alt={subMenu.title} width={20} height={20} />
      <span> {subMenu.title}</span>

      {subMenu.notificationCount && subMenu.notificationCount !== 0 ? (
        <span className={cx("a-sub-menu-item__link__notification_count")}>
          {subMenu.notificationCount}
        </span>
      ) : (
        <></>
      )}
    </Link>
  </li>
);
