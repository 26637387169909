import React from "react";
import { useSelector } from "react-redux";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./NotificationSettingCard.module.scss";

import Card from "../Card/Card";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import { Mixpanel } from "../../../MixPanel/mixpanel";
import Checkbox from "../Checkbox/Checkbox";

const cx = createModuleStyleExtractor(styles);

export const NetworkSettingCard = ({
  title,
  subTitle,
  pushValue,
  emailValue,
  updateValue,
  showEmailFrequency,
  emailFrequencyKey,
  onToggle = () => {},
  onFrequencyChange = () => {},
}) => {
  const notificationState = useSelector((state) => state.notificationState);
  const notificationSettings = notificationState.notificationSettings;
  const unsubscribePush = notificationState.unsubscribePush;
  const unsubscribeEmail = notificationState.unsubscribeEmail;

  const EMAIL_NOTIFICATION_FREQUENCY = [
    {
      label: "Daily",
      value: "daily",
    },
    {
      label: "Weekly",
      value: "weekly",
    },
  ];

  return (
    <Card>
      <div className={cx("a-notifications-settings-wrapper")}>
        <div className={cx("a-notifications-settings-content")}>
          {title && <h3>{title}</h3>}
          {subTitle && (
            <span className={cx("a-notifications-settings-content__subtitle")}>
              {subTitle}
            </span>
          )}

          {showEmailFrequency &&
            !unsubscribeEmail &&
            notificationSettings[emailValue] && (
              <div
                className={cx(
                  "a-notifications-settings-content__email-frequency"
                )}
              >
                <div
                  className={cx(
                    "a-notifications-settings-content__email-frequency__title"
                  )}
                >
                  Choose email frequency
                </div>
                <ul
                  className={cx(
                    "a-notifications-settings-content__email-frequency__options"
                  )}
                >
                  {EMAIL_NOTIFICATION_FREQUENCY.map((option, i) => (
                    <li key={i}>
                      <Checkbox
                        value={
                          notificationSettings[emailFrequencyKey] ===
                          option.value
                        }
                        onChange={() =>
                          onFrequencyChange(emailFrequencyKey, option.value)
                        }
                        name={option.label}
                      />

                      <span
                        className={cx(
                          "a-notifications-settings-content__email-frequency__options__name"
                        )}
                      >
                        {option.label}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
        </div>

        <div className={cx("a-notifications-settings-controls")}>
          <div className={cx("a-notifications-settings-controls-row")}>
            <span>In app push</span>
            <ToggleSwitch
              isChecked={!unsubscribePush && notificationSettings[pushValue]}
              setIsChecked={() => {
                Mixpanel.track("Notification settings changed", {
                  type: "push",
                  value: updateValue,
                  status:
                    !unsubscribePush && notificationSettings[pushValue]
                      ? "Off"
                      : "On",
                });
                onToggle("push", updateValue);
              }}
              isDisabled={unsubscribePush}
            />
          </div>
          <div className={cx("a-notifications-settings-controls-row")}>
            <span>Email</span>
            <ToggleSwitch
              isChecked={!unsubscribeEmail && notificationSettings[emailValue]}
              setIsChecked={() => {
                Mixpanel.track("Notification settings changed", {
                  type: "email",
                  value: updateValue,
                  status:
                    !unsubscribeEmail && notificationSettings[emailValue]
                      ? "Off"
                      : "On",
                });
                onToggle("email", updateValue);
              }}
              isDisabled={unsubscribeEmail}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default NetworkSettingCard;
