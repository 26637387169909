import React, { useState, memo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import Bugsnag from "@bugsnag/js";

import { createModuleStyleExtractor } from "../../../utils/css";
import { Button } from "../../atoms/Button/Button";
import { setLoginData } from "../../../redux/reducers/userReducer";
import styles from "./AuthSignUp.module.scss";
import {
  prospectParentSignUp,
  socialLoginAuthorization,
  socialLoginAuthorizationLinkedin,
  socialLoginAuthorizationGoogle,
} from "../../../api/onboarding";
import { ROUTES } from "../../../routes";
import AuthFacebook from "../../atoms/AuthFacebook/AuthFacebook";
import AuthGoogle from "../../atoms/AuthGoogle/AuthGoogle";
import AuthLinkedin from "../../atoms/AuthLinkedin/AuthLinkedin";
import toastify from "../../../utils/toast";
import BackIcon from "../../../assets/images/f-btn-arrow-gray.svg";
import MuiTextbox from "../../atoms/MuiTextbox/MuiTextbox";
import Checkbox from "../../atoms/Checkbox/Checkbox";
import {
  validateString,
  validateEmail,
  validatePassword,
  isEmpty,
} from "../../../utils/validations";
import { Mixpanel } from "../../../MixPanel/mixpanel";
import Link from "../../atoms/Link/Link";
import {
  getCommonSignInFields,
  setMSClarityTagsOnSignUp,
} from "../../../utils/common";
import { INTERSTRIDE_LOGO_URL } from "../../../utils/constants";

const cx = createModuleStyleExtractor(styles);

const AuthSignUp = ({
  goBackToLogin,
  goBackToRoleSelection,
  goToVerifyEmail,
  schoolData,
  role,
  token,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const validToken = schoolData && schoolData !== null;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [tnc, setTnc] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showCareerLink, setShowCareerLink] = useState(false);

  const handleLoginResponse = (response, source = "Normal") => {
    if (response.success && response.data) {
      const { users = {}, schools = [] } = response.data;

      sessionStorage.setItem("isLogin", "1");

      // Set user details for bugsnag
      Bugsnag.setUser(users.id, users.email, users.name);
      Mixpanel.identify(users.id);
      Mixpanel.track("Prospect sign up");
      setMSClarityTagsOnSignUp(users);

      dispatch(
        setLoginData({
          user: { ...users },
          schools,
        })
      );

      Mixpanel.track("Login successfully", {
        Source: source,
      });
      // Redirection to route after login is handled from App.js effects
      setIsSubmitting(false);
    } else {
      toastify(response.message);
      setIsSubmitting(false);
    }
  };

  const handleSignUpClick = async () => {
    setIsSubmitting(true);
    const params = {
      user: {
        email,
        password,
      },
      role,
      ...getCommonSignInFields(),
    };

    if (validToken) {
      params.school_id = schoolData.id;
      params.school_token = token;
    }

    const response = await prospectParentSignUp(params);

    if (response.success) {
      goToVerifyEmail();
      setIsSubmitting(false);
    } else {
      toastify(response.message);
      setIsSubmitting(false);
    }
  };

  const handleSocialLoginResponse = (response, Source) => {
    if (response.success && response.data) {
      // Don't allow user to login if authorized false for special scenarios of pending/revoked user
      if (response.data.authorized === false) {
        if (response.message) toastify(response.message);
        else toastify("Your email address is not registered with us.");
      }
      // If a user is registered and only has career access then show career link
      else if (response.data.career === true) {
        setShowCareerLink(true);
      }
      // If a user is SSO then redirect to university SSO login page
      else if (response.data.sso === true) {
        window.location.href =
          response.data.base_url +
          "?entityID=" +
          response.data.entity_id +
          "&target=" +
          response.data.target_url;
      }
      // If a user is registered
      else if (response.data.users && !isEmpty(response.data.users)) {
        handleLoginResponse(response, Source);
      }
      //  If a user is an invited ambassador but hasn't registered yet then show ambassador signup page
      else if (response.data.verified_ambassador === true) {
        navigate(`${ROUTES.AMBASSADOR_SIGN_UP}?redirect=login`, {
          replace: true,
        });
      }
      // No case is passed hence user is unauthorized.
      else {
        toastify(
          "Your email address is not registered with us. Please complete the process with Sign Up"
        );
      }
    } else {
      if (response.message) toastify(response.message);
      else toastify("Your email address is not registered with us.");
    }
  };

  const checkFacebookAuthentication = async (callbackData) => {
    console.log("Facebook callback", callbackData);
    if (
      callbackData &&
      callbackData.status !== "unknown" &&
      callbackData.accessToken
    ) {
      const params = {
        provider: "facebook",
        uid: callbackData.userID,
        id_token: callbackData.accessToken,
        info: {
          email: callbackData.email,
          first_name: callbackData.first_name,
          last_name: callbackData.last_name,
          profile_url: callbackData.picture
            ? callbackData.picture.data.url
            : "",
        },
        ...getCommonSignInFields(),
      };

      if (validToken) {
        params.school_id = schoolData.id;
        params.school_token = token;
      }

      const response = await socialLoginAuthorization(params);
      handleSocialLoginResponse(response, "Facebook");
    }
  };

  const checkGoogleAuthentication = async (callbackData) => {
    console.log("Google callback", callbackData);
    if (callbackData && !callbackData.error) {
      const url = new URL(window.location.href);
      const params = {
        provider: "google",
        code: callbackData.code,
        redirect_uri: url.origin,
        ...getCommonSignInFields(),
      };

      if (validToken) {
        params.school_id = schoolData.id;
        params.school_token = token;
      }

      const response = await socialLoginAuthorizationGoogle(params);
      handleSocialLoginResponse(response, "Google");
    }
  };

  const checkLinkedinAuthentication = async (code, redirect_uri) => {
    if (code) {
      const params = {
        provider: "linkedin",
        code,
        redirect_uri,
        ...getCommonSignInFields(),
      };
      if (validToken) {
        params.school_id = schoolData.id;
        params.school_token = token;
      }
      // Validate Code from BE API
      const response = await socialLoginAuthorizationLinkedin(params);
      handleSocialLoginResponse(response, "LinkedIn");
    } else {
      toastify("Oops! something went wrong. Please try again");
    }
  };

  return (
    <div className={cx("m-auth-sign-up-container")}>
      <div className={cx("m-auth-sign-up-container__back-btn")}>
        <a
          href="/sign-up"
          onClick={(e) => {
            e?.preventDefault && e.preventDefault();
            goBackToRoleSelection();
          }}
        >
          <img src={BackIcon} id="BackArrow" width="18px" alt="Back arrow" />
          <span>Back</span>
        </a>
      </div>
      <div className={cx("m-auth-sign-up-container__content")}>
        <div className={cx("m-auth-sign-up-container__content__unlogo")}>
          <img src={schoolData.logo_url} alt="University logo" />
        </div>
        <div className={cx("m-auth-sign-up-container__content__sitelogo")}>
          <h3>Powered by</h3>
          <img src={INTERSTRIDE_LOGO_URL} alt="interstride logo" />
        </div>
        <h2>Welcome to the Admissions Portal</h2>

        <h5>
          Already have an account?
          <a
            href="/login"
            onClick={(e) => {
              e?.preventDefault && e.preventDefault();
              goBackToLogin();
            }}
          >
            Log In
          </a>
        </h5>

        <h5>Sign up with an account</h5>
        <ul>
          <li>
            <AuthGoogle callback={checkGoogleAuthentication} />
          </li>
          <li>
            <AuthFacebook callback={checkFacebookAuthentication} />
          </li>
          <li>
            <AuthLinkedin callback={checkLinkedinAuthentication} />
          </li>
        </ul>

        <h5 className={cx("m-auth-sign-up-container__content--or")}>
          <span></span>
          <span className={cx("m-auth-sign-up-container__content--or--text")}>
            Or create an account with email
          </span>
          <span></span>
        </h5>

        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          initialTouched={{
            email: true,
          }}
          validateOnChange={true}
          validateOnBlur={true}
          validate={(values) => {
            let errors = {};

            if (validateString(values.email)) {
              errors.email = "Email is required";
            } else if (validateEmail(values.email)) {
              errors.email = "Email is not valid";
            }

            if (validateString(values.password)) {
              errors.password = "Password is required";
            } else if (values.password?.length < 8) {
              errors.password = "Password must have at least 8 characters";
            } else if (validatePassword(values.password)) {
              errors.password =
                "Password should have both upper and lowercase characters";
            }

            return errors;
          }}
          onSubmit={() => {
            handleSignUpClick();
          }}
        >
          {({ isValid }) => (
            <Form>
              <div
                className={cx("m-auth-sign-up-container__content__formfield")}
              >
                <Field name="email">
                  {({ field, meta }) => (
                    <div>
                      <MuiTextbox
                        {...field}
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          field.onChange(e);
                          setShowCareerLink(false);
                        }}
                        name={"email"}
                        label="Enter your email"
                        placeholder={"Enter your email"}
                        error={meta.touched && meta.error}
                      />
                    </div>
                  )}
                </Field>

                <Field name="password">
                  {({ field, meta }) => (
                    <div>
                      <MuiTextbox
                        {...field}
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          field.onChange(e);
                        }}
                        name={"password"}
                        label="Choose a password"
                        placeholder={"Choose a password"}
                        type="password"
                        error={meta.touched && meta.error}
                      />
                    </div>
                  )}
                </Field>

                {showCareerLink === true && (
                  <div
                    className={cx(
                      "m-auth-sign-up-container__content__formfield__career-text"
                    )}
                  >
                    You are already registered with our career portal. Please
                    click{" "}
                    <Link
                      href="https://student.interstride.com/"
                      target="_blank"
                    >
                      here
                    </Link>{" "}
                    to login to our career portal.
                  </div>
                )}

                <div
                  className={cx(
                    "m-auth-sign-up-container__content__formfield__keepMeSignin--T-C"
                  )}
                >
                  <Checkbox
                    value={tnc}
                    onChange={(e) => {
                      setTnc(e.target.checked);
                    }}
                    name={"tnc"}
                  />

                  <span>
                    I agree to the Interstride{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={"https://www.interstride.com/terms-and-conditions/"}
                      className="link-focus"
                    >
                      Terms & Conditions
                    </a>{" "}
                    and{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={"https://www.interstride.com/privacy-policy/"}
                      className="link-focus"
                    >
                      Privacy Policy
                    </a>
                  </span>
                </div>

                <Button
                  type="submit"
                  isSubmitting={isSubmitting}
                  disabled={!tnc || !isValid}
                >
                  Create account
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
export default memo(AuthSignUp);
