export const ViewIcon = () => {
  return (
    <svg
      width="21px"
      height="20px"
      viewBox="0 0 21 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Asset-Artboard-Page"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Action/Messages/Upload"
          transform="translate(0.222576, 0.000000)"
          fillRule="nonzero"
          stroke={"var(--primary-color)"}
        >
          <path
            d="M10,4 C5.90909091,4 2.41545455,6.488 1,10 C2.41545455,13.512 5.90909091,16 10,16 C14.0909091,16 17.5845455,13.512 19,10 C17.5845455,6.488 14.0909091,4 10,4 Z M10,14 C7.74181818,14 5.90909091,12.208 5.90909091,10 C5.90909091,7.792 7.74181818,6 10,6 C12.2581818,6 14.0909091,7.792 14.0909091,10 C14.0909091,12.208 12.2581818,14 10,14 Z M10,7.6 C8.64181818,7.6 7.54545455,8.672 7.54545455,10 C7.54545455,11.328 8.64181818,12.4 10,12.4 C11.3581818,12.4 12.4545455,11.328 12.4545455,10 C12.4545455,8.672 11.3581818,7.6 10,7.6 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
};
