import React, { memo } from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./SocialIcon.module.scss";
const cx = createModuleStyleExtractor(styles);

const SocialIcon = ({ icon, text = null, onClick }) => (
  <div className={cx("a-login-social-icon")}>
    <a
      href="/#"
      onClick={(e) => {
        e?.preventDefault && e.preventDefault();
        onClick && onClick();
      }}
    >
      {icon}
    </a>
    {text !== null && text}
  </div>
);

export default memo(SocialIcon);
