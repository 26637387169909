import React, { useState, useEffect, memo } from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./StudentServicesCategories.module.scss";

import FilterIcon from "../../../assets/Icons/FilterIcon";

const cx = createModuleStyleExtractor(styles);

const StudentServicesCategories = ({
  categories = [],
  selectedCategory,
  getServiceData = () => {},
}) => {
  const [openFilter, toggleFilter] = useState(false);

  useEffect(() => {
    if (selectedCategory !== null) toggleFilter(false);
  }, [selectedCategory]);

  return (
    <div
      className={`collapsible-section ${
        openFilter ? "collapsible-section--open" : ""
      }`}
    >
      <div id={"collapsible-section-header"}>
        <h2 className="only-for-mobile">Categories</h2>
        <a
          href="/#"
          onClick={(e) => {
            e?.preventDefault && e.preventDefault();
            toggleFilter(!openFilter);
          }}
          id="collapsible-section-header-icon"
        >
          <FilterIcon fill={openFilter} />
        </a>
      </div>
      <div id="collapsible-section-search-filter">
        <div className={cx("m-student-service-categories")}>
          <div className={cx("m-student-service-categories__wrapper")}>
            {categories.map((data, index) => (
              <a
                href
                className={cx([
                  "m-student-service-categories__wrapper__item",
                  data.id === selectedCategory.id
                    ? "m-student-service-categories__wrapper__item__active"
                    : "",
                ])}
                key={index}
                onClick={(e) => {
                  e?.preventDefault && e.preventDefault();
                  getServiceData(data);
                }}
              >
                <div
                  className={cx(
                    "m-student-service-categories__wrapper__item__image-wrapper"
                  )}
                >
                  <img src={data.logo_url} alt="logo" />
                </div>
                <span>{data.name}</span>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(StudentServicesCategories);
