import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import _debounce from "lodash/debounce";
import { ShimmerThumbnail } from "react-shimmer-effects";

import ResourcesCategories from "../../molecules/ResourcesCategories/ResourcesCategories";
import ResourceModal from "../../molecules/ResourceModal/ResourceModal";
import ResourceCard from "../../molecules/ResourceCard/ResourceCard";
import EmptyResources from "../../atoms/EmptyResources/EmptyResources";
import MuiSelect from "../../atoms/MuiSelect/MuiSelect";
import MuiTextbox from "../../atoms/MuiTextbox/MuiTextbox";
import {
  DEFAULT_RESOURCE_FILTER_OPTIONS,
  parseResourceAPIFilters,
} from "../../../utils/common";
import { createModuleStyleExtractor } from "../../../utils/css";
import toastify from "../../../utils/toast";
import {
  getResourceCategories,
  getResourcesByCategoryAndSortType,
  saveResource,
  getSavedResourcesList,
} from "../../../api/resources";
import styles from "./Resources.module.scss";
import { Mixpanel } from "../../../MixPanel/mixpanel";

import backArrowV2 from "../../../assets/svg/back-arrow-v2.svg";
import savePinIcon from "../../../assets/svg/save-pin.svg";
import arrowRight from "../../../assets/svg/arrow-right.svg";
import NoContentIllustration from "../../../assets/images/discover_no_people.svg";
import FaceliftSearchIcon from "../../../assets/Icons/FaceliftSearchIcon";

const cx = createModuleStyleExtractor(styles);
const pages = {
  MAIN: "main",
  RESOURCES: "resources",
  SAVED: "saved",
};
const resourcesSortOptions = [
  { value: "date", label: "Date added" },
  { value: "type", label: "Type" },
];

export default function Resources() {
  const userState = useSelector((state) => state.userState);
  const selectedSchool = userState.selectedSchool;

  const [page, setPage] = useState(pages.MAIN);
  const [previousPage, setPreviousPage] = useState(pages.MAIN);
  const [searchText, setSearchText] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoriesLoader, setCategoriesLoader] = useState(false);
  const [selectedCategoryResources, setSelectedCategoryResources] =
    useState(null);
  const [resourcesLoader, setResourcesLoader] = useState(false);
  const [resourceFilterOptions, setResourceFilterOptions] = useState(
    DEFAULT_RESOURCE_FILTER_OPTIONS
  );
  const [savedResources, setSavedResources] = useState([]);
  const [savedResourcesFilterOptions, setSavedResourcesFilterOptions] =
    useState([]);
  const [savedResourcesFilter, setSavedResourcesFilter] = useState(null);
  const [savedResourcesLoader, setSavedResourcesLoader] = useState(false);
  const [resourceSort, setResourceSort] = useState(
    resourcesSortOptions[0].value
  );
  const [resourceFilter, setResourceFilter] = useState(null);
  const [state, setModel] = useState({
    showModel: false,
    link_type: null,
    image: null,
    link: null,
    title: "",
  });

  // on DOM Load
  useEffect(() => {
    // Call APIs
    categoriesAPICall(searchText);
    savedResourcesAPICall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const categoriesAPICall = async (_searchText) => {
    try {
      setCategoriesLoader(true);

      const response = await getResourceCategories(
        _searchText.trim(),
        selectedSchool.id
      );

      if (response && response.success) {
        setCategories(response.data.video_categories);
        setCategoriesLoader(false);
      }
    } catch (error) {
      console.error(error);
      setCategoriesLoader(false);
    }
  };

  const savedResourcesAPICall = async (search, filter) => {
    try {
      setSavedResourcesLoader(true);
      const response = await getSavedResourcesList(
        selectedSchool.id,
        filter,
        search
      );
      if (response && response.success) {
        setSavedResources(response?.data?.saved_resources_list || []);
        setSavedResourcesFilterOptions(
          parseResourceAPIFilters(response?.data?.filter_resource_type || [])
        );
        setSavedResourcesLoader(false);
      }
    } catch (error) {
      console.error(error);
      setSavedResourcesLoader(false);
    }
  };

  const resourcesAPICall = async (video_category_id, sort_by, group_by) => {
    try {
      setResourcesLoader(true);
      const response = await getResourcesByCategoryAndSortType(
        selectedSchool.id,
        video_category_id,
        sort_by,
        group_by
      );

      if (response && response.success) {
        if (response?.data?.filter_resource_type) {
          setResourceFilterOptions(
            parseResourceAPIFilters(response.data.filter_resource_type)
          );
        }
        setSelectedCategoryResources(response.data.resources);
        setResourcesLoader(false);
      }
    } catch (error) {
      console.error(error);
      setResourcesLoader(false);
    }
  };

  const handleSearch = _debounce(() => {
    categoriesAPICall(searchText);
  }, 500);

  const handleSearchSavedResources = _debounce(() => {
    setSavedResourcesFilter(null);
    savedResourcesAPICall(searchText, null);
  }, 500);

  const handleClearSearch = () => {
    categoriesAPICall("");
    setSearchText("");
  };

  const navigationHandler = (_page, _selectedCategory = null) => {
    setResourceFilter(null);
    setSearchText("");
    if (_page === pages.MAIN) {
      categoriesAPICall("");
      savedResourcesAPICall();
      setResourceFilterOptions(DEFAULT_RESOURCE_FILTER_OPTIONS);
      setPage(pages.MAIN);
    } else if (_page === pages.RESOURCES) {
      setSelectedCategory(_selectedCategory);
      resourcesAPICall(_selectedCategory.id, resourceSort);
      savedResourcesAPICall();
      setPreviousPage(pages.MAIN);
      setPage(pages.RESOURCES);
    } else if (_page === pages.SAVED) {
      setResourceFilterOptions(DEFAULT_RESOURCE_FILTER_OPTIONS);
      setSavedResourcesFilter(null);
      savedResourcesAPICall();
      setPreviousPage(page);
      setPage(pages.SAVED);
    }
  };

  const handleFavorite = async (resource_id) => {
    try {
      const response = await saveResource(resource_id);
      if (response?.success) {
        if (response?.data === "") {
          if (page === pages.SAVED) {
            // Recall saved resources API if on saved resources page
            savedResourcesAPICall(searchText, savedResourcesFilter);
          } else {
            // Resource removed
            setSavedResources((current) =>
              current.filter((item) => item.id !== resource_id)
            );
          }
        } else {
          // resource added
          setSavedResources((current) => [...current, { id: resource_id }]);
        }
        toastify(response.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const modelHandler = (
    modelStatus = false,
    link_type = null,
    image = null,
    link = null,
    title = ""
  ) => {
    setModel({
      showModel: modelStatus,
      link_type,
      image,
      link,
      title,
    });
  };

  const handleFilterType = (item) => {
    if (page === pages.RESOURCES) {
      setResourceFilter(item);
      resourcesAPICall(selectedCategory.id, resourceSort, item.value);
    } else {
      setSavedResourcesFilter(item);
      savedResourcesAPICall(searchText, item);
    }
  };

  const handleClearFilterType = (e) => {
    e?.preventDefault && e.preventDefault();

    if (page === pages.RESOURCES) {
      setResourceFilter(null);
      setResourceSort(resourcesSortOptions[0].value); // Reset sort filter
      resourcesAPICall(selectedCategory.id, resourcesSortOptions[0].value);
    } else {
      setSearchText("");
      setSavedResourcesFilter(null);
      savedResourcesAPICall();
    }
  };

  const handleSavedResourcesFilterClick = (item) => {
    setSavedResourcesFilter(item);
    savedResourcesAPICall(searchText, item);
  };

  // JSX Element for reusable resource card
  const resourceCard = (resource, index) => {
    const isResourceFavorite =
      savedResources.findIndex((item) => item.id === resource.id) === -1
        ? false
        : true;
    let categoryName = "";
    if (page === pages.MAIN || page === pages.SAVED) {
      const _index = categories.findIndex(
        (category) => category.id === resource.video_category_id
      );
      if (_index !== -1) {
        categoryName = categories[_index].category_name;
      }
    }
    return (
      <ResourceCard
        key={index}
        resource={resource}
        index={index}
        isResourceFavorite={isResourceFavorite}
        categoryName={categoryName}
        handleFavorite={handleFavorite}
        onClick={(e) => {
          e?.preventDefault && e.preventDefault();
          Mixpanel.track("Resource clicked", {
            schoolID: selectedSchool.id,
            objId: resource.id,
            objClass: "Video",
          });
          ["Interstride Video", "YouTube Video", "PDF"].includes(
            resource.link_type
          )
            ? modelHandler(
                true,
                resource.link_type,
                resource.image,
                resource.link,
                resource.title
              )
            : window.open(
                resource.link_type === "Website Link"
                  ? resource.link
                  : resource.image
              );
        }}
      />
    );
  };

  // JSX Element for reusbale resource card loader
  const resourceCardLoaders = () =>
    [...Array(4)].map((_item, index) => (
      <ShimmerThumbnail key={index} width={255} height={297} rounded />
    ));

  return (
    <div className={cx("m-resources")}>
      <div className={cx("m-resources__header")}>
        <h2>
          {page === pages.MAIN
            ? "Explore our Resources"
            : page === pages.RESOURCES
            ? selectedCategory.category_name
            : "Your Saved Resources"}
        </h2>
      </div>
      {page === pages.MAIN ? (
        <>
          <div className={cx("m-resources__search")}>
            <div className={cx("m-resources__search__input")}>
              <MuiTextbox
                onEnterKey={handleSearch}
                name="search"
                placeholder="Search resources"
                value={searchText}
                onChange={(event) => setSearchText(event.target.value)}
              />
            </div>
            <div className={cx("m-resources__search__controls")}>
              <a
                className="no-outline"
                href
                onClick={(e) => {
                  e?.preventDefault && e.preventDefault();
                  handleSearch();
                }}
              >
                <FaceliftSearchIcon />
              </a>
              {searchText && (
                <a
                  className={cx("m-resources__search__controls__clear")}
                  href
                  onClick={(e) => {
                    e?.preventDefault && e.preventDefault();
                    handleClearSearch();
                  }}
                >
                  <span>Clear all</span>
                </a>
              )}
            </div>
          </div>
          <div className={cx("m-resources__body")}>
            <ResourcesCategories
              categories={categories}
              categoriesLoader={categoriesLoader}
              navigationHandler={(item) =>
                navigationHandler(pages.RESOURCES, item)
              }
            />
            {savedResources.length > 0 && (
              <div className={cx("m-resources__body__saved")}>
                <div className={cx("m-resources__body__saved__title")}>
                  <h2>Your saved resources</h2>
                  <div
                    className={cx("m-resources__body__saved__title__view-all")}
                  >
                    <span onClick={() => navigationHandler(pages.SAVED, null)}>
                      View all (
                      {savedResourcesLoader ? "Loading" : savedResources.length}
                      )
                    </span>
                  </div>
                </div>
                <div className={cx("m-resources__body__saved__body")}>
                  {savedResourcesLoader ? (
                    resourceCardLoaders()
                  ) : savedResources.length > 0 ? (
                    <>
                      {savedResources.map((resource, index) =>
                        resourceCard(resource, index)
                      )}
                    </>
                  ) : (
                    <EmptyResources />
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className={cx("m-resources__navigation")}>
            <a
              href
              className={cx([
                "m-resources__navigation__back-to-categories",
                page === pages.SAVED
                  ? "m-resources__navigation__back-to-categories--saved"
                  : "",
              ])}
              onClick={(e) => {
                e?.preventDefault && e.preventDefault();
                if (previousPage === pages.MAIN) {
                  navigationHandler(pages.MAIN);
                } else {
                  navigationHandler(pages.RESOURCES, selectedCategory);
                }
              }}
            >
              <img src={backArrowV2} alt="back-arrow" />
              <span>
                {page === pages.RESOURCES ? "Back to Resources" : "Back"}
              </span>
            </a>
            {page === pages.RESOURCES && savedResources?.length > 0 && (
              <a
                href
                className={cx("m-resources__navigation__to-saved-service")}
                onClick={(e) => {
                  e?.preventDefault && e.preventDefault();
                  navigationHandler(pages.SAVED, null);
                }}
              >
                <img src={savePinIcon} alt="save-pin" />
                <span>Your saved resources ({savedResources.length})</span>
                <img src={arrowRight} alt="arrow-right" />
              </a>
            )}
          </div>
          {(page === pages.RESOURCES || page === pages.SAVED) && (
            <div className={cx("m-resources__filters")}>
              {page === pages.SAVED && (
                <div className={cx("m-resources__filters__search")}>
                  <div className={cx("m-resources__filters__search__input")}>
                    <MuiTextbox
                      onEnterKey={handleSearchSavedResources}
                      name="search"
                      placeholder="Search saved resources"
                      value={searchText}
                      onChange={(event) => setSearchText(event.target.value)}
                    />
                  </div>
                  <div className={cx("m-resources__filters__search__controls")}>
                    <a
                      href
                      onClick={(e) => {
                        e?.preventDefault && e.preventDefault();
                        handleSearchSavedResources();
                      }}
                    >
                      <FaceliftSearchIcon />
                    </a>
                    {(searchText || savedResourcesFilter) && (
                      <div
                        className={cx(
                          "m-resources__filters__search__controls__clear"
                        )}
                      >
                        <a href onClick={handleClearFilterType}>
                          Clear all
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {page === pages.SAVED &&
                !savedResourcesLoader &&
                savedResourcesFilterOptions?.length > 0 && (
                  <>
                    <div className={cx("m-resources__filters__type")}>
                      <div className={cx("m-resources__filters__type__items")}>
                        <div
                          className={cx(
                            "m-resources__filters__type__items__heading"
                          )}
                        >
                          <span>Filter</span>
                        </div>
                        {savedResourcesFilterOptions.map((item, index) => (
                          <a
                            href
                            className={cx([
                              "m-resources__filters__type__items__item",
                              savedResourcesFilter === item.value
                                ? "m-resources__filters__type__items__item--active"
                                : "",
                            ])}
                            key={index}
                            onClick={(e) => {
                              e?.preventDefault && e.preventDefault();
                              handleSavedResourcesFilterClick(item.value);
                            }}
                          >
                            <span>{item.label}</span>
                          </a>
                        ))}
                      </div>
                    </div>
                  </>
                )}
              {page === pages.RESOURCES &&
                !resourcesLoader &&
                selectedCategoryResources.length !== 0 && (
                  <>
                    <div className={cx("m-resources__filters__type")}>
                      <div className={cx("m-resources__filters__type__items")}>
                        <div
                          className={cx(
                            "m-resources_filters__type__items__heading"
                          )}
                        >
                          <span>Filter</span>
                        </div>
                        {resourceFilterOptions.map((item, index) => (
                          <a
                            href
                            className={cx([
                              "m-resources__filters__type__items__item",
                              resourceFilter &&
                              resourceFilter.value === item.value
                                ? "m-resources__filters__type__items__item--active"
                                : "",
                            ])}
                            key={index}
                            onClick={(e) => {
                              e?.preventDefault && e.preventDefault();
                              handleFilterType(item);
                            }}
                          >
                            <span>{item.label}</span>
                          </a>
                        ))}
                        {(resourceFilter !== null || searchText) && (
                          <div
                            className={cx(
                              "m-resources__filters__type__items__clear-all"
                            )}
                          >
                            <a href onClick={handleClearFilterType}>
                              Clear all
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={cx("m-resources__filters__sort")}>
                      <MuiSelect
                        name="resourcesSort"
                        options={resourcesSortOptions}
                        value={resourcesSortOptions.find(
                          (item) => item.value === resourceSort
                        )}
                        justifyContent="left"
                        onChange={(e) => {
                          setResourceSort(e.value);
                          resourcesAPICall(
                            selectedCategory.id,
                            e.value,
                            resourceFilter?.value
                          );
                        }}
                      />
                    </div>
                  </>
                )}
            </div>
          )}
          <div className={cx("m-resources__listing")}>
            {page === pages.RESOURCES && resourcesLoader ? (
              <div className={cx("m-resources__listing__loaders")}>
                {resourceCardLoaders()}
              </div>
            ) : page === pages.RESOURCES && !resourcesLoader ? (
              <div className={cx("m-resources__listing__cards")}>
                {selectedCategoryResources.length === 0 ? (
                  <div className={cx("m-resources__no-saved-resources")}>
                    <img src={NoContentIllustration} alt="no Categories" />
                    <h3>Looks like there are no resources with this search.</h3>
                    <h4>
                      Try expanding your search to discover other resources!
                    </h4>
                  </div>
                ) : (
                  selectedCategoryResources.map((item, index) =>
                    resourceCard(item, index)
                  )
                )}
              </div>
            ) : (
              <div className={cx("m-resources__listing__cards")}>
                {savedResourcesLoader ? (
                  resourceCardLoaders()
                ) : savedResources.length === 0 ? (
                  <EmptyResources />
                ) : (
                  <>
                    {savedResources.map((item, index) =>
                      resourceCard(item, index)
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </>
      )}
      <ResourceModal state={state} onClose={() => modelHandler(false)} />
    </div>
  );
}
