import React, { memo } from "react";
import { useSearchParams } from "react-router-dom";

import { Col } from "../../helpers/Grid/Col";
import { Row } from "../../helpers/Grid/Row";
import styles from "./ForgotPassword.module.scss";
import Perspective from "../../molecules/Perspective/Perspective";
import { createModuleStyleExtractor } from "../../../utils/css";
import ForgotPassword from "../../molecules/ForgotPassword/ForgotPassword";

const cx = createModuleStyleExtractor(styles);

const ForgotPasswordOrganism = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.getAll("email")
    ? decodeURIComponent(searchParams.get("email"))
    : false;

  return (
    <Row className={cx("o-forgot-password-container")}>
      <Col xs={12} sm={6} md={6} lg={6}>
        <Perspective schoolData={null} />
      </Col>
      <Col xs={12} sm={6} md={6} lg={6}>
        <ForgotPassword defaultEmail={email} />
      </Col>
    </Row>
  );
};
export default memo(ForgotPasswordOrganism);
