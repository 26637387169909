import React, { memo, useState } from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./WidgetMessagesTab.module.scss";
import { isEmpty } from "../../../utils/validations";
import SingleSelectCard from "../../atoms/SingleSelectCard/SingleSelectCard";
import { CircularAvatar } from "../../atoms/CircularAvatar/CircularAvatar";
import {
  getUniversityVisitURL,
  ROUTE_TYPES,
  UNIVERSITY_WIDGET_SOURCE,
} from "../../../utils/common";
import { Button } from "../../atoms/Button";
import MenuDotsHorizontal from "../../../assets/Icons/MenuDotsHorizontal";
import ChatTextBox from "../ChatTextBox/ChatTextBox";

const cx = createModuleStyleExtractor(styles);

const WidgetMessagesTab = ({ token = null, chatDetails = {} }) => {
  const showChatView = chatDetails && !isEmpty(chatDetails) ? true : false;
  const [role, setRole] = useState();

  const {
    id = "",
    name = "",
    first_name = "",
    profile_image_url = "",
    view = "map",
  } = chatDetails;

  const onSignupClick = () => {
    window.open(
      getUniversityVisitURL(token, UNIVERSITY_WIDGET_SOURCE, {
        role,
        route_type: ROUTE_TYPES.CHAT,
        route_id: id,
        route_view: view,
      })
    );
  };

  return (
    <div className={cx("m-widget-messages-tab")}>
      {showChatView ? (
        <div className={cx("m-widget-messages-tab__chat-view")}>
          <div className={cx("m-widget-messages-tab__chat-view__post")}>
            <div
              className={cx("m-widget-messages-tab__chat-view__post__content")}
            >
              <CircularAvatar
                src={profile_image_url}
                name={name}
                round={true}
                size={73}
                wrapperClass={cx(
                  "m-widget-messages-tab__chat-view__post__content__pic"
                )}
              />
              <h2> Chat with {first_name}</h2>

              <div
                className={cx(
                  "m-widget-messages-tab__chat-view__post__content__menu"
                )}
              >
                <span
                  className={cx(
                    "m-widget-messages-tab__chat-view__post__content__menu__icon"
                  )}
                >
                  <MenuDotsHorizontal />
                </span>
              </div>
            </div>
            <ChatTextBox disabled={true} />
          </div>
          <div
            className={cx("m-widget-messages-tab__chat-view__create-account")}
          >
            <div
              className={cx(
                "m-widget-messages-tab__chat-view__create-account__overlay"
              )}
            >
              <div
                className={cx(
                  "m-widget-messages-tab__chat-view__create-account__overlay__title"
                )}
              >
                Create a free account to chat with {first_name}
              </div>
              <div
                className={cx(
                  "m-widget-messages-tab__chat-view__create-account__overlay__sub-title"
                )}
              >
                Send {first_name} a message and get notified when they reply.
              </div>
              <h5>
                Already have an account?
                <a
                  href={getUniversityVisitURL(token, UNIVERSITY_WIDGET_SOURCE, {
                    route_type: ROUTE_TYPES.CHAT,
                    route_id: id,
                    route_view: view,
                  })}
                  target="_blank"
                  rel="noreferrer"
                >
                  Log In
                </a>
              </h5>
              <div
                className={cx(
                  "m-widget-messages-tab__chat-view__create-account__overlay__select"
                )}
              >
                <SingleSelectCard
                  header={"Before creating an account, tell us who you are."}
                  options={[
                    {
                      value: "prospect",
                      title: "I'm a student",
                    },
                    {
                      value: "parent",
                      title: "I'm a parent",
                    },
                  ]}
                  value={role}
                  onClick={(value) => setRole(value)}
                />
              </div>
              <Button type="submit" disabled={!role} onClick={onSignupClick}>
                Get started
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className={cx("m-widget-messages-tab__signup-view")}>
          <h2>Sign up to start chatting</h2>
          <h5>
            Already have an account?
            <a
              href={getUniversityVisitURL(token, UNIVERSITY_WIDGET_SOURCE, {
                route_type: ROUTE_TYPES.CHAT,
                route_id: id,
                route_view: view,
              })}
              target="_blank"
              rel="noreferrer"
            >
              Log In
            </a>
          </h5>
          <div className={cx("m-widget-messages-tab__signup-view__select")}>
            <SingleSelectCard
              header={"Before creating an account, tell us who you are."}
              options={[
                {
                  value: "prospect",
                  title: "I'm a student",
                },
                {
                  value: "parent",
                  title: "I'm a parent",
                },
              ]}
              value={role}
              onClick={(value) => setRole(value)}
            />
          </div>
          <Button type="submit" disabled={!role} onClick={onSignupClick}>
            Get started
          </Button>
        </div>
      )}
    </div>
  );
};
export default memo(WidgetMessagesTab);
