import React from "react";
import { useDropzone } from "react-dropzone";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./CustomDropzone.module.scss";

const cx = createModuleStyleExtractor(styles);

export default function CustomDropzone({
  handleUploadFile = () => {}, // Callback receives as array of files
  textOne = "Drop the files here ...", // Text displayed when file dragged atop the component
  textTwo = "Drag 'n' drop some files here, or click to select files", // Text displayed when file not dragged atop the component
  validFiles = { "image/*": [] }, //The value must be an object with a common MIME type as keys and an array of file extensions as values. Default to accept all images
  multiple = false,
}) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleUploadFile,
    multiple: multiple,
    accept: validFiles,
  });

  return (
    <div className={cx("a-custom-dropzone")} {...getRootProps()}>
      <input {...getInputProps()} accept={validFiles} />
      {isDragActive ? (
        <div className={cx("a-custom-dropzone__text_one")}>
          <span>{textOne}</span>
        </div>
      ) : (
        <div className={cx("a-custom-dropzone__text_two")}>
          <span>{textTwo}</span>
        </div>
      )}
    </div>
  );
}
