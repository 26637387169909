import React from "react";
import { ClickAwayListener, TextareaAutosize } from "@mui/material";
import Picker from "emoji-picker-react";

import { Button } from "../../../atoms/Button";

import emojiIcon from "../../../../assets/svg/community-emoji-icon.svg";

export default function CreateComment({
  commentText = "",
  emojiContainer = false,
  maxCommentLength = 500,
  apiFetching = false,
  edit = false,
  commentMenuState = {},
  setCommentText = () => {},
  handleSubmitComment = () => {},
  setEmojiContainer = () => {},
  setCommentMenuState = () => {},
}) {
  const onEmojiClick = (emojiObject) => {
    if (emojiObject?.emoji) {
      if (edit) {
        setCommentMenuState((current) => ({
          ...current,
          comment: {
            ...current?.comment,
            comment: current?.comment?.comment + emojiObject.emoji,
          },
        }));
      } else {
        setCommentText((current) => current + emojiObject.emoji);
      }
    }
    setEmojiContainer(false);
  };

  const handleChangeText = (text = "") => {
    if (edit) {
      setCommentMenuState((current) => ({
        ...current,
        comment: {
          ...current?.comment,
          comment: text,
        },
      }));
    } else {
      setCommentText(text);
    }
  };

  return (
    <div className="comment-box">
      <div className="comment-box__text">
        <TextareaAutosize
          name="comment"
          placeholder="Write your reply"
          value={edit ? commentMenuState?.comment?.comment : commentText}
          onChange={(e) => handleChangeText(e.target.value)}
          minRows={1}
          maxRows={2}
          maxLength={maxCommentLength}
        />
      </div>
      <div className="comment-box__action">
        <div className="comment-box__action__left">
          <div className="emoji-picker-wrapper">
            <img
              src={emojiIcon}
              alt="emoji"
              onClick={() => setEmojiContainer((current) => !current)}
              className="emoji-icon"
            />
            {emojiContainer && (
              <ClickAwayListener onClickAway={() => setEmojiContainer(false)}>
                <div className="emoji-picker">
                  <Picker
                    onEmojiClick={onEmojiClick}
                    skinTonesDisabled
                    width="100%"
                  />
                </div>
              </ClickAwayListener>
            )}
          </div>
        </div>
        <div className="comment-box__action__post">
          <Button
            isSubmitting={apiFetching}
            isDisabled={
              edit
                ? commentMenuState?.comment?.comment?.trim() === ""
                : commentText.trim() === ""
            }
            onClick={() => handleSubmitComment()}
          >
            {apiFetching ? "" : "Post"}
          </Button>
        </div>
      </div>
    </div>
  );
}
