import React, { memo } from "react";
import { useLinkedIn } from "react-linkedin-login-oauth2";

import toastify from "../../../utils/toast";
import {
  LINKEDIN_CLIENT_ID,
  LINKEDIN_REDIRECT_URI,
} from "../../../config/config";
import LinkedinLoginIcon from "../../../assets/Icons/LinkedinLoginIcon";

import SocialIcon from "../SocialIcon/SocialIcon";

const AuthLinkedin = ({ callback = () => {} }) => {
  const handleFailure = (error) => {
    toastify("error", error.errorMessage);
  };
  // Linkedin Button Hook
  const { linkedInLogin } = useLinkedIn({
    clientId: LINKEDIN_CLIENT_ID,
    redirectUri: LINKEDIN_REDIRECT_URI,
    onSuccess: (code) => {
      callback(code, LINKEDIN_REDIRECT_URI);
    },
    onError: handleFailure,
    scope: "r_liteprofile,r_emailaddress",
  });

  return (
    <SocialIcon
      text={"LinkedIn"}
      icon={<LinkedinLoginIcon />}
      onClick={linkedInLogin}
    />
  );
};

export default memo(AuthLinkedin);
