import NotificationSettingCard from "../../../atoms/NotificationSettingCard/NotificationSettingCard";

export default function NetworkSettings({ onToggle, onFrequencyChange }) {
  // const [groupOneOptions] = useState(["Individual email", "Weekly digest", "Never"]);
  // const [selectedGroupOneOption, setSelectedGroupOneOption] = useState(0);

  return (
    <>
      <NotificationSettingCard
        title={"Messages from network"}
        subTitle={"Receive alerts whenever someone sends you a message."}
        pushValue={"network_message_push"}
        emailValue={"network_message_email"}
        updateValue="network_messages"
        onToggle={onToggle}
        showEmailFrequency={true}
        emailFrequencyKey={"unread_messages_job_frequency"}
        onFrequencyChange={onFrequencyChange}
      />
      <NotificationSettingCard
        title={"Messages from group chats"}
        subTitle={
          "Receive alerts whenever someone sends a message in your group chats."
        }
        pushValue={"group_message_push"}
        emailValue={"group_message_email"}
        updateValue="group_messages"
        onToggle={onToggle}
        showEmailFrequency={true}
        emailFrequencyKey={"unread_group_messages_job_frequency"}
        onFrequencyChange={onFrequencyChange}
      />

      {/* <Card>
        <div className={cx("o-notifications-listing-list-item-wrapper")}>
          <div className={cx("o-notifications-listing-list-item-content")}>
            <h3>Messages you have received</h3>
            <div className={cx("o-notifications-listing-list-item-group-options-wrapper")}>
              <div className={cx("o-notifications-listing-list-item-group-options-list")}>
                {groupOneOptions.map((option, index) => (
                  <div
                    className={cx("o-notifications-listing-list-item-group-options-item")}
                    key={index}
                  >
                    <input
                      checked={selectedGroupOneOption === index ? true : false}
                      type="radio"
                      value={index}
                      onChange={() => setSelectedGroupOneOption(index)}
                    />
                    <span>{option}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Card> */}
    </>
  );
}
