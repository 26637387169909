import React, { useEffect, useRef } from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";

import MuiSelect from "../../atoms/MuiSelect/MuiSelect";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./AlumniNationalityGraph.module.scss";
import Card from "../../atoms/Card/Card";
import { getGraphData } from "../../../api/employmentDataHub";
import MarkerWorldMap from "../../atoms/MarkerWorldMap/MarkerWorldMap";
import { createFriendlyTitle } from "../../../utils/common";

import NoDataImage from "../../../assets/images/chart_placeholder.png";
import FilterIcon from "../../../assets/Icons/FilterIcon";

const cx = createModuleStyleExtractor(styles);

export default function AlumniNationalityGraph({
  filters = {},
  state,
  setState,
  openFilter,
  toggleFilter,
  schoolId,
  processGraphClick,
}) {
  // Calls API upon component and/or selected school change
  useEffect(() => {
    (async () => {
      const response = await getGraphData(
        schoolId,
        "nationality",
        state?.selectedFilters?.major?.value,
        state?.selectedFilters?.year?.value,
        null,
        state?.selectedFilters?.degree?.value
      );
      if (response?.success && response?.data?.length > 0) {
        // Process data
        let _data_set = {};
        response?.data?.forEach((item) => {
          const formattedName = createFriendlyTitle(item?.nationality);
          _data_set[formattedName] = item?.count;
        });
        setState((current) => ({
          ...current,
          loader: false,
          data_set: _data_set,
        }));
        return;
      }
      setState((current) => ({
        ...current,
        loader: false,
        data_set: null,
      }));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolId, state?.selectedFilters]);

  // We are storing reference of state to use in callback of graph click
  // so that when ever the click call back is triggered on graph, it has the latest state
  const stateRef = useRef(state);
  // Update stateRef with latest state
  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  return (
    <div className={cx("m-alum-nat")}>
      <div
        className={`collapsible-section ${
          openFilter ? "collapsible-section--open" : ""
        }`}
      >
        <div
          className={cx("m-alum-nat__header")}
          id={"collapsible-section-header"}
        >
          <h2 className={cx("m-alum-nat__header__title")}>
            Nationality of international graduates with employment
          </h2>
          <a
            href="/#"
            onClick={(e) => {
              e?.preventDefault && e.preventDefault();
              toggleFilter(!openFilter);
            }}
            id="collapsible-section-header-icon"
          >
            <FilterIcon fill={openFilter} />
          </a>
          <div
            className={cx("m-alum-nat__header__filters")}
            id="collapsible-section-search-filter"
          >
            <MuiSelect
              placeholder="Filter by degree"
              justifyContent
              isClearable={true}
              isDisabled={state.loader}
              options={filters?.degree}
              value={state?.selectedFilters?.degree}
              onChange={(option) =>
                setState((current) => ({
                  ...current,
                  selectedFilters: {
                    ...current.selectedFilters,
                    degree: option,
                  },
                }))
              }
            />
            <MuiSelect
              placeholder="Filter by major"
              justifyContent
              isClearable={true}
              isDisabled={state.loader}
              options={filters?.major}
              value={state?.selectedFilters?.major}
              onChange={(option) =>
                setState((current) => ({
                  ...current,
                  selectedFilters: {
                    ...current.selectedFilters,
                    major: option,
                  },
                }))
              }
            />
            <MuiSelect
              placeholder="Filter by year"
              justifyContent
              isClearable={true}
              isDisabled={state.loader}
              options={filters?.year}
              value={state?.selectedFilters?.year}
              onChange={(option) =>
                setState((current) => ({
                  ...current,
                  selectedFilters: {
                    ...current.selectedFilters,
                    year: option,
                  },
                }))
              }
            />
          </div>
        </div>
      </div>
      <div className={cx("m-alum-nat__body")}>
        <Card>
          <div className={cx("m-alum-nat__body__card")}>
            {state.loader ? (
              <div className={cx("m-alum-nat__body__card__loader")}>
                <ShimmerThumbnail rounded />
              </div>
            ) : !state.loader &&
              (state.data_set === null ||
                Object.keys(state.data_set)?.length === 0) ? (
              <div className={cx("m-alum-nat__body__card__no-data")}>
                <img src={NoDataImage} alt="No Data Available" />
                <h3>No data available</h3>
              </div>
            ) : !state.loader && Object.keys(state.data_set)?.length > 0 ? (
              <div className={cx("m-alum-nat__body__card__graph")}>
                <MarkerWorldMap
                  height={450}
                  data={state.data_set}
                  valueLabel="Alumni"
                  onClick={(nationality) =>
                    processGraphClick(
                      { label: nationality, value: nationality },
                      "AlumniNationality",
                      stateRef?.current // use Reference to view latest state
                    )
                  }
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
}
