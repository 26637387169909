import React, { memo, useState, useEffect } from "react";
import { Tooltip } from "@mui/material";
import { useSelector } from "react-redux";

import { isNull } from "../../../utils/validations";
import ChecklistTextbox from "../../atoms/ChecklistTextbox/ChecklistTextbox";
import { Button, ButtonModifier } from "../../atoms/Button/Button";
import RadioButton from "../../atoms/RadioButton/RadioButton";
import styles from "./ChecklistSubtask.module.scss";
import { createModuleStyleExtractor } from "../../../utils/css";

import deleteIcon from "../../../assets/svg/f-delete-icon.svg";
import editIcon from "../../../assets/svg/f-edit-icon.svg";

const cx = createModuleStyleExtractor(styles);

const ChecklistSubtask = ({
  task,
  createSubTask,
  subCategoryId,
  mainIndex,
  subIndex,
  subTaskMark,
  removeSubTaskStaticFn,
  removeSubTask,
  editSubtask,
  editTask,
  showAddEditDeleteButton = true,
  activeEditTask,
  activeEditSubTask,
  setActiveEditSubTask,
  setHideSubtaskButton,
}) => {
  const [taskName, setTaskName] = useState("");
  const [taskId, setTaskId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [showTooltip, setShowTooltip] = useState(null);

  const userState = useSelector((state) => state.userState);
  const userId = userState.user.id;

  useEffect(() => {
    task.title && setTaskName(task.title);
    task.is_mark === "true" && setIsEdit(false);
  }, [task]);

  useEffect(() => {
    if (task.title !== taskName) {
      setActiveEditSubTask({
        id: task.id,
        title: taskName,
        task_id: task.task_id,
      });
    } else {
      setActiveEditSubTask(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskName]);

  return (
    <div className={cx("m-checklist-subtask")}>
      <div className={cx("m-checklist-subtask__container")}>
        <RadioButton
          id={`subtask-${task.id}`}
          value={task.is_mark}
          onChange={(e) => {
            if (task.id !== undefined) {
              setIsEdit(false);
              subTaskMark(task.id, e.target.checked);
            }
          }}
          inputProps={{ "aria-label": taskName ?? "task" }}
        />

        <div
          className={cx([
            "m-checklist-subtask__container__text",
            ...(task.is_mark === "true"
              ? ["m-checklist-subtask__container__text--completed"]
              : []),
          ])}
        >
          {isEdit ? (
            <ChecklistTextbox
              placeholder="Add to subtask"
              value={taskName}
              onChange={(e) => {
                setTaskName(e.target.value);
                setHideSubtaskButton(false);
              }}
            />
          ) : task.title === undefined ? (
            <ChecklistTextbox
              type="text"
              placeholder="Add to subtask"
              value={taskName}
              onChange={(e) => setTaskName(e.target.value)}
            />
          ) : (
            taskName
          )}
        </div>

        {isEdit && activeEditSubTask?.id === taskId ? (
          <Button
            onClick={() => {
              setIsEdit(false);
              setHideSubtaskButton(false);
              taskName &&
                taskName.trim().length > 0 &&
                editSubtask(task.id, taskName, task.task_id);
            }}
            modifier={ButtonModifier.CHECKLIST_SAVE}
          >
            Save task
          </Button>
        ) : task.title === undefined ? (
          <Button
            onClick={() => {
              setHideSubtaskButton(false);
              taskName &&
                taskName.trim().length > 0 &&
                createSubTask(subCategoryId, taskName);
            }}
            modifier={ButtonModifier.CHECKLIST_SAVE}
          >
            Save task
          </Button>
        ) : null}

        {task &&
          !!task.title &&
          task.user_id === userId &&
          showAddEditDeleteButton && (
            <Tooltip
              title="Edit subtask"
              placement="bottom"
              open={!isNull(showTooltip) && showTooltip === `edit-${task.id}`}
              disableHoverListener
              onMouseEnter={() => setShowTooltip(`edit-${task.id}`)}
              onMouseLeave={() => setShowTooltip(null)}
              enterTouchDelay={0}
            >
              <a
                href="/#"
                onClick={(e) => {
                  e?.preventDefault && e.preventDefault();
                  if (activeEditSubTask && taskId !== activeEditSubTask?.id) {
                    editSubtask(
                      activeEditSubTask.id,
                      activeEditSubTask.title,
                      activeEditSubTask.task_id,
                      false
                    );
                  }
                  if (activeEditTask) {
                    editTask(activeEditTask.id, activeEditTask.taskName);
                  }
                  setTaskId(task.id);
                  setActiveEditSubTask({
                    id: task.id,
                    title: taskName,
                    task_id: task.task_id,
                  });
                  setIsEdit(true);
                }}
                className={cx("m-checklist-subtask__container__button-edit")}
                aria-label={`Edit Subtask ${taskName}`}
                aria-describedby={`subtask-${task.id}`}
              >
                <img src={editIcon} alt="Edit task icon" />
              </a>
            </Tooltip>
          )}
        {showAddEditDeleteButton && (
          <Tooltip
            title="Delete subtask"
            placement="bottom"
            open={!isNull(showTooltip) && showTooltip === task.id}
            disableHoverListener
            onMouseEnter={() => setShowTooltip(task.id)}
            onMouseLeave={() => setShowTooltip(null)}
            enterTouchDelay={0}
          >
            <button
              className={cx("m-checklist-subtask__container__button-close")}
              onClick={() => {
                setHideSubtaskButton(false);
                task.id
                  ? removeSubTask(task.id)
                  : removeSubTaskStaticFn(mainIndex, subIndex);
              }}
              aria-label={`Close Subtask ${taskName}`}
              aria-describedby={`subtask-${task.id}`}
            >
              <img src={deleteIcon} alt="Delete task icon" />
            </button>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default memo(ChecklistSubtask);
