import React, { memo } from "react";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

import { processWebinarsCalendarEventsV2 } from "../../../../utils/common";
import HandshakeRssLogo from "../../../../assets/images/handshake_rss_logo.png";

const WebinarsCalendarV2 = ({
  myWebinars = [],
  handleViewDetailsClick = () => {},
}) => {
  const calendarEvents = processWebinarsCalendarEventsV2(myWebinars);

  const renderEventContent = ({ event }) => {
    const startTime = moment(event.start).format("hh:mm a");
    let eventClass =
      event.extendedProps.type === "upcoming" ? "upcoming-event" : "past-event";
    return (
      <div className={eventClass}>
        {event.extendedProps.rss_feed_id &&
        event.extendedProps.rss_feed_id != null ? (
          <img
            src={HandshakeRssLogo}
            className="custom-rss-icon"
            alt="RSS Icon"
          />
        ) : (
          ""
        )}
        {startTime ? (
          <span>
            <strong>{startTime}</strong> {" - "} {event.title}
          </span>
        ) : (
          <span>{event.title}</span>
        )}
      </div>
    );
  };

  return (
    <div className="m-webinar-calendar">
      <FullCalendar
        headerToolbar={{
          left: "",
          center: "prev,title,next",
          right: "",
        }}
        plugins={[interactionPlugin, dayGridPlugin]}
        initialView="dayGridMonth"
        weekends={true}
        dayMaxEvents={1}
        events={calendarEvents}
        dayHeaderFormat={({ date }) => moment(date).format("dddd")}
        eventContent={renderEventContent}
        eventClick={(info) => handleViewDetailsClick(info.event._def.publicId)}
        viewClassNames="view-header"
        dayHeaderClassNames="cal-day-header"
        weekNumberClassNames="cal-week-number"
        eventClassNames="cal-event"
      />
    </div>
  );
};

export default memo(WebinarsCalendarV2);
