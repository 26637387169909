import { ApiWrapper, REQUEST } from ".";

export const getDashboardPosts = async (school_id, page = 1, per_page = 10) => {
  return await ApiWrapper({
    url: `user_posts?school_id=${school_id}&page=${page}&per_page=${per_page}`,
    method: REQUEST.GET,
  });
};

export const getSavedPosts = async (page = 1, per_page = 10) => {
  return await ApiWrapper({
    url: `user_posts/saved_posts_list?page=${page}&per_page=${per_page}`,
    method: REQUEST.GET,
  });
};

export const savePost = async (user_post_id) => {
  return await ApiWrapper({
    url: `user_posts/save_post?user_post_id=${user_post_id}`,
    method: REQUEST.POST,
  });
};

export const hidePost = async (user_post_id) => {
  return await ApiWrapper({
    url: `user_posts/hide_post?user_post_id=${user_post_id}`,
    method: REQUEST.POST,
  });
};
