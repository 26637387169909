import React, { memo } from "react";
import { Link } from "react-router-dom";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./ProspectHeaderLogos.module.scss";
import { ROUTES } from "../../../routes";

import accordionarrow from "../../../assets/images/accordian-arrow-header.svg";
import { INTERSTRIDE_LOGO_URL } from "../../../utils/constants";

const cx = createModuleStyleExtractor(styles);

const ProspectHeaderLogos = ({
  schoolsList = [],
  selectedSchool = {},
  showSchoolsMenu,
  onSchoolMenuClick = () => {},
  onSelectedSchoolClick = () => {},
  onDashboardLinkClick = () => {},
  schoolsMenuRef,
}) => (
  <div className={cx("a-prospect-header-logos")}>
    <div
      className={cx("a-prospect-header-logos__school-logos")}
      ref={schoolsMenuRef}
    >
      <div className={cx("a-prospect-header-logos__school-logos__container")}>
        <Link to={ROUTES.DASHBOARD} onClick={onSelectedSchoolClick}>
          <img
            src={selectedSchool.logo_url}
            alt={`${selectedSchool.name}'s logo`}
          />
        </Link>
        {schoolsList && schoolsList.length > 1 && (
          <div
            className={cx([
              "a-prospect-header-logos__school-logos__container__dropdown",
              showSchoolsMenu
                ? "a-prospect-header-logos__school-logos__container__dropdown--active"
                : "",
            ])}
          >
            <img
              src={accordionarrow}
              alt="arrow"
              onClick={onSelectedSchoolClick}
            />
          </div>
        )}
      </div>

      {schoolsList && schoolsList.length > 1 && (
        <ul
          className={cx([
            "a-prospect-header-logos__school-logos__list",
            showSchoolsMenu
              ? "a-prospect-header-logos__school-logos__list--show"
              : "",
          ])}
        >
          {schoolsList.map((school, index) => (
            <li
              key={index}
              className={cx([
                "a-prospect-header-logos__school-logos__list__item",
                ...(selectedSchool.school_id === school.id
                  ? ["a-prospect-header-logos__school-logos__list__--active"]
                  : []),
              ])}
            >
              <Link
                to={ROUTES.DASHBOARD}
                onClick={(e) => {
                  onSchoolMenuClick(school);
                }}
              >
                <img src={school.logo_url} alt="University logo" />
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
    <Link to={ROUTES.DASHBOARD} onClick={onDashboardLinkClick}>
      <img src={INTERSTRIDE_LOGO_URL} alt="interstride logo" />
    </Link>
  </div>
);

export default memo(ProspectHeaderLogos);
