import React from "react";
import styles from "./MasterclassIndex.module.scss";
import { createModuleStyleExtractor } from "../../../utils/css";
import { MASTERCLASS_MODULES } from "../../../utils/constants";
import { noop } from "../../../utils/noop";

const cx = createModuleStyleExtractor(styles);

const MasterclassItem = ({ record = {}, setActiveCourse = noop }) => {
  return (
    <div className={cx("record-container")}>
      <div className={cx("record-content-wrapper")}>
        <div className={cx("record-icon-container")}>
          <img src={record.icon} alt={record.module} />
        </div>
        <div className={cx("record-details")}>
          <h3>{record.name}</h3>
          <h2>{record.title}</h2>
          <h5>{record.desc}</h5>
        </div>
      </div>
      <div className={cx("action-div")}>
        <a
          href="/#"
          onClick={(e) => {
            e.preventDefault();
            setActiveCourse(record);
          }}
        >
          Go to module
        </a>
      </div>
    </div>
  );
};

const MasterclassIndex = ({ setActiveCourse = noop }) => {
  return (
    <div className={cx("masterclass-index-container")}>
      {MASTERCLASS_MODULES.map((record) => (
        <MasterclassItem
          record={record}
          key={record.module}
          setActiveCourse={setActiveCourse}
        />
      ))}
    </div>
  );
};

export default MasterclassIndex;
