import React, { useState, useEffect } from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./Accordian.module.scss";

import accordionarrow from "../../../assets/images/accordion-arrow.svg";

const cx = createModuleStyleExtractor(styles);

export default function Accordion({
  openState = false,
  title = "",
  children,
  openStateChange = () => {},
}) {
  const [open, setIsOpen] = useState(openState);

  useEffect(() => {
    setIsOpen(openState);
  }, [openState]);

  return (
    <div className={cx("a-accordion-flush")}>
      <div className={cx("a-accordion-flush__accordion-item")}>
        <h2 className={cx("a-accordion-flush__accordion-item__accordion-header")}>
          <button
            className={cx(
              "a-accordion-flush__accordion-item__accordion-header__accordion-button"
            )}
            onClick={() => {
              setIsOpen(!open);
              openStateChange(!open);
            }}
          >
            {title}
            <img
              className={cx([
                "a-accordion-flush__accordion-item__accordion-header__accordion-button__accordion-arrow",
                open ? "a-accordion-flush__accordion-item__accordion-header__accordion-button__accordion-arrow--open": "",
              ])}
              src={accordionarrow}
              alt="accordionarrow"
            />
          </button>
        </h2>

        <div
          className={cx([
            "a-accordion-flush__accordion-item__accordion-collapse",
            ...(open
              ? ["a-accordion-flush__accordion-item__accordion-collapse--open"]
              : []),
          ])}
        >
          <div
            className={cx(
              "a-accordion-flush__accordion-item__accordion-collapse__accordion-body"
            )}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
