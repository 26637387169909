export const defaultState = {
  chatActionOptions: [
    {
      id: 1,
      title: "Delete Chat",
    },

    {
      id: 2,
      title: "Mute Chat",
    },
    {
      id: 3,
      title: "Report Chat",
    },
  ],
  show: false,
  showConfirmRemoveChat: false,
  showConfirmMuteChat: false,
  showConfirmReportChat: false,
  deleteChat: {
    confirm: false,
    id: null,
  },
  isMuteChatSubmitting: false,
  isDeleteChatSubmitting: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "modalShow":
      return {
        ...state,
        show: action.payload,
      };
    case "deleteChat":
      return {
        ...state,
        deleteChat: {
          ...action.payload,
        },
      };

    case "bulkUpdate":
      return {
        ...state,
        ...action.payload,
      };
    case "chatActionOptions":
      return {
        ...state,
        chatActionOptions: state.chatActionOptions.map((item) =>
          item.id === 2
            ? {
                ...item,
                title: action.flag ? "Unmute Chat" : "Mute Chat",
              }
            : item
        ),
      };
    case "updateState":
      return {
        ...state,
        [action.key]: action.value,
      };
    default:
      return {
        ...state,
      };
  }
};
