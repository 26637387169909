import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { ShimmerCategoryItem } from "react-shimmer-effects";

import { createModuleStyleExtractor } from "../../../utils/css";
import ChatPreviewItem from "../../atoms/ChatPreviewItem/ChatPreviewItem";
import ChatSearch from "../ChatSearch/ChatSearch";
import { NoChatsPreview } from "../NoChatsPreview/NoChatsPreview";
import styles from "./ChatPreview.module.scss";
const cx = createModuleStyleExtractor(styles);

const ChatPreview = ({ isMobile = false }) => {
  const chatStore = useSelector((state) => state.chatStore);

  const { chats, isChatsLoading, isChatsLoaded } = chatStore || {};

  // Reorder chats according to latest message
  const reorderedChats = useMemo(() => {
    if (!chats) return [];

    let _chats = [...chats];
    return _chats.sort((a, b) => new Date(b.time) - new Date(a.time));
  }, [chats]);

  return (
    <div className={cx("m-preview-container")}>
      <div className={cx("m-preview-container__searchbox")}>
        <ChatSearch isMobile={isMobile} />
      </div>
      {isChatsLoading &&
        Array.from(Array(5).keys()).map((_, index) => (
          <div className={cx("m-preview-container__item")} key={index}>
            <ShimmerCategoryItem
              hasImage
              imageType="circular"
              imageWidth={60}
              imageHeight={60}
              title
            />
          </div>
        ))}

      {isChatsLoaded && !isChatsLoading && (
        <>
          {!chats || chats.length === 0 ? (
            <NoChatsPreview />
          ) : (
            <div className={cx("m-preview-container__contacts-list")}>
              {reorderedChats?.map((item) => (
                <ChatPreviewItem user={item} key={item?.conversation_id} />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default memo(ChatPreview);
