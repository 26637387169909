import { ApiWrapper, REQUEST } from "./index";

export const getGraphData = async (
  school_id = null,
  graph_type = null,
  major = null,
  year = null,
  nationality = null,
  degree = null,
  employment_type = null,
  country = null
) => {
  return await ApiWrapper({
    url: `schools/${school_id}/opts/graph_data`,
    method: REQUEST.GET,
    config: {
      params: {
        graph_type,
        "filters[major]": major,
        "filters[year]": year,
        "filters[nationality]": nationality,
        "filters[degree]": degree,
        "filters[employment_type]": employment_type,
        "filters[country]": country,
      },
    },
  });
};

export const getFiltersData = async (school_id) => {
  return await ApiWrapper({
    url: `schools/${school_id}/opts/discover_search_list`,
    method: REQUEST.GET,
  });
};

export const getGraphTableData = async (
  school_id = null,
  page = 1,
  per_page = 10,
  search = null,
  major = null,
  year = null,
  nationality = null,
  degree = null,
  employment_type = null,
  country = null,
  order_by = null,
  order_direction = null
) => {
  return await ApiWrapper({
    url: `schools/${school_id}/opts/listing`,
    method: REQUEST.GET,
    config: {
      params: {
        search: search?.length > 0 ? search : null,
        "pagination[page]": page,
        "pagination[per_page]": per_page,
        "filters[major]": major,
        "filters[year]": year,
        "filters[nationality]": nationality,
        "filters[degree]": degree,
        "filters[employment_type]": employment_type,
        "filters[country]": country,
        order_by,
        order_direction,
      },
    },
  });
};
