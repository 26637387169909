import React, { memo } from "react";
import { Link } from "react-router-dom";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./MessagesIcon.module.scss";
import { ROUTES } from "../../../routes";
import { Mixpanel } from "../../../MixPanel/mixpanel";

import Message from "../../../assets/images/f-chat-icon.svg";

const cx = createModuleStyleExtractor(styles);

const MessagesIcon = ({ active = false }) => (
  <div
    className={cx(["a-messages-icon", active ? "a-messages-icon--active" : ""])}
  >
    <Link
      to={ROUTES.MESSAGES}
      onClick={() => {
        Mixpanel.track("Messages visited");
      }}
      className={`${active ? "a-messages-icon--active" : ""}`}
    >
      <img src={Message} alt="Message" />
    </Link>
  </div>
);

export default memo(MessagesIcon);
