import React, { memo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./UserProfileIcon.module.scss";
import { ROUTES } from "../../../routes";
import { CircularAvatar } from "../CircularAvatar/CircularAvatar";
import { isEmpty, validateAttribute } from "../../../utils/validations";
import NoImageIcon from "../../../assets/Icons/NoImageIcon";
import CloseIconV2 from "../../../assets/Icons/CloseIconV2";
import SignOutModal from "../../organisms/Profile/SignOutModal/SignOutModal";
import { resetLoginData } from "../../../redux/reducers/userReducer";
import { resetChatState } from "../../../redux/reducers/chat/reducers";
import { resetInitialState } from "../../../redux/reducers/webSocketReducer";
import { resetNotificationData } from "../../../redux/reducers/notificationReducer";
import { wipeOutUserData } from "../../../utils/common";
import { resetCalculatorData } from "../../../redux/reducers/calculatorReducer";

const cx = createModuleStyleExtractor(styles);

export const UserProfileIconModifier = {
  ACTIVE: "a-header-user-profile-icon--active",
  CLOSE: "a-header-user-profile-icon--close",
};

export const UserProfileIcon = ({
  modifier,
  userImg = "",
  first_name,
  last_name,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fullName =
    (validateAttribute(first_name) ? first_name : "") +
    (validateAttribute(last_name) ? ` ${last_name}` : "");

  const [showMenu, setShowMenu] = useState(false);
  const [showSignoutModal, setShowSignoutModal] = useState(false);

  const handleOnSignout = () => {
    setShowSignoutModal(false);
    dispatch(resetLoginData());
    dispatch(resetNotificationData());
    dispatch(resetChatState());
    dispatch(resetInitialState()); // Web socket redux state reset
    dispatch(resetCalculatorData());
    wipeOutUserData();
  };

  return (
    <div
      className={cx(
        "a-header-user-profile-icon",
        showMenu
          ? { [UserProfileIconModifier.CLOSE]: true }
          : modifier
          ? {
              [modifier]: true,
            }
          : {}
      )}
    >
      {showMenu ? (
        <span className={cx("a-header-user-profile-icon--closeicon")}>
          <CloseIconV2
            onClick={(e) => {
              setShowMenu(false);
            }}
          />
        </span>
      ) : (
        <Link
          to={ROUTES.PROFILE}
          onClick={(e) => {
            e?.preventDefault && e.preventDefault();
            setShowMenu(!showMenu);
          }}
        >
          {userImg && userImg.length !== 0 ? (
            <img src={userImg} alt="User Profile" />
          ) : !fullName || isEmpty(fullName) ? (
            <NoImageIcon width={50} height={50} />
          ) : (
            <div className={cx("a-header-user-profile-icon__initial-avtar")}>
              <CircularAvatar name={fullName} round={true} size={50} />
            </div>
          )}
        </Link>
      )}

      {showMenu && (
        <div className={cx("a-header-user-profile-icon__menu")}>
          <span
            onClick={(e) => {
              navigate(ROUTES.PROFILE, { replace: true });
              setShowMenu(false);
            }}
          >
            <span>Profile</span>
          </span>
          <span
            onClick={(e) => {
              setShowMenu(false);
              setShowSignoutModal(true);
            }}
          >
            <span>Sign Out</span>
          </span>
        </div>
      )}

      {/* Sign out modal */}
      <SignOutModal
        showModal={showSignoutModal}
        closeModal={() => {
          setShowMenu(false);
          setShowSignoutModal(false);
        }}
        handleOnSignout={handleOnSignout}
      />
    </div>
  );
};

export default memo(UserProfileIcon);
