export const createModuleStyleExtractor = (styles) => {
  return (
    key, //key: string | string[],
    conditional, //conditional?: Record<string, boolean>,
    additional = [] //  additional: string[] = []
  ) => {
    const moduleStyles = Array.isArray(key)
      ? key.filter(Boolean).map((k) => styles[k])
      : [styles[key]];

    if (conditional) {
      Object.keys(conditional).forEach((style) => {
        if (conditional[style]) {
          moduleStyles.push(styles[style]);
        }
      });
    }

    return additional.filter(Boolean).concat(moduleStyles).join(" ");
  };
};
