import React, { memo } from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./InvalidProspectToken.module.scss";
import BackIcon from "../../../assets/Icons/BackIcon";
import { INTERSTRIDE_LOGO_URL } from "../../../utils/constants";

const cx = createModuleStyleExtractor(styles);

const InvalidProspectToken = ({ goBackToLogin }) => {
  return (
    <>
      <div className={cx("m-invalid-prospect-token")}>
        <h5 className={cx("m-invalid-prospect-token__back-btn")}>
          <a
            href="/login"
            onClick={(e) => {
              e?.preventDefault && e.preventDefault();
              goBackToLogin();
            }}
          >
            <BackIcon />
            Back
          </a>
        </h5>
        <div className={cx("m-invalid-prospect-token__content")}>
          <div className={cx("m-invalid-prospect-token__content__sitelogo")}>
            <img src={INTERSTRIDE_LOGO_URL} alt="interstride logo" />
          </div>
          <h2>
            Your request to join Interstride has been sent to our
            administrators.
          </h2>
        </div>
        <h6>
          If you have any questions, please reach out to us at{" "}
          <a href="mailto:contact@interstride.com">contact@interstride.com.</a>
        </h6>
      </div>
    </>
  );
};
export default memo(InvalidProspectToken);
