import React, { memo, useEffect } from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./VerifyEmail.module.scss";
import BackIcon from "../../../assets/images/f-btn-arrow-gray.svg";
import { Button } from "../../atoms/Button/Button";
import { Mixpanel } from "../../../MixPanel/mixpanel";
import { INTERSTRIDE_LOGO_URL } from "../../../utils/constants";

const cx = createModuleStyleExtractor(styles);

const VerifyEmail = ({ goBackToLogin, schoolData = {} }) => {
  useEffect(() => {
    Mixpanel.track(`Verify email visited`);
  }, []);

  return (
    <>
      <div className={cx("m-verify-email-container")}>
        <h5 className={cx("m-verify-email-container__back-btn")}>
          <a
            href="/login"
            onClick={(e) => {
              e?.preventDefault && e.preventDefault();
              goBackToLogin();
            }}
          >
            <img src={BackIcon} id="BackArrow" width="18px" alt="Back arrow" />
            <span>Back</span>
          </a>
        </h5>
        <div className={cx("m-verify-email-container__content")}>
          <div className={cx("m-verify-email-container__content__unlogo")}>
            <img src={schoolData.logo_url} alt="University logo" />
          </div>
          <div className={cx("m-verify-email-container__content__sitelogo")}>
            <h3>Powered by</h3>
            <img src={INTERSTRIDE_LOGO_URL} alt="interstride logo" />
          </div>
          <h2>Verify your email.</h2>
          <h5>
            We sent you an email with a link to verify your email address.
          </h5>

          <Button
            onClick={() => {
              goBackToLogin();
            }}
          >
            All done!
          </Button>
        </div>
        <h6>
          <p className="mt-0">
            If you don’t see the email, please check your spam folder.
          </p>
          <p className="m-0">Still having problems?</p>
          Email us at{" "}
          <a href="mailto:contact@interstride.com">contact@interstride.com.</a>
        </h6>
      </div>
    </>
  );
};
export default memo(VerifyEmail);
