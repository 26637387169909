import React, { memo, useState, useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import { ShimmerCategoryList, ShimmerSocialPost } from "react-shimmer-effects";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./MyTopic.module.scss";
import Categories, {
  CategoriesModifier,
} from "../../atoms/Categories/Categories";
import { Row } from "../../helpers/Grid/Row";
import { Col } from "../../helpers/Grid/Col";
import { Button, ButtonModifier } from "../../atoms/Button/Button";
import { Modal } from "../../atoms/Modal/Modal";
import { ModelHeader } from "../../atoms/Modal/ModelHeader/ModelHeader";
import { ModalBody } from "../../atoms/Modal/ModalBody/ModalBody";
import { ModalFooter } from "../../atoms/Modal/ModalFooter/ModalFooter";
import {
  getTopicDetails,
  readTopicNotification,
  getMyTopics,
  followUnfollowTopic,
  topicMuteOrUnmute,
  reportTopic,
} from "../../../api/topics";
import { fetchAllNotificationCount } from "../../../api/notification";
import TopicDetails from "../TopicDetails/TopicDetails";
import TopicPosts from "../TopicPosts/TopicPosts";
import toastify from "../../../utils/toast";
import MuiTextbox from "../../atoms/MuiTextbox/MuiTextbox";
import { validateString } from "../../../utils/validations";
import { setAllNotificationCounts } from "../../../redux/reducers/notificationReducer";

import EmptyDiscoverTopicsIcon from "../../../assets/Icons/EmptyDiscoverTopicsIcon";
import FilterIcon from "../../../assets/Icons/FilterIcon";
import elipsis from "../../../assets/images/f-elipsis.svg";
import close from "../../../assets/images/f-close.svg";

const cx = createModuleStyleExtractor(styles);

const MyTopic = ({ followingTopics, setFolllowingTopics, setTab }) => {
  const dispatch = useDispatch();
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [topicDetails, setTopicDetails] = useState(null);
  const [topicMuteStatus, setTopicMuteStatus] = useState(null);
  const [isCallingTopicDetailsAPI, setIsCallingTopicDetailsApi] =
    useState(false);
  const [isCallingTopicUnfollowAPI, setIsCallingTopicUnfollowAPI] =
    useState(false);
  const [isCallingTopicsAPI, setIsCallingTopicsAPI] = useState(true);
  const [topicOptionsShow, setTopicOptionsShow] = useState(false);
  const [showUnfollowModal, setShowUnfollowModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showReportConfirmationModal, setShowReportConfirmationModal] =
    useState(false);
  const [isSubmittingReport, setIsSubmittingReport] = useState(false);
  const [showNoTopicFollowedModal, setShowNoTopicFollowedModal] =
    useState(false);
  const [openFilter, toggleFilter] = useState(false);

  const userState = useSelector((state) => state.userState);
  const userId = userState.user.id;
  const selectedSchool = userState.selectedSchool || {};

  const notificationState = useSelector((state) => state.notificationState);
  const { topicSpecificNotificationCount = {} } = notificationState;

  const fetchMyTopics = async () => {
    try {
      setIsCallingTopicsAPI(true);
      setIsCallingTopicDetailsApi(true);
      const response = await getMyTopics(selectedSchool.id);
      if (response.success) {
        const { following_topics = [] } = response.data;

        setFolllowingTopics(following_topics);

        if (following_topics.length > 0) {
          setSelectedCategoryId(following_topics[0].id);
          fetchTopicDetails(following_topics[0].id);
          setTopicOptionsShow(false);
        } else {
          setShowNoTopicFollowedModal(true);
        }

        setIsCallingTopicsAPI(false);
      } else {
        setIsCallingTopicsAPI(false);
        setIsCallingTopicDetailsApi(false);
      }
    } catch (error) {
      setIsCallingTopicsAPI(false);
      setIsCallingTopicDetailsApi(false);
    }
  };

  // Get TOPICS data via API upon DOM load
  useEffect(() => {
    fetchMyTopics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSchool]);

  const fetchTopicDetails = async (topicId) => {
    setIsCallingTopicDetailsApi(true);
    const response = await getTopicDetails(topicId, selectedSchool?.id);
    setIsCallingTopicDetailsApi(false);
    if (response?.success) {
      setTopicDetails(response.data.topic_details[0]);
      // Find topic Mute Status and update State
      setTopicMuteStatus(
        response?.data?.topic_details[0]?.participants_list?.filter(
          (element) => element.user_id === userId
        )[0]?.mute_topic || null
      );
    }
  };

  const handleTopicUnfollowOption = () => {
    setTopicOptionsShow(false);
    setShowUnfollowModal(true);
  };

  const handleTopicReportOption = () => {
    setTopicOptionsShow(false);
    setShowReportModal(true);
  };

  const handleTopicUnfollow = async () => {
    setIsCallingTopicUnfollowAPI(true);
    const responseFollowUnfollowTopic = await followUnfollowTopic(
      selectedCategoryId,
      selectedSchool?.id || null
    );
    setIsCallingTopicUnfollowAPI(false);
    if (responseFollowUnfollowTopic.success) {
      fetchMyTopics();
      setShowUnfollowModal(false);
    }
  };

  const handleMuteUnmuteTopic = async () => {
    // Find topic follower ID
    const { topic_follower_id } =
      topicDetails?.participants_list?.filter(
        (element) => element?.user_id === userId
      )[0] || {};

    if (!topic_follower_id) {
      toastify("Oops! something went wrong. Please try again");
      setTopicOptionsShow(false);
      console.error("User not present in topic participants list");
      return;
    }

    try {
      const response = await topicMuteOrUnmute(topic_follower_id);
      if (response.success && response.data) {
        toastify(response.message);
        setTopicOptionsShow(false);
        setTopicMuteStatus(response.data.mute_topic);
      }
    } catch (error) {
      setTopicOptionsShow(false);
      console.error(error);
    }
  };

  const markReadTopicNotification = async (topicId) => {
    try {
      const response = await readTopicNotification(topicId);
      if (response.success) {
        refreshAllNotifications();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const refreshAllNotifications = async () => {
    const response = await fetchAllNotificationCount();

    if (response.success && response.data) {
      dispatch(setAllNotificationCounts(response.data));
    }
  };

  const onTopicClick = (topicId) => {
    setSelectedCategoryId(topicId);
    fetchTopicDetails(topicId);
    markReadTopicNotification(topicId);
  };

  const validateReportTopic = (values) => {
    const errors = {};
    if (validateString(values.report_text)) {
      errors.report_text = "Please enter your report";
    }
    return errors;
  };

  const handleSubmitReportTopic = async (values) => {
    setIsSubmittingReport(true);
    try {
      const response = await reportTopic(
        values.report_text,
        selectedCategoryId
      );
      if (response?.success) {
        setIsSubmittingReport(false);
        setShowReportModal(false);
        setShowReportConfirmationModal(true);
      }
    } catch (error) {
      toastify("Oops! something went wrong. Please try again");
      setIsSubmittingReport(false);
    }
  };

  const formikReportTopic = useFormik({
    initialValues: {
      report_text: "",
    },
    validate: validateReportTopic,
    onSubmit: handleSubmitReportTopic,
  });

  // Reset fields of report chat
  useEffect(() => {
    if (showReportModal === false) {
      formikReportTopic.setFieldValue("report_text", "");
      formikReportTopic.setErrors({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showReportModal]);

  const isEmptyTopics =
    !isCallingTopicsAPI && (!followingTopics || followingTopics.length === 0);

  return (
    <div className={cx("m-my-topic-wrapper")}>
      {isEmptyTopics ? (
        <div className={cx("m-my-topic-wrapper__empty")}>
          <EmptyDiscoverTopicsIcon />
          <h2>Looks like you haven’t followed any Community yet.</h2>
        </div>
      ) : (
        <Row>
          <Col xs={12} sm={12} md={3} lg={3}>
            {isCallingTopicsAPI ? (
              <ShimmerCategoryList items={4} categoryStyle="STYLE_FIVE" />
            ) : (
              <div
                className={`collapsible-section ${
                  openFilter ? "collapsible-section--open" : ""
                }`}
              >
                <div
                  className={cx("m-list-header-wrapper")}
                  id={"collapsible-section-header"}
                >
                  <h2 className="only-for-mobile">Categories</h2>
                  <a
                    href="/#"
                    onClick={(e) => {
                      e?.preventDefault && e.preventDefault();
                      toggleFilter(!openFilter);
                    }}
                    id="collapsible-section-header-icon"
                  >
                    <FilterIcon fill={openFilter} />
                  </a>
                </div>
                <div id="collapsible-section-search-filter">
                  <Categories
                    modifier={CategoriesModifier.COMMUNITY_CATEGORIES}
                    type="myTopic"
                    data={followingTopics}
                    selectedCategoryId={selectedCategoryId}
                    onCategorySelect={onTopicClick}
                    notificationCounts={topicSpecificNotificationCount}
                    toggleFilter={toggleFilter}
                  />
                </div>
              </div>
            )}
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <div className={cx("m-my-topic-middle-wrapper")}>
              <div className={cx("m-my-topic-middle-heading")}>
                <h2>
                  {followingTopics &&
                    followingTopics.length > 0 &&
                    followingTopics.filter(
                      (topic) => topic.id === selectedCategoryId
                    )[0]?.topic_name}
                </h2>
                <span
                  className={cx([
                    "m-my-topic-middle-heading-menu-button-wrapper",
                    followingTopics.length === 0 &&
                      "m-my-topic-middle-heading-menu-button-wrapper-disabled",
                  ])}
                >
                  {topicOptionsShow ? (
                    <img
                      src={close}
                      width="16"
                      onClick={() => setTopicOptionsShow(false)}
                      alt="close"
                    />
                  ) : (
                    <img
                      src={elipsis}
                      width="24"
                      onClick={() => setTopicOptionsShow(true)}
                      alt="show"
                    />
                  )}
                </span>
                {topicOptionsShow && (
                  <div className={cx("m-my-topic-middle-heading-menu-wrapper")}>
                    <h5 onClick={handleMuteUnmuteTopic}>
                      {topicMuteStatus ? (
                        <span>Unmute Community</span>
                      ) : (
                        <span>Mute Community</span>
                      )}
                    </h5>
                    <h5 onClick={handleTopicUnfollowOption}>
                      <span>Unfollow Community</span>
                    </h5>
                    <h5 onClick={handleTopicReportOption}>
                      <span>Report</span>
                    </h5>
                  </div>
                )}
              </div>
              <TopicPosts
                topicDetails={topicDetails}
                followingTopics={followingTopics}
                setTopicDetails={setTopicDetails}
                isCallingTopicDetailsAPI={isCallingTopicDetailsAPI}
              />
            </div>
          </Col>
          <Col xs={12} sm={12} md={3} lg={3}>
            {topicDetails === null || isCallingTopicDetailsAPI ? (
              <ShimmerSocialPost type="image" title />
            ) : (
              <TopicDetails topicDetails={topicDetails} />
            )}
          </Col>
        </Row>
      )}
      {/* Modals */}
      <Modal
        show={showUnfollowModal}
        hideClose
        marginTop={200}
        onClose={() => setShowUnfollowModal(false)}
      >
        <ModalBody>
          <h2 className={cx("m-my-topic-unfollow-modal-title")}>
            Are you sure you want to unfollow this Community?
          </h2>
          <Button
            modifier={ButtonModifier.TOPICS_UNFOLLOW}
            disabled={isCallingTopicUnfollowAPI}
            onClick={() => handleTopicUnfollow()}
          >
            {isCallingTopicUnfollowAPI ? (
              <ThreeDots color="#ffffff" height={25} width={50} />
            ) : (
              <>Unfollow</>
            )}
          </Button>
        </ModalBody>
        <ModalFooter
          className={cx("m-prospect-sign-in-form-container__footer")}
        >
          <div>
            <span
              className={cx("m-my-topic-unfollow-modal-cancel")}
              onClick={() => {
                setShowUnfollowModal(false);
              }}
            >
              Cancel
            </span>
          </div>
        </ModalFooter>
      </Modal>
      <Modal
        show={showNoTopicFollowedModal}
        marginTop={200}
        onClose={() => setShowNoTopicFollowedModal(false)}
      >
        <ModalBody>
          <h2 className={cx("m-my-topic-unfollow-modal-title")}>
            Looks like you haven’t followed any Community yet.
          </h2>
          <h3 className={cx("m-my-topic-unfollow-modal-description")}>
            Click below to discover Community and add one to your list.
          </h3>
          <Button
            modifier={ButtonModifier.TOPICS_UNFOLLOW}
            onClick={() => {
              setShowNoTopicFollowedModal(false);
              setTab("right");
            }}
          >
            Discover Community
          </Button>
        </ModalBody>
      </Modal>
      <Modal
        centerAlign
        show={showReportModal}
        onClose={() => setShowReportModal(false)}
      >
        <ModelHeader title="What would you like to report?" />
        <ModalBody>
          <div className={cx("m-my-topic-report-modal")}>
            <form onSubmit={formikReportTopic.handleSubmit}>
              <MuiTextbox
                name="report_text"
                onBlur={formikReportTopic.handleBlur}
                maxLength={500}
                label="Your report*"
                value={formikReportTopic.values.report_text}
                onChange={(event) =>
                  formikReportTopic.setFieldValue(
                    "report_text",
                    event.target.value
                  )
                }
                isMultiline
                rows={3}
                placeholder="Please share any complaints or inappropriate behavior."
                error={
                  formikReportTopic.touched.report_text &&
                  formikReportTopic.errors.report_text
                    ? formikReportTopic.errors.report_text
                    : ""
                }
              />
              <Button
                type="submit"
                modifier={ButtonModifier.TOPICS_UNFOLLOW}
                isSubmitting={isSubmittingReport}
              >
                Share report
              </Button>
              <div className={cx("m-my-topic-report-modal__cancel")}>
                <span onClick={() => setShowReportModal(false)}>No thanks</span>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        show={showReportConfirmationModal}
        marginTop={200}
        onClose={() => setShowReportConfirmationModal(false)}
      >
        <ModelHeader title="Thanks for sharing your feedback!" />
        <ModalBody>
          <div className={cx("m-my-topic-report-confirmation-modal")}>
            <h3>We will review your report and take the necessary action.</h3>
            <Button onClick={() => setShowReportConfirmationModal(false)}>
              Got it
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default memo(MyTopic);
