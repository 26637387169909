import React, { memo } from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";

import Card from "../../atoms/Card/Card";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./ResourcesCategories.module.scss";

import PlaceholderCategoryImage from "../../../assets/svg/placeholderCategoryImage.svg";
import NoCategoriesIcon from "../../../assets/svg/error404.svg";

const cx = createModuleStyleExtractor(styles);

const ResourcesCategories = ({
  categories = [],
  categoriesLoader = false,
  navigationHandler = () => {},
}) => {
  return (
    <div className={cx("m-resource-categories")}>
      {categoriesLoader ? (
        <div className={cx("m-resource-categories__loader")}>
          {[...Array(4)].map((_item, index) => (
            <ShimmerThumbnail key={index} width={255} height={223} rounded />
          ))}
        </div>
      ) : categories.length === 0 ? (
        <div className={cx("m-resource-categories__placeholder")}>
          <h3>We don't have any resource listed right now.</h3>
          <img src={NoCategoriesIcon} alt="NoCategories" />
        </div>
      ) : (
        <div className={cx("m-resource-categories__content")}>
          {categories.map((item, index) => (
            <div
              className={cx("m-resource-categories__content__card")}
              key={index}
            >
              <Card onClick={() => navigationHandler(item)}>
                <div
                  className={cx("m-resource-categories__content__card__item")}
                >
                  <div
                    className={cx(
                      "m-resource-categories__content__card__item__image"
                    )}
                  >
                    <img
                      src={item.logo_url || PlaceholderCategoryImage}
                      alt="category"
                    />
                  </div>
                  <span>{item.category_name}</span>
                </div>
              </Card>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default memo(ResourcesCategories);
