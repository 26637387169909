import React from "react";

function LinkedinLoginIcon({ onClick }) {
  return (
    <svg
      width="65"
      height="65"
      viewBox="0 0 65 65"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g fill="none">
        <circle fill="#F1F4F7" cx="32.5" cy="32.5" r="32.5" />
        <g transform="translate(26 20)" />
        <path
          d="M46.05 43.597h-4.912v-7.644c0-1.86 0-4.132-2.612-4.132-2.611 0-2.926 1.963-2.926 4.03V43.7h-4.91V27.793h4.597v2.17h.105c.626-1.239 2.299-2.583 4.701-2.583 5.016 0 5.957 3.305 5.957 7.54v8.676ZM24.93 25.46a2.874 2.874 0 0 1-2.88-2.88 2.874 2.874 0 0 1 2.88-2.88 2.875 2.875 0 0 1 2.88 2.88 2.874 2.874 0 0 1-2.88 2.88ZM22.05 43.7h4.8V28.34h-4.8V43.7Z"
          fill="#0077B5"
        />
      </g>
    </svg>
  );
}

export default React.memo(LinkedinLoginIcon);
