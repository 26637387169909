import { ApiWrapper, REQUEST } from "./index";

export const getStudentServicesCategories = async (school_id, search) => {
  return await ApiWrapper({
    url: `deals/categories?school_id=${school_id}${
      search ? `&search=${search}` : ""
    }`,
    method: REQUEST.GET,
  });
};

export const getStudentServicesByCategory = async (
  school_id,
  id,
  page = 1,
  per_page = 10
) => {
  return await ApiWrapper({
    url: `deals/${id}/category?school_id=${school_id}&page=${page}&per_page=${per_page}`,
    method: REQUEST.GET,
  });
};

export const getSavedServicesList = async (school_id) => {
  return await ApiWrapper({
    url: `deals/favorite_deals?school_id=${school_id}`,
    method: REQUEST.GET,
  });
};

export const saveService = async (deal_id, like) => {
  const payload = { deal_id, like };
  return await ApiWrapper({
    url: `deals/favorite_deal`,
    method: REQUEST.POST,
    data: payload,
  });
};
