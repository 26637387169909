import React, { memo } from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./SelectiveCard.module.scss";

const cx = createModuleStyleExtractor(styles);

const SelectiveCard = ({ onClick = () => {}, title = "", active = false }) => {
  return (
    <div
      className={cx(
        "a-selective-card",
        active
          ? {
              "a-selective-card--active": true,
            }
          : {}
      )}
      onClick={onClick}
    >
      {title && title !== null && <h6>{title}</h6>}
    </div>
  );
};

export default memo(SelectiveCard);
