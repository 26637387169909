import React, { memo, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import CloseIcon from "../../../assets/Icons/CloseIcon";
import { ROUTES } from "../../../routes";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./Footer.module.scss";
import { INTERSTRIDE_LOGO_URL } from "../../../utils/constants";

const cx = createModuleStyleExtractor(styles);

const Footer = ({ setShowFooter = () => {} }) => {
  const {
    isProspect = false,
    isParent = false,
    permissions = {},
  } = useSelector((state) => state.userState);

  useEffect(() => {
    document.body.classList.add("hide-scroll");
    document.body.tabIndex = -1;

    return () => {
      document.body.classList.remove("hide-scroll");
      document.body.tabIndex = 0;
    };
  }, []);

  return (
    <div className={cx("m-footer")}>
      <div className={cx("m-footer__content")}>
        <div className={cx("m-footer__content__close")}>
          <CloseIcon onClick={() => setShowFooter(false)} />
        </div>

        <div className={cx("m-footer__content__main")}>
          <div className={cx("m-footer__content__main__logo")}>
            <img src={INTERSTRIDE_LOGO_URL} alt="interstride logo" />
            <div className={cx("m-footer__content__main__logo__text")}>
              Success beyond boundaries.
            </div>
          </div>

          <div className={cx("m-footer__content__main__links")}>
            {isProspect || isParent ? (
              <>
                {(permissions["chat"] || permissions["topics"]) && (
                  <div className={cx("m-footer__content__main__links__menu")}>
                    <span>Network</span>
                    {permissions["chat"] && (
                      <>
                        <Link to={ROUTES.MESSAGES}>Messages</Link>
                        <Link to={ROUTES.CHAT.AMBASSADORS}>Ambassador</Link>
                      </>
                    )}
                    {permissions["topics"] && (
                      <Link to={ROUTES.COMMUNITY.TOPICS}>Community</Link>
                    )}
                  </div>
                )}

                {(permissions["webinar"] ||
                  permissions["resources"] ||
                  permissions["student_services"] ||
                  permissions["checklist"]) && (
                  <div className={cx("m-footer__content__main__links__menu")}>
                    <span>Learn</span>
                    {permissions["webinar"] && (
                      <Link to={ROUTES.DISCOVER.WEBINARS}>Webinars</Link>
                    )}
                    {permissions["resources"] && (
                      <Link to={ROUTES.DISCOVER.RESOURCES}>Resources</Link>
                    )}
                    {permissions["student_services"] && (
                      <Link to={ROUTES.DISCOVER.STUDENT_SERVICES}>
                        Services
                      </Link>
                    )}
                    {permissions["courses"] && (
                      <Link to={ROUTES.DISCOVER.COURSES}>Courses</Link>
                    )}
                  </div>
                )}

                {permissions["checklist"] && (
                  <div className={cx("m-footer__content__main__links__menu")}>
                    <span>Tools</span>
                    {permissions["checklist"] && (
                      <Link to={ROUTES.TOOLS.CHECKLISTS}>Checklists</Link>
                    )}
                  </div>
                )}

                <div className={cx("m-footer__content__main__links__menu")}>
                  <span>Tools</span>
                  <Link to={ROUTES.CALCULATOR}>Calculator</Link>
                  {permissions["campus_map"] &&
                    permissions["campus_map_data_uploaded"] && (
                      <Link to={ROUTES.CAMPUS_MAP}>Campus Map</Link>
                    )}
                </div>
              </>
            ) : (
              <>
                {permissions["chat"] && (
                  <>
                    <div className={cx("m-footer__content__main__links__menu")}>
                      <span>Discover</span>
                      <Link to={ROUTES.CHAT.AMBASSADORS}>Ambassador</Link>
                    </div>

                    <div className={cx("m-footer__content__main__links__menu")}>
                      <span>Network</span>
                      <Link to={ROUTES.MESSAGES}>Messages</Link>
                    </div>
                  </>
                )}
              </>
            )}

            <div className={cx("m-footer__content__main__links__menu")}>
              <span>Account</span>
              <Link to={ROUTES.PROFILE}>View Profile</Link>
              <Link to={ROUTES.NOTIFICATIONS}>Notifications</Link>
              <Link to={ROUTES.NOTIFICATIONS_SETTINGS}>Settings</Link>
            </div>

            <div className={cx("m-footer__content__main__links__menu")}>
              <span>Support</span>
              <Link to={ROUTES.HELP_CENTER}>Help Center</Link>
              <Link to={ROUTES.TERMS_AND_CONDITIONS}>Terms & Conditions</Link>
              <Link to={ROUTES.PRIVACY_POLICY}>Privacy Policy</Link>
            </div>
          </div>
        </div>

        <div className={cx("m-footer__content__copyright")}>
          © {new Date().getFullYear()} Interstride. All rights reserved.
        </div>
      </div>
    </div>
  );
};
export default memo(Footer);
