import React, { useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";

import MuiSelect from "../../atoms/MuiSelect/MuiSelect";
import MuiTextbox from "../../atoms/MuiTextbox/MuiTextbox";
import MuiTable from "../../atoms/MuiTable/MuiTable";
import { createModuleStyleExtractor } from "../../../utils/css";
import { getGraphTableData } from "../../../api/employmentDataHub";
import { getValidSearchText } from "../../../utils/validations";
import {
  createFriendlyTitle,
  getCapitalizedHeader,
} from "../../../utils/common";
import { dataTableHeaders } from "../../organisms/EmploymentDataHub/EmploymentDataHubContants";
import styles from "./EmploymentDataTable.module.scss";
import { initialListingStateDataListing } from "../../organisms/EmploymentDataHub/EmploymentDataHubContants";

import FilterIcon from "../../../assets/Icons/FilterIcon";

const cx = createModuleStyleExtractor(styles);

export default function EmploymentDataTable({
  tableState,
  setTableState,
  tableSearch,
  setTableSearch,
  openFilter,
  toggleFilter,
  filters,
  selectedSchoolId,
}) {
  // Call table data api upon filter change change and component load
  useEffect(() => {
    getOPTListingData({
      page: 1,
      per_page: 10,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableState?.selectedFilters]);

  // Debounce search callback
  const debouncedSearchCallback = useDebouncedCallback(async (value) => {
    setTableState((current) => ({
      ...current,
      loader: true,
    }));
    getOPTListingData({ search: value });
  }, 1000);

  const handlePaginationChange = (page, per_page) => {
    getOPTListingData({ search: null, page, per_page });
  };

  const getOPTListingData = async ({
    search = null,
    page = null,
    per_page = null,
    order_by = null,
    order_direction = null,
  }) => {
    const response = await getGraphTableData(
      selectedSchoolId,
      page || tableState.page,
      per_page || tableState.per_page,
      search?.trim() || tableSearch?.trim(),
      tableState.selectedFilters.major?.value,
      tableState.selectedFilters.year?.value,
      tableState?.selectedFilters?.nationality?.value,
      tableState?.selectedFilters?.degree?.value,
      tableState?.selectedFilters?.employment_type?.value,
      tableState?.selectedFilters?.country?.value,
      order_by || tableState?.order_by,
      order_direction || tableState?.order_direction
    );
    if (response?.success && response?.data?.opts?.length > 0) {
      // Process listing data
      let _data = [];
      response.data.opts.forEach((record) => {
        _data.push([
          getCapitalizedHeader(record.company),
          getCapitalizedHeader(record.job_title),
          getCapitalizedHeader(record.degree),
          createFriendlyTitle(record.major),
          createFriendlyTitle(record.nationality),
          record.year,
          record.employment_type,
          createFriendlyTitle(record.country),
        ]);
      });
      setTableState((current) => ({
        ...current,
        loader: false,
        data: _data,
        page: parseInt(response?.data?.current_page),
        per_page: per_page || tableState.per_page,
        total_pages: response?.data?.total_pages,
      }));
      // Scroll to top
      window.scrollTo({ top: 0, behavior: "smooth" });
      return;
    }
    setTableState((current) => ({
      ...current,
      loader: false,
      data: null,
      page: 1,
      per_page: 10,
      total_pages: 1,
    }));
  };

  const changeSortingFn = (orderByName, order_direction) => {
    // Process propertyName to keyname for BE API
    let order_by = null;
    if (orderByName === "Job Title") order_by = "job_title";
    else if (orderByName === "Type") order_by = "employment_type";
    else order_by = orderByName?.toLowerCase();

    // Update table state
    setTableState((current) => ({
      ...current,
      order_by,
      order_direction,
    }));

    getOPTListingData({
      search: tableSearch,
      page: tableState.page,
      per_page: tableState.per_page,
      order_by,
      order_direction,
    });
  };

  const handleClearAllFilter = () => {
    if (
      tableSearch?.length > 0 &&
      tableState?.selectedFilters?.degree === null &&
      tableState?.selectedFilters?.major === null &&
      tableState?.selectedFilters?.nationality === null &&
      tableState?.selectedFilters?.year === null &&
      tableState?.selectedFilters?.employment_type === null &&
      tableState?.selectedFilters?.country === null
    ) {
      setTableSearch("");
      debouncedSearchCallback("");
    } else {
      tableSearch?.length > 0 && setTableSearch("");
      setTableState((current) => ({
        ...current,
        selectedFilters: initialListingStateDataListing.selectedFilters,
      }));
    }
  };

  //  CPT, OPT and STEM OPT are only relevant for US
  const onEmploymentTypeChange = (option) => {
    const isUSSpecific =
      option?.value &&
      (option.value === "CPT" ||
        option.value === "OPT" ||
        option.value === "STEM OPT");
    // clearing residue country value conditionally

    setTableState((current) => ({
      ...current,
      selectedFilters: {
        ...current.selectedFilters,
        country: isUSSpecific ? null : current.selectedFilters.country,
        employment_type: option,
      },
    }));
  };

  return (
    <div className={cx("m-emp-table")}>
      <div
        className={`collapsible-section ${
          openFilter ? "collapsible-section--open" : ""
        }`}
      >
        <div className={cx("m-emp-table__header")}>
          <div id={"collapsible-section-header"}>
            <h2>Filters</h2>
            <a
              href="/#"
              onClick={(e) => {
                e?.preventDefault && e.preventDefault();
                toggleFilter(!openFilter);
              }}
              id="collapsible-section-header-icon"
            >
              <FilterIcon fill={openFilter} />
            </a>
          </div>
          <div id="collapsible-section-search-filter">
            <div className={cx("m-emp-table__header__filter")}>
              <div className={cx("m-emp-table__header__filter__item")}>
                <MuiTextbox
                  placeholder="Search"
                  value={tableSearch}
                  onChange={(e) => {
                    const text = getValidSearchText(e.target.value);
                    setTableSearch(text);
                    debouncedSearchCallback(text);
                  }}
                  isDisable={tableState.loader}
                />
              </div>
              <div className={cx("m-emp-table__header__filter__item")}>
                <MuiSelect
                  placeholder="Degree"
                  justifyContent
                  isClearable={true}
                  options={filters?.degree}
                  value={tableState?.selectedFilters?.degree}
                  onChange={(option) =>
                    setTableState((current) => ({
                      ...current,
                      loader: true,
                      selectedFilters: {
                        ...current.selectedFilters,
                        degree: option,
                      },
                    }))
                  }
                  isDisabled={tableState?.loader}
                />
              </div>
              <div className={cx("m-emp-table__header__filter__item")}>
                <MuiSelect
                  placeholder="Major"
                  justifyContent
                  isClearable={true}
                  options={filters?.major}
                  value={tableState?.selectedFilters?.major}
                  onChange={(option) =>
                    setTableState((current) => ({
                      ...current,
                      loader: true,
                      selectedFilters: {
                        ...current.selectedFilters,
                        major: option,
                      },
                    }))
                  }
                  isDisabled={tableState?.loader}
                />
              </div>
              <div className={cx("m-emp-table__header__filter__item")}>
                <MuiSelect
                  placeholder="Nationality"
                  justifyContent
                  isClearable={true}
                  options={filters?.nationality}
                  value={tableState?.selectedFilters?.nationality}
                  onChange={(option) =>
                    setTableState((current) => ({
                      ...current,
                      loader: true,
                      selectedFilters: {
                        ...current.selectedFilters,
                        nationality: option,
                      },
                    }))
                  }
                  isDisabled={tableState?.loader}
                />
              </div>
              <div className={cx("m-emp-table__header__filter__item")}>
                <MuiSelect
                  placeholder="Year"
                  justifyContent
                  isClearable={true}
                  options={filters?.year}
                  value={tableState?.selectedFilters?.year}
                  onChange={(option) =>
                    setTableState((current) => ({
                      ...current,
                      loader: true,
                      selectedFilters: {
                        ...current.selectedFilters,
                        year: option,
                      },
                    }))
                  }
                  isDisabled={tableState?.loader}
                />
              </div>
              <div className={cx("m-emp-table__header__filter__item")}>
                <MuiSelect
                  placeholder="Type"
                  justifyContent
                  isClearable={true}
                  options={filters?.employment_type}
                  value={tableState?.selectedFilters?.employment_type}
                  onChange={onEmploymentTypeChange}
                  isDisabled={tableState?.loader}
                />
              </div>
              {tableState?.selectedFilters?.employment_type?.value === "CPT" ||
              tableState?.selectedFilters?.employment_type?.value === "OPT" ||
              tableState?.selectedFilters?.employment_type?.value ===
                "STEM OPT" ? (
                <></>
              ) : (
                <div className={cx("m-emp-table__header__filter__item")}>
                  <MuiSelect
                    placeholder="Job Location"
                    justifyContent
                    isClearable={true}
                    options={filters?.country}
                    value={tableState?.selectedFilters?.country}
                    onChange={(option) =>
                      setTableState((current) => ({
                        ...current,
                        loader: true,
                        selectedFilters: {
                          ...current.selectedFilters,
                          country: option,
                        },
                      }))
                    }
                    isDisabled={tableState?.loader}
                  />
                </div>
              )}
              {(tableSearch?.length > 0 ||
                tableState?.selectedFilters?.degree !== null ||
                tableState?.selectedFilters?.major !== null ||
                tableState?.selectedFilters?.nationality !== null ||
                tableState?.selectedFilters?.year !== null ||
                tableState?.selectedFilters?.employment_type !== null ||
                tableState?.selectedFilters?.country !== null) && (
                <div className={cx("m-emp-table__header__filter__item--clear")}>
                  <span onClick={handleClearAllFilter}>Clear all</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={cx("m-emp-table__body")}>
        <MuiTable
          tableHeaders={dataTableHeaders}
          tableData={tableState.data}
          pagination
          handlePaginationChange={handlePaginationChange}
          page={tableState.page}
          total_pages={tableState?.total_pages}
          tableLoader={tableState?.loader}
          noDataTextHeader="Looks like there are no career data with this search."
          noDataTextBody="Try removing some filters to expand your search and get more results."
          changeSortingFn={changeSortingFn}
        />
      </div>
    </div>
  );
}
