import React, { memo } from "react";

import styles from "./TermsAndConditionsDetails.module.scss";
import { createModuleStyleExtractor } from "../../../utils/css";
import Card from "../../atoms/Card/Card";

const cx = createModuleStyleExtractor(styles);

const TermsAndConditionsDetails = ({ selectedCategory = {} }) => {
  const { category, details } = selectedCategory;
  return (
    <div className={cx("m-terms-and-conditions-details")}>
      <div className={cx("m-terms-and-conditions-details__header")}>
        <h2>{category ? category : "Overview"}</h2>
      </div>
      <div className={cx("m-terms-and-conditions-details__date")}>
        <h3>Last Updated : January 1, 2023</h3>
      </div>

      <div className={cx("m-terms-and-conditions-details__content")}>
        <Card>
          <p
            className={cx("m-terms-and-conditions-details__content__paragraph")}
            dangerouslySetInnerHTML={{ __html: details }}
          />
        </Card>
      </div>
    </div>
  );
};

export default memo(TermsAndConditionsDetails);
