import React, { memo } from "react";

function DiscoverMapIcon({ selected = false }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
    >
      <path
        d="m1 3.333 19.174 18.21M1 3.334V22h19.31v-8.235M1 3.333h9.087m.957 9.993-9.587 8.218m16.68-16.81v-.07M22 4.651c0 2.435-3.862 5.681-3.862 5.681s-3.862-3.246-3.862-5.68C14.276 2.634 16.005 1 18.138 1S22 2.635 22 4.652"
        stroke={selected ? "#FFFFFF" : "var(--primary-font-color)"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default memo(DiscoverMapIcon);
