import React from "react";

function SliderPreviousArrow({ className, style, onClick = () => {} }) {
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.8625 25.8379C19.1 25.8379 19.3375 25.7504 19.525 25.5629C19.8875 25.2004 19.8875 24.6004 19.525 24.2379L11.375 16.0879C10.775 15.4879 10.775 14.5129 11.375 13.9129L19.525 5.76289C19.8875 5.40039 19.8875 4.80039 19.525 4.43789C19.1625 4.07539 18.5625 4.07539 18.2 4.43789L10.05 12.5879C9.4125 13.2254 9.05 14.0879 9.05 15.0004C9.05 15.9129 9.4 16.7754 10.05 17.4129L18.2 25.5629C18.3875 25.7379 18.625 25.8379 18.8625 25.8379Z"
          fill="var(--primary-color)"
        />
      </svg>
    </div>
  );
}

export default React.memo(SliderPreviousArrow);
