import React, { memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  ShimmerTitle,
  ShimmerText,
  ShimmerCircularImage,
  ShimmerSectionHeader,
} from "react-shimmer-effects";

import { getConversationByConversationID } from "../../../redux/reducers/chat/selectors";
import { CircularAvatar } from "../../atoms/CircularAvatar/CircularAvatar";
import { createModuleStyleExtractor } from "../../../utils/css";
import { GroupParticipant } from "../../atoms/GroupParticipant/GroupParticipant";
import ChevronLeft from "../../../assets/Icons/ChevronLeft";
import { bulkUpdate } from "../../../redux/reducers/chat/reducers";
import TruncateText from "../../atoms/TruncateText/TruncateText";
import styles from "./GroupProfile.module.scss";

const cx = createModuleStyleExtractor(styles);

const GroupProfile = () => {
  const conversation = useSelector((state) =>
    getConversationByConversationID(state.chatStore)
  );
  const { group_details } = conversation || {};
  const { group_user_details, group_icon, group_title, group_info } =
    group_details || {};

  const dispatch = useDispatch();
  const handleBackOperation = () => {
    dispatch(
      bulkUpdate({
        isChatPreview: false,
        isConversationView: !false,
        isProfileView: !true,
      })
    );
  };

  return (
    <div className={cx("m-group-profile-container")}>
      <button className={cx("m-group-profile-container__close")}>
        <ChevronLeft style={{ width: "25px" }} onClick={handleBackOperation} />
      </button>
      {!conversation ? (
        <div className={cx("profile-shimmer")}>
          <ShimmerCircularImage size={90} />
          <ShimmerTitle
            line={1}
            variant={"primary"}
            className={cx("profile-shimmer__title")}
          />

          <ShimmerSectionHeader center={true} />
          <ShimmerText
            line={10}
            gap={10}
            className={cx("profile-shimmer__text")}
          />
        </div>
      ) : (
        <>
          <div className={cx("group-profile__image")}>
            <CircularAvatar
              name={group_title}
              src={group_icon}
              size={70}
              alt={"Group Profile"}
              round
            />
          </div>
          <div className={cx("group-profile__title")}>
            <span>{group_title}</span>
          </div>
          {group_info && (
            <div className={cx("group-description")}>
              <TruncateText text={group_info} length={100} />
            </div>
          )}

          <p className={cx("group-participants--count")}>
            {group_user_details && group_user_details.length} participants
          </p>
          {group_user_details && (
            <div className={cx("participants")}>
              {group_user_details.map(
                (
                  { user_name, user_image, is_group_admin, account_type },
                  index
                ) => (
                  <GroupParticipant
                    name={user_name}
                    image={user_image}
                    admin={is_group_admin}
                    key={index}
                    accountType={account_type}
                  />
                )
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default memo(GroupProfile);
