import React, { memo, useState, useEffect } from "react";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ShimmerThumbnail } from "react-shimmer-effects";

import styles from "./DashboardUpcomingWebinars.module.scss";
import { createModuleStyleExtractor } from "../../../utils/css";
import Card from "../../atoms/Card/Card";
import { getUpcomingWebinars } from "../../../api/webinars";
import { ROUTES } from "../../../routes";
import { createtTime } from "../../../utils/common";

const cx = createModuleStyleExtractor(styles);

const DashboardUpcomingWebinars = ({ showCard = false }) => {
  const [webinarsLoader, setWebinarsLoader] = useState(false);
  const [upcomingWebinars, setUpcomingWebinars] = useState([]);

  const userState = useSelector((state) => state.userState);
  const selectedSchool = userState.selectedSchool;

  const getWebinars = async () => {
    setWebinarsLoader(true);
    const response = await getUpcomingWebinars("", [], selectedSchool.id);
    if (response.success) {
      setUpcomingWebinars(response.data ? response.data : []);
    }
    setWebinarsLoader(false);
  };

  useEffect(() => {
    getWebinars({ initialLoad: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSchool]);

  return (
    <>
      {webinarsLoader ? (
        <>
          {Array.from(Array(2).keys()).map((_, index) => (
            <ShimmerThumbnail key={index} height={150} width={360} rounded />
          ))}
        </>
      ) : (
        <>
          {upcomingWebinars.length === 0 ? (
            <></>
          ) : (
            <div className={cx("m-webinars-calendar-sidebar-header")}>
              {showCard ? (
                <Card>
                  <div
                    className={cx(
                      "m-webinars-calendar-sidebar-title-wrapper-card"
                    )}
                  >
                    <h2>Upcoming Webinars</h2>
                    <Link to={ROUTES.DISCOVER.WEBINARS}>Go to webinars</Link>
                  </div>
                </Card>
              ) : (
                <div
                  className={cx("m-webinars-calendar-sidebar-title-wrapper")}
                >
                  <h2>Upcoming Webinars</h2>
                  <Link to={ROUTES.DISCOVER.WEBINARS}>Go to webinars</Link>
                </div>
              )}

              {upcomingWebinars.slice(0, 2).map((webinar, index) => (
                <Card key={index}>
                  <div
                    className={cx("m-webinars-calendar-sidebar-item-wrapper")}
                  >
                    <div
                      className={cx("m-webinars-calendar-sidebar-item-date")}
                    >
                      {moment
                        .unix(webinar?.epoch_time)
                        .local()
                        .format("dddd, MMMM Do YYYY")}
                    </div>
                    <div
                      className={cx("m-webinars-calendar-sidebar-item-time")}
                    >
                      {`${moment
                        .utc(webinar.webinar_time)
                        .format("hh:mm a")} - ${createtTime(
                        webinar.webinar_time,
                        webinar && webinar.duration
                          ? webinar.duration.split(" ")[0]
                          : 0,
                        "minutes"
                      )}`}{" "}
                      ({webinar.webinar_time_zone_short})
                    </div>
                    <div
                      className={cx("m-webinars-calendar-sidebar-item-title")}
                    >
                      {webinar.name}
                    </div>
                  </div>
                </Card>
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default memo(DashboardUpcomingWebinars);
