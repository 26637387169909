import React, { memo, useState, useEffect } from "react";
import { Tooltip } from "@mui/material";
import { useSelector } from "react-redux";

import { isNull, isEmpty } from "../../../utils/validations";
import ChecklistTextbox from "../../atoms/ChecklistTextbox/ChecklistTextbox";
import { Button, ButtonModifier } from "../../atoms/Button/Button";
import RadioButton from "../../atoms/RadioButton/RadioButton";
import styles from "./ChecklistTask.module.scss";
import { createModuleStyleExtractor } from "../../../utils/css";

import deleteIcon from "../../../assets/svg/f-delete-icon.svg";
import editIcon from "../../../assets/svg/f-edit-icon.svg";
import plusIcon from "../../../assets/svg/f-plus.svg";

const cx = createModuleStyleExtractor(styles);

const ChecklistTask = ({
  setAddNewSubTask,
  taskItem,
  taskIndex,
  createNewTask,
  editTask,
  editSubtask,
  taskMark,
  removeTask,
  removeStaticTask,
  showAddEditDeleteButton = true,
  activeEditTask,
  setActiveEditTask,
  activeEditSubTask,
  hideSubtaskButton,
  setHideSubtaskButton,
}) => {
  const [taskName, setTaskName] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [showTooltip, setShowTooltip] = useState(null);

  const userState = useSelector((state) => state.userState);
  const userId = userState.user.id;

  useEffect(() => {
    taskItem.task_name && setTaskName(taskItem.task_name);
  }, [taskItem]);

  useEffect(() => {
    if (!activeEditTask) {
      setIsEdit(false);
    }
  }, [activeEditTask]);

  useEffect(() => {
    if (taskItem.task_name !== taskName) {
      setActiveEditTask({ id: taskItem.id, taskName: taskName });
    } else {
      setActiveEditTask(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskName]);

  return (
    <div className={cx("m-checklist-task")} id={taskItem.sort_order}>
      <div className={cx("m-checklist-task__container")}>
        <div className={cx("m-checklist-task__container__main")}>
          <RadioButton
            id={`task-${taskItem.id}`}
            value={taskItem.is_mark}
            onChange={(e) => {
              if (taskItem.id !== undefined) {
                setIsEdit(false);
                taskMark(taskItem.id, e.target.checked);
              }
            }}
            inputProps={{
              "aria-label": taskItem.task_name ?? "task",
            }}
          />

          <div
            className={cx([
              "m-checklist-task__container__main__text",
              ...(taskItem.task_name === undefined
                ? ["m-checklist-task__container__main__text--gray"]
                : ["m-checklist-task__container__main__text--primary"]),
              ...(taskItem.is_mark === "true"
                ? ["m-checklist-task__container__main__text--completed"]
                : []),
            ])}
          >
            {isEdit ? (
              <ChecklistTextbox
                value={taskName}
                onChange={(e) => {
                  setTaskName(e.target.value);
                }}
                placeholder="Add a new task"
              />
            ) : taskItem.task_name === undefined ? (
              <ChecklistTextbox
                type="text"
                value={taskName}
                onChange={(e) => {
                  setTaskName(e.target.value);
                }}
                placeholder="Add a new task"
              />
            ) : (
              <h5>{taskName}</h5>
            )}

            {taskItem &&
              taskItem.link_name &&
              (!isNull(taskItem.link_name) || !isEmpty(taskItem.link_name)) && (
                <a
                  href
                  onClick={(e) => {
                    e?.preventDefault && e.preventDefault();
                    window.open(taskItem.link_url);
                  }}
                  className={cx("m-checklist-task__container__main__link")}
                >
                  {taskItem.link_name}
                </a>
              )}
          </div>
        </div>

        {isEdit ? (
          <Button
            onClick={() => {
              setIsEdit(false);
              setActiveEditTask({ id: taskItem.id, taskName: taskName });
              taskName &&
                taskName.trim().length > 0 &&
                editTask(taskItem.id, taskName);
            }}
            modifier={ButtonModifier.CHECKLIST_SAVE}
          >
            Save task
          </Button>
        ) : taskItem.task_name === undefined ? (
          <Button
            onClick={() => {
              setIsEdit(false);
              taskName && taskName.trim().length > 0 && createNewTask(taskName);
            }}
            modifier={ButtonModifier.CHECKLIST_SAVE}
          >
            Save task
          </Button>
        ) : null}
        {taskItem &&
          taskItem.task_name &&
          taskItem.user_id === userId &&
          showAddEditDeleteButton && (
            <>
              <Tooltip
                title="Edit task"
                placement="bottom"
                open={!isNull(showTooltip) && showTooltip === "main-edit"}
                disableHoverListener
                onMouseEnter={() => setShowTooltip("main-edit")}
                onMouseLeave={() => setShowTooltip(null)}
                enterTouchDelay={0}
              >
                <a
                  href
                  onClick={(e) => {
                    e.preventDefault();
                    setIsEdit(true);
                    setActiveEditTask({ id: taskItem.id, taskName: taskName });
                    if (activeEditSubTask) {
                      editSubtask(
                        activeEditSubTask.id,
                        activeEditSubTask.title,
                        activeEditSubTask.task_id,
                        true
                      );
                    }
                  }}
                  className={cx("m-checklist-task__container__button-edit")}
                >
                  <img src={editIcon} alt="Edit button" />
                </a>
              </Tooltip>
              <Tooltip
                title="Delete task"
                placement="bottom"
                open={!isNull(showTooltip) && showTooltip === "main-delete"}
                disableHoverListener
                onMouseEnter={() => setShowTooltip("main-delete")}
                onMouseLeave={() => setShowTooltip(null)}
                enterTouchDelay={0}
              >
                <button
                  className={cx("m-checklist-task__container__button-close")}
                  onClick={() =>
                    taskItem.id
                      ? removeTask(taskItem.id)
                      : removeStaticTask(taskIndex)
                  }
                  aria-label={`Remove task ${taskItem.task_name}`}
                  aria-describedby={`task-${taskItem.id}`}
                >
                  <img src={deleteIcon} alt="Close icon" />
                </button>
              </Tooltip>
            </>
          )}

        {showAddEditDeleteButton && !taskItem.id && (
          <Tooltip
            title="Delete task"
            placement="bottom"
            open={!isNull(showTooltip) && showTooltip === "main-delete"}
            disableHoverListener
            onMouseEnter={() => setShowTooltip("main-delete")}
            onMouseLeave={() => setShowTooltip(null)}
            enterTouchDelay={0}
          >
            <button
              className={cx("m-checklist-task__container__button-close")}
              onClick={() => removeStaticTask(taskIndex)}
              aria-label={`Close task ${taskItem.task_name}`}
              aria-describedby={`task-${taskItem.id}`}
            >
              <img src={deleteIcon} alt="Close icon" />
            </button>
          </Tooltip>
        )}
        {showAddEditDeleteButton && !hideSubtaskButton && (
          <div className={cx("m-checklist-task__container__button-add")}>
            <Button
              onClick={() => {
                setAddNewSubTask(taskIndex);
                setHideSubtaskButton(true);
              }}
              modifier={ButtonModifier.CHECKLIST_ADD}
              isDisabled={hideSubtaskButton || !taskItem.task_name}
              aria-label={`Add Subtasks of task ${taskItem.task_name}`}
            >
              Add subtask
              <img src={plusIcon} className="plus-icon" alt="Plus icon" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(ChecklistTask);
