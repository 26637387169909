import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import { ShimmerCategoryItem } from "react-shimmer-effects";

import {
  getChecklistTasks,
  addNewTask,
  removeTask,
  updateChecklistTask,
  markUnmarkChecklistTask,
  addChecklistSubtask,
  removeChecklistSubtask,
  updateChecklistSubtask,
  markUnmarkChecklistSubTask,
} from "../../../api/checklists";
import toastify from "../../../utils/toast";
import ChecklistTask from "../ChecklistTask/ChecklistTask";
import ChecklistSubtask from "../ChecklistSubtask/ChecklistSubtask";
import EmptyListWithImage from "../../atoms/EmptyListWithImage/EmptyListWithImage";
import Card from "../../atoms/Card/Card";
import styles from "./ChecklistsList.module.scss";
import { createModuleStyleExtractor } from "../../../utils/css";

import EmptyChecklistIcon from "../../../assets/svg/empty-checklist.svg";

const cx = createModuleStyleExtractor(styles);

const ChecklistList = ({
  checkListCategories = [],
  selectedCategory = 0,
  checklistTasks,
  setChecklistTasks,
  checklistTasksLoader,
  setAddNewSubTask,
}) => {
  const [activeEditTask, setActiveEditTask] = useState(null);
  const [activeEditSubTask, setActiveEditSubTask] = useState(null);
  const [hideSubtaskButton, setHideSubtaskButton] = useState(false);

  const userState = useSelector((state) => state.userState);
  const userId = userState.user.id;
  const selectedSchool = userState.selectedSchool;

  const currentCategoryId = checkListCategories?.[selectedCategory]?.id;

  const getRequestChecklistById = async () => {
    const response = await getChecklistTasks(
      checkListCategories[selectedCategory].id,
      selectedSchool.id
    );

    if (response.success) {
      setChecklistTasks(response.data?.tasks ?? []);
    } else {
      toastify(response.message);
    }
  };

  const createNewTask = async (taskName) => {
    try {
      const resopnse = await addNewTask(currentCategoryId, taskName);
      let checklistdata = checklistTasks.filter((record) => record.id);

      checklistdata.unshift({
        ...resopnse?.data?.checklist_subcategory,
        is_mark: false,
        sub_tasks: [],
      });

      setChecklistTasks([...checklistdata]);
    } catch (error) {
      toastify(error.data.message);
    }
  };

  const createNewSubTask = async (sub_id, task) => {
    const response = await addChecklistSubtask(sub_id, task);
    if (response.success) {
      let checklistdata = checklistTasks;

      let existIndex = checklistdata.findIndex(
        ({ id }) => id === response?.data?.checklist?.task_id
      );

      let particularSubTaskData = checklistdata[existIndex]?.sub_tasks.filter(
        (record) => record.id
      );

      checklistdata[existIndex].sub_tasks = particularSubTaskData;

      if (existIndex >= 0) {
        checklistdata[existIndex].sub_tasks.push({
          ...response.data.checklist,
          is_mark: false,
          user_id: userId,
        });
      }

      setChecklistTasks([...checklistdata]);
      setActiveEditSubTask(null);
    } else {
      toastify(response.message);
    }
  };

  const taskMarked = async (id, check) => {
    const response = await markUnmarkChecklistTask(id, check);

    if (response.success) {
      toastify(response.message);
      getRequestChecklistById();
    } else {
      toastify(response.message);
    }
  };

  const subtaskMarked = async (id, check) => {
    const response = await markUnmarkChecklistSubTask(id, check);

    if (response.success) {
      const task = response?.data?.user_checklist;
      toastify(response.message);
      let checklistdata = checklistTasks;

      let existIndex = checklistdata.findIndex(({ id }) => id === task.task_id);

      if (
        checklistdata[existIndex] !== undefined &&
        checklistdata[existIndex]?.sub_tasks &&
        checklistdata[existIndex]?.sub_tasks.length
      ) {
        let existSubIndex = checklistdata[existIndex]?.sub_tasks.findIndex(
          ({ id }) => id === task.sub_task_id
        );

        if (existSubIndex >= 0) {
          checklistTasks[existIndex].sub_tasks[existSubIndex].is_mark =
            task.is_mark.toString();
        }
      }
      setChecklistTasks([...checklistdata]);
    } else {
      getRequestChecklistById();
    }
  };

  const editTask = async (taskId, taskName) => {
    const response = await updateChecklistTask(
      taskId,
      taskName,
      currentCategoryId
    );

    if (response.success) {
      toastify(response.message);
      setActiveEditTask(null);
      getRequestChecklistById();
    } else {
      toastify(response.message);
    }
  };

  const editSubTask = async (
    id,
    taskName,
    task_id,
    clearActiveEditTask = true
  ) => {
    const response = await updateChecklistSubtask(id, taskName, task_id);

    if (response.success) {
      toastify(response.message);
      if (clearActiveEditTask) {
        setActiveEditSubTask(null);
      }
      getRequestChecklistById();
    } else {
      toastify(response.message);
    }
  };

  const removeTaskFn = async (taskId) => {
    const response = await removeTask(taskId);

    if (response.success) {
      toastify(response.message);
      getRequestChecklistById();
    } else {
      toastify(response.message);
    }
  };

  const removeSubTaskFn = async (taskId) => {
    const response = await removeChecklistSubtask(taskId);

    if (response.success) {
      toastify(response.message);
      getRequestChecklistById();
    } else {
      toastify(response.message);
    }
  };

  const removeStaticTask = (index) => {
    let checkListData = checklistTasks;
    checkListData.splice(index, 1);

    setChecklistTasks([...checkListData]);
  };

  const removeSubTaskStaticFn = (mainIndex, SubIndex) => {
    let checkListData = checklistTasks;
    checkListData[mainIndex].sub_tasks.splice(SubIndex, 1);

    setChecklistTasks([...checkListData]);
  };

  return (
    <>
      {checklistTasks && checklistTasks?.length > 0 ? (
        <ol className={cx("m-checklist-list")}>
          {checklistTasks.map((value) => (
            <li key={value.id}>
              <Card>
                <ChecklistTask
                  taskItem={value}
                  taskIndex={checklistTasks.findIndex((o) => o.id === value.id)}
                  setAddNewSubTask={setAddNewSubTask}
                  createNewTask={createNewTask}
                  editTask={editTask}
                  editSubtask={editSubTask}
                  taskMark={taskMarked}
                  removeTask={removeTaskFn}
                  removeStaticTask={removeStaticTask}
                  activeEditTask={activeEditTask}
                  setActiveEditTask={setActiveEditTask}
                  activeEditSubTask={activeEditSubTask}
                  hideSubtaskButton={hideSubtaskButton}
                  setHideSubtaskButton={setHideSubtaskButton}
                />
                {value.sub_tasks && value.sub_tasks.length > 0 && (
                  <ul>
                    {value.sub_tasks &&
                      value.sub_tasks.map((task, ti) => (
                        <li key={ti}>
                          <ChecklistSubtask
                            task={task}
                            mainIndex={checklistTasks.findIndex(
                              (o) => o.id === value.id
                            )}
                            subIndex={ti}
                            createSubTask={createNewSubTask}
                            subCategoryId={value.id}
                            subTaskMark={subtaskMarked}
                            removeSubTask={removeSubTaskFn}
                            removeSubTaskStaticFn={removeSubTaskStaticFn}
                            editSubtask={editSubTask}
                            editTask={editTask}
                            activeEditTask={activeEditTask}
                            activeEditSubTask={activeEditSubTask}
                            setActiveEditSubTask={setActiveEditSubTask}
                            setHideSubtaskButton={setHideSubtaskButton}
                          />
                        </li>
                      ))}
                  </ul>
                )}
              </Card>
            </li>
          ))}
        </ol>
      ) : (
        <>
          {checklistTasksLoader ? (
            <>
              {Array.from(Array(5).keys()).map((_, index) => (
                <div className={cx("m-checklist-list__loader")} key={index}>
                  <ShimmerCategoryItem text title />
                </div>
              ))}
            </>
          ) : (
            checklistTasks?.length === 0 && (
              <EmptyListWithImage
                Image={EmptyChecklistIcon}
                title={"Looks like there are no tasks in this checklist"}
              />
            )
          )}
        </>
      )}
    </>
  );
};

export default memo(ChecklistList);
