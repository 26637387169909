import React, { useEffect, useState, memo } from "react";
import ReactPlayer from "react-player";
import moment from "moment";
import { ClickAwayListener } from "@mui/material";

import { createModuleStyleExtractor } from "../../../utils/css";
import Card from "../../atoms/Card/Card";
import ReadMoreReadLess from "../../atoms/ReadMoreReadLess/ReadMoreReadLess";
import styles from "./DashboardPost.module.scss";
import MenuDotsHorizontal from "../../../assets/Icons/MenuDotsHorizontal";
import { CircularAvatar } from "../../atoms/CircularAvatar/CircularAvatar";
import LinkContent from "../../atoms/LinkContent/LinkContent";
import {
  isEmpty,
  validateString,
  validateYoutubeURL,
} from "../../../utils/validations";
import { Modal, ModalModifier } from "../../atoms/Modal/Modal";
import { ModalBody } from "../../atoms/Modal/ModalBody/ModalBody";
import { hidePost, savePost } from "../../../api/dashboardPosts";
import toastify from "../../../utils/toast";
import DashboardPostSlider from "../DashboardPostSlider/DashboardPostSlider";

import BigPlayIcon from "../../../assets/images/BigPlayIcon.png";
import CloseIconV2 from "../../../assets/Icons/CloseIconV2";

const cx = createModuleStyleExtractor(styles);

const DashboardPost = ({
  post = {},
  index = 0,
  isFavorite = false,
  refreshSavedPosts = () => {},
}) => {
  const {
    is_favourite,
    title,
    post_image_type,
    post_image_url,
    id,
    description,
    posted_by,
    posted_by_image,
    created_at,
    share_link,
    youtube_link,
    post_link_type,
    attachments_file,
  } = post;

  const [showMenu, setShowMenu] = useState(false);
  const [isSaved, setIsSaved] = useState(
    isFavorite ? isFavorite : is_favourite
  );
  const [isHide, setIsHide] = useState(false);
  const [postModal, setPostModal] = useState({
    show: false,
    link: "",
  });

  useEffect(() => {
    setIsSaved(is_favourite);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_favourite]);

  const handleSavePost = async () => {
    const response = await savePost(id);
    if (response.success) {
      toastify(response.message);
      setIsSaved(!isSaved);
      refreshSavedPosts && refreshSavedPosts();
      setShowMenu(!showMenu);
    } else {
      toastify(response.message);
      setShowMenu(!showMenu);
    }
  };

  const handleHidePost = async () => {
    const response = await hidePost(id);
    if (response.success) {
      toastify(response.message);
      setIsHide(true);
      refreshSavedPosts && refreshSavedPosts();
      setShowMenu(!showMenu);
    } else {
      toastify(response.message);
      setShowMenu(!showMenu);
    }
  };

  const handleClick = () => {
    // Open pdf in new window
    if (isPdf) {
      window.open(attachments_file?.[0]?.file_url);
      return;
    }

    if (uploadedAttachment && !validateString(share_link)) {
      window.open(share_link);
      return;
    }

    if (isInterstrideVideo) {
      setPostModal({
        show: true,
        link: post_image_url,
      });
      return;
    }

    // if share link or youtube link contains video link
    const isVideoLink = validateYoutubeURL(share_link ?? youtube_link);
    if (isVideoLink) {
      setPostModal({
        show: true,
        link: share_link,
      });
      return;
    }
    if (share_link ?? youtube_link) window.open(share_link ?? youtube_link);
  };

  const closeModal = () => {
    setPostModal({
      show: false,
      link: "",
    });
  };

  const uploadedAttachment =
    !isEmpty(attachments_file) && attachments_file.length > 0;

  const isPdf =
    uploadedAttachment &&
    attachments_file?.[0]?.file_content_type?.includes("pdf");

  const isInterstrideVideo =
    !uploadedAttachment &&
    post_link_type &&
    post_image_url &&
    post_image_type &&
    post_image_type.split("/")[0] === "video"
      ? true
      : false;

  const isLink = !isEmpty(share_link) || !isEmpty(youtube_link) ? true : false;

  const isYoutubeLink =
    isLink && share_link && share_link.split("watch?v=")[1] ? true : false;

  const isAnnouncement = post_link_type === "announcement";

  const MAX_LINES_TOPIC_POST = isAnnouncement ? 10 : 5;

  return (
    <>
      {isHide ? (
        <></>
      ) : (
        <>
          <div
            className={cx("m-dashboard-post")}
            key={index}
            id={`dashboard-post-${id}`}
            style={{
              scrollMarginTop: index === 0 ? "150px" : "70px",
            }}
          >
            <Card>
              <div className={cx("m-dashboard-post__header")}>
                <div className={cx("m-dashboard-post__header__posted-info")}>
                  <div
                    className={cx(
                      "m-dashboard-post__header__posted-info__posted-image"
                    )}
                  >
                    <CircularAvatar
                      src={posted_by_image}
                      alt="posted by"
                      round
                      size={40}
                      name={posted_by}
                    />
                  </div>
                  <div
                    className={cx(
                      "m-dashboard-post__header__posted-info__posted-details"
                    )}
                  >
                    <span>{posted_by}</span>
                    <span>
                      at{" "}
                      {moment(created_at).calendar(null, {
                        sameElse: "MMM DD, YYYY",
                      })}
                    </span>
                  </div>
                </div>

                <ClickAwayListener onClickAway={() => setShowMenu(false)}>
                  <div className={cx("m-dashboard-post__header__menu")}>
                    <span
                      className={cx("m-dashboard-post__header__menu__icon")}
                    >
                      {showMenu ? (
                        <CloseIconV2 onClick={() => setShowMenu(false)} />
                      ) : (
                        <MenuDotsHorizontal
                          onClick={(e) => setShowMenu(true)}
                        />
                      )}
                    </span>
                    {showMenu && (
                      <div
                        className={cx("m-dashboard-post__header__menu__items")}
                      >
                        <span onClick={() => handleSavePost()}>
                          {isSaved ? (
                            <span>Remove from Saved</span>
                          ) : (
                            <span>Save Post</span>
                          )}
                        </span>
                        <span onClick={() => handleHidePost()}>
                          <span>Hide</span>
                        </span>
                      </div>
                    )}
                  </div>
                </ClickAwayListener>
              </div>
              <div
                className={cx("m-dashboard-post__content")}
                onClick={handleClick}
              >
                {isPdf ? (
                  <div className={cx("m-dashboard-post__content__pdf")}>
                    <div className={cx("m-dashboard-post__content__pdf__tag")}>
                      PDF
                    </div>
                    <img
                      src={attachments_file?.[0]?.thumb_file_url}
                      alt={`More about ${title}`}
                      className={cx("m-dashboard-post__content__pdf__image")}
                    />
                  </div>
                ) : uploadedAttachment ? (
                  <DashboardPostSlider
                    files={attachments_file}
                    handleClick={handleClick}
                  />
                ) : isLink && !isInterstrideVideo && post_image_url ? (
                  <div
                    className={cx("m-dashboard-post__content__shared-media")}
                  >
                    <img
                      src={post_image_url}
                      className={cx(
                        "m-dashboard-post__content__shared-media__image"
                      )}
                      alt={`More about ${title}`}
                    />
                    {isYoutubeLink && (
                      <img
                        src={BigPlayIcon}
                        className={cx(
                          "m-dashboard-post__content__shared-media__play-icon"
                        )}
                        alt="play-icon"
                      />
                    )}
                  </div>
                ) : (
                  <>
                    {isInterstrideVideo ? (
                      <div
                        className={cx(
                          "m-dashboard-post__content__interstride-media"
                        )}
                      >
                        <video
                          src={post_image_url}
                          className={cx(
                            "m-dashboard-post__content__interstride-media__video"
                          )}
                          alt={`Interstride Video`}
                          controls={false}
                          autoPlay={false}
                        />
                        <img
                          src={BigPlayIcon}
                          className={cx(
                            "m-dashboard-post__content__interstride-media__play-icon"
                          )}
                          alt="play-icon"
                        />
                      </div>
                    ) : !isAnnouncement ? (
                      <div
                        className={cx("m-dashboard-post__content__image-media")}
                      >
                        {post_image_url && (
                          <img
                            src={post_image_url}
                            className={cx(
                              "m-dashboard-post__content__image-media__image"
                            )}
                            alt={`More about ${title}`}
                          />
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
              {title && (
                <h2 className={cx("m-dashboard-post__title")}>{title}</h2>
              )}
              {description?.length > 0 && (
                <>
                  <p
                    className={cx([
                      "m-dashboard-post__description",
                      isAnnouncement
                        ? "m-dashboard-post__description--large-font"
                        : "",
                    ])}
                  >
                    <LinkContent>
                      <ReadMoreReadLess
                        maxLines={MAX_LINES_TOPIC_POST}
                        moreComp={
                          <p className={cx("m-dashboard-post__read-more-less")}>
                            Read more
                          </p>
                        }
                        lessComp={
                          <p className={cx("m-dashboard-post__read-more-less")}>
                            Read less
                          </p>
                        }
                      >
                        {description}
                      </ReadMoreReadLess>
                    </LinkContent>
                  </p>
                </>
              )}
            </Card>
          </div>
          <Modal
            show={postModal.show}
            onClose={closeModal}
            modifier={ModalModifier.DASHBOARD_POST}
          >
            <ModalBody>
              <div className={cx("m-dashboard-post__modal")}>
                {post_link_type === "link_image" ? (
                  <video
                    className={cx("m-dashboard-post__modal__video")}
                    controls
                    autoPlay={false}
                    muted
                    controlsList="nodownload"
                  >
                    <source src={post_image_url} />
                  </video>
                ) : (
                  <></>
                )}

                {post_link_type === "article" && (
                  <div className={cx("m-dashboard-post__modal__link-video")}>
                    {postModal.link ? (
                      <ReactPlayer
                        url={postModal.link ?? ""}
                        controls={true}
                        width={"100%"}
                        height={"100%"}
                      />
                    ) : (
                      <h3>{`Oops, ${post_link_type} is not available`}</h3>
                    )}
                  </div>
                )}
              </div>
            </ModalBody>
          </Modal>
        </>
      )}
    </>
  );
};

export default memo(DashboardPost);
