import React, { memo } from "react";
import { useSelector } from "react-redux";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./DashboardUserProfileCard.module.scss";
import Card from "../../atoms/Card/Card";
import { isEmpty, validateAttribute } from "../../../utils/validations";
import { CircularAvatar } from "../../atoms/CircularAvatar/CircularAvatar";
import NoImageIcon from "../../../assets/Icons/NoImageIcon";

const cx = createModuleStyleExtractor(styles);

const DashboardUserProfileCard = () => {
  const { user, isProspect, permissions } = useSelector(
    (state) => state.userState
  );

  const {
    profile_image_url,
    first_name,
    last_name,
    country_image,
    nationality,
    intro,
    educations = [],
  } = user;

  const { major, graduation_year } =
    educations && educations[0] ? educations[0] : {};

  // Selected School Profile Field Settings
  const onboardingSettings = permissions?.custom_onboarding_field;
  const nationalitySetting = onboardingSettings?.country_of_origin;
  const majorSetting = onboardingSettings?.major;
  const enrollmentYearSetting = onboardingSettings?.graduation_year;

  const generateDisplayName = () => {
    const fullName =
      (validateAttribute(first_name) ? first_name : "") +
      (validateAttribute(last_name) ? ` ${last_name}` : "");

    const words = fullName.split(" ");
    if (words?.length > 1) {
      return `${words[0]} ${words[1][0].toUpperCase()}.`;
    }
    return words[0];
  };

  const displayName = generateDisplayName();
  return (
    <div className={cx("m-dashboard-user-profile-card")}>
      <Card>
        <div className={cx("m-dashboard-user-profile-card__content")}>
          <div className={cx("m-dashboard-user-profile-card__content__image")}>
            {profile_image_url && profile_image_url.length !== 0 ? (
              <img src={profile_image_url} alt="User Profile" />
            ) : !displayName || isEmpty(displayName) ? (
              <NoImageIcon />
            ) : (
              <CircularAvatar name={displayName} round={true} size={70} />
            )}
          </div>

          <div
            className={cx("m-dashboard-user-profile-card__content__user-name")}
          >
            <h3>{generateDisplayName()}</h3>
          </div>

          {isProspect && !nationalitySetting?.is_visible ? (
            <></>
          ) : country_image && nationality ? (
            <h3
              className={cx(
                "m-dashboard-user-profile-card__content__country-info"
              )}
            >
              <span
                className={cx(
                  "m-dashboard-user-profile-card__content__country-info__country-flag"
                )}
              >
                <img src={country_image} alt="flag" />
              </span>
              <span
                className={cx(
                  "m-dashboard-user-profile-card__content__country-info__country-name"
                )}
              >
                From {nationality}
              </span>
            </h3>
          ) : (
            <></>
          )}

          {isProspect &&
          (!majorSetting?.is_visible || !enrollmentYearSetting?.is_visible) ? (
            <></>
          ) : validateAttribute(major) || validateAttribute(graduation_year) ? (
            <p
              className={cx(
                "m-dashboard-user-profile-card__content__education-info"
              )}
            >
              {validateAttribute(major) ? major : " "}
              {validateAttribute(major) && validateAttribute(graduation_year)
                ? ", "
                : ""}
              {validateAttribute(graduation_year) ? graduation_year : " "}
            </p>
          ) : (
            <></>
          )}

          {validateAttribute(intro) && (
            <div
              className={cx("m-dashboard-user-profile-card__content__intro")}
            >
              {intro.length > 185 ? intro.slice(0, 185) + "..." : intro}
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};
export default memo(DashboardUserProfileCard);
