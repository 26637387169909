import NotificationSettingCard from "../../../atoms/NotificationSettingCard/NotificationSettingCard";

export default function TopicsSettings({ onToggle }) {
  return (
    <>
      <NotificationSettingCard
        title={"Comments from Communities"}
        subTitle={
          "Receive alerts whenever someone adds a comment to a post you have created or commented on."
        }
        pushValue={"followed_topic_push"}
        emailValue={"followed_topic_email"}
        updateValue="followed_topics"
        onToggle={onToggle}
      />
      <NotificationSettingCard
        title={"New Communities"}
        subTitle={"Receive alerts whenever a new community is created."}
        pushValue={"new_topic_push"}
        emailValue={"new_topic_email"}
        updateValue="new_topics"
        onToggle={onToggle}
      />
    </>
  );
}
