import React from "react";

export default function GoogleMapDirectionIcon({ onClick = () => {} }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="34"
      viewBox="0 0 35 34"
      fill="none"
      style={{ cursor: "pointer" }}
      onClick={onClick}
    >
      <circle cx="17.5" cy="17" r="17" fill="#F1F4F7" />
      <path
        d="m11.076 11.5 12 11.716m-12-11.716a2.46 2.46 0 0 1 1.858-.833h4.057m-5.915.833c-.36.41-.576.94-.576 1.52v8.627c0 1.3 1.09 2.353 2.434 2.353h8.925c1.344 0 2.434-1.053 2.434-2.353v-3.53m-7.302 0-5.274 5.099m11.024-11.55v-.05m2.759-.007c0 1.739-2.759 4.058-2.759 4.058s-2.758-2.32-2.758-4.058C19.983 10.168 21.218 9 22.74 9c1.524 0 2.759 1.168 2.759 2.609"
        stroke="var(--primary-color)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
