import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
import { isEmpty } from "lodash";

import EmptyListWithImage from "../../molecules/EmptyListWithImage/EmptyListWithImage";
import CourseIframe from "../../molecules/CourseIframe/CourseIframe";
import MasterclassIndex from "../../molecules/MasterclassIndex/MasterclassIndex";

import { COURSES_LIST, MASTERCLASS_MODULES } from "../../../utils/constants";
import BackIcon from "../../../assets/images/f-btn-arrow-gray.svg";

const Courses = () => {
  let query = new URLSearchParams(useLocation().search);
  const courseParam = query.get("course");
  const [allowedCourses, setAllowedCourses] = useState([]);
  const [activeCourse, setActiveCourse] = useState({});
  const masterclassIds = MASTERCLASS_MODULES.map((module) => module?.id);

  useEffect(() => {
    // need to add courses permissions once implemented from BE
    setAllowedCourses([...COURSES_LIST]);

    if (!isEmpty(COURSES_LIST)) {
      let selectedCourse = COURSES_LIST[0];
      if (courseParam) {
        const course = COURSES_LIST.find((course) => course.id === courseParam);
        if (course) selectedCourse = course;
      }
      setActiveCourse(selectedCourse);
    }
    document.body.classList.add("course-page-body");

    return () => {
      document.body.classList.remove("course-page-body");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={"course-mainpage"}>
      <Grid container spacing={3}>
        {allowedCourses && !isEmpty(allowedCourses) ? (
          <>
            <Grid item xs={3}>
              <h2 className={"title-primary text-left mb-15"}>Categories</h2>
              <div>
                <div className={"categories"}>
                  <ul>
                    {allowedCourses.map((course) => (
                      <li
                        key={course.id}
                        className={`category-item cursor-pointer ${
                          (course.id === activeCourse.id ||
                            (course.id === COURSES_LIST[0].id &&
                              masterclassIds?.includes(activeCourse?.id))) &&
                          "active"
                        }`}
                      >
                        <a
                          href="/#"
                          onClick={(e) => {
                            e.preventDefault();
                            setActiveCourse(course);
                          }}
                        >
                          <div className={"item"}>
                            <div className={"cat-name"}>
                              <div className={"header-primary"}>
                                {course.name}
                              </div>
                            </div>
                          </div>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </Grid>
            <Grid item xs={9}>
              <div className={`${activeCourse.id} iframe-content`}>
                <div className="course-header">
                  {masterclassIds?.includes(activeCourse?.id) ? (
                    <a
                      className="masterclass-reset"
                      href="#/"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveCourse(COURSES_LIST[0]);
                      }}
                    >
                      <img
                        src={BackIcon}
                        id="BackArrow"
                        width="18px"
                        alt="Back arrow"
                      />
                      <span>Back to Ultimate guide to US admissions</span>
                    </a>
                  ) : (
                    <></>
                  )}
                  <h2 className="title-primary">{activeCourse.name}</h2>
                </div>
                {activeCourse?.url === COURSES_LIST[0].url ? (
                  <MasterclassIndex setActiveCourse={setActiveCourse} />
                ) : (
                  <CourseIframe
                    coursesList={[...allowedCourses, ...MASTERCLASS_MODULES]}
                    activeCourse={activeCourse.id}
                  />
                )}
              </div>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <EmptyListWithImage
              title={`Looks like there are no courses for you.`}
              className={"default-empty-cover"}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default Courses;
