import NotificationSettingCard from "../../../atoms/NotificationSettingCard/NotificationSettingCard";

export default function HomeFeedSettings({ onToggle }) {
  return (
    <NotificationSettingCard
      title={"New Posts"}
      subTitle={"Receive alerts whenever a new post is created."}
      pushValue={"user_post_push"}
      emailValue={"user_post_email"}
      updateValue="user_posts"
      onToggle={onToggle}
    />
  );
}
