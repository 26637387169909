import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip, ClickAwayListener } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import { capitalizeFirstLetterOnType } from "../../../../utils/common";
import toastify from "../../../../utils/toast";
import { ROUTES } from "../../../../routes";
import {
  followUnfollowTopicAPI,
  closeTopicAPI,
  muteUnmuteTopicAPI,
  reportTopicAPI,
  favouriteTopicAPI,
} from "../../../../api/community";
import Card from "../../../atoms/Card/Card";
import MenuDialog from "./MenuDialog";
import ReadMoreReadLess from "../../../atoms/ReadMoreReadLess/ReadMoreReadLess";
import {
  toggleActiveTopicMuteStatus,
  setPreApiCallSavedTopic,
  handlePostTopicCloseUnfollow,
  updateTopicFavouriteStatus,
} from "../../../../redux/reducers/communityReducer";
import { CircularAvatar } from "../../../atoms/CircularAvatar/CircularAvatar";

import topicMenuOpen from "../../../../assets/svg/community-menu-open.svg";
import topicMenuClose from "../../../../assets/svg/community-menu-close.svg";
import savePinIcon from "../../../../assets/svg/save-pin.svg";

const MAX_LINES_TOPIC_POST = 7;

const TopicRight = ({ callGetSavedTopicPostsAPI = () => {} }) => {
  const { activeTopic, activeTopicId, topicPosts } = useSelector(
    (state) => state.communityState
  );
  const { user = {}, selectedSchool = {} } = useSelector(
    (state) => state.userState
  );
  const { id: userId } = user || {};
  const selectedSchoolId = selectedSchool?.id || null;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [menuOptions, setMenuOptions] = useState(false);
  const [menuModelState, setMenuModelState] = useState({
    isOpen: false,
  });
  const [reportText, setReportText] = useState("");
  const [loadingAPI, setLoadingAPI] = useState(false);

  const callMuteUnmuteTopicAPI = async (
    topic_follower_id = null,
    topic_id = null
  ) => {
    setLoadingAPI(true);
    const response = await muteUnmuteTopicAPI(
      topic_follower_id,
      topic_id,
      selectedSchoolId
    );
    setLoadingAPI(false);
    if (response.success) {
      toastify(response.message);
      dispatch(toggleActiveTopicMuteStatus());
      closeMenuModel();
    } else {
      toastify(response?.message, "error");
    }
  };

  const callCloseTopicAPI = async (topic_id = null) => {
    setLoadingAPI(true);
    const response = await closeTopicAPI(topic_id);
    setLoadingAPI(false);
    if (response?.success && response?.data) {
      dispatch(handlePostTopicCloseUnfollow(activeTopic?.id));
      toastify(response?.message);
      closeMenuModel();
    } else {
      toastify(response.message, "error");
    }
  };

  const callReportTopicAPI = async (reportData = {}) => {
    setLoadingAPI(true);
    const response = await reportTopicAPI(reportData);
    setLoadingAPI(false);
    if (response.success) {
      toastify(response?.message);
      menuModelHandler(4);
    } else {
      toastify(response?.message, "error");
    }
  };

  const callFollowUnfollowTopicAPI = async (topic_id = null) => {
    setLoadingAPI(true);
    const response = await followUnfollowTopicAPI(topic_id, selectedSchoolId);
    setLoadingAPI(false);
    if (response.success) {
      dispatch(handlePostTopicCloseUnfollow(activeTopic?.id));
      toastify(response?.message);
      closeMenuModel();
    } else {
      toastify(response?.message, "error");
    }
  };

  const menuModelHandler = (modelId) => {
    switch (modelId) {
      case 1:
        setMenuModelState((current) => ({
          ...current,
          modelId,
          isOpen: true,
          title: `Are you sure you want to ${
            activeTopic?.mute_topic ? "unmute" : "mute"
          } this Community?`,
          btnText: `${activeTopic?.mute_topic ? "Unmute" : "Mute"}`,
          bottomText: "Cancel",
          showTextBox: false,
        }));
        break;
      case 2:
        setMenuModelState((current) => ({
          ...current,
          modelId,
          isOpen: true,
          title: "Are you sure you want to close this Community?",
          subTitle:
            "If you close this Community, others will no longer be able to access the content.",
          btnText: "Close Community for all",
          bottomText: "Keep Community open",
          showTextBox: false,
        }));
        break;
      case 3:
        setMenuModelState((current) => ({
          ...current,
          modelId,
          isOpen: true,
          title: "What would you like to report?",
          btnText: "Share Report",
          bottomText: "No thanks",
          showTextBox: true,
        }));
        break;
      case 4:
        setMenuModelState((current) => ({
          ...current,
          modelId,
          isOpen: true,
          title: "Thanks for sharing your feedback!",
          subTitle: "We will review your report and take the necessary action.",
          btnText: "Got It",
          bottomText: "",
          showTextBox: false,
          className: "report-feedback",
        }));
        break;
      case 5:
        setMenuModelState((current) => ({
          ...current,
          modelId,
          isOpen: true,
          title: `Are you sure you want to unfollow this Community?`,
          btnText: `Unfollow`,
          bottomText: "Cancel",
          showTextBox: false,
        }));
        break;
      default:
        break;
    }
  };

  const closeMenuModel = () => {
    setMenuModelState({ isOpen: false });
  };

  const onTextChange = (value) => {
    setReportText(value);
  };

  const callAPI = (modelId) => {
    switch (modelId) {
      case 1:
        // mute-unmute
        callMuteUnmuteTopicAPI(activeTopic?.topic_follower_id, activeTopic?.id);
        break;
      case 2:
        // close Community
        callCloseTopicAPI(activeTopic?.id);
        break;
      case 3:
        //Report Community
        callReportTopicAPI({
          report_text: reportText?.trim(),
          topic_id: activeTopic?.id,
        });
        break;
      case 4:
        closeMenuModel();
        break;
      case 5:
        // Unfollow Community
        callFollowUnfollowTopicAPI(activeTopic?.id);
        break;
      default:
        break;
    }
  };

  const handleSavePostsClick = () => {
    dispatch(setPreApiCallSavedTopic("topic-saved"));
    callGetSavedTopicPostsAPI(activeTopicId);
  };

  const handleFavorite = async () => {
    setMenuOptions(false);
    if (activeTopic?.id) {
      const response = await favouriteTopicAPI(
        activeTopic.id,
        selectedSchoolId
      );
      if (response?.success && response?.data) {
        toastify(response?.message);
        // Update local app state
        dispatch(updateTopicFavouriteStatus(activeTopic.id));
      } else {
        toastify(response?.message, "error");
      }
    } else {
      toastify("", "error");
    }
  };

  const handleEditTopic = (topicId = null) => {
    if (topicId) {
      navigate(ROUTES.COMMUNITY.ADD_COMMUNITY + "?topicId=" + topicId);
    }
  };

  return (
    <div className="right-section">
      <MenuDialog
        isOpen={menuModelState.isOpen}
        data={menuModelState}
        handleClose={closeMenuModel}
        callAPI={callAPI}
        onTextChange={onTextChange}
        reportText={reportText}
        loader={loadingAPI}
      />
      <div className="details ntopics-cards-wrapper">
        <Card>
          <div className="right-section__options">
            <img
              alt="menu-icon"
              src={menuOptions ? topicMenuClose : topicMenuOpen}
              onClick={() => {
                setMenuOptions((current) => !current);
              }}
            />
            {menuOptions && (
              <ClickAwayListener onClickAway={() => setMenuOptions(false)}>
                <ul className="right-section__options__list">
                  <li onClick={handleFavorite}>
                    {activeTopic?.favourite ? "Unfavorite" : "Favorite"}
                  </li>
                  <li onClick={() => menuModelHandler(1)}>
                    {activeTopic?.mute_topic
                      ? "Unmute Community"
                      : "Mute Community"}
                  </li>
                  <li onClick={() => menuModelHandler(3)}>Report</li>
                  {activeTopic?.created_by_id === userId ? (
                    <>
                      <li onClick={() => menuModelHandler(2)}>
                        Close Community
                      </li>
                      <li
                        onClick={(e) => {
                          e.preventDefault();
                          handleEditTopic(activeTopic?.id);
                        }}
                      >
                        Edit Community
                      </li>
                    </>
                  ) : !activeTopic?.show_default ? (
                    <li onClick={() => menuModelHandler(5)}>
                      Unfollow Community
                    </li>
                  ) : (
                    <></>
                  )}
                </ul>
              </ClickAwayListener>
            )}
          </div>

          <div className="max-width-wrap">
            <div>
              <CircularAvatar
                src={activeTopic?.topic_icon}
                name={activeTopic?.topic_name}
                alt={activeTopic?.topic_name}
                round={true}
                size={90}
              />
            </div>
            <Tooltip title={activeTopic?.topic_name}>
              <div className="title-primary">
                {activeTopic?.topic_name?.length > 60
                  ? activeTopic?.topic_name?.slice(0, 60) + "..."
                  : activeTopic?.topic_name}
              </div>
            </Tooltip>
            <div className="decription">
              <ReadMoreReadLess
                maxLines={MAX_LINES_TOPIC_POST}
                moreComp={
                  <span className="cursor-pointer nlink-secondary">
                    Read more
                  </span>
                }
                lessComp={
                  <span className="cursor-pointer nlink-secondary">
                    Read less
                  </span>
                }
              >
                {activeTopic.topic_description}
              </ReadMoreReadLess>
            </div>
            {activeTopic?.total_participants >= 1 ? (
              <div className="participant">
                {`${activeTopic?.total_participants} ${
                  activeTopic?.total_participants > 1 ? "members" : "member"
                }`}
              </div>
            ) : (
              <></>
            )}
            <div className="type">
              {capitalizeFirstLetterOnType(activeTopic?.topic_type)}
            </div>
          </div>
        </Card>
      </div>
      {topicPosts?.saved_posts > 0 && (
        <div className="right-section__saved" onClick={handleSavePostsClick}>
          <img src={savePinIcon} alt="save" />
          <span>Your saved posts ({topicPosts?.saved_posts})</span>
        </div>
      )}
      <div className="right-section__members">
        <h3 className="right-section__members__header">
          {activeTopic?.global ? "Community Creator" : "Creator and members"}
        </h3>
        <div className="right-section__members__creator">
          <CircularAvatar
            src={activeTopic?.created_by_image}
            name={activeTopic?.created_by_name}
            alt={activeTopic?.created_by_name}
            round={true}
            size={50}
          />
          <div className="right-section__members__creator__title">
            <div className="right-section__members__creator__title__line-one">
              <span className="right-section__members__creator__title__line-one__name">
                {activeTopic?.created_by_name}
              </span>
              {activeTopic?.user_type && (
                <div className="right-section__members__creator__title__line-one__tag">
                  {activeTopic?.user_type}
                </div>
              )}
            </div>
            <div className="right-section__members__creator__title__line-two">
              <span>{activeTopic?.created_by_school_name}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TopicRight;
