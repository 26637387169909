import React, { memo } from "react";

function GoogleIcon() {
  return (
    <svg
      width="65px"
      height="65px"
      viewBox="0 0 65 65"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Final-Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Log-In" transform="translate(-991.000000, -360.000000)">
          <g id="Group-4" transform="translate(991.000000, 360.000000)">
            <circle id="Oval" fill="#F1F4F7" cx="32.5" cy="32.5" r="32.5"></circle>
            <g
              id="Icon/Logo/LinkedIn"
              transform="translate(19.000000, 19.000000)"
              fillRule="nonzero"
            >
              <g id="Icon/Logo/GoogleCustom">
                <path
                  d="M25.6446011,13.7803868 C25.6446011,12.9600022 25.5690011,12.181156 25.4394011,11.4230791 L13.2354011,11.4230791 L13.2354011,16.1065407 L20.2230011,16.1065407 C19.9098011,17.6434638 18.9918011,18.9415408 17.6310011,19.8242331 L17.6310011,22.9396177 L21.7998011,22.9396177 C24.2406011,20.7692331 25.6446011,17.5707715 25.6446011,13.7803868 Z"
                  id="Path"
                  fill="#4285F4"
                ></path>
                <path
                  d="M13.2354011,25.9615409 C16.7346011,25.9615409 19.6614011,24.8400024 21.7998011,22.9396177 L17.6310011,19.824233 C16.4646011,20.5719253 14.9850011,21.0288484 13.2354011,21.0288484 C9.85500108,21.0288484 6.99300108,18.8376945 5.96700108,15.878079 L1.66860108,15.878079 L1.66860108,19.0869253 C3.79620108,23.1576947 8.17020108,25.9615409 13.2354011,25.9615409 Z"
                  id="Path"
                  fill="#34A853"
                ></path>
                <path
                  d="M5.96700108,15.8780788 C5.69700108,15.1303864 5.55660108,14.3307711 5.55660108,13.5000018 C5.55660108,12.6692326 5.70780108,11.8696172 5.96700108,11.1219249 L5.96700108,7.91307878 L1.66860108,7.91307878 C0.78300108,9.59538647 0.27540108,11.4853865 0.27540108,13.5000018 C0.27540108,15.5146172 0.78300108,17.4046172 1.66860108,19.0869249 L5.96700108,15.8780788 Z"
                  id="Path"
                  fill="#FBBC05"
                ></path>
                <path
                  d="M13.2354011,5.97115616 C15.1470011,5.97115616 16.8534011,6.60461772 18.2034011,7.840387 L21.8970011,4.2888484 C19.6614011,2.27423294 16.7346011,1.03846366 13.2354011,1.03846366 C8.17020108,1.03846366 3.79620108,3.84230992 1.66860108,7.91307931 L5.96700108,11.1219256 C6.99300108,8.16231009 9.85500108,5.97115616 13.2354011,5.97115616 Z"
                  id="Path"
                  fill="#EA4335"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default memo(GoogleIcon);
