const config =
  process.env.REACT_APP_ENV === "prod"
    ? {
        API_URL: "https://web.interstride.com/api/v1/admission_portal/",
        SOCKET_URL: "wss://cable.interstride.com/cable?token=",
        BUGSNAG_API_KEY: "04bbca3ae0194c95c3fdeb422b224299",
        MIXPANEL_PROJECT_KEY: "013535125b91629da0960d092b32ebc1",
      }
    : process.env.REACT_APP_ENV === "pre_prod"
    ? {
        API_URL: "https://web.staging.interstride.com/api/v1/admission_portal/",
        SOCKET_URL: "wss://staging.cable.interstride.com/cable?token=",
        BUGSNAG_API_KEY: "1073ea34972d6dfd31bc94643a7f9530",
        MIXPANEL_PROJECT_KEY: "90b46fb71d8c22174b340153d62325c4",
      }
    : {
        API_URL: "https://web.testing.interstride.com/api/v1/admission_portal/",
        SOCKET_URL: "wss://web.cable.testing2.interstride.com/cable?token=",
        BUGSNAG_API_KEY: "1073ea34972d6dfd31bc94643a7f9530",
        MIXPANEL_PROJECT_KEY: "90b46fb71d8c22174b340153d62325c4",
      };

export const LINK_PREVIEW_SERVICE = "https://previewlink.interstride.com/";
export const FACEBOOK_CLIENT_ID = `${process.env.REACT_APP_FACEBOOK_CLIENT_ID}`;
export const GOOGLE_CLIENT_ID = `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`;
export const LINKEDIN_CLIENT_ID = `${process.env.REACT_APP_LINKEDIN_CLIENT_ID}`;
export const LINKEDIN_REDIRECT_URI =
  process.env.REACT_APP_ENV === "prod"
    ? "https://admissions.interstride.com/linkedin"
    : window.location.origin + "/linkedin";
export const CHAT_BOT_APP_ID = `${process.env.REACT_APP_CHAT_BOT_APP_ID}`;
export const CHAT_BOT_APP_NAME = `${process.env.REACT_APP_CHAT_BOT_APP_NAME}`;
export const MICROSOFT_CLARITY_ID = `${process.env.REACT_APP_MICROSOFT_CLARITY_ID}`;
export const GOOGLE_MAP_KEY = `${process.env.REACT_APP_GOOGLE_MAP_KEY}`;
export const GOOGLE_MAP_ID = `${process.env.REACT_APP_GOOGLE_MAP_ID}`;
export const GOOGLE_TAG_MANAGER_ID = `${process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID}`;
export const DEFAULT_PRIMARY_COLOR = `#000485`;
export const DEFAULT_NITIN_IMAGE =
  "https://ca.slack-edge.com/T32PWRGTV-UD3NTBRM1-5b90b7a031e9-512";

export const { API_URL, SOCKET_URL, BUGSNAG_API_KEY, MIXPANEL_PROJECT_KEY } =
  config;
