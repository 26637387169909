import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { ShimmerThumbnail } from "react-shimmer-effects";

import ListingEffect from "../../atoms/ListingEffect/ListingEffect";
import { Button, ButtonModifier } from "../../atoms/Button/Button";
import ChecklistsCategories from "../../molecules/ChecklistsCategories/ChecklistsCategories";
import ChecklistsList from "../../molecules/ChecklistsList/ChecklistsList";
import EmptyListWithImage from "../../atoms/EmptyListWithImage/EmptyListWithImage";
import { isEmpty } from "../../../utils/validations";
import { getChecklistCategories } from "../../../api/checklists";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./Checklists.module.scss";

import EmptyChecklistIcon from "../../../assets/svg/empty-checklist.svg";
import PlusIcon from "../../../assets/svg/f-plus.svg";

const cx = createModuleStyleExtractor(styles);

const Checklists = () => {
  const [checkListCategories, setChecklistCategories] = useState([]);
  const [checklistCategoriesLoader, setChecklistCategoriesLoader] =
    useState(true);
  const [selectedCategory, setSelectedCategory] = useState(0);

  const [checklistTasks, setChecklistTasks] = useState([]);
  const [checklistTasksLoader, setChecklistTasksLoader] = useState(false);

  const userState = useSelector((state) => state.userState);
  const selectedSchool = userState.selectedSchool;

  useEffect(() => {
    const fetchChecklistCategories = async () => {
      setChecklistCategoriesLoader(true);
      const response = await getChecklistCategories(selectedSchool.id);
      if (response.success) {
        const { checklist = [] } = response.data;

        if (checklist && checklist.length > 0) {
          setChecklistCategories(checklist);
        } else {
          setChecklistCategories([]);
        }
      } else {
        setChecklistCategories([]);
      }
      setChecklistCategoriesLoader(false);
    };

    fetchChecklistCategories();
  }, [selectedSchool]);

  const setAddNewChecklist = () => {
    let newChecklist = [...checklistTasks];
    newChecklist.unshift({ title: undefined, is_mark: false });
    setChecklistTasks([...newChecklist]);
  };

  const setAddNewSubTask = (taskIndex) => {
    let newChecklist = [...checklistTasks];
    let newSubTaskList = newChecklist[taskIndex].sub_tasks;
    newSubTaskList.push({ title: undefined, is_mark: false });
    newChecklist[taskIndex].sub_tasks = newSubTaskList;
    setChecklistTasks([...newChecklist]);
  };

  const checklistProps = {
    checkListCategories,
    selectedCategory,
    setSelectedCategory,
    checklistTasks,
    setChecklistTasks,
    checklistTasksLoader,
    setChecklistTasksLoader,
    setAddNewSubTask,
  };

  return (
    <div className={cx("o-checklists")}>
      <div className={cx("o-checklists__header")}>
        <h2>Checklist</h2>
      </div>
      <Grid container spacing={2}>
        {checklistCategoriesLoader ? (
          <>
            <Grid item xs={3}>
              {[...Array(8)].map((_, index) => (
                <ShimmerThumbnail height={50} key={index} />
              ))}
            </Grid>
            <Grid item xs={9}>
              <ListingEffect count={5} sideSpace={false} />
            </Grid>
          </>
        ) : isEmpty(checkListCategories) && isEmpty(checklistTasks) ? (
          <Grid item xs={12}>
            <EmptyListWithImage
              Image={EmptyChecklistIcon}
              title={"Looks like there are no tasks in this checklist"}
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={3}>
              <ChecklistsCategories {...checklistProps} />
            </Grid>
            <Grid item xs={9}>
              <div className={cx("o-checklists__category-header")}>
                <h2>{checkListCategories[selectedCategory].category_name}</h2>
                <Button
                  onClick={setAddNewChecklist}
                  modifier={ButtonModifier.CHECKLIST_ADD}
                >
                  Add a new task
                  <img src={PlusIcon} alt="add" className="plus-icon" />
                </Button>
              </div>
              <ChecklistsList {...checklistProps} />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default memo(Checklists);
