import React, { memo } from "react";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./EmptyServices.module.scss";

import NoContentIllustration from "../../../assets/images/discover_no_people.svg";

const cx = createModuleStyleExtractor(styles);

const EmptyResources = () => {
  return (
    <div className={cx("a-empty-services")}>
      <img src={NoContentIllustration} alt="no-services" />
      <h2>Looks like there are no services with this search.</h2>
      <h3>Try expanding your search to discover other services!</h3>
    </div>
  );
};
export default memo(EmptyResources);
