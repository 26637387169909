import React, { memo, useState } from "react";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import AsyncSelect from "react-select/async";

import { createModuleStyleExtractor } from "../../../utils/css";
import { noop } from "../../../utils/noop";
import { isNull, checkInvalidSelectValue } from "../../../utils/validations";
import styles from "./MuiSelect.module.scss";

import SelectUpArrow from "../../../assets/Icons/SelectUpArrow";
import SelectDownArrow from "../../../assets/Icons/SelectDownArrow";

const { ValueContainer, Placeholder } = components;

const cx = createModuleStyleExtractor(styles);

const CustomValueContainer = ({ children, selectProps, ...props }) => {
  const { value = null } = selectProps;

  const noValue =
    checkInvalidSelectValue(value) ||
    (checkInvalidSelectValue(value.label) &&
      checkInvalidSelectValue(value.value));

  return (
    <ValueContainer {...props}>
      {noValue && (
        <Placeholder {...props} isFocused={props.isFocused}>
          {selectProps.placeholder}
        </Placeholder>
      )}

      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? <SelectUpArrow /> : <SelectDownArrow />}
    </components.DropdownIndicator>
  );
};

export const MuiSelectModifier = {
  LEFT_ALIGN: "a-mui-select--left-align",
  CENTER_ALIGN: "a-mui-select--center-align",
};

const MuiSelect = ({
  onClick = noop,
  onFocus = noop,
  onChange = noop,
  onBlur = noop,
  onInputChange = noop,
  loadOptions = noop,
  name,
  value,
  defaultValue = null,
  placeholder,
  width = "100%",
  height = 50,
  label = "",
  showLabelAtTop = true,
  options = [],
  justifyContent = "center",
  isMulti = false,
  isDisabled = false,
  error = false,
  errorText = "",
  validationError = "",
  touched = false,
  isAsync = false,
  inputId = null,
  autoFocus = false,
  noOptionsMessage = "No options",
  validationErrorStyles = {},
  showStarIcon = true,
  modifier,
  editable = false,
  addText = "Add Option",
  isClearable = false,
  isSearchable = true,
  ...props
}) => {
  const colourStyles = {
    multiValue: (styles) => ({
      ...styles,
      background: "#E5EAF7",
      borderRadius: "45px",
      color: "var(--primary-font-color)",
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: "var(--primary-font-color)",
      padding: "4px 0 1px 0",
      paddingLeft: "10px",
    }),
    valueContainer: (styles) => ({ ...styles, justifyContent: justifyContent }),
    indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
    menu: (styles) => ({
      ...styles,
      border: "2px solid var(--light-gray-color)",
      borderRadius: "10px",
      backgroundColor: "#FFFFFF",
      boxShadow: "0 5px 14px 3px rgba(209,221,229,0.4)",
      padding: "4px 0px 4px 0px !important",
    }),
    menuList: (base) => ({
      ...base,
      "::-webkit-scrollbar": {
        height: "7px",
        width: "7px",
        boxSizing: "border-box",
      },
      "::-webkit-scrollbar-track": {
        background: "white",
      },
      "::-webkit-scrollbar-thumb": {
        borderRadius: "8px",
        background: "rgba(0, 0, 0, 0.2)",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
    control: (styles, { isFocused }) => ({
      ...styles,
      cursor: "pointer",
      backgroundColor: "white",
      width: `${width}px`,
      minHeight: `${height}px`,
      height: "max-content",
      border: "2px solid var(--light-gray-color)",
      borderRadius: "45px",
      color: "#607790",
      fontFamily: "TTCommons-Regular",

      fontSize: "18px",
      fontWeight: "400",
      letterSpacing: "0",
      lineHeight: "17px",
      // --Focus style
      // boxShadow: isFocused
      //   ? "0 0 8px 0px var(--primary-font-color) !important"
      //   : "",
      borderColor: isFocused ? "var(--primary-font-color) !important" : "",
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        // cursor: "pointer !important",
        borderRadius: isFocused ? "0px 0px 0px 0px" : "0px",
        padding: "12px 20px 8px 20px",
        backgroundColor: isDisabled
          ? "#fff"
          : isSelected && !isFocused
          ? "#fff"
          : isFocused
          ? "var(--primary-font-color)"
          : null,
        color: isDisabled
          ? "var(--primary-font-color)"
          : isSelected && !isFocused
          ? "var(--primary-font-color)"
          : isFocused
          ? "#fff"
          : "var(--primary-font-color)",
        fontSize: "18px",
        fontFamily: "TTCommons-Regular",
        fontWeight: 400,
        letterSpacing: 0,
        lineHeight: "21.7px",
        wordBreak: "break-word !important",
        cursor: isDisabled ? "not-allowed" : "default",
        justifyContent: justifyContent,
        textAlign: justifyContent,
        display: "flex",
        ":active": {
          ...styles[":active"],
        },
      };
    },
    input: (styles) => ({
      ...styles,
      textAlign: justifyContent,
      color: "#607790",
      fontFamily: "TTCommons-Regular",
    }),
    placeholder: (styles) => ({
      ...styles,
      color: "var(--field-label-color)",
      fontFamily: "TTCommons-Regular",
      textAlign: justifyContent,
    }),
    singleValue: (styles, { isDisabled }) => ({
      ...styles,
      justifyContent: justifyContent,
      color: "var(--primary-font-color)",
      fontFamily: "TTCommons-Regular",
      opacity: isDisabled ? 0.6 : 1,
    }),
  };

  const id = Math.random();

  const [inputChangeVal, setInputChangeVal] = useState("");
  const handleInputChange = (e) => {
    onInputChange(e);
    setInputChangeVal(e);
  };

  return (
    <div
      className={cx(
        "a-mui-select",
        modifier
          ? {
              [modifier]: true,
            }
          : {}
      )}
    >
      <div className="field-group">
        {label && <label>{label}</label>}

        {isAsync ? (
          <AsyncSelect
            components={{
              DropdownIndicator,
            }}
            captureMenuScroll={false}
            cacheOptions
            className={`a-mui-select__input ${
              isMulti ? "a-mui-select__input--multi" : ""
            }`}
            name={name}
            options={options}
            loadOptions={loadOptions}
            styles={colourStyles}
            onClick={onClick}
            onFocus={onFocus}
            onChange={onChange}
            onBlur={onBlur}
            value={value || ""}
            defaultValue={{ label: defaultValue, value: defaultValue }}
            placeholder={placeholder || label}
            isMulti={isMulti}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary50: "var(--primary-font-color)",
                primary25: "var(--primary-font-color)",
                primary: "$gray-outline-color",
              },
            })}
            aria-label={placeholder || label}
            inputId={`dropdown-input-${id}`}
            autoFocus={autoFocus}
            {...props}
          />
        ) : editable ? (
          <CreatableSelect
            components={{
              DropdownIndicator,
              ...(isMulti ? {} : { ValueContainer: CustomValueContainer }),
            }}
            captureMenuScroll={false}
            className={`a-mui-select__input ${
              isMulti ? "a-mui-select__input--multi" : ""
            }`}
            name={name}
            options={options}
            styles={colourStyles}
            onClick={onClick}
            onFocus={onFocus}
            onChange={onChange}
            onBlur={onBlur}
            isDisabled={isDisabled}
            onInputChange={(e) => handleInputChange(e)}
            value={value || ""}
            defaultValue={{ label: defaultValue, value: defaultValue }}
            placeholder={inputChangeVal ? "" : placeholder || label}
            isMulti={isMulti}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary50: "var(--primary-font-color)",
                primary25: "var(--primary-font-color)",
                primary: "$gray-outline-color",
              },
            })}
            aria-label={placeholder || label}
            inputId={!isNull(inputId) ? inputId : `dropdown-input-${id}`}
            isOptionDisabled={(option) => option.isDisabled}
            autoFocus={autoFocus}
            isClearable={false}
            formatCreateLabel={(inputValue) => `${addText} "${inputValue}"`}
          />
        ) : (
          <Select
            isClearable={isClearable}
            components={{
              DropdownIndicator,
              ...(isMulti ? {} : { ValueContainer: CustomValueContainer }),
            }}
            captureMenuScroll={false}
            className={`a-mui-select__input ${
              isMulti ? "a-mui-select__input--multi" : ""
            }`}
            name={name}
            options={options}
            styles={colourStyles}
            onClick={onClick}
            onFocus={onFocus}
            onChange={onChange}
            onBlur={onBlur}
            isDisabled={isDisabled}
            onInputChange={(e) => handleInputChange(e)}
            value={value || ""}
            defaultValue={{ label: defaultValue, value: defaultValue }}
            placeholder={inputChangeVal ? "" : placeholder || label}
            isMulti={isMulti}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary50: "var(--primary-font-color)",
                primary25: "var(--primary-font-color)",
                primary: "$gray-outline-color",
              },
            })}
            aria-label={placeholder || label}
            inputId={!isNull(inputId) ? inputId : `dropdown-input-${id}`}
            isOptionDisabled={(option) => option.isDisabled}
            autoFocus={autoFocus}
            isSearchable={isSearchable}
          />
        )}
        {error && <span className="p-error">{error}</span>}
      </div>
    </div>
  );
};

export default memo(MuiSelect);
