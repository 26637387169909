import React, { memo, useState } from "react";

import { Col } from "../../helpers/Grid/Col";
import { Row } from "../../helpers/Grid/Row";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./TermsAndConditions.module.scss";
import Categories from "../../atoms/Categories/Categories";
import { TERMS_AND_CONDITIONS } from "../../../utils/constants";
import TermsAndConditionsDetails from "../../molecules/TermsAndConditionsDetails/TermsAndConditionsDetails";

import FilterIcon from "../../../assets/Icons/FilterIcon";

const cx = createModuleStyleExtractor(styles);

const TermsAndConditions = () => {
  const [selectedCategory, setSelectedCategory] = useState(
    TERMS_AND_CONDITIONS[0]
  );
  const [openFilter, toggleFilter] = useState(false);

  return (
    <div className={cx("o-terms-and-conditions")}>
      <Row className={"two-section-row-with-spacing"}>
        <Col xs={1} sm={1} md={1} lg={1} />
        <Col xs={3} sm={3} md={3} lg={3}>
          <div
            className={`collapsible-section ${
              openFilter ? "collapsible-section--open" : ""
            }`}
          >
            <div
              className={cx("o-terms-and-conditions__header")}
              id={"collapsible-section-header"}
            >
              <h2>Terms & Conditions</h2>
              <a
                href="/#"
                onClick={(e) => {
                  e?.preventDefault && e.preventDefault();
                  toggleFilter(!openFilter);
                }}
                id="collapsible-section-header-icon"
              >
                <FilterIcon fill={openFilter} />
              </a>
            </div>
            <div id="collapsible-section-search-filter">
              <Categories
                data={TERMS_AND_CONDITIONS}
                selectedCategoryId={selectedCategory.id}
                onCategorySelect={setSelectedCategory}
                toggleFilter={toggleFilter}
              />
            </div>
          </div>
        </Col>
        <Col xs={7} sm={7} md={7} lg={7}>
          <TermsAndConditionsDetails selectedCategory={selectedCategory} />
        </Col>
        <Col xs={1} sm={1} md={1} lg={1} />
      </Row>
    </div>
  );
};

export default memo(TermsAndConditions);
