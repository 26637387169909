import React, { memo } from "react";

function WidgetMessagesIcon() {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke={"var(--primary-font-color)"}
        strokeWidth="1.2"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M19.361 25.788c.892 3.12 3.82 5.409 7.294 5.409h7.572V23.79c0-4.092-3.39-7.408-7.572-7.408-.72 0-1.416.098-2.075.281" />
        <path d="M14.812 26.427H5v-9.713C5 11.349 9.393 7 14.812 7s9.812 4.349 9.812 9.714c0 5.364-4.393 9.713-9.812 9.713" />
        <path
          d="M25.5 24.463h3.897M9.688 14.635h8.996m-8.996 4.828h5.124"
          fill="#000C3D"
        />
      </g>
    </svg>
  );
}

export default memo(WidgetMessagesIcon);
