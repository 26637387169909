import React, { memo } from "react";
import { Radio } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./RadioButton.module.scss";

const cx = createModuleStyleExtractor(styles);

const RadioButton = ({ id, value, onChange = () => {}, ...props }) => {
  return (
    <div className={cx("a-radio-button")}>
      <Radio
        disableRipple={true}
        type="checkbox"
        id={id}
        checkedIcon={<CheckCircle color={"primary"} fontSize="small" />}
        color="primary"
        size="small"
        value={value}
        checked={value === "true"}
        onChange={onChange}
        {...props}
      />
    </div>
  );
};
export default memo(RadioButton);
