import React, { memo } from "react";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./EmptyListWithImage.module.scss";

import EmptyImage from "../../../assets/svg/error404.svg";

const cx = createModuleStyleExtractor(styles);

const EmptyListWithImage = ({ title, subTitle, Image = EmptyImage }) => {
  return (
    <div className={cx("a-empty-list-with-image")}>
      <div className={cx("a-empty-list-with-image__image-wrapper")}>
        <img src={Image} alt="empty" />
      </div>
      {title && <h3>{title}</h3>}
      {subTitle && <p>{subTitle}</p>}
    </div>
  );
};
export default memo(EmptyListWithImage);
