import React from "react";
import { useSelector } from "react-redux";

import Card from "../../../atoms/Card/Card";
import { Button } from "../../../atoms/Button";
import StudentProfile from "./StudentProfile";
import { createModuleStyleExtractor } from "../../../../utils/css";
import styles from "./RecommendationsDetails.module.scss";

import CloseIcon from "../../../../assets/images/f-close.svg";

const cx = createModuleStyleExtractor(styles);

const RecommendationsDetails = ({
  handleClose,
  details = {},
  hideClose = false,
  showMessageButton = false,
  showMessageButtonText = "Message",
  handleFavorite = () => {},
  handleMessage = () => {},
}) => {
  const { user } = useSelector((store) => store.userState);

  return (
    <Card>
      <div className={cx("recom-details-card")}>
        {!hideClose && (
          <a
            href="/#"
            className={cx("recom-details-card__close-btn")}
            onClick={(e) => {
              e.preventDefault();
              handleClose(e);
            }}
          >
            <img
              src={CloseIcon}
              id="CloseIcon"
              width="18px"
              alt="Close Profile Dialog"
            />
          </a>
        )}

        {details && (
          <StudentProfile
            user={details}
            showFavourite={
              user?.id === details?.id || user?.school_id !== details?.school_id
                ? false
                : true
            }
            handleFavorite={handleFavorite}
            discoverNetwork={true}
            showUniversityName={details?.school_id !== user?.school_id}
          />
        )}

        {showMessageButton && (
          <div className={cx("recom-details-card__message-btn")}>
            <Button onClick={(e) => handleMessage(e, details)}>
              {showMessageButtonText}
            </Button>
          </div>
        )}
      </div>
    </Card>
  );
};

export default RecommendationsDetails;
