import React, { memo } from "react";

function AccordionArrow({ color = "#000C3D", onClick = () => {} }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 0 48 48"
      width="48"
      fill={color}
      onClick={onClick}
    >
      <path d="M14.83 16.42 24 25.59l9.17-9.17L36 19.25l-12 12-12-12z" />
      <path d="M0-.75h48v48H0z" fill="none" />
    </svg>
  );
}

export default memo(AccordionArrow);
