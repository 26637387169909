import React from "react";

function EmojiPickerIcon({ onClick }) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g transform="translate(.095)" fill="none" fillRule="evenodd">
        <path
          d="M10.016 19c4.978 0 9.014-4.03 9.014-9 0-4.57-3.412-8.345-7.832-8.923A9.113 9.113 0 0 0 10.016 1c-4.979 0-9.014 4.03-9.014 9s4.035 9 9.014 9Z"
          stroke="#607790"
          strokeWidth="1.5"
        />
        <path
          d="M6.473 12.106a3.999 3.999 0 0 0 7.1-.026"
          stroke="#607790"
          strokeWidth="1.5"
        />
        <ellipse fill="#607790" cx="8.013" cy="8" rx="1.002" ry="1" />
        <ellipse fill="#607790" cx="12.019" cy="8" rx="1.002" ry="1" />
      </g>
    </svg>
  );
}

export default React.memo(EmojiPickerIcon);
