import React, { memo } from "react";
import moment from "moment/moment";

import styles from "./WebinarsCalendarSidebarV2.module.scss";
import { createModuleStyleExtractor } from "../../../../utils/css";
import Card from "../../../atoms/Card/Card";
import {
  calculateLocalEndTimeFromEpoch,
  getLocalTzAbbr,
} from "../../../../utils/common";
import { isNull } from "../../../../utils/validations";

import userAvatar from "../../../../assets/Icons/f-user-avatar.svg";

const cx = createModuleStyleExtractor(styles);

const WebinarsCalendarSidebarV2 = ({
  myWebinars = [],
  handleViewDetailsClick = () => {},
}) => {
  return (
    <div className={cx("m-webinars-calendar-sidebar")}>
      {myWebinars?.map((webinar, index) => (
        <Card key={index}>
          <div
            className={cx("m-webinars-calendar-sidebar__item")}
            onClick={() => handleViewDetailsClick(webinar.id)}
          >
            <div className={cx("m-webinars-calendar-sidebar__item__left")}>
              <h3>
                {moment
                  .unix(webinar?.epoch_time)
                  .local()
                  .format("dddd, MMMM Do YYYY")}
              </h3>
              <h3>
                {`${moment
                  .unix(webinar?.epoch_time)
                  .local()
                  .format("hh:mm a")} - ${calculateLocalEndTimeFromEpoch(
                  webinar?.epoch_time,
                  webinar && webinar.duration && !isNull(webinar.duration)
                    ? webinar.duration.split(" ")[0]
                    : 0,
                  "minutes"
                )}`}{" "}
                ({getLocalTzAbbr()})
              </h3>

              {webinar.webinar_category_name &&
                webinar.webinar_category_name !== "undefined" && (
                  <div
                    className={cx(
                      "m-webinars-calendar-sidebar__item__left__category"
                    )}
                  >
                    {webinar.webinar_category_name}
                  </div>
                )}

              {webinar.presenter && webinar.presenter.length > 0 && (
                <div
                  className={cx(
                    "m-webinars-calendar-sidebar__item__left__presenter"
                  )}
                >
                  <span>
                    <img src={userAvatar} alt="User avatar" />
                  </span>
                  {webinar.presenter}
                </div>
              )}
            </div>
            <div className={cx("m-webinars-calendar-sidebar__item__right")}>
              <div
                className={cx(
                  "m-webinars-calendar-sidebar__item__right__title"
                )}
              >
                {webinar?.name}
              </div>
              <div
                className={cx(
                  "m-webinars-calendar-sidebar__item__right__description"
                )}
              >
                {webinar?.description}
              </div>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default memo(WebinarsCalendarSidebarV2);
