import { ApiWrapper, REQUEST } from "./index";

export const contactUs = async (payload) => {
  return await ApiWrapper({
    url: "contact_us",
    method: REQUEST.POST,
    data: payload,
  });
};

export const send_feedbacks = async (payload) => {
  return await ApiWrapper({
    url: "send_feedbacks",
    method: REQUEST.POST,
    data: payload,
  });
};

export const getAdmissionContactDetails = async (school_id) => {
  return await ApiWrapper({
    url: `school_career_center_details?school_id=${school_id}`,
    method: REQUEST.GET,
  });
};
