import React from "react";
import { noop } from "../../utils/noop";
function Icon({ onClick, styles }) {
  return (
    <svg
      onClick={onClick ?? noop}
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      style={styles}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#607790" fillRule="nonzero" transform="translate(.159)">
          <path
            d="M13.521 4v11.5c0 2.21-1.792 4-4.006 4a4.002 4.002 0 01-4.006-4V3C5.509 1.62 6.63.5 8.013.5A2.503 2.503 0 0110.517 3v10.5c0 .55-.451 1-1.002 1-.55 0-1.001-.45-1.001-1V4H7.01v9.5c0 1.38 1.122 2.5 2.504 2.5a2.503 2.503 0 002.504-2.5V3c0-2.21-1.793-4-4.006-4a4.002 4.002 0 00-4.007 4v12.5c0 3.04 2.464 5.5 5.51 5.5a5.501 5.501 0 005.508-5.5V4H13.52z"
            transform="rotate(45 9.515 10)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default React.memo(Icon);
