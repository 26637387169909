import React, { memo, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./HelpCenter.module.scss";
import Categories from "../../atoms/Categories/Categories";
import { FAQ } from "../../../utils/constants";
import HelpCenterList from "../../molecules/HelpCenterList/HelpCenterList";
import ContactUsCard from "../../molecules/ContactUsCard/ContactUsCard";
import LeaveFeedbackCard from "../../molecules/LeaveFeedbackCard/LeaveFeedbackCard";
import ContactCareerCenter from "../../molecules/ContactCareerCenter/ContactCareerCenter";
import Chatbot from "../../atoms/Chatbot/Chatbot";
import Card from "../../atoms/Card/Card";
import { getAdmissionContactDetails } from "../../../api/helpcenter";

import FilterIcon from "../../../assets/Icons/FilterIcon";

const cx = createModuleStyleExtractor(styles);
const WelcomeVideoURL =
  "https://interstride.s3.amazonaws.com/miscellaneous/assets/videos/Admissions_Intro.mp4";

const HelpCenter = () => {
  const [selectedCategory, setSelectedCategory] = useState(FAQ[0]);
  const [openFilter, toggleFilter] = useState(false);
  const [admissionCenterDetails, setAdmissionCenterDetails] = useState(null);

  const { id = null } = useSelector((state) => state.userState.selectedSchool);
  useEffect(() => {
    if (!id) return;
    (async () => {
      const response = await getAdmissionContactDetails(id);
      if (response.status === 200) {
        setAdmissionCenterDetails(response.data);
      }
    })();
  }, [id]);

  return (
    <div className={cx("o-help-center")}>
      <div className={cx("o-help-center__pageheader")}>
        <h2>Help Center</h2>
      </div>
      <Chatbot />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <div
            className={`collapsible-section ${
              openFilter ? "collapsible-section--open" : ""
            }`}
          >
            <div
              className={cx("o-help-center__header")}
              id={"collapsible-section-header"}
            >
              <h2>FAQ</h2>
              <a
                href="/#"
                onClick={(e) => {
                  e?.preventDefault && e.preventDefault();
                  toggleFilter(!openFilter);
                }}
                id="collapsible-section-header-icon"
              >
                <FilterIcon fill={openFilter} />
              </a>
            </div>
            <div id="collapsible-section-search-filter">
              <Categories
                data={FAQ}
                selectedCategoryId={selectedCategory.id}
                onCategorySelect={setSelectedCategory}
                toggleFilter={toggleFilter}
              />
            </div>
          </div>
          <div className="ShowResponsiveDiv">
            {admissionCenterDetails && (
              <ContactCareerCenter
                admissionCenterDetails={admissionCenterDetails}
              />
            )}
            <ContactUsCard />
            <LeaveFeedbackCard />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          {selectedCategory?.id === 1 ? (
            <div className={cx("o-help-center__tutorial")}>
              <h2>Welcome tutorial</h2>
              <Card>
                <div className={cx("o-help-center__tutorial__title")}>
                  Welcome to Interstride! Here’s a quick introduction to
                  Interstride's admission portal.
                </div>
                <div className={cx("o-help-center__tutorial__video")}>
                  <video controls autoPlay={false} muted>
                    <source src={WelcomeVideoURL} />
                  </video>
                </div>
              </Card>
            </div>
          ) : (
            <HelpCenterList selectedCategory={selectedCategory} />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <div className={cx("o-help-center__right-menu-spacer")} />
          {admissionCenterDetails && (
            <ContactCareerCenter
              admissionCenterDetails={admissionCenterDetails}
            />
          )}
          <ContactUsCard />
          <LeaveFeedbackCard />
        </Grid>
      </Grid>
    </div>
  );
};

export default memo(HelpCenter);
