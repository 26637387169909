import React, { useState, memo } from "react";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./TopicDetails.module.scss";
import { CircularAvatar } from "../../atoms/CircularAvatar/CircularAvatar";
import Card from "../../atoms/Card/Card";
import { Button, ButtonModifier } from "../../atoms/Button/Button";
import { isEmpty } from "../../../utils/validations";

const cx = createModuleStyleExtractor(styles);

const TopicDetails = ({ topicDetails = {} }) => {
  const SHOW_LESS_PARTICIPANT_NUMBER = 5;
  const SHOW_MORE_PARTICIPANT_NUMBER = 10000;

  const [participantsListExpanded, setParticipantsListExpanded] =
    useState(false);

  return (
    <div className={cx("m-my-topic-right")}>
      <Card>
        <div className={cx("m-my-topic-right-wrapper")}>
          <div className={cx("m-my-topic-right-topic-img-wrapper")}>
            <CircularAvatar
              src={topicDetails.topic_info.topic_icon}
              name={topicDetails.topic_info.topic_name}
              alt="topic"
              round
              size={70}
            />
          </div>
          <div className={cx("m-my-topic-right-title-primary")}>
            {topicDetails.topic_info.topic_name}
          </div>
          <div className={cx("m-my-topic-right-description")}>
            {topicDetails.topic_info.topic_description}
          </div>
          <div className={cx("m-my-topic-right-created-by")}>
            Created by <span>{topicDetails.topic_info.created_by_name}</span>
          </div>
          <div className={cx("m-my-topic-right-type")}>
            {topicDetails.topic_info.topic_type}
          </div>
          {topicDetails?.total_participants > 0 && (
            <div className={cx("m-my-topic-right-participants-count")}>
              {topicDetails.total_participants} Participants
            </div>
          )}
          <div
            className={cx([
              "m-my-topic-right-participants-wrapper",
              `${
                participantsListExpanded
                  ? "m-my-topic-right-participants-wrapper-overflow"
                  : ""
              }`,
            ])}
          >
            {topicDetails.participants_list
              .slice(
                0,
                participantsListExpanded
                  ? SHOW_MORE_PARTICIPANT_NUMBER
                  : SHOW_LESS_PARTICIPANT_NUMBER
              )
              .map((participant, index) => (
                <div key={index} className={cx("m-my-topic-right-participant")}>
                  <CircularAvatar
                    src={participant.user_image}
                    alt="user-avatar"
                    size={"30"}
                    name={participant.user_name}
                    round={true}
                    fgColor="white"
                    color="#BDBDBD"
                  />
                  <div className={cx("m-my-topic-right-participant-text")}>
                    <span
                      className={cx("m-my-topic-right-participant-text__name")}
                    >
                      {participant.user_name}
                    </span>
                  </div>
                  {participant.user_type && !isEmpty(participant.user_type) && (
                    <span className={cx("m-my-topic-right-participant__tag")}>
                      {participant.user_type}
                    </span>
                  )}
                </div>
              ))}
          </div>
          {topicDetails.participants_list.length >
            SHOW_LESS_PARTICIPANT_NUMBER && (
            <div className={cx("m-my-topic-right-participants-button-wrapper")}>
              <Button
                modifier={ButtonModifier.TOPICS_PARTICIPANT_LIST_VIEW}
                onClick={() =>
                  setParticipantsListExpanded((current) => !current)
                }
              >
                {participantsListExpanded ? "Less" : "View more"}
              </Button>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};
export default memo(TopicDetails);
