import React, { memo } from "react";
import { useSelector } from "react-redux";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./EmptyChatView.module.scss";

const cx = createModuleStyleExtractor(styles);

const EmptyChatView = () => {
  const { name } = useSelector((state) => state.userState.selectedSchool);
  return (
    <div className={cx("m-empty-chat-view")}>
      <h4>Welcome to Interstride Chat</h4>
      <img src="/images/welcome.svg" width="400px" alt="" srcSet="" />
      <p>
        {`Connect with ${
          name || "university"
        }'s staff, students, and alumni. Ask about
          academics, campus life, and anything that you are curious about!`}
      </p>
    </div>
  );
};

export default memo(EmptyChatView);
