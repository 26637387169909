import React, { useState } from "react";
import { Tooltip } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import { followUnfollowTopicAPI } from "../../../../api/community";
import { Button } from "../../../atoms/Button";
import { capitalizeFirstLetterOnType } from "../../../../utils/common";
import toastify from "../../../../utils/toast";
import { CircularAvatar } from "../../../atoms/CircularAvatar/CircularAvatar";
import Card from "../../../atoms/Card/Card";
import { createModuleStyleExtractor } from "../../../../utils/css";
import styles from "./SuggestedTopics.module.scss";
import { postViewSuggestedTopic } from "../../../../redux/reducers/communityReducer";

import ArrowRightIcon from "../../../../assets/svg/arrow-right.svg";

const cx = createModuleStyleExtractor(styles);

export default function SuggestedTopics({
  callGetFollowedCommunitiesAPI = () => {},
}) {
  const dispatch = useDispatch();
  const { suggestedTopics } = useSelector((state) => state.communityState);
  const { selectedSchool = {} } = useSelector((state) => state.userState);
  const selectedSchoolId = selectedSchool?.id || null;

  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [loadingFollowUnfollowTopicAPI, setLoadingFollowUnfollowTopicAPI] =
    useState(false);

  const callFollowUnfollowTopicAPI = async (topic_id = null) => {
    setLoadingFollowUnfollowTopicAPI(true);
    const response = await followUnfollowTopicAPI(topic_id, selectedSchoolId);
    setLoadingFollowUnfollowTopicAPI(false);
    if (response.success) {
      toastify(response.message);
      callGetFollowedCommunitiesAPI();
    } else {
      toastify(response.message, "error");
    }
  };

  const handleViewTopic = (topic) => {
    dispatch(postViewSuggestedTopic(topic));
  };

  const followTopic = (topicId) => {
    callFollowUnfollowTopicAPI(topicId);
  };

  return (
    <>
      {suggestedTopics?.length > 0 && (
        <div className={cx("suggested-topics")}>
          <h3 className={cx("suggested-topics__title")}>
            Suggested Communities for you
          </h3>
          <div className={cx("suggested-topics__list")}>
            {suggestedTopics?.map((topic, index) => (
              <div
                key={index}
                className={cx([
                  "suggested-topics__list__item",
                  selectedCardIndex === index
                    ? "suggested-topics__list__item--selected"
                    : "",
                ])}
                onClick={() =>
                  setSelectedCardIndex((current) =>
                    current === index ? null : index
                  )
                }
              >
                <Card>
                  <div className={cx("suggested-topics__list__item__body")}>
                    <div
                      className={cx(
                        "suggested-topics__list__item__body__title"
                      )}
                    >
                      <div
                        className={cx(
                          "suggested-topics__list__item__body__title__avatar"
                        )}
                      >
                        <CircularAvatar
                          src={topic?.topic_icon}
                          name={topic?.topic_name}
                          round={true}
                          size={40}
                        />
                      </div>
                      <div
                        className={cx(
                          "suggested-topics__list__item__body__title__text"
                        )}
                      >
                        <Tooltip title={topic?.topic_name}>
                          <div
                            className={cx(
                              "suggested-topics__list__item__body__title__text__line-one"
                            )}
                          >
                            {topic?.topic_name?.length > 60
                              ? topic?.topic_name?.slice(0, 60) + "..."
                              : topic?.topic_name}
                          </div>
                        </Tooltip>
                        <div
                          className={cx(
                            "suggested-topics__list__item__body__title__text__line-two"
                          )}
                        >
                          {`${topic.total_participants} members`}
                        </div>
                      </div>
                    </div>
                    <div
                      className={cx(
                        "suggested-topics__list__item__body__labels"
                      )}
                    >
                      <span
                        className={cx(
                          "suggested-topics__list__item__body__labels__type"
                        )}
                      >
                        {capitalizeFirstLetterOnType(topic?.topic_type)}
                      </span>
                      {topic?.topic_portal_type === "admissions" && (
                        <div
                          className={cx([
                            "suggested-topics__list__item__body__labels__tag",
                            "suggested-topics__list__item__body__labels__tag--ambassador",
                          ])}
                        >
                          Admissions
                        </div>
                      )}
                    </div>
                    <div
                      className={cx(
                        "suggested-topics__list__item__body__description"
                      )}
                    >
                      <Tooltip title={topic?.topic_description}>
                        <p>
                          {topic?.topic_description?.length > 80
                            ? topic?.topic_description?.slice(0, 80) + "..."
                            : topic?.topic_description}
                        </p>
                      </Tooltip>
                    </div>
                    {selectedCardIndex === index && (
                      <div
                        className={cx(
                          "suggested-topics__list__item__body__actions"
                        )}
                      >
                        <div
                          className={cx(
                            "suggested-topics__list__item__body__actions__view-topic"
                          )}
                        >
                          <Button onClick={() => handleViewTopic(topic)}>
                            <div
                              className={cx(
                                "suggested-topics__list__item__body__actions__view-topic__body"
                              )}
                            >
                              <span>View</span>
                              <img src={ArrowRightIcon} alt="arrow" />
                            </div>
                          </Button>
                        </div>
                        <div
                          className={cx(
                            "suggested-topics__list__item__body__actions__request"
                          )}
                        >
                          <Button
                            disabled={
                              topic.following_status === "requested"
                                ? true
                                : false
                            }
                            is
                            onClick={() => followTopic(topic.id)}
                            isSubmitting={loadingFollowUnfollowTopicAPI}
                          >
                            <span>
                              {capitalizeFirstLetterOnType(
                                topic.following_status
                              )}
                            </span>
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </Card>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
