import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import FollowedCommunity from "./FollowedCommunity/FollowedCommunity";
import DiscoverCommunity from "./DiscoverCommunity/DiscoverCommunity";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./Community.module.scss";
import { communitiesTabOptions } from "../../../utils/constants";
import {
  resetStateTopics,
  setActiveTopic,
} from "../../../redux/reducers/communityReducer";

const cx = createModuleStyleExtractor(styles);

export default function Community() {
  const dispatch = useDispatch();
  const { tab, activeTopicId, followedTopics } = useSelector(
    (store) => store.communityState
  );

  useEffect(() => {
    return () => {
      // On component unmount
      dispatch(resetStateTopics());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Track redux state and update activeTopic based on activeTopicId
  useEffect(() => {
    if (activeTopicId === null || followedTopics?.length === 0) {
      dispatch(setActiveTopic({}));
    } else if (activeTopicId && followedTopics?.length > 0) {
      dispatch(
        setActiveTopic(
          followedTopics.filter((topic) => topic.id === activeTopicId)[0]
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTopicId, followedTopics]);

  return (
    <div className={cx("o-community")}>
      {tab === communitiesTabOptions[0] ? (
        <FollowedCommunity />
      ) : (
        <DiscoverCommunity />
      )}
    </div>
  );
}
