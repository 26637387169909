import React, { memo, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Col } from "../../helpers/Grid/Col";
import { Row } from "../../helpers/Grid/Row";
import styles from "./ProspectParentInviteSignUp.module.scss";
import Perspective from "../../molecules/Perspective/Perspective";
import { createModuleStyleExtractor } from "../../../utils/css";
import { validateInviteToken } from "../../../api/onboarding";
import { DEFAULT_PRIMARY_COLOR } from "../../../config/config";
import { setThemeColor } from "../../../redux/reducers/preservedReducer";
import { ROUTES } from "../../../routes";
import toastify from "../../../utils/toast";
import InterstridePageLoader from "../../atoms/InterstridePageLoader/InterstridePageLoader";
import AuthInviteSignUp from "../../molecules/AuthInviteSignUp/AuthInviteSignUp";

const cx = createModuleStyleExtractor(styles);

const ProspectParentInviteSignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const token = searchParams.getAll("invite_token")
    ? searchParams.get("invite_token")
    : false;

  const [showLoader, setShowLoader] = useState(token ? true : false);
  const [schoolData, setSchoolData] = useState(null);
  const [email, setEmail] = useState(null);

  useEffect(() => {
    const checkInviteToken = async () => {
      const response = await validateInviteToken(token);
      if (response.success && response.data) {
        const {
          data: { school = {}, user = {} },
        } = response;

        const themeColor =
          school && school.school_setting
            ? school.school_setting.theme_color
            : DEFAULT_PRIMARY_COLOR;
        dispatch(setThemeColor(themeColor));
        setSchoolData(school);
        setEmail(user.email);
        setShowLoader(false);
      } else {
        toastify(response.message);
        setShowLoader(false);
        navigate(ROUTES.LOGIN_WITH_PARAMS, { replace: true });
      }
    };
    if (checkInviteToken) {
      checkInviteToken();
    } else {
      navigate(ROUTES.LOGIN_WITH_PARAMS, { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {showLoader ? (
        <InterstridePageLoader />
      ) : (
        <Row className={cx("o-prospect-parent-invite-sign-up")}>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Perspective schoolData={schoolData} />
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <AuthInviteSignUp
              schoolData={schoolData}
              email={email}
              token={token}
            />
          </Col>
        </Row>
      )}
    </>
  );
};
export default memo(ProspectParentInviteSignUp);
