import React, { memo } from "react";

import Card, { CardModifier } from "../../atoms/Card/Card";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./ServiceCard.module.scss";

import StarIcon from "../../../assets/Icons/StarIconFacelift";

const cx = createModuleStyleExtractor(styles);

const ServiceCard = ({
  data = {},
  isFavourite = false,
  onClickStar = () => {},
  key = 0,
  label = false,
}) => {
  return (
    <div className={cx("m-service-card")} key={key}>
      <div className={cx("m-service-card__wrapper")}>
        <Card modifier={CardModifier.STUDENT_SERVICE}>
          <div className={cx("m-service-card__wrapper__content")}>
            <div className={cx("m-service-card__wrapper__content__header")}>
              {label && data?.deal_category?.name && (
                <div
                  className={cx(
                    "m-service-card__wrapper__content__header__label"
                  )}
                >
                  <span>{data.deal_category.name}</span>
                </div>
              )}
            </div>
            <div className={cx("m-service-card__wrapper__content__image")}>
              <img src={data.logo_url} alt={data.name} />
            </div>
            <div className={cx("m-service-card__wrapper__content__title")}>
              <h4>{data.name}</h4>
            </div>
            <div
              className={cx("m-service-card__wrapper__content__description")}
              dangerouslySetInnerHTML={{ __html: data.description }}
            />
          </div>
        </Card>
      </div>
      <div
        className={cx([
          "m-service-card__favorite",
          isFavourite ? "m-service-card__favorite--active" : "",
        ])}
      >
        {isFavourite ? (
          <StarIcon
            aria-label={`Remove ${data.name} from saved services`}
            variant="filled"
            onClick={() => onClickStar(data.id, false)}
          />
        ) : (
          <StarIcon
            aria-label={`Add ${data.name} to saved services`}
            onClick={() => onClickStar(data.id, true)}
          />
        )}
      </div>
      <div className={cx(["m-service-card__learn-more"])}>
        <a
          href
          onClick={(e) => {
            e?.preventDefault && e.preventDefault();
            window.open(data.url, "_blank");
          }}
        >
          Learn More
        </a>
      </div>
    </div>
  );
};

export default memo(ServiceCard);
