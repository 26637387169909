import React, { memo } from "react";
import { Link } from "react-router-dom";
import DownArrow from "../../../assets/Icons/DownArrow";
import { ROUTES } from "../../../routes";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./DashboardLinks.module.scss";

const cx = createModuleStyleExtractor(styles);

const DashboardLinks = ({ setShowFooter = () => {} }) => {
  return (
    <div className={cx("m-dashboard-link")}>
      <div className={cx("m-dashboard-link__row")}>
        <a
          className={cx("m-dashboard-link__row__link")}
          href="https://www.interstride.com/"
          target={"_blank"}
          rel="noreferrer"
        >
          About
        </a>

        <Link
          className={cx("m-dashboard-link__row__link")}
          to={ROUTES.TERMS_AND_CONDITIONS}
        >
          Terms & Conditions
        </Link>

        <Link
          className={cx("m-dashboard-link__row__link")}
          to={ROUTES.PRIVACY_POLICY}
        >
          Privacy Policy
        </Link>
      </div>

      <div className={cx("m-dashboard-link__row")}>
        <Link
          className={cx("m-dashboard-link__row__link")}
          to={ROUTES.HELP_CENTER}
        >
          Help Center
        </Link>

        <a
          className={cx("m-dashboard-link__row__link")}
          href="/footer"
          onClick={(e) => {
            e?.preventDefault && e.preventDefault();
            setShowFooter(true);
          }}
        >
          More
          <DownArrow />
        </a>
      </div>
    </div>
  );
};
export default memo(DashboardLinks);
