import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useCheckBreakpoint } from "../../../hooks/useCheckBreakpoint";
import {
  bulkUpdate,
  setCurrentConversationId,
  setCurrentSearchMessageItem,
} from "../../../redux/reducers/chat/reducers";
import {
  renderTimestamp,
  scrollToActiveConversation,
  generateDisplayName,
  extractFirstName,
} from "../../../utils/common";
import { createModuleStyleExtractor } from "../../../utils/css";
import { CircularAvatar } from "../CircularAvatar/CircularAvatar";
import styles from "./ChatPreviewItem.module.scss";

const cx = createModuleStyleExtractor(styles);

const ChatPreviewItem = ({ user }) => {
  const isMobileView = useCheckBreakpoint(992);
  const {
    time,
    latest_message,
    user_image,
    title,
    conversation_id,
    file_name,
    conversation_type,
    last_message_sender_name,
    total_group_members = 0,
  } = user || {};
  const isGroup = total_group_members > 0;
  const chatStore = useSelector((state) => state.chatStore);
  const { chatSpecificNotificationCount } = useSelector(
    (state) => state.notificationState
  );
  const firstNameLastMessage = extractFirstName(last_message_sender_name);

  const dispatch = useDispatch();
  const { activeConversation } = chatStore || {};

  const onItemClicked = async (conversation_id) => {
    if (activeConversation === conversation_id && !isMobileView) return;

    dispatch(setCurrentConversationId(conversation_id));
    scrollToActiveConversation(conversation_id);
    dispatch(setCurrentSearchMessageItem(null));

    if (isMobileView) {
      dispatch(
        bulkUpdate({
          isConversationView: true,
          isChatPreview: false,
        })
      );
    }
  };

  return (
    <div
      id={`chat_contact_list-interstride--${conversation_id}`}
      onClick={() => onItemClicked(conversation_id)}
      className={cx([
        `a-chat-preview-container`,
        activeConversation === conversation_id
          ? "a-chat-preview-container--active"
          : "",
      ])}
    >
      <div className={cx("a-chat-preview-container__profile")}>
        <CircularAvatar
          src={user_image}
          size={"50"}
          name={
            user?.account_type === "student" || user?.account_type === "alumni"
              ? title.split(" ")[0]
              : title
          }
          round={true}
        />
      </div>
      <div className={cx("a-chat-preview-container__details")}>
        <h4 className={cx("title")}>
          <strong>
            {Object.keys(user).includes("total_group_members") &&
            user.total_group_members > 0
              ? title
              : user?.account_type === "student" ||
                user?.account_type === "alumni"
              ? title.split(" ")[0]
              : generateDisplayName(title)}
          </strong>
          <span className={cx("title__time-ago")}>
            {time && renderTimestamp(time)}
          </span>
          <span
            style={{
              display:
                chatSpecificNotificationCount[conversation_id] === 0 ||
                chatSpecificNotificationCount[conversation_id] === undefined
                  ? "none"
                  : "block",
            }}
            className={cx("title__count")}
          >
            {chatSpecificNotificationCount[conversation_id]}
          </span>
        </h4>
        {conversation_type === "request_meeting" && (
          <div className={cx("a-chat-preview-container__details__tags")}>
            <div
              className={cx("a-chat-preview-container__details__tags__meeting")}
            >
              Meeting
            </div>
          </div>
        )}
        <p className={cx("description")}>
          {(latest_message || file_name) && (
            <span className={cx("description__text")}>
              {isGroup &&
                firstNameLastMessage &&
                (firstNameLastMessage?.length > 15
                  ? firstNameLastMessage?.slice(0, 15) + "... "
                  : firstNameLastMessage) + ": "}
              {latest_message || file_name}
            </span>
          )}
        </p>
      </div>
    </div>
  );
};
export default memo(ChatPreviewItem);
