import React, { memo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { createModuleStyleExtractor } from "../../../utils/css";
import { validateWidgetToken } from "../../../api/widget";
import { DEFAULT_PRIMARY_COLOR } from "../../../config/config";
import { setThemeColor } from "../../../redux/reducers/preservedReducer";
import styles from "./UniversityWidgetChat.module.scss";
import InterstridePageLoader from "../../atoms/InterstridePageLoader/InterstridePageLoader";
import WidgetChatList from "../../molecules/WidgetChatList/WidgetChatList";
import { INTERSTRIDE_LOGO_URL } from "../../../utils/constants";

const cx = createModuleStyleExtractor(styles);

const UniversityWidgetChat = () => {
  const dispatch = useDispatch();

  const params = useParams();

  const universityToken = params.university_token
    ? params.university_token
    : false;

  const [schoolData, setSchoolData] = useState({});
  const [showLoader, setShowLoader] = useState(universityToken ? true : false);
  const [showInvalidToken, setShowInvalidToken] = useState(false);

  useEffect(() => {
    const checkUniversityToken = async () => {
      const response = await validateWidgetToken(universityToken);

      if (response.success && response.data) {
        const {
          data: { school = {} },
        } = response;

        const themeColor =
          school && school.theme_color
            ? school.theme_color
            : DEFAULT_PRIMARY_COLOR;
        dispatch(setThemeColor(themeColor));
        setSchoolData({ ...school, token: universityToken });
        setShowLoader(false);
      } else {
        // Show invalid token page
        setShowInvalidToken(true);
        setShowLoader(false);
      }
    };
    if (universityToken) {
      checkUniversityToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [universityToken, dispatch]);

  return (
    <>
      {showLoader ? (
        <InterstridePageLoader />
      ) : showInvalidToken ? (
        <div className={cx("o-university-chat")}>
          <div className={cx("o-university-chat__invalid-token")}>
            <div className={cx("o-university-chat__invalid-token__content")}>
              <div
                className={cx(
                  "o-university-chat__invalid-token__content__sitelogo"
                )}
              >
                <img src={INTERSTRIDE_LOGO_URL} alt="interstride logo" />
              </div>
              <h2>
                Woops!
                <br /> We couldn't find the resource you are looking for!
              </h2>
            </div>
            <h6>
              If you have any questions, please reach out to us at{" "}
              <a href="mailto:contact@interstride.com">
                contact@interstride.com.
              </a>
            </h6>
          </div>
        </div>
      ) : (
        <div className="widget-wrapper">
          <WidgetChatList schoolData={schoolData} />
        </div>
      )}
    </>
  );
};
export default memo(UniversityWidgetChat);
