import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { ShimmerText } from "react-shimmer-effects";
import { isEmpty } from "../../../../utils/validations";

import { createModuleStyleExtractor } from "../../../../utils/css";
import styles from "./FollowedCommunity.module.scss";
import {
  getFollowingTopicsAPI,
  readTopicNotificationAPI,
  getTopicFeedAPI,
  getTopicDetailsAPI,
  getTopicPendingRequestAPI,
  getSavedTopicPostsAPI,
  getTopicPostReactionsAPI,
} from "../../../../api/community";
import toastify from "../../../../utils/toast";
import {
  setActiveTopicId,
  setFollowingAndSuggestedTopics,
  changeTab,
  navigateSavedToFeedHandler,
  navigateSavedToFollowing,
  setTopicPosts,
  setTopicPendingRequest,
  setLoadingTopicPosts,
  updateReactionsData,
  setPreApiCallSavedTopic,
} from "../../../../redux/reducers/communityReducer";
import {
  setCurrentConversationId,
  setChatsList,
  setConversationInformation,
  createFreshChatRecord,
} from "../../../../redux/reducers/chat/reducers";
import { ROUTES } from "../../../../routes";
import { Modal } from "../../../atoms/Modal/Modal";
import { ModalBody } from "../../../atoms/Modal/ModalBody/ModalBody";
import { Button, ButtonModifier } from "../../../atoms/Button";
import { communitiesTabOptions } from "../../../../utils/constants";
import CommunityLoader from "../Utility/CommunityLoader";
import MyTopic from "../MyTopic/MyTopic";
import TopicMiddle from "../TopicMiddle/TopicMiddle";
import TopicRight from "../Utility/TopicRight";
import SuggestedTopics from "../Utility/SuggestedTopics";
import RecommendationsDetails from "../Utility/RecommendationsDetails";
import { Mixpanel } from "../../../../MixPanel/mixpanel";
import { createFreshChatPreview } from "../../../../utils/common";

import BackIcon from "../../../../assets/svg/back-arrow-v2.svg";
import ArrowRightIcon from "../../../../assets/svg/arrow-right.svg";
import SavedPinIcon from "../../../../assets/svg/save-pin.svg";

const cx = createModuleStyleExtractor(styles);

export default function FollowedCommunity() {
  const refActiveTopicIdAPI = useRef(undefined);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { activeTopicId, topicPosts, loadingTopicPosts } = useSelector(
    (state) => state.communityState
  );
  const { chats = [] } = useSelector((store) => store.chatStore);
  const { selectedSchool = {}, userId = null } = useSelector(
    (state) => state.userState
  );
  const selectedSchoolId = selectedSchool?.id || null;

  const [communityModuleLoader, setCommunityModuleLoader] = useState(true);
  const [showFollowCommunityModal, setShowFollowCommunityModal] =
    useState(false);
  const [activeUser, setActiveUser] = useState(null);
  const [showUserPopup, setShowUserPopup] = useState(false);

  // Process query url params
  const query = new URLSearchParams(useLocation().search);
  const urlTopicId = parseInt(query.get("topicId") || "-1");

  const callGetFollowedCommunitiesAPI = async () => {
    setCommunityModuleLoader(true);
    const response = await getFollowingTopicsAPI(
      null,
      null,
      null,
      selectedSchoolId
    );

    setCommunityModuleLoader(false);

    if (response?.success && response?.data) {
      const followedTopics = response?.data?.topics || [];
      const suggestedTopics = response?.data?.suggested_topics || [];

      if (urlTopicId !== -1 && followedTopics?.length > 0) {
        const index = followedTopics?.findIndex(
          (topic) => topic.id === urlTopicId
        );
        if (index !== -1) {
          dispatch(setActiveTopicId(urlTopicId));
          await readTopicNotificationAPI(urlTopicId, selectedSchoolId);
        }
      }

      // Resets query parametes in state if present
      if (urlTopicId !== -1) {
        navigate(ROUTES.COMMUNITY.PATH);
      }

      dispatch(
        setFollowingAndSuggestedTopics({ followedTopics, suggestedTopics })
      );
      setShowFollowCommunityModal(followedTopics?.length > 0 ? false : true);
    } else {
      toastify("", "error");
    }
  };

  const callGetTopicFeedAPI = async (page = 1, per_page = 10) => {
    const response = await getTopicFeedAPI(page, per_page, selectedSchoolId);
    if (response?.success && response.data) {
      dispatch(setTopicPosts({ topicPosts: response.data, type: "feed" }));
    }
    setCommunityModuleLoader(false);
  };

  const callGetTopicDetailsAPI = async (
    topicId = null,
    page = 1,
    per_page = 10
  ) => {
    const response = await getTopicDetailsAPI(
      topicId,
      page,
      per_page,
      selectedSchoolId
    );
    if (response?.success) {
      // This check will avoid race condition on multiple concurrent API calls
      if (refActiveTopicIdAPI.current === response?.data?.topic_id) {
        dispatch(setTopicPosts({ topicPosts: response.data, type: "topic" })); // Update state
        callGetTopicPendingRequestAPI(activeTopicId); // Get topic pending requests
        await readTopicNotificationAPI(activeTopicId, selectedSchoolId); // Read current topic notifications
      }
    }
  };

  const callGetTopicPendingRequestAPI = async (topicId = null) => {
    const response = await getTopicPendingRequestAPI(topicId, selectedSchoolId);
    if (response.success && response.data) {
      dispatch(setTopicPendingRequest(response.data));
    }
  };

  const handleBackToFeedFromAllSaved = () => {
    dispatch(navigateSavedToFeedHandler());
    callGetTopicFeedAPI();
  };

  const handleBackToFollowingTopics = () => {
    dispatch(navigateSavedToFollowing());
    callGetTopicDetailsAPI(activeTopicId);
  };

  const callGetSavedTopicPostsAPI = async (
    topic_id = null,
    page = 1,
    per_page = 10
  ) => {
    const response = await getSavedTopicPostsAPI(
      topic_id,
      page,
      per_page,
      selectedSchoolId
    );
    if (response.success && response.data) {
      dispatch(
        setTopicPosts({
          topicPosts: response.data,
          type: activeTopicId ? "topic-saved" : "all-saved",
        })
      );
    }
  };

  const handleClickAllSavedPosts = async () => {
    dispatch(setPreApiCallSavedTopic("all-saved"));
    callGetSavedTopicPostsAPI();
  };

  const handleClose = (e) => {
    if (e) e.stopPropagation();
    setShowUserPopup(false);
  };

  const startChatWithNewUser = (e, item = activeUser) => {
    e.stopPropagation && e.stopPropagation();
    try {
      const { id = null } = item;

      // Check if conversation already present with selected user
      const conversationCheckIndex =
        chats && !isEmpty(chats)
          ? chats.findIndex((item) => item.receiver_id === id)
          : -1;
      if (conversationCheckIndex !== -1) {
        const conversationId = chats[conversationCheckIndex].conversation_id;
        Mixpanel.track("Chat initiated", {
          schoolID: selectedSchoolId,
          objId: id,
          objClass: "User",
        });
        dispatch(setCurrentConversationId(conversationId));
        navigate(ROUTES.MESSAGES);
      } else {
        const randomConversationId = new Date().getTime();
        const { preview, conversation } = createFreshChatPreview(
          {
            full_name: item?.name,
            country_of_origin: item?.country_of_origin,
            user_type: item?.account_type,
            school_name: item?.school_name,
            user_profile: item.user_profile_url,
            id: item.id,
            account_type: item?.account_type,
          },
          randomConversationId
        );
        Mixpanel.track("Chat initiated", {
          schoolID: selectedSchoolId,
          objId: id,
          objClass: "User",
        });
        dispatch(setChatsList({ chats: [preview, ...(chats || [])] }));
        dispatch(
          setConversationInformation({
            conversation,
            conversation_id: randomConversationId,
          })
        );
        dispatch(setCurrentConversationId(randomConversationId));
        dispatch(createFreshChatRecord({ id: randomConversationId }));
        navigate(ROUTES.MESSAGES);
      }
    } catch (error) {
      toastify("", "error");
      console.log(error);
    }
  };

  const handleFavorite = async (e, userId) => {
    // const response = await favoriteUnfavoriteNetworkAPI(userId);
    // if (response.success){
    //   toastify(response?.message);
    //   const responseFavoriteList = await getFavoriteNetworksAPI();
    //   if (responseFavoriteList?.success && responseFavoriteList?.data) {
    //     const { favourite_list = [] } = responseFavoriteList.data;
    //     setFavoriteUsersCount(favourite_list.length);
    //     setFavoriteUsers(favourite_list.map(({ id }) => id));
    //   }
    // } else {
    //   toastify(response?.message, "error");
    // }
  };

  useEffect(() => {
    callGetFollowedCommunitiesAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !loadingTopicPosts && dispatch(setLoadingTopicPosts(true));
    if (activeTopicId) {
      refActiveTopicIdAPI.current = activeTopicId;
      callGetTopicDetailsAPI(activeTopicId);
    } else if (activeTopicId === null && urlTopicId === -1) {
      refActiveTopicIdAPI.current = null;
      callGetTopicFeedAPI();
    } else {
      refActiveTopicIdAPI.current = undefined; // Reset Ref
    }
    // If active user present then reset it
    activeUser !== null && setActiveUser(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTopicId]);

  useEffect(() => {
    if (!loadingTopicPosts && topicPosts?.posts?.length > 0) {
      (async () => {
        let param = `?school_id=${selectedSchoolId}`;
        topicPosts.posts.forEach((post) => {
          if (!post.details) param += `&topic_post_ids[]=${post?.id}`;
        });

        // Call API
        const response = await getTopicPostReactionsAPI(param);

        // Update app state
        if (response.success) {
          dispatch(updateReactionsData(response?.posts));
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicPosts?.posts?.length]);

  return (
    <div className={cx("followed-community")}>
      {communityModuleLoader ? (
        <CommunityLoader />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            {topicPosts?.type === "all-saved" ||
            topicPosts?.type === "topic-saved" ? (
              <div className={cx("followed-community__saved")}>
                <Button
                  onClick={() =>
                    topicPosts?.type === "all-saved"
                      ? handleBackToFeedFromAllSaved()
                      : handleBackToFollowingTopics()
                  }
                  modifier={ButtonModifier.COMMUNITY_SAVED}
                >
                  <img alt="arrow" src={BackIcon} />
                  <span>
                    {topicPosts?.type === "all-saved"
                      ? "Back to Community Feed"
                      : "Back to My Community"}
                  </span>
                </Button>
              </div>
            ) : (
              <div className={cx("followed-community__discover")}>
                <Button
                  onClick={() => dispatch(changeTab(communitiesTabOptions[1]))}
                  modifier={ButtonModifier.COMMUNITY_DISCOVER}
                >
                  <span>Discover Communities</span>
                  <img alt="arrow" src={ArrowRightIcon} />
                </Button>
              </div>
            )}

            {topicPosts?.type !== "all-saved" &&
              topicPosts?.type !== "topic-saved" && <MyTopic />}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TopicMiddle
              setActiveUser={setActiveUser}
              setShowUserPopup={setShowUserPopup}
              callGetTopicFeedAPI={callGetTopicFeedAPI}
              callGetTopicDetailsAPI={callGetTopicDetailsAPI}
              callGetSavedTopicPostsAPI={callGetSavedTopicPostsAPI}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            {loadingTopicPosts ? (
              <div style={{ marginTop: "74px" }}>
                <ShimmerText line={20} />
              </div>
            ) : topicPosts.type === "topic" ? (
              <TopicRight
                callGetSavedTopicPostsAPI={callGetSavedTopicPostsAPI}
              />
            ) : topicPosts.type === "feed" ? (
              <>
                {topicPosts?.saved_posts > 0 && (
                  <div
                    className="mytopic-mainpage__view-pinned"
                    onClick={handleClickAllSavedPosts}
                  >
                    <img src={SavedPinIcon} alt="save" />
                    <span>All saved posts ({topicPosts?.saved_posts})</span>
                  </div>
                )}
                <SuggestedTopics
                  callGetFollowedCommunitiesAPI={callGetFollowedCommunitiesAPI}
                />
              </>
            ) : (
              <></>
            )}
            {showUserPopup && activeUser && (
              <div className="mytopic-mainpage__recommendation-detail-wrapper">
                <RecommendationsDetails
                  details={activeUser}
                  handleClose={handleClose}
                  handleMessage={startChatWithNewUser}
                  handleFavorite={handleFavorite}
                  showMessageButtonText="Message"
                  showMessageButton={
                    activeUser?.school_id === selectedSchoolId &&
                    activeUser?.is_network_popup_shown &&
                    activeUser.user_type !== "prospect" &&
                    activeUser?.id !== userId
                  }
                />
              </div>
            )}
          </Grid>
        </Grid>
      )}
      <Modal
        show={showFollowCommunityModal}
        onClose={() => setShowFollowCommunityModal(false)}
        maxWidth={600}
      >
        <ModalBody>
          <div className={cx("followed-community__no-community-modal")}>
            <h2>Looks like you haven’t followed any Community yet.</h2>
            <h3>
              Click below to discover Communities and add one to your list.
            </h3>
            <div
              className={cx("followed-community__no-community-modal__button")}
            >
              <Button
                onClick={() => dispatch(changeTab(communitiesTabOptions[1]))}
              >
                Discover Communities
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
