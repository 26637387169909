import { toast, Slide } from "react-toastify";
// import { CheckCircleOutline, HighlightOff, ErrorOutline, Warning } from "@material-ui/icons";

// const getToastifyIcon = (type) => {
//   switch (type) {
//     case "success":
//       return <CheckCircleOutline />;
//     case "error":
//       return <HighlightOff />;
//     case "info":
//       return <ErrorOutline />;
//     case "warning":
//       return <Warning />;
//     default:
//       return <div></div>;
//   }
// };

const toastify = (message = "", type = "success", autoClose = 5000) => {
  switch (type) {
    case "success":
      toast.success(
        <div className="d-flex align-items-center">
          {/* {getToastifyIcon(type)} */}
          <div>{message}</div>
        </div>,
        {
          autoClose: autoClose,
          position: toast.POSITION.BOTTOM_CENTER,
          transition: Slide,
          closeOnClick: true,
          role: "alert",
        }
      );
      break;
    case "error":
      toast.error(
        <div className="d-flex align-items-center">
          {/* {getToastifyIcon(type)} */}
          <div style={{ display: "block" }}>
            {message ? message : "Something went wrong!"}
          </div>
        </div>,
        {
          autoClose: autoClose,
          position: toast.POSITION.BOTTOM_CENTER,
          transition: Slide,
          closeOnClick: true,
          role: "alert",
        }
      );
      break;
    case "info":
      toast.info(
        <div className="d-flex align-items-center">
          {/* {getToastifyIcon(type)} */}
          <div style={{ display: "block" }}>{message}</div>
        </div>,
        {
          autoClose: autoClose,
          position: toast.POSITION.BOTTOM_CENTER,
          transition: Slide,
          closeOnClick: true,
          role: "alert",
        }
      );
      break;
    case "warning":
      toast.warning(
        <div className="d-flex align-items-center">
          {/* {getToastifyIcon(type)} */}
          <div style={{ display: "block" }}>{message}</div>
        </div>,
        {
          autoClose: autoClose,
          position: toast.POSITION.BOTTOM_CENTER,
          transition: Slide,
          closeOnClick: true,
          role: "alert",
        }
      );
      break;
    default:
      break;
  }
};

export default toastify;
