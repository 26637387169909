import React, { memo, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import { Col } from "../../helpers/Grid/Col";
import { Row } from "../../helpers/Grid/Row";
import styles from "./ResetPassword.module.scss";
import Perspective from "../../molecules/Perspective/Perspective";
import { createModuleStyleExtractor } from "../../../utils/css";
import ResetPassword from "../../molecules/ResetPassword/ResetPassword";
import { ROUTES } from "../../../routes";

const cx = createModuleStyleExtractor(styles);

const ResetPasswordOrganism = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email")
    ? decodeURIComponent(searchParams.get("email"))
    : false;
  const resetPasswordToken = searchParams.getAll("reset_password_token")
    ? searchParams.get("reset_password_token")
    : false;

  useEffect(() => {
    if (!email || !resetPasswordToken) {
      navigate(ROUTES.LOGIN, { replace: true });
    }
  }, [email, resetPasswordToken, navigate]);

  return (
    <Row className={cx("o-reset-password-container")}>
      <Col xs={12} sm={6} md={6} lg={6}>
        <Perspective schoolData={null} />
      </Col>
      <Col xs={12} sm={6} md={6} lg={6}>
        <ResetPassword email={email} resetPasswordToken={resetPasswordToken} />
      </Col>
    </Row>
  );
};
export default memo(ResetPasswordOrganism);
