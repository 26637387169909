import React, { memo } from "react";
import { useNavigate } from "react-router-dom";

import { createModuleStyleExtractor } from "../../../utils/css";
import { Button, ButtonModifier } from "../../atoms/Button";
import styles from "./DiscoverNetworkSlide.module.scss";
import { ROUTES } from "../../../routes";

const cx = createModuleStyleExtractor(styles);

const DiscoverNetworkSlide = ({
  isProspect,
  selectedSchoolApplyLink,
  selectedSchoolName,
}) => {
  const navigate = useNavigate();

  return (
    <div className={cx("m-discover-network-slide")}>
      <div className={cx("m-discover-network-slide__title")}>
        <h3>
          {isProspect
            ? selectedSchoolApplyLink
              ? `Apply to ${selectedSchoolName}`
              : "Connect with students and alumni"
            : "Connect with students and alumni"}
        </h3>
      </div>
      <div className={cx("m-discover-network-slide__images")}>
        <img src="/images/User1.svg" alt="User 1" />
        <img src="/images/User2.svg" alt="User 2" />
        <img src="/images/User3.svg" alt="User 3" />
        <img src="/images/User4.svg" alt="User 4" />
      </div>
      <div className={cx("m-discover-network-slide__button")}>
        {selectedSchoolApplyLink ? (
          <Button
            modifier={ButtonModifier.SLIDER_ACTION}
            onClick={() => {
              window.open(selectedSchoolApplyLink, "_blank");
            }}
          >
            Apply
          </Button>
        ) : (
          <Button
            modifier={ButtonModifier.SLIDER_ACTION}
            onClick={() => navigate(ROUTES.CHAT.AMBASSADORS)}
          >
            Network
          </Button>
        )}
      </div>
    </div>
  );
};
export default memo(DiscoverNetworkSlide);
