export const GridViewportKey = "xs" | "sm" | "md" | "lg";
export const ColumnSize =
  "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "10" | "11" | "12";
export const horizontal = "center" | "end" | "start";
export const vertical = "top" | "center" | "bottom" | "middle";

export const generateColumnClasses = (config) => {
  const className = Object.keys(config).map(
    (item) => `col-${item}-${config[`${item}`]}`
  );
  return className.join(" ");
};
