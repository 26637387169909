import React from "react";

import { createModuleStyleExtractor } from "../../../../utils/css";
import styles from "../Modal.module.scss";

const cx = createModuleStyleExtractor(styles);

export const ModalBody = ({ children, className }) => {
  return (
    <div
      className={cx([
        "a-modal__dialog__content__body",
        ...(className ? [className] : []),
      ])}
    >
      {children}
    </div>
  );
};
