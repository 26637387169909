import React, { useState, useRef, useLayoutEffect } from "react";
import { Popover } from "@mui/material";

import { isElementVisibleOnDOM } from "../../../utils/common";

import InformationIcon from "../../../assets/Icons/Information.svg";

const InfoIconPopup = ({
  popoverContent = null,
  margin = "0px",
  defaultShow = false,
  onDefaultShow = () => {},
  variant = "small",
}) => {
  let iconRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);

  useLayoutEffect(() => {
    if (
      defaultShow &&
      iconRef.current &&
      isElementVisibleOnDOM(iconRef.current)
    ) {
      iconRef.current.click();
      onDefaultShow();
    }
  }, [defaultShow, onDefaultShow]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? "info-icon-popover" : undefined;

  return (
    <>
      <img
        src={InformationIcon}
        alt="info"
        onClick={handleClick}
        style={{ margin, cursor: "pointer" }}
        ref={iconRef}
        className="cursor-pointer"
        height={variant === "extra-small" ? 13 : variant === "small" ? 17 : 20}
        width={variant === "extra-small" ? 13 : variant === "small" ? 17 : 20}
      />
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className="mui-popover-wrapper"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        {popoverContent}
      </Popover>
    </>
  );
};

export default InfoIconPopup;
