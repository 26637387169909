export const initialState = {
  //// for chat preview
  chats: null,
  isChatsLoaded: false,
  isChatsLoading: false,

  activeConversation: -1,
  previousActiveConversation: -1,
  contacts: null,
  conversations: {},
  messageSending: false,
  reloadChats: false,
  freshChat: [], // this to keep track for the new chat
  networkFilters: {},
  currentNetworkFilters: {},
  groupTags: [],
  //to detect the modal toggling
  chatModal: false,
  groupModal: false,
  currentGroupModalId: -1, // this is for the case when you need to add new group member to already created group
  isGroupCreating: false,
  discoverNetworkFilter: {},
  isDiscoverNetworkFilterInProgress: false,
  currentSearchMessageItem: null, // needs to hold the record for lazy loading and updating UI
  unreadMessagesCount: 0,
  triggerReloadUnreadCount: false, //this will help to re-call custom hook again
  broadcastData: null,
  attachment: false,

  isProfileView: false,
  isConversationView: false,
  isChatPreview: true,

  linkPreviews: {},

  isFullChatMode:
    JSON.parse(localStorage.getItem("interstride__chat_view_mode")) || false,
};
