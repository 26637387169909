import React, { useState } from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./WidgetTopicCard.module.scss";
import { CircularAvatar } from "../CircularAvatar/CircularAvatar";
import collapsearrow from "../../../assets/images/accordion-arrow.svg";
import { Button, ButtonModifier } from "../Button";

const cx = createModuleStyleExtractor(styles);

const WidgetTopicCard = ({ topic, onJoinTopic }) => {
  const [show, setShow] = useState(false);

  const handleArrowClick = () => {
    setShow((value) => !value);
  };

  return (
    <div className={cx("a-widget-topic-card")}>
      <div className={cx("a-widget-topic-card__profile")}>
        <div className={cx("a-widget-topic-card__profile__image")}>
          <CircularAvatar
            alt="topic"
            src={topic.topic_icon}
            name={topic.topic_name}
            size={85}
          />
        </div>
      </div>
      <div
        className={cx([
          "a-widget-topic-card__collapse-wrapper",
          ...(show ? ["a-widget-topic-card__collapse-wrapper--open"] : []),
        ])}
      >
        <div
          className={cx("a-widget-topic-card__collapse-wrapper__description")}
        >
          <h2>{topic.topic_name}</h2>

          <div
            className={cx(
              "a-widget-topic-card__collapse-wrapper__description__body_wrapper"
            )}
          >
            <p>{topic.topic_description}</p>
            <img
              src={collapsearrow}
              alt="collapsearrow"
              onClick={handleArrowClick}
            />
          </div>
        </div>
        <div
          className={cx("a-widget-topic-card__collapse-wrapper__btns-wrapper")}
        >
          <Button
            modifier={ButtonModifier.WIDGET_CARD_BUTTON}
            onClick={onJoinTopic}
          >
            Join
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WidgetTopicCard;
