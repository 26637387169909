import { ApiWrapper, REQUEST } from "./index";

export const getChecklistCategories = async (school_id) => {
  return await ApiWrapper({
    url: `checklist_categories?school_id=${school_id}`,
    method: REQUEST.GET,
  });
};

export const getChecklistTasks = async (id, school_id) => {
  return await ApiWrapper({
    url: `checklist_categories/checklist_subcategories?checklist_category_id=${id}&school_id=${school_id}`,
    method: REQUEST.GET,
  });
};

export const markUnmarkChecklistTask = async (subcategoryId, status = true) => {
  return await ApiWrapper({
    url: "checklist_categories/mark_task",
    method: REQUEST.POST,
    data: {
      user_subchecklist: {
        subcategory_id: subcategoryId,
        is_mark: `${status}`,
      },
    },
  });
};

export const addChecklistSubtask = async (subcategoryId, title) => {
  return await ApiWrapper({
    url: "checklist_categories/add_subtask",
    method: REQUEST.POST,
    data: {
      checklist: {
        title: title,
        subcategory_id: subcategoryId,
      },
    },
  });
};

export const removeChecklistSubtask = async (id) => {
  return await ApiWrapper({
    url: "checklist_categories/remove_subtask",
    method: REQUEST.DELETE,
    config: {
      data: {
        checklist: {
          id: id,
        },
      },
    },
  });
};

export const updateChecklistSubtask = async (subTaskId, title, taskId) => {
  return await ApiWrapper({
    url: "checklist_categories/update_subtask",
    method: REQUEST.PATCH,
    data: {
      checklist: {
        id: subTaskId,
        title: title,
        subcategory_id: taskId,
      },
    },
  });
};

export const markUnmarkChecklistSubTask = async (
  subcategoryId,
  status = true
) => {
  return await ApiWrapper({
    url: "checklist_categories/mark_subtask",
    method: REQUEST.POST,
    data: {
      user_checklist: {
        checklist_id: subcategoryId,
        is_mark: status,
      },
    },
  });
};

export const addNewTask = async (
  categoryId,
  taskName,
  subCategoryTaskName = "",
  subCategoryTaskLink = ""
) => {
  return await ApiWrapper({
    url: "checklist_categories/add_task",
    method: REQUEST.POST,
    data: {
      checklist_subcategory: {
        task_name: taskName,
        subcategory_name: subCategoryTaskName,
        subcategory_link: subCategoryTaskLink,
        category_id: categoryId,
      },
    },
  });
};

export const removeTask = async (id) => {
  return await ApiWrapper({
    url: "checklist_categories/remove_task",
    method: REQUEST.DELETE,
    config: {
      data: {
        checklist_subcategory: {
          id: id,
        },
      },
    },
  });
};

export const updateChecklistTask = async (
  taskId,
  taskName,
  categoryId,
  subcategory_name = "",
  subcategory_link = ""
) => {
  return await ApiWrapper({
    url: "checklist_categories/update_task",
    method: REQUEST.PATCH,
    data: {
      checklist_subcategory: {
        id: taskId,
        task_name: taskName,
        category_id: categoryId,
        subcategory_name,
        subcategory_link,
      },
    },
  });
};
