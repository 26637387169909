import mixpanel from "mixpanel-browser";

import { MIXPANEL_PROJECT_KEY } from "../config/config";
import { store } from "../redux/store";
import { isNull } from "../utils/validations";

mixpanel.init(MIXPANEL_PROJECT_KEY);

let actions = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  alias: (id) => {
    mixpanel.alias(id);
  },
  track: (name, props) => {
    let state = store.getState();
    const userId = state?.userState?.user?.id ?? null;
    const selectedSchoolId = state?.userState?.selectedSchool?.id ?? null;

    // Pass selected school_id and user_id for all tracked events
    const defaultProps = {};
    if (!isNull(userId)) defaultProps.user_id = userId;
    if (!isNull(selectedSchoolId)) defaultProps.school_id = selectedSchoolId;

    mixpanel.track(name, { ...defaultProps, ...props });
  },
  people: {
    set: (props) => {
      mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;
