import React, { memo, useState, useEffect } from "react";
import { useFormik } from "formik";
import Rating from "react-rating";
import { useSelector } from "react-redux";
import moment from "moment";

import { createModuleStyleExtractor } from "../../../utils/css";
import { Button } from "../../atoms/Button";
import { Modal, ModalModifier } from "../../atoms/Modal/Modal";
import {
  ModalHeaderModifier,
  ModelHeader,
} from "../../atoms/Modal/ModelHeader/ModelHeader";
import { ModalBody } from "../../atoms/Modal/ModalBody/ModalBody";
import MuiTextbox from "../../atoms/MuiTextbox/MuiTextbox";
import { send_feedbacks } from "../../../api/helpcenter";
import toastify from "../../../utils/toast";
import styles from "./LeaveFeedbackModal.module.scss";

import BlueStarEmptyIcon from "../../../assets/Icons/blueStarEmpty.svg";
import BlueStarFillIcon from "../../../assets/Icons/blueStarFilled.svg";

const cx = createModuleStyleExtractor(styles);

const LeaveFeedbackModal = ({ setShowModal = () => {} }) => {
  const [showFeedbackModal, setShowFeedbackModal] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { selectedSchool = {} } = useSelector((state) => state.userState);

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1)
      document.body.classList.add("windows-os");

    return () => {
      document.body.classList.remove("windows-os");
    };
  }, []);

  // Form Submission
  const handleSave = async () => {
    try {
      setIsSubmitting(true);
      const response = await send_feedbacks({
        send_feedback: {
          rate: formik.values.rate,
          comment: formik.values.comment,
          recommended_rate: formik.values.recommended_rate,
          school_id: selectedSchool?.id,
        },
      });
      if (response && response.data) {
        setShowFeedbackModal(false);
        setShowConfirmationModal(true);
        toastify(response.message);
      } else {
        toastify("Oops! something went wrong. Please try again");
      }
    } catch (error) {
      console.error(error);
      toastify("Oops! something went wrong. Please try again");
    }
  };

  // Validation
  const validate = (values) => {
    const errors = {};
    // Comment
    if (values.comment === "") {
      errors.comment = "Please tell us more about the issue";
    }
    return errors;
  };

  // Formik Hook
  const formik = useFormik({
    initialValues: {
      rate: null,
      comment: "",
      recommended_rate: null,
    },
    validate,
    onSubmit: handleSave,
  });

  const handleModalClose = () => {
    // 2 days delay for next time popup
    localStorage.setItem(
      "feedback_dialog_timestamp",
      moment().add(2, "days").format("YYYY-MM-DD HH:mm")
    );
    setShowModal(false);
  };

  const handleThanksModalClose = () => {
    setShowConfirmationModal(false);
    setShowModal(false);
  };

  return (
    <>
      {/* Modal */}
      <Modal
        show={showFeedbackModal}
        onClose={handleModalClose}
        centerAlign={false}
      >
        <ModelHeader title="We’d love to hear your feedback" />
        <ModalBody>
          <div className={cx("m-leave-feedback-modal-body")}>
            <div className={cx("m-leave-feedback-modal-body__form")}>
              <form onSubmit={formik.handleSubmit}>
                <div className={cx("m-leave-feedback-modal-body__form__body")}>
                  <div
                    className={cx(
                      "m-leave-feedback-modal-body__form__body__rate"
                    )}
                  >
                    <p
                      className={cx(
                        "m-leave-feedback-modal-body__form__body__rate__label"
                      )}
                    >
                      Give us a rating*
                    </p>
                    <Rating
                      start={0}
                      stop={5}
                      step={1}
                      emptySymbol={
                        <img src={BlueStarEmptyIcon} alt="Non-rated star" />
                      }
                      fullSymbol={
                        <img src={BlueStarFillIcon} alt="Rated star" />
                      }
                      placeholderSymbol={
                        <img src={BlueStarFillIcon} alt="Rate star" />
                      }
                      fractions={1}
                      value={formik.values.rate}
                      placeholderRating={formik.values.rate}
                      onClick={(rate) => formik.setFieldValue("rate", rate)}
                    />
                  </div>
                  <div
                    className={cx(
                      "m-leave-feedback-modal-body__form__body__comment"
                    )}
                  >
                    <MuiTextbox
                      name="comment"
                      onBlur={formik.handleBlur}
                      maxLength={500}
                      label="Tell us more*"
                      value={formik.values.comment}
                      onChange={(event) =>
                        formik.setFieldValue("comment", event.target.value)
                      }
                      isMultiline
                      rows={1}
                      placeholder="Share any thoughts on what is working and how we can improve."
                      error={
                        formik.touched.comment && formik.errors.comment
                          ? formik.errors.comment
                          : ""
                      }
                    />
                  </div>
                  <div
                    className={cx(
                      "m-leave-feedback-modal-body__form__body__recommended_rate"
                    )}
                  >
                    <p
                      className={cx(
                        "m-leave-feedback-modal-body__form__body__recommended_rate__label"
                      )}
                    >
                      Would you recommend Interstride to a friend?*
                    </p>
                    <ul className="rating-points">
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((point, index) => (
                        <li
                          key={index}
                          className={cx([
                            "point",
                            formik.values.recommended_rate === point &&
                              "active",
                          ])}
                          onClick={() =>
                            formik.setFieldValue("recommended_rate", point)
                          }
                        >
                          {point}
                        </li>
                      ))}
                    </ul>
                    <div
                      className={cx(
                        "m-leave-feedback-modal-body__form__body__recommended_rate__description"
                      )}
                    >
                      <span>Not Likely</span>
                      <span>Extremely likely</span>
                    </div>
                  </div>
                  <div
                    className={cx(
                      "m-leave-feedback-modal-body__form__body__submit_button"
                    )}
                  >
                    <Button
                      isSubmitting={isSubmitting}
                      type="submit"
                      disabled={
                        formik.values.rate === null ||
                        formik.values.recommended_rate === null
                          ? true
                          : false
                      }
                    >
                      Send
                    </Button>
                  </div>
                  <div
                    className={cx(
                      "m-leave-feedback-modal-body__form__body__cancel"
                    )}
                  >
                    <span onClick={handleModalClose}>Cancel</span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        show={showConfirmationModal}
        onClose={handleThanksModalClose}
        modifier={ModalModifier.FEEDBACK_CONFIRMATION}
        maxWidth={480}
      >
        <ModelHeader
          title="Thanks for sharing your feedback!"
          modifier={ModalHeaderModifier.FEEDBACK_CONFIRMATION}
        />
        <ModalBody className={"feedback-confirmation-body"}>
          <div className={cx("m-leave-feedback-confirmation-modal-body")}>
            <p>We value your opinion and will review your feedback soon.</p>
            <div
              className={cx(
                "m-leave-feedback-confirmation-modal-body__buttons"
              )}
            >
              <Button onClick={handleThanksModalClose}>Got it!</Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default memo(LeaveFeedbackModal);
