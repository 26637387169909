import React, { memo } from "react";

import styles from "./PrivacyPolicyDetails.module.scss";
import { createModuleStyleExtractor } from "../../../utils/css";
import Card from "../../atoms/Card/Card";

const cx = createModuleStyleExtractor(styles);

const TermsAndConditionsDetails = ({ selectedCategory = {} }) => {
  const { category, details } = selectedCategory;
  return (
    <div className={cx("m-privacy-policy-details")}>
      <div className={cx("m-privacy-policy-details__header")}>
        <h2>{category ? category : "Overview"}</h2>
      </div>
      <div className={cx("m-privacy-policy-details__date")}>
        <h3>Last Updated : August 28, 2024</h3>
      </div>

      <div className={cx("m-privacy-policy-details__content")}>
        <Card>
          <p
            className={cx("m-privacy-policy-details__content__paragraph")}
            dangerouslySetInnerHTML={{ __html: details }}
          />
        </Card>
      </div>
    </div>
  );
};

export default memo(TermsAndConditionsDetails);
