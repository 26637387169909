import React from "react";
import { createModuleStyleExtractor } from "../../../../utils/css";
import styles from "../Modal.module.scss";
const cx = createModuleStyleExtractor(styles);

export const ModalHeaderModifier = {
  FEEDBACK_CONFIRMATION: "feedback-confirmation",
};

export const ModelHeader = ({ title, modifier }) => {
  return (
    <div
      className={cx(
        "a-modal__dialog__content__header",
        modifier
          ? {
              [`a-modal__dialog__content__header--${modifier}`]: true,
            }
          : {}
      )}
    >
      <h5
        className={cx(
          "a-modal__dialog__content__header__title",
          modifier
            ? {
                [`a-modal__dialog__content__header__title--${modifier}`]: true,
              }
            : {}
        )}
      >
        {title}
      </h5>
    </div>
  );
};
