import React, { useState, useEffect } from "react";
import { ShimmerCircularImage, ShimmerText } from "react-shimmer-effects";

import { CircularAvatar } from "../../atoms/CircularAvatar/CircularAvatar";
import { getUserDetails } from "../../../api/widget";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./WidgetUserDetails.module.scss";
import {
  computeNetworkUserIntro,
  formatValueBySeparator,
} from "../../../utils/common";
import { Button, ButtonModifier } from "../../atoms/Button";
import WidgetMessagesTab from "../WidgetMessagesTab/WidgetMessagesTab";

import arrowLeft from "../../../assets/svg/arrow-right-network-card.svg";
import LinkedinLoginIcon from "../../../assets/Icons/LinkedinLoginIcon";

const cx = createModuleStyleExtractor(styles);

export default function WidgetUserDetails({
  handleBackUserDetails = () => {},
  activeUserId = null,
  token = null,
  tabView = {},
}) {
  const [fetchingUserDetails, setFetchingUserDetails] = useState(true);
  const [activeUserDetails, setActiveUserDetails] = useState(null);
  const [showMessageDetails, setShowMessageDetails] = useState(false);

  useEffect(() => {
    if (!activeUserId || !token) return;
    // Call API to fetch details
    (async () => {
      const response = await getUserDetails(token, activeUserId);
      setFetchingUserDetails(false);
      if (response?.success && response?.data?.user) {
        setActiveUserDetails(response?.data);
      } else {
        setActiveUserDetails(null);
      }
    })();
    return () => {
      setFetchingUserDetails(true);
      setActiveUserDetails(null);
    };
  }, [activeUserId, token]);

  return (
    <div className={cx("m-user-widget-details")}>
      <div
        className={cx("m-user-widget-details__back")}
        onClick={() => {
          showMessageDetails
            ? setShowMessageDetails(false)
            : handleBackUserDetails();
        }}
      >
        <img src={arrowLeft} alt="arrow" />
        <span>Back</span>
      </div>
      {fetchingUserDetails ? (
        <div className={cx("m-user-widget-details__loader")}>
          <ShimmerCircularImage size={100} center={true} />
          <ShimmerText line={10} gap={15} />
        </div>
      ) : !fetchingUserDetails && activeUserDetails && !showMessageDetails ? (
        <div className={cx("m-user-widget-details__body")}>
          <CircularAvatar
            src={activeUserDetails?.user?.profile_image_url}
            name={activeUserDetails?.user?.name}
            round={true}
            size={100}
          />
          {activeUserDetails?.user?.name && (
            <h2 className={cx("m-user-widget-details__body__title")}>
              {activeUserDetails?.user?.name}
            </h2>
          )}
          {activeUserDetails?.user?.user_types?.length > 0 && (
            <div className={cx("m-user-widget-details__body__types")}>
              {activeUserDetails?.user?.user_types?.map((type, index) => (
                <div
                  key={index}
                  className={cx("m-user-widget-details__body__types__type")}
                >
                  {type}
                </div>
              ))}
            </div>
          )}
          {activeUserDetails?.educations[0]?.degree &&
            activeUserDetails?.educations[0]?.graduation_year && (
              <h3 className={cx("m-user-widget-details__body__year")}>
                {activeUserDetails?.educations[0]?.degree}, Class of{" "}
                {activeUserDetails?.educations[0]?.graduation_year}
              </h3>
            )}
          {activeUserDetails?.user?.country && (
            <div className={cx("m-user-widget-details__body__country")}>
              {activeUserDetails?.user?.country_image && (
                <img
                  alt={activeUserDetails?.user.country || "country"}
                  src={activeUserDetails?.user?.country_image}
                />
              )}
              <span>From {activeUserDetails?.user.country}</span>
            </div>
          )}
          <p className={cx("m-user-widget-details__body__intro")}>
            {computeNetworkUserIntro(
              activeUserDetails?.user?.intro,
              activeUserDetails?.user?.user_types[1] ||
                activeUserDetails?.user?.user_type,
              activeUserDetails?.educations[0]?.major,
              activeUserDetails?.educations[0]?.degree,
              activeUserDetails?.educations[0]?.school_name
            )}
          </p>
          {(activeUserDetails?.educations[0]?.major ||
            activeUserDetails?.educations[0]?.second_major ||
            activeUserDetails?.educations[0]?.minor) && (
            <div className={cx("m-user-widget-details__body__academic")}>
              <span
                className={cx("m-user-widget-details__body__academic__header")}
              >
                Academic journey
              </span>
              {activeUserDetails?.educations[0]?.major && (
                <>
                  <span
                    className={cx(
                      "m-user-widget-details__body__academic__title"
                    )}
                  >
                    Major
                  </span>
                  <span
                    className={cx(
                      "m-user-widget-details__body__academic__value"
                    )}
                  >
                    {activeUserDetails?.educations[0]?.major}
                  </span>
                </>
              )}
              {activeUserDetails?.educations[0]?.second_major && (
                <>
                  <span
                    className={cx(
                      "m-user-widget-details__body__academic__title"
                    )}
                  >
                    Double Major
                  </span>
                  <span
                    className={cx(
                      "m-user-widget-details__body__academic__value"
                    )}
                  >
                    {activeUserDetails?.educations[0]?.second_major}
                  </span>
                </>
              )}
              {activeUserDetails?.educations[0]?.minor && (
                <>
                  <span
                    className={cx(
                      "m-user-widget-details__body__academic__title"
                    )}
                  >
                    Minor
                  </span>
                  <span
                    className={cx(
                      "m-user-widget-details__body__academic__value"
                    )}
                  >
                    {activeUserDetails?.educations[0]?.minor}
                  </span>
                </>
              )}
            </div>
          )}
          {activeUserDetails?.experiences?.length > 0 && (
            <div className={cx("m-user-widget-details__body__academic")}>
              <span
                className={cx("m-user-widget-details__body__academic__header")}
              >
                Work Experience
              </span>
              {activeUserDetails?.experiences?.map((experience, index) => (
                <div key={index}>
                  <span
                    className={cx(
                      "m-user-widget-details__body__academic__title"
                    )}
                  >
                    Experience
                  </span>
                  <span
                    className={cx(
                      "m-user-widget-details__body__academic__value"
                    )}
                  >
                    {experience?.organization}, {experience?.role}
                  </span>
                </div>
              ))}
            </div>
          )}
          {(activeUserDetails?.user?.ambassador_detail?.summary ||
            activeUserDetails?.user?.ambassador_detail?.clubs ||
            activeUserDetails?.user?.ambassador_detail
              ?.hobbies_and_interests) && (
            <div className={cx("m-user-widget-details__body__academic")}>
              <span
                className={cx("m-user-widget-details__body__academic__header")}
              >
                More about me
              </span>
              {activeUserDetails?.user?.ambassador_detail?.summary && (
                <>
                  <span
                    className={cx(
                      "m-user-widget-details__body__academic__title"
                    )}
                  >
                    Ambassador Summary
                  </span>
                  <span
                    className={cx(
                      "m-user-widget-details__body__academic__value"
                    )}
                  >
                    {activeUserDetails?.user?.ambassador_detail?.summary}
                  </span>
                </>
              )}
              {activeUserDetails?.user?.ambassador_detail?.clubs && (
                <>
                  <span
                    className={cx(
                      "m-user-widget-details__body__academic__title"
                    )}
                  >
                    Clubs I'm in
                  </span>
                  <span
                    className={cx(
                      "m-user-widget-details__body__academic__value"
                    )}
                  >
                    {formatValueBySeparator(
                      activeUserDetails?.user?.ambassador_detail?.clubs
                    )}
                  </span>
                </>
              )}
              {activeUserDetails?.user?.ambassador_detail
                ?.hobbies_and_interests && (
                <>
                  <span
                    className={cx(
                      "m-user-widget-details__body__academic__title"
                    )}
                  >
                    Hobbies I like
                  </span>
                  <span
                    className={cx(
                      "m-user-widget-details__body__academic__value"
                    )}
                  >
                    {formatValueBySeparator(
                      activeUserDetails?.user?.ambassador_detail
                        ?.hobbies_and_interests
                    )}
                  </span>
                </>
              )}
            </div>
          )}
          {activeUserDetails?.user?.linkedin_url && (
            <div className={cx("m-user-widget-details__body__linkedin")}>
              <LinkedinLoginIcon
                onClick={() =>
                  window
                    .open(activeUserDetails?.user?.linkedin_url, "_blank")
                    .focus()
                }
              />
            </div>
          )}
          <div className={cx("m-user-widget-details__body__chat")}>
            <Button
              modifier={ButtonModifier.WIDGET_CARD_BUTTON}
              onClick={() => setShowMessageDetails(true)}
            >
              Chat with {activeUserDetails?.user.first_name}
            </Button>
          </div>
        </div>
      ) : !fetchingUserDetails && activeUserDetails && showMessageDetails ? (
        <WidgetMessagesTab
          token={token}
          chatDetails={{
            id: activeUserDetails?.user?.id,
            name: activeUserDetails?.user?.name,
            first_name: activeUserDetails?.user?.first_name,
            profile_image_url: activeUserDetails?.user?.profile_image_url,
            view: tabView?.key,
          }}
        />
      ) : (
        <h2 className={cx("m-user-widget-details__no-user")}>User not found</h2>
      )}
    </div>
  );
}
