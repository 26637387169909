import { ApiWrapper, REQUEST } from ".";

export const getDiscoverTopicsByNameAPI = async (
  search_keyword = "",
  topic_type = null,
  interests = "",
  school_id = null
) => {
  let additionalParams = {};
  if (topic_type === "student" || topic_type === "admissions") {
    additionalParams.user_type = topic_type;
    additionalParams.filter = topic_type;
  } else {
    additionalParams.topic_type = topic_type;
  }
  additionalParams.school_id = school_id;
  return await ApiWrapper({
    url: "topics/discover_dashboard",
    method: REQUEST.GET,
    config: {
      params: {
        search_keyword,
        interests,
        ...additionalParams,
      },
    },
  });
};

export const getTopicDetailsAPI = async (
  topicId = null,
  page = 1,
  per_page = 10,
  school_id = null
) => {
  return await ApiWrapper({
    url: `communities/${topicId}`,
    method: REQUEST.GET,
    config: {
      params: {
        page,
        per_page,
        school_id,
      },
    },
  });
};

export const getTopicParticipantsDetailsAPI = async (
  topicId = null,
  page = 1,
  filters = {},
  search = "",
  school_id = null
) => {
  let query = `?pagination[page]=${page}&pagination[per_page]=10&school_id=${school_id}`;
  if (search?.length > 0) query += "&search[name]=" + search;
  if (filters?.country?.value)
    query += "&search[country]=" + filters?.country.value;
  if (filters?.account_type?.value)
    query += "&search[user_type]=" + filters?.account_type.value;
  if (filters?.degree?.value)
    query += "&search[degree]=" + filters?.degree.value;
  if (filters?.major?.value) query += "&search[major]=" + filters?.major.value;
  if (filters?.graduation_year?.value)
    query += "&search[graduation_year]=" + filters?.graduation_year.value;
  if (filters?.department?.value)
    query += "&search[department]=" + filters?.department.value;
  return await ApiWrapper({
    url: `topics/${topicId}/participants${query}`,
    method: REQUEST.GET,
  });
};

export const getMyTopicsAPI = async (filter = "all", school_id = null) => {
  return await ApiWrapper({
    url: "topics",
    method: REQUEST.GET,
    config: {
      params: {
        filter,
        school_id,
      },
    },
  });
};

export const getTopicPreviewAPI = async (topic_url = "") => {
  return await ApiWrapper({
    url: "topics/topic_thumbnailer_url",
    method: REQUEST.GET,
    config: {
      params: {
        topic_url,
      },
    },
  });
};

export const getTopicPendingRequestAPI = async (
  topic_id = null,
  school_id = null
) => {
  return await ApiWrapper({
    url: "topics/following_request_list",
    method: REQUEST.GET,
    config: {
      params: {
        topic_id,
        school_id,
      },
    },
  });
};

export const acceptDeclineTopicRequestAPI = async (payload = {}) => {
  return await ApiWrapper({
    url: "topics/follow_unfollow_topic",
    method: REQUEST.POST,
    data: payload,
  });
};

export const createNewTopicAPI = async (payload = {}) => {
  return await ApiWrapper({
    url: "topics",
    method: REQUEST.POST,
    data: payload,
  });
};

export const editTopicAPI = async (payload = {}, topicId = null) => {
  return await ApiWrapper({
    url: `topics/${topicId}`,
    method: REQUEST.PUT,
    data: payload,
  });
};

export const createNewPostAPI = async (payload = {}) => {
  return await ApiWrapper({
    url: "topics/create_or_update_post",
    method: REQUEST.POST,
    data: payload,
  });
};

export const sendCommentAPI = async (payload = {}) => {
  return await ApiWrapper({
    url: "topics/create_or_update_post_comment",
    method: REQUEST.POST,
    data: payload,
  });
};

export const muteUnmuteTopicAPI = async (
  topic_follower_id = null,
  topic_id = null,
  school_id = null
) => {
  return await ApiWrapper({
    url: "topics/mute_or_unmute_topic",
    method: REQUEST.GET,
    config: {
      params: {
        topic_follower_id,
        topic_id,
        school_id,
      },
    },
  });
};

export const closeTopicAPI = async (id = null) => {
  return await ApiWrapper({
    url: `topics/${id}`,
    method: REQUEST.DELETE,
  });
};

export const reportTopicAPI = async (reportData = {}) => {
  return await ApiWrapper({
    url: "topics/report_topic",
    method: REQUEST.POST,
    data: reportData,
  });
};

export const departmentListAPI = async (school_id = null) => {
  return await ApiWrapper({
    url: "department_list",
    method: REQUEST.GET,
    config: {
      params: {
        school_id,
      },
    },
  });
};

export const getTopicsNotificationCountsAPI = async (school_id = null) => {
  return await ApiWrapper({
    url: "notifications/all_topic_notifications",
    method: REQUEST.GET,
    config: {
      params: {
        school_id,
      },
    },
  });
};

export const readTopicNotificationAPI = async (
  topic_id = null,
  school_id = null
) => {
  return await ApiWrapper({
    url: "notifications/read_topic_notifications",
    method: REQUEST.POST,
    data: { topic_id, school_id },
  });
};

export const getTopicParticipantsFiltersAPI = async (topicId = null) => {
  return await ApiWrapper({
    url: `topics/${topicId}/participants_search_list`,
    method: REQUEST.GET,
  });
};

export const favouriteTopicAPI = async (topic_id = null, school_id = null) => {
  return await ApiWrapper({
    url: `communities/${topic_id}/favourite?school_id=${school_id}`,
    method: REQUEST.PATCH,
    data: null,
  });
};

export const getTopicFeedAPI = async (
  page = 1,
  per_page = 10,
  school_id = null
) => {
  return await ApiWrapper({
    url: "topic_posts",
    method: REQUEST.GET,
    config: {
      params: {
        page,
        per_page,
        school_id,
      },
    },
  });
};

export const getTopicPostCommentsAPI = async (
  topic_post_id,
  page = 1,
  per_page = 10,
  school_id = null
) => {
  return await ApiWrapper({
    url: `topic_posts/${topic_post_id}/comments`,
    method: REQUEST.GET,
    config: {
      params: {
        page,
        per_page,
        school_id,
      },
    },
  });
};

export const getTopicPostReactionsAPI = async (topic_post_ids = "") => {
  return await ApiWrapper({
    url: `topic_posts/details${topic_post_ids}`,
    method: REQUEST.GET,
  });
};

export const updateTopicPostReactionAPI = async (
  topic_post_id = null,
  payload = {}
) => {
  return await ApiWrapper({
    url: `topic_posts/${topic_post_id}/reaction`,
    method: REQUEST.POST,
    data: payload,
  });
};

export const saveUnsaveTopicPostAPI = async (
  topic_post_id = null,
  school_id = null
) => {
  return await ApiWrapper({
    url: `topic_posts/${topic_post_id}/save?school_id=${school_id}`,
    method: REQUEST.POST,
  });
};

export const reportTopicPostAPI = async (topic_post_id = null, params = {}) => {
  return await ApiWrapper({
    url: `topic_posts/${topic_post_id}/report`,
    method: REQUEST.POST,
    data: params,
  });
};

export const getFollowingTopicsAPI = async (
  filter = "all",
  page = 1,
  per_page = 50,
  school_id = null
) => {
  return await ApiWrapper({
    url: "communities/followings",
    method: REQUEST.GET,
    config: {
      params: {
        filter,
        page,
        per_page,
        school_id,
      },
    },
  });
};

export const getSavedTopicPostsAPI = async (
  topic_id = null, // Optional param
  page = 1,
  per_page = 10,
  school_id = null
) => {
  return await ApiWrapper({
    url: "topic_posts/saved",
    method: REQUEST.GET,
    config: {
      params: {
        ...(topic_id ? { topic_id } : {}),
        page,
        per_page,
        school_id,
      },
    },
  });
};

export const deleteTopicPostAPI = async (
  topic_post_id = null,
  school_id = null
) => {
  return await ApiWrapper({
    url: `topics/delete_post?topic_post_id=${topic_post_id}&school_id=${school_id}`,
    method: REQUEST.DELETE,
  });
};

export const deleteTopicPostComment = async (
  post_comment_id = null,
  school_id = null
) => {
  return await ApiWrapper({
    url: "topics/delete_post_comment",
    method: REQUEST.DELETE,
    config: {
      data: {
        post_comment_id,
        school_id,
      },
    },
  });
};

export const getUserByIdAPI = async (userID = null) => {
  return await ApiWrapper({
    url: `users/${userID}`,
    method: REQUEST.GET,
  });
};

export const followUnfollowTopicAPI = async (
  topic_id = null,
  school_id = null
) => {
  return await ApiWrapper({
    url: "topics/follow_unfollow_topic",
    method: REQUEST.POST,
    data: { topic_id, school_id },
  });
};

export const favoriteUnfavoriteNetworkAPI = async (
  favourite_user_id = null,
  school_id = null
) => {
  return await ApiWrapper({
    url: "network_modules/add_or_remove_favourites",
    method: REQUEST.POST,
    data: { favourite_user_id, school_id },
  });
};

export const getFavoriteNetworksAPI = async (school_id = null) => {
  return await ApiWrapper({
    url: "network_modules/favourites_list",
    method: REQUEST.GET,
    config: {
      params: {
        school_id,
      },
    },
  });
};

export const getRecommendedTopicsAPI = async (school_id = null) => {
  return await ApiWrapper({
    url: "topics",
    method: REQUEST.GET,
    config: {
      params: {
        school_id,
      },
    },
  });
};
