import React from "react";

export default function CampusMapIcons({ variant = "default" }) {
  switch (variant) {
    case "Banks":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="43"
          viewBox="0 0 36 43"
          fill="none"
        >
          <path
            d="M36 17.623C36 27.356 18 43 18 43S0 27.356 0 17.623 8.059 0 18 0s18 7.89 18 17.623"
            fill="var(--primary-color)"
          />
          <path
            clip-rule="evenodd"
            d="M28.263 27.273a.2.2 0 0 1-.2.2H7.937a.2.2 0 0 1-.2-.2v-2.758c0-.11.09-.2.2-.2h20.126c.11 0 .2.09.2.2zm0-14.209a.2.2 0 0 1-.2.2H7.937a.2.2 0 0 1-.2-.2v-.98a.2.2 0 0 1 .124-.184l10.124-4.163L28.139 11.9a.2.2 0 0 1 .124.185z"
            stroke="#fff"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.798 23.94V13.722Z"
            fill="#fff"
          />
          <path d="M9.798 23.94V13.722" stroke="#fff" stroke-linecap="square" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.728 23.94V13.722Z"
            fill="#fff"
          />
          <path
            d="M11.728 23.94V13.722"
            stroke="#fff"
            stroke-linecap="square"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.623 23.94V13.722Z"
            fill="#fff"
          />
          <path
            d="M14.623 23.94V13.722"
            stroke="#fff"
            stroke-linecap="square"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.553 23.94V13.722Z"
            fill="#fff"
          />
          <path
            d="M16.553 23.94V13.722"
            stroke="#fff"
            stroke-linecap="square"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.447 23.94V13.722Z"
            fill="#fff"
          />
          <path
            d="M19.447 23.94V13.722"
            stroke="#fff"
            stroke-linecap="square"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M21.377 23.94V13.722Z"
            fill="#fff"
          />
          <path
            d="M21.377 23.94V13.722"
            stroke="#fff"
            stroke-linecap="square"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M24.272 23.94V13.722Z"
            fill="#fff"
          />
          <path
            d="M24.272 23.94V13.722"
            stroke="#fff"
            stroke-linecap="square"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M26.202 23.94V13.722Z"
            fill="#fff"
          />
          <path
            d="M26.202 23.94V13.722"
            stroke="#fff"
            stroke-linecap="square"
          />
        </svg>
      );
    case "Student Offices":
    case "Student offices":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="43"
          viewBox="0 0 36 43"
          fill="none"
        >
          <path
            d="M36 17.623C36 27.356 18 43 18 43S0 27.356 0 17.623 8.059 0 18 0s18 7.89 18 17.623"
            fill="var(--primary-color)"
          />
          <path
            clip-rule="evenodd"
            d="M21.976 6.947c.927 0 1.686.738 1.686 1.64v10.451c0 .901-.759 1.639-1.686 1.639s-1.685-.738-1.685-1.639V8.586c0-.901.759-1.639 1.685-1.639"
            stroke="#fff"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.054 27.21v-2.152h10.448v2.152"
            stroke="#fff"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            clip-rule="evenodd"
            d="M13.56 28.503c0 .686-.573 1.242-1.278 1.242s-1.277-.556-1.277-1.242.572-1.241 1.277-1.241 1.277.556 1.277 1.241m10.449 0c0 .686-.572 1.242-1.277 1.242-.706 0-1.277-.556-1.277-1.242s.572-1.241 1.277-1.241 1.277.556 1.277 1.241"
            stroke="#fff"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.278 25.057v-4.38m4.698 0H12.58c-.927 0-1.685-.737-1.685-1.639 0-.9.758-1.638 1.685-1.638h7.711"
            stroke="#fff"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
    case "Housing":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="43"
          viewBox="0 0 36 43"
          fill="none"
        >
          <path
            d="M36 17.623C36 27.356 18 43 18 43S0 27.356 0 17.623 8.059 0 18 0s18 7.89 18 17.623"
            fill="var(--primary-color)"
          />
          <path d="M6 16.798 18 8l11.29 8.798" stroke="#fff" />
          <path
            d="M9 14.981v12.672a.5.5 0 0 0 .5.5h5.713a.5.5 0 0 0 .5-.5v-5.276a.5.5 0 0 1 .5-.5H19.5a.5.5 0 0 1 .5.5v5.276a.5.5 0 0 0 .5.5h6.016a.5.5 0 0 0 .5-.5V14.98"
            stroke="#fff"
          />
        </svg>
      );
    case "Groceries":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="43"
          viewBox="0 0 36 43"
          fill="none"
        >
          <path
            d="M36 17.623C36 27.356 18 43 18 43S0 27.356 0 17.623 8.059 0 18 0s18 7.89 18 17.623"
            fill="var(--primary-color)"
          />
          <path
            d="M12.5 13.5v-3a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3M11.083 27h13.434a.5.5 0 0 0 .486-.384l2.85-12a.5.5 0 0 0-.486-.616H7.662a.5.5 0 0 0-.48.637l3.42 12a.5.5 0 0 0 .481.363ZM24.5 14 18 27m-.5-13L11 27m0-13 6.5 13m.5-13 6.5 13"
            stroke="#fff"
          />
        </svg>
      );
    case "Religious Centers":
    case "Religious centers":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="43"
          viewBox="0 0 36 43"
          fill="none"
        >
          <path
            d="M36 17.623C36 27.356 18 43 18 43S0 27.356 0 17.623 8.059 0 18 0s18 7.89 18 17.623"
            fill="var(--primary-color)"
          />
          <path d="M6.553 18.901 17.978 7.476l11.864 11.425" stroke="#fff" />
          <path
            d="M9.19 16.265v10.864a1 1 0 0 0 1 1h4.536a.176.176 0 0 0 .176-.176v-6.415a3.076 3.076 0 1 1 6.152 0v6.415c0 .097.078.176.175.176h4.977a1 1 0 0 0 1-1V16.265m-8.344-3.137-.424-.96a.51.51 0 0 0-.933 0l-.424.96-.96.424a.51.51 0 0 0 0 .934l.96.423.424.96a.51.51 0 0 0 .933 0l.424-.96.96-.424a.51.51 0 0 0 0-.933z"
            stroke="#fff"
          />
        </svg>
      );
    case "Embassies and Consulates":
    case "Embassy":
    case "Embassies":
    case "Counslates":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="58"
          viewBox="0 0 48 58"
          fill="none"
        >
          <path
            d="M48 23.77C48 36.9 24 58 24 58S0 36.899 0 23.77 10.745 0 24 0s24 10.642 24 23.77"
            fill="var(--primary-color)"
          />
          <rect
            x="9.263"
            y="20.256"
            width="29.474"
            height="20.277"
            rx="1"
            stroke="#fff"
          />
          <rect
            x="14.175"
            y="24.987"
            width="4.912"
            height="4.055"
            rx=".5"
            stroke="#fff"
          />
          <rect
            x="14.175"
            y="32.422"
            width="4.912"
            height="4.055"
            rx=".5"
            stroke="#fff"
          />
          <rect
            x="21.544"
            y="24.987"
            width="4.912"
            height="4.055"
            rx=".5"
            stroke="#fff"
          />
          <rect
            x="21.544"
            y="32.422"
            width="4.912"
            height="8.111"
            rx=".5"
            stroke="#fff"
          />
          <rect
            x="28.912"
            y="24.987"
            width="4.912"
            height="4.055"
            rx=".5"
            stroke="#fff"
          />
          <rect
            x="28.912"
            y="32.422"
            width="4.912"
            height="4.055"
            rx=".5"
            stroke="#fff"
          />
          <path
            d="M11.72 20.256h24.56V17.2a1 1 0 0 0-1-1H12.72a1 1 0 0 0-1 1zm11.666-4.416 1.565-6.937"
            stroke="#fff"
          />
          <rect
            width="5.78"
            height="4.041"
            rx=".5"
            transform="matrix(.9631 .2544 -.2137 .9803 25.055 8.534)"
            stroke="#fff"
          />
        </svg>
      );
    case "Healthcare":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="43"
          viewBox="0 0 36 43"
          fill="none"
        >
          <path
            d="M36 17.623C36 27.356 18 43 18 43S0 27.356 0 17.623 8.059 0 18 0s18 7.89 18 17.623"
            fill="var(--primary-color)"
          />
          <path
            d="M27 15.951V8.387a1 1 0 0 0-1-1H10a1 1 0 0 0-1 1v7.564a13 13 0 0 0 5.89 10.884l2.563 1.674a1 1 0 0 0 1.094 0l2.563-1.674A13 13 0 0 0 27 15.95Z"
            stroke="#fff"
          />
          <path
            d="M16.899 13.367h2.2v-1h-2.2zm.4 2.2v-2.6h-1v2.6zm-3.2.6h2.6v-1h-2.6zm.4 1.8v-2.2h-1v2.2zm2.2-.4h-2.6v1h2.6zm.6 3.2v-2.6h-1v2.6zm1.8-.4h-2.2v1h2.2zm-.4-2.2v2.6h1v-2.6zm3.2-.6h-2.6v1h2.6zm-.4-1.8v2.2h1v-2.2zm-2.2.4h2.6v-1h-2.6zm-.6-3.2v2.6h1v-2.6zm.6 2.2a.4.4 0 0 1 .4.4h-1a.6.6 0 0 0 .6.6zm3.2.6a.6.6 0 0 0-.6-.6v1a.4.4 0 0 1-.4-.4zm-.6 2.8a.6.6 0 0 0 .6-.6h-1a.4.4 0 0 1 .4-.4zm-2.2-.4a.4.4 0 0 1-.4.4v-1a.6.6 0 0 0-.6.6zm-.6 3.2a.6.6 0 0 0 .6-.6h-1a.4.4 0 0 1 .4-.4zm-2.8-.6a.6.6 0 0 0 .6.6v-1a.4.4 0 0 1 .4.4zm.4-2.2a.4.4 0 0 1-.4-.4h1a.6.6 0 0 0-.6-.6zm-3.2-.6a.6.6 0 0 0 .6.6v-1a.4.4 0 0 1 .4.4zm.6-2.8a.6.6 0 0 0-.6.6h1a.4.4 0 0 1-.4.4zm2.2.4a.4.4 0 0 1 .4-.4v1a.6.6 0 0 0 .6-.6zm2.8-2.2a.4.4 0 0 1-.4-.4h1a.6.6 0 0 0-.6-.6zm-2.2-1a.6.6 0 0 0-.6.6h1a.4.4 0 0 1-.4.4z"
            fill="#fff"
          />
        </svg>
      );
    case "Restaurants":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="43"
          viewBox="0 0 36 43"
          fill="none"
        >
          <path
            d="M36 17.623C36 27.356 18 43 18 43S0 27.356 0 17.623 8.059 0 18 0s18 7.89 18 17.623"
            fill="var(--primary-color)"
          />
          <path
            d="M11.353 25.895 23.73 12.976m1.375 12.919L11.181 10.423a.164.164 0 0 0-.286.11v0c0 2.823.946 5.565 2.688 7.787l1.437 1.833m6.877-9.09-1.833 2.185a2.39 2.39 0 0 0 .106 3.19v0a2.39 2.39 0 0 0 3.453 0l1.482-1.548"
            stroke="#fff"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
    case "Hotels":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="43"
          viewBox="0 0 36 43"
          fill="none"
        >
          <path
            d="M36 17.623C36 27.356 18 43 18 43S0 27.356 0 17.623 8.059 0 18 0s18 7.89 18 17.623"
            fill="var(--primary-color)"
          />
          <path
            stroke="#fff"
            d="M8 22.5h22m-22-4h22M7.5 10v17m22-11v11M8 15h3a3 3 0 0 1 3 3"
          />
        </svg>
      );
    case "Postal Office":
    case "Postal office":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="43"
          viewBox="0 0 36 43"
          fill="none"
        >
          <path
            d="M36 17.623C36 27.356 18 43 18 43S0 27.356 0 17.623 8.059 0 18 0s18 7.89 18 17.623"
            fill="var(--primary-color)"
          />
          <path
            d="M8 12a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v11a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2z"
            stroke="#fff"
          />
          <path d="m8.5 11.5 9.5 7 9.5-7" stroke="#fff" />
        </svg>
      );
    default:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="43"
          viewBox="0 0 36 43"
          fill="none"
        >
          <path
            d="M36 17.623C36 27.356 18 43 18 43S0 27.356 0 17.623 8.059 0 18 0s18 7.89 18 17.623"
            fill="var(--primary-color)"
          />
        </svg>
      );
  }
}
