import React, { memo } from "react";

function NoImageIcon({ width = 60.5, height = 59.314 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 60.5 59.314"
    >
      <g
        id="Icon_TopBar_Profile_Empty"
        data-name="Icon/TopBar/Profile/Empty"
        transform="translate(3.3 2.157)"
      >
        <ellipse
          id="Oval"
          cx="30.25"
          cy="29.657"
          rx="30.25"
          ry="29.657"
          transform="translate(-3.3 -2.157)"
          fill="#d1dde5"
        />
        <path
          id="Stroke_3"
          data-name="Stroke 3"
          d="M20.524,0A16.68,16.68,0,0,1,33,16.214a55.009,55.009,0,0,1-17.227,3.2A41.567,41.567,0,0,1,0,16.214,16.711,16.711,0,0,1,10.7.561"
          transform="translate(11 25.882)"
          fill="#fff"
          stroke="#d1dde5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="3.095"
        />
        <path
          id="Stroke_5"
          data-name="Stroke 5"
          d="M16.5,8.09a8.171,8.171,0,0,1-8.251,8.087A8.17,8.17,0,0,1,0,8.09,8.171,8.171,0,0,1,8.249,0,8.171,8.171,0,0,1,16.5,8.09Z"
          transform="translate(18.7 11.863)"
          fill="#fff"
          stroke="#d1dde5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2.1"
        />
      </g>
    </svg>
  );
}

export default memo(NoImageIcon);
