import React, { memo } from "react";
import { Modal } from "../../atoms/Modal/Modal";
import { ModelHeader } from "../../atoms/Modal/ModelHeader/ModelHeader";
import { ModalBody } from "../../atoms/Modal/ModalBody/ModalBody";
import { Button } from "../../atoms/Button/Button";
import styles from "./ConfirmRemoveChat.module.scss";
import { createModuleStyleExtractor } from "../../../utils/css";
const cx = createModuleStyleExtractor(styles);
const ConfirmRemoveChat = ({ show, onClose, onSuccess, loading }) => {
  return (
    <Modal show={show} onClose={onClose}>
      <ModelHeader title={"Are you sure you want to delete this chat?"} />
      <ModalBody>
        <div className={cx("button-wrapper")}>
          <Button onClick={onSuccess} isSubmitting={loading}>
            Delete Chat
          </Button>
        </div>
        <p className={cx("button-cancel")}>
          <span onClick={onClose}>Cancel</span>
        </p>
      </ModalBody>
    </Modal>
  );
};

export default memo(ConfirmRemoveChat);
