import React from "react";

function HomeIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
      <path d="m17.77 10.11.025 5.63c0 .098-.007.19-.017.284v.57c0 .777-.622 1.406-1.39 1.406h-.555c-.038 0-.076-.032-.114-.004-.049-.028-.097.004-.146.004H13.61c-.767 0-1.389-.63-1.389-1.406V13.5c0-.622-.496-1.125-1.11-1.125H8.888c-.615 0-1.111.503-1.111 1.125v3.094c0 .777-.622 1.406-1.39 1.406h-1.94c-.052 0-.104-.004-.156-.007a1.49 1.49 0 0 1-.125.007H3.61c-.767 0-1.389-.63-1.389-1.406v-3.938c0-.031.001-.067.003-.098V10.11H1.113A1.108 1.108 0 0 1 0 8.982c0-.316.104-.597.348-.843L9.25.282C9.493.035 9.77 0 10.014 0c.243 0 .52.07.733.247l3.697 3.293V2.25c0-.621.497-1.125 1.112-1.125h1.11c.615 0 1.112.504 1.112 1.125v4.254l1.833 1.635c.278.246.42.527.382.843 0 .633-.52 1.129-1.111 1.129H17.77z" fill="#BFCED8"/>
    </svg>
  );
}

export default React.memo(HomeIcon);
