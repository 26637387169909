import React, { memo } from "react";

import { Col } from "../../helpers/Grid/Col";
import { Row } from "../../helpers/Grid/Row";
import styles from "./NotificationUnsubscribe.module.scss";
import { createModuleStyleExtractor } from "../../../utils/css";
import Perspective from "../../molecules/Perspective/Perspective";
import Unsubscribe from "../../molecules/Unsubscribe/Unsubscribe";

const cx = createModuleStyleExtractor(styles);

const NotificationUnsubscribe = () => {
  return (
    <Row className={cx("o-notification-unsubscribe-container")}>
      <Col xs={12} sm={6} md={6} lg={6}>
        <Perspective />
      </Col>
      <Col xs={12} sm={6} md={6} lg={6}>
        <Unsubscribe />
      </Col>
    </Row>
  );
};
export default memo(NotificationUnsubscribe);
