import React, { memo } from "react";

function FacebookIcon() {
  return (
    <svg
      width="65px"
      height="65px"
      viewBox="0 0 65 65"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <polygon
          id="path-1"
          points="1.80744308e-12 0.0362424242 13.2961111 0.0362424242 13.2961111 26 1.80744308e-12 26"
        ></polygon>
      </defs>
      <g id="Final-Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Log-In" transform="translate(-1094.000000, -360.000000)">
          <g id="Group-3" transform="translate(1094.000000, 360.000000)">
            <circle id="Oval-Copy" fill="#F1F4F7" cx="32.5" cy="32.5" r="32.5"></circle>
            <g id="Group-5" transform="translate(26.000000, 20.000000)">
              <mask id="mask-2" fill="white">
                <use href="#path-1"></use>
              </mask>
              <g id="Clip-4"></g>
              <path
                d="M12.8333333,5.36976869 L10.5143999,5.36976869 C8.65865263,5.36976869 8.34936141,6.30059172 8.34936141,7.54168908 L8.34936141,10.3334051 L12.6786877,10.3334051 L12.0601053,14.6764927 L8.34936141,14.6764927 L8.34936141,26 L3.86538949,26 L3.86538949,14.6764927 L1.8189894e-12,14.6764927 L1.8189894e-12,10.3334051 L3.86538949,10.3334051 L3.86538949,7.07627757 C3.71074388,3.1986014 6.02967729,1.18181818 9.43188067,1.18181818 C10.9783367,1.18181818 12.3693965,1.33695535 12.8333333,1.33695535 L12.8333333,5.36976869 Z"
                id="Fill-3"
                fill="#0030B5"
                mask="url(#mask-2)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default memo(FacebookIcon);
