import React, { memo, useState } from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./WidgetResourcesTab.module.scss";
import NSwitch from "../../atoms/NSwitch/NSwitch";
import WidgetWebinars from "../WidgetWebinars/WidgetWebinars";
import WidgetServices from "../WidgetServices/WidgetServices";
import WidgetResources from "../WidgetResources/WidgetResources";
import WidgetSignUpOverlay from "../WidgetSignUpOverlay/WidgetSignUpOverlay";
import WidgetSignUpBar from "../../atoms/WidgetSignUpBar/WidgetSignUpBar";
import WidgetCourses from "../WidgetCourses/WidgetCourses";

const cx = createModuleStyleExtractor(styles);

const WidgetResourcesTab = ({ schoolData = {}, resourceSubTabs = [] }) => {
  const [showSignupOverlay, setShowSignupOverlay] = useState(false);
  const [tab, setTab] = useState(resourceSubTabs[0]);

  const showOverlay = () => {
    setShowSignupOverlay(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const closeOverlay = () => setShowSignupOverlay(false);

  return (
    <>
      {showSignupOverlay && tab.signup_overlay && (
        <WidgetSignUpOverlay
          token={schoolData?.token}
          title={`Sign up to access ${tab.key}`}
          route_type={tab.route_type}
          closeOverlay={closeOverlay}
        />
      )}
      <div className={cx("m-widget-resources-tab")}>
        <WidgetSignUpBar
          text="Get full access on Interstride’s portal"
          buttonText="Sign up for free"
          onSignupClick={showOverlay}
        />
        <div className={cx("m-widget-resources-tab__switch")}>
          <NSwitch
            activeSide={tab}
            setActiveSide={setTab}
            options={resourceSubTabs}
            variant="buttons"
          />
        </div>
        {!showSignupOverlay && tab.key === "courses" ? (
          <></>
        ) : (
          <h2 className={cx("m-widget-resources-tab__title")}>
            Get insider knowledge and boost your application
          </h2>
        )}

        {tab.key === "webinars" ? (
          <WidgetWebinars schoolData={schoolData} onSignupClick={showOverlay} />
        ) : tab.key === "courses" ? (
          <WidgetCourses schoolData={schoolData} showOverlay={showOverlay} />
        ) : tab.key === "services" ? (
          <WidgetServices schoolData={schoolData} onSignupClick={showOverlay} />
        ) : tab.key === "resources" ? (
          <WidgetResources
            schoolData={schoolData}
            onSignupClick={showOverlay}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default memo(WidgetResourcesTab);
