import React, { useState, useRef, useEffect } from "react";

const ReadMoreReadLess = ({
  children,
  maxLines = 3,
  moreComp = "Read more",
  lessComp = "Read less",
}) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const [textHeight, setTextHeight] = useState(0);
  const [lineCount, setLineCount] = useState(0);
  const textRef = useRef(null);

  useEffect(() => {
    const element = textRef.current;
    if (element) {
      const lineHeight = parseFloat(
        window.getComputedStyle(element).lineHeight
      );
      const height = element.scrollHeight;
      const numberOfLines = Math.floor(height / lineHeight);
      setLineCount(numberOfLines);
    }
  }, []);

  useEffect(() => {
    if (textRef.current) {
      const lineHeight = parseInt(getComputedStyle(textRef.current).lineHeight);
      setTextHeight(lineHeight * maxLines);
    }
  }, [maxLines]);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const getTextContainerStyles = (isReadMore) => {
    if (isReadMore) {
      return {
        transition: "max-height 0.3s ease",
        WebkitTransition: "max-height 0.3s ease",
        MozTransition: "max-height 0.3s ease",
        OTransition: "max-height 0.3s ease",
        msTransition: "max-height 0.3s ease",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        MozBoxOrient: "vertical",
        msFlexDirection: "column",
        WebkitFlexDirection: "column",
        flexDirection: "column",
        overflow: "hidden",
        textOverflow: "ellipsis",
        WebkitLineClamp: maxLines,
        maxHeight: `${textHeight}px`,
        WebkitMaxHeight: `${textHeight}px`,
        MozMaxHeight: `${textHeight}px`,
        msMaxHeight: `${textHeight}px`,
        paddingTop: "5px",
      };
    } else {
      return {
        overflow: "hidden",
        transition: "max-height 0.3s ease",
        WebkitTransition: "max-height 0.3s ease",
        MozTransition: "max-height 0.3s ease",
        OTransition: "max-height 0.3s ease",
        msTransition: "max-height 0.3s ease",
        maxHeight: "none",
        WebkitMaxHeight: "none",
        MozMaxHeight: "none",
        msMaxHeight: "none",
        whiteSpace: "pre-wrap",
        WebkitWhiteSpace: "pre-wrap",
        MozWhiteSpace: "pre-wrap",
        msWhiteSpace: "pre-wrap",
      };
    }
  };

  return (
    <>
      <span ref={textRef} style={getTextContainerStyles(isReadMore)}>
        {children}
      </span>
      {maxLines < lineCount && (
        <span onClick={toggleReadMore}>{isReadMore ? moreComp : lessComp}</span>
      )}
    </>
  );
};

export default ReadMoreReadLess;
