import { ApiWrapper, REQUEST } from ".";
import { API_URL } from "../config/config";

export const emailCheck = async (email) => {
  return await ApiWrapper({
    url: `email_check?email=${encodeURIComponent(email)}`,
    method: REQUEST.GET,
  });
};

export const prospectLogin = async (data) => {
  return await ApiWrapper({
    url: "users/sign_in",
    method: REQUEST.POST,
    data,
  });
};

export const ssoLogin = async (data) => {
  return await ApiWrapper({
    url: "users/sso_sign_in",
    method: REQUEST.POST,
    data,
  });
};

export const validateUniversityToken = async (token) => {
  return await ApiWrapper({
    url: `schools/${token}`,
    method: REQUEST.GET,
  });
};

export const prospectParentSignUp = async (data) => {
  return await ApiWrapper({
    url: "users",
    method: REQUEST.POST,
    data,
  });
};

export const socialLoginAuthorization = async (data) => {
  return await ApiWrapper({
    url: "social_auth/callback",
    method: REQUEST.POST,
    data,
  });
};

export const validateEmailToken = async (email, prospect_token) => {
  return await ApiWrapper({
    url: `verify_email?email=${encodeURIComponent(
      email
    )}&prospect_token=${prospect_token}`,
    method: REQUEST.GET,
  });
};

export const resendVerifyEmail = async (data) => {
  return await ApiWrapper({
    url: `users/resend_verification_email`,
    method: REQUEST.POST,
    data,
  });
};

export const addSchoolForProspect = async (data) => {
  return await ApiWrapper({
    url: `users/logged_in`,
    method: REQUEST.POST,
    data,
  });
};

export const getDropDownOptions = async ({ school_id, role, data_filters }) => {
  return await ApiWrapper({
    url: `users/user_details?school_id=${school_id}&role=${role}&data_filters=${JSON.stringify(
      data_filters
    )}`,
    method: REQUEST.GET,
  });
};

export const getUserProfileData = async ({ school_id }) => {
  return await ApiWrapper({
    url: `users/user_profile_data?school_id=${school_id}`,
    method: REQUEST.GET,
  });
};

export const updateOnboardigUserProfile = async (id, data) => {
  return await ApiWrapper({
    url: `users/${id}`,
    method: REQUEST.PUT,
    data,
  });
};

export const validateAmbassadorUniversityToken = async (
  confirmation_token,
  email
) => {
  return await ApiWrapper({
    url: `ambassador/verify_email?confirmation_token=${confirmation_token}&email=${encodeURIComponent(
      email
    )}`,
    method: REQUEST.GET,
  });
};

export const socialSignUpAuthorizationForAmbassador = async (data) => {
  return await ApiWrapper({
    url: "social_auth/callback",
    method: REQUEST.POST,
    data,
  });
};

export const ambassadorSignUp = async (data) => {
  return await ApiWrapper({
    url: "ambassador/sign_up",
    method: REQUEST.POST,
    data,
  });
};

export const getNewToken = async (keepSignedIn) => {
  return await ApiWrapper({
    url: `users/regenerate_token${keepSignedIn ? "?remember_me=true" : ""}`,
    method: REQUEST.GET,
  });
};

export const acceptAmbassadorInvite = async (token) => {
  return await ApiWrapper({
    url: `ambassador/invite/accept?ambassador_accept_token=${token}`,
    method: REQUEST.GET,
  });
};

export const sendForgotPasswordEmail = async (email) => {
  return await ApiWrapper({
    url: `users/password?email=${encodeURIComponent(email)}`,
    method: REQUEST.POST,
  });
};

export const resetPassword = async (data) => {
  return await ApiWrapper({
    url: `users/password`,
    method: REQUEST.PUT,
    data,
  });
};

export const inviteProspectParent = async (data) => {
  return await ApiWrapper({
    url: `user_invites`,
    method: REQUEST.POST,
    data,
  });
};

export const validateInviteToken = async (token) => {
  return await ApiWrapper({
    url: `user_invites/${token}/verify_token`,
    method: REQUEST.GET,
  });
};

export const invitedProspectParentSignUp = async (data) => {
  return await ApiWrapper({
    url: "invite/sign_up",
    method: REQUEST.POST,
    data,
  });
};

export const updateLastActiveSessionAPI = async () => {
  return await ApiWrapper({
    url: "users/update_session_details",
    method: REQUEST.PUT,
  });
};

export const socialLoginAuthorizationLinkedin = async (data) => {
  return await ApiWrapper({
    url: "social_auth/sign_in_with_linkedin",
    method: REQUEST.POST,
    data,
  });
};

export const socialLoginAuthorizationGoogle = async (data) => {
  return await ApiWrapper({
    url: "social_auth/sign_in_with_google",
    method: REQUEST.POST,
    data,
  });
};

export const getUserPermissions = async (school_id) => {
  return await ApiWrapper({
    url: `/school_module_permissions?school_id=${school_id}`,
    method: REQUEST.GET,
  });
};

/**
 * Used SP API
 */
export const getFeedbackStatus = async (user_id) => {
  return await ApiWrapper({
    url: `boost_profiles/${user_id}`,
    method: REQUEST.GET,
    config: { baseURL: API_URL.replace("/admission_portal", "") },
  });
};
