import { ApiWrapper, REQUEST } from ".";
export const getMyWebinarsData = async (school_id) => {
  return await ApiWrapper({
    url: `webinars/my_webinars_list?school_id=${school_id}`,
    method: REQUEST.GET,
  });
};

export const getWebinarsData = async (
  search = "",
  category = [],
  school_id
) => {
  let query = "";

  if (search !== "" && category.length !== 0) {
    let categoryQuery = ``;
    for (let i = 0; i < category.length; i++) {
      categoryQuery += `&category_id[]=${category[i]}`;
    }
    query = `&search=${search}${categoryQuery}`;
  } else if (search === "" && category.length !== 0) {
    let categoryQuery = ``;
    for (let i = 0; i < category.length; i++) {
      categoryQuery += `&category_id[]=${category[i]}`;
    }
    query = `${categoryQuery}`;
  } else if (search !== "" && category.length === 0) {
    query = `&search=${search}`;
  }

  return await ApiWrapper({
    url: `webinars?school_id=${school_id}${query}`,
    method: REQUEST.GET,
  });
};

export const getUpcomingWebinars = async (school_id) => {
  return await ApiWrapper({
    url: `webinars/get_upcoming_webinars?school_id=${school_id}`,
    method: REQUEST.GET,
  });
};
