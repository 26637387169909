import React, { memo, useState, useEffect } from "react";
import { useFormik } from "formik";

import { createModuleStyleExtractor } from "../../../utils/css";
import { Button } from "../../atoms/Button";
import Card from "../../atoms/Card/Card";
import { Modal } from "../../atoms/Modal/Modal";
import { ModelHeader } from "../../atoms/Modal/ModelHeader/ModelHeader";
import { ModalBody } from "../../atoms/Modal/ModalBody/ModalBody";
import MuiSelect from "../../atoms/MuiSelect/MuiSelect";
import MuiTextbox from "../../atoms/MuiTextbox/MuiTextbox";
import { getSelectOptions } from "../../../utils/noop";
import CustomDropzone from "../../atoms/CustomDropzone/CustomDropzone";
import toastify from "../../../utils/toast";
import { contactUs } from "../../../api/helpcenter";
import styles from "./ContactUsCard.module.scss";

const cx = createModuleStyleExtractor(styles);

const issueOptions = getSelectOptions([
  "Technical Support",
  "Interstride Services",
  "Report a Bug",
  "Other Issues",
]);

const ContactUsCard = () => {
  const [showModal, setShowModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  // Form Submission
  const handleSave = async () => {
    setSubmitting(true);
    const formData = new FormData();
    formData.append("contact_us[topic]", formik.values.topic);
    formData.append("contact_us[comment]", formik.values.comment);
    formik.values.issue_image !== "" &&
      formData.append("contact_us[issue_image]", formik.values.issue_image);
    try {
      const response = await contactUs(formData);
      if (response && response.success) {
        toastify(response.message);
        handleCancel();
      } else {
        toastify("Oops! something went wrong. Please try again");
        setSubmitting(false);
      }
    } catch (error) {
      console.error(error);
      toastify("Oops! something went wrong. Please try again");
      handleCancel();
    }
  };

  // Validation
  const validate = (values) => {
    const errors = {};
    // Topic
    if (Object.keys(values.topic).length === 0) {
      errors.topic = "Please select an issue";
    }
    // Comment
    if (values.comment === "") {
      errors.comment = "Please tell us more about the issue";
    }
    return errors;
  };

  // Formik Hook
  const formik = useFormik({
    initialValues: {
      topic: {},
      comment: "",
      issue_image: "",
    },
    validate,
    onSubmit: handleSave,
  });

  const handleFileUpload = (file) => {
    formik.setFieldValue("issue_image", file[0]);
  };

  const handleCancel = () => {
    setShowModal(false);
    setSubmitting(false);
    formik.values.topic = {};
    formik.values.comment = "";
    formik.values.issue_image = "";
    formik.setErrors({});
  };

  // Reset for values upon close
  useEffect(() => {
    if (!showModal) {
      handleCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  return (
    <>
      <div className={cx("m-contact-us-card")}>
        <Card>
          <div className={cx("m-contact-us-card__title")}>
            <h3>Still got questions?</h3>
          </div>

          <div className={cx("m-contact-us-card__subtitle")}>
            <span>
              We’re here to clear up any questions you may have about the
              platform.
            </span>
          </div>

          <div className={cx("m-contact-us-card__button")}>
            <Button onClick={() => setShowModal(true)}>
              Contact Interstride
            </Button>
          </div>
        </Card>
      </div>
      {/* Modal */}
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        centerAlign={false}
      >
        <ModelHeader title="Contact us" />
        <ModalBody>
          <div className={cx("m-contact-us-modal-body")}>
            <p className={cx("m-contact-us-modal-body__descriptor")}>
              Describe the issue with all the details, or connect with us at the{" "}
              <a href="mailto:support@interstride.com">
                support@interstride.com
              </a>{" "}
              email address.
            </p>
            <div className={cx("m-contact-us-modal-body__form")}>
              <form onSubmit={formik.handleSubmit}>
                <div className={cx("m-contact-us-modal-body__form__body")}>
                  <div
                    className={cx("m-contact-us-modal-body__form__body__issue")}
                  >
                    <MuiSelect
                      name="topic"
                      onBlur={formik.handleBlur}
                      label="Select an issue*"
                      placeholder="Select topic"
                      value={
                        issueOptions
                          ? issueOptions.find(
                              (option) => option.value === formik.values.topic
                            )
                          : ""
                      }
                      options={issueOptions}
                      onChange={(item) =>
                        formik.setFieldValue("topic", item.value)
                      }
                      error={
                        formik.touched.topic && formik.errors.topic
                          ? formik.errors.topic
                          : ""
                      }
                    />
                  </div>
                  <div
                    className={cx(
                      "m-contact-us-modal-body__form__body__comment"
                    )}
                  >
                    <MuiTextbox
                      name="comment"
                      onBlur={formik.handleBlur}
                      maxLength={500}
                      label="Tell us more*"
                      value={formik.values.comment}
                      onChange={(event) =>
                        formik.setFieldValue("comment", event.target.value)
                      }
                      isMultiline
                      rows={1}
                      placeholder="Describe the problem here. Please share details about your device and browser to speed up the process."
                      error={
                        formik.touched.comment && formik.errors.comment
                          ? formik.errors.comment
                          : ""
                      }
                    />
                  </div>
                  <div
                    className={cx(
                      "m-contact-us-modal-body__form__body__file_upload"
                    )}
                  >
                    <CustomDropzone
                      handleUploadFile={handleFileUpload}
                      textOne="Drag an image here"
                      textTwo="Upload an Image"
                    />
                    {formik.values.issue_image && (
                      <p>{formik.values.issue_image.path}</p>
                    )}
                  </div>
                  <div
                    className={cx(
                      "m-contact-us-modal-body__form__body__submit_button"
                    )}
                  >
                    <Button type="submit" isSubmitting={submitting}>
                      Send
                    </Button>
                  </div>
                  <div
                    className={cx(
                      "m-contact-us-modal-body__form__body__cancel"
                    )}
                  >
                    <span onClick={handleCancel}>Cancel</span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
export default memo(ContactUsCard);
