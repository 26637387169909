import React from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./ToggleSwitch.module.scss";

const cx = createModuleStyleExtractor(styles);

export default function ToggleSwitch({
  isChecked,
  setIsChecked,
  isDisabled,
  label,
}) {
  return (
    <div
      className={cx([
        "a-toggle-switch-wrapper",
        ...(isDisabled ? ["a-toggle-switch-wrapper--disabled"] : []),
      ])}
    >
      <label className={cx("a-toggle-switch")}>
        <input
          className={cx("a-toggle-switch-input")}
          type="checkbox"
          checked={isChecked}
          onChange={() => setIsChecked((value) => !value)}
          disabled={isDisabled}
        />
        <span className={cx(["a-toggle-switch-slider", "round"])}></span>
      </label>
    </div>
  );
}
