import React, { useEffect, useRef } from "react";

import { COURSES_LIST, MASTERCLASS_MODULES } from "../../../utils/constants";

const CourseIframe = ({ coursesList, activeCourse }) => {
  const ref = useRef(null);

  useEffect(() => {
    let iframe = document.getElementById("iframeTarget");
    let iframeWin = iframe.contentWindow || iframe.contentDocument.parentWindow;
    if (iframeWin.document.body)
      // iframe.height = window.screen.width > 985 ? window.screen.height - 480 : window.screen.height - 360
      iframe.width =
        window.screen.width > 985
          ? window.screen.width - 90
          : window.screen.width - 30;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getCourseUrl = () => {
    const extractedUrl = coursesList.find(
      (course) => course.id === activeCourse
    )?.url;
    if (extractedUrl === COURSES_LIST[0].url) {
      // allow masterclass modules
      return MASTERCLASS_MODULES[0].url;
    }
    return extractedUrl;
  };

  return (
    <div className="course-iframe-wrapper">
      <iframe
        title={"courses"}
        src={getCourseUrl() ?? MASTERCLASS_MODULES[0]?.url}
        style={{
          border: 0,
          width: "100%",
          height: "calc(100vh - 200px)",
        }}
        id="iframeTarget"
        ref={ref}
      />
    </div>
  );
};
export default CourseIframe;
