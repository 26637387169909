import React, { memo } from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { Grid } from "@mui/material";

import ServiceCard from "../ServiceCard/ServiceCard";
import { saveService } from "../../../api/studentServices";
import toastify from "../../../utils/toast";
import styles from "./SavedStudentServices.module.scss";
import { createModuleStyleExtractor } from "../../../utils/css";

import NoContentIllustration from "../../../assets/images/discover_no_people.svg";

const cx = createModuleStyleExtractor(styles);

const SavedStudentServices = ({
  showLoader = false,
  savedServiceList,
  refreshSavedServices = () => {},
}) => {
  // Handler for favorite and un favorite service
  const handleFavoriteClick = async (deal_id, like) => {
    try {
      const response = await saveService(deal_id, like);
      if (response.success) {
        toastify(response.message);
        refreshSavedServices();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={cx("m-saved-services")}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className={cx("m-saved-services__content")}>
            {showLoader ? (
              <div className={cx("m-saved-services__content__loader")}>
                {[...Array(4)].map((_, index) => (
                  <ShimmerThumbnail width={267} height={345} key={index} />
                ))}
              </div>
            ) : savedServiceList && savedServiceList.length > 0 ? (
              <div className={cx("m-saved-services__content__item")}>
                {savedServiceList.map((data, index) => (
                  <ServiceCard
                    key={index}
                    data={data.deal || {}}
                    isFavourite={true}
                    onClickStar={handleFavoriteClick}
                    label
                  />
                ))}
              </div>
            ) : (
              <div className={cx("m-saved-services__content__placeholder")}>
                <img src={NoContentIllustration} alt="no-services" />
                <h2>Looks like there are no saved services</h2>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default memo(SavedStudentServices);
