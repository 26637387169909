import React, { memo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ShimmerCategoryItem } from "react-shimmer-effects";
import { ClickAwayListener } from "@mui/material";

import { fetchConversation } from "../../../api/chat";
import { useSearchDebounce } from "../../../hooks/useSearchMessage";
import {
  setConversationInformation,
  setCurrentConversationId,
  setCurrentSearchMessageItem,
  bulkUpdate,
} from "../../../redux/reducers/chat/reducers";
import { getConversationByConversationID } from "../../../redux/reducers/chat/selectors";
import { renderTimestamp } from "../../../utils/common";
import { createModuleStyleExtractor } from "../../../utils/css";
import MuiTextbox from "../../atoms/MuiTextbox/MuiTextbox";
import { CircularAvatar } from "../../atoms/CircularAvatar/CircularAvatar";
import styles from "./ChatSearch.module.scss";

import CloseIcon from "../../../assets/Icons/CloseIcon";

const cx = createModuleStyleExtractor(styles);
const maxSearchTextLength = 60;

const ChatSearch = ({ isMobile }) => {
  const dispatch = useDispatch();
  const chatStore = useSelector((store) => store.chatStore);
  const { activeConversation, currentSearchMessageItem } = chatStore || {};
  const currentConversation = useSelector((store) =>
    getConversationByConversationID(store.chatStore, activeConversation)
  );
  const [page, setCurrentPage] = useState(1);
  //while typing
  const {
    searchQuery,
    setSearchQuery,
    isLoading,
    searchResults,
    loaded,
    searchedConversations,
  } = useSearchDebounce({
    delay: 1000,
    current_page: page,
  });

  const [cache, setCache] = useState({});
  const _getDeliveryTime = (deliveryTime) => {
    if (cache[deliveryTime]) {
      return cache[deliveryTime];
    }
    const response = deliveryTime ? renderTimestamp(deliveryTime) : "";
    setCache({
      ...cache,
      [deliveryTime]: response,
    });
    return response;
  };

  useEffect(() => {
    if (currentSearchMessageItem?.conversation_id === activeConversation) {
      if (currentConversation) {
        const messageItem = currentConversation?.messages.find(
          (message) => message.id === currentSearchMessageItem.id
        );
        if (messageItem) {
          //ready to move scroller
          const element = document.getElementById(
            `single_chat_item_id_${messageItem.id}`
          );
          if (element) {
            element.scrollIntoView({ behavior: "auto" });
            setTimeout(() => {
              // setCurrentSearchMessageItem(null);
            }, 3000);
          }
        }
      }
    }
  }, [currentSearchMessageItem, activeConversation, currentConversation]);

  const onClearSearch = () => {
    setSearchQuery("");
    setCurrentPage(1);
  };
  const onClickLoadMore = (page) => {
    setCurrentPage(page);
  };
  const doFetchConversationMessages = async (item) => {
    const { conversation_id, id } = item;
    try {
      const response = await fetchConversation(conversation_id, {
        id_gt_inclusive: id,
      });
      const { data } = response || {};
      if (data) {
        dispatch(setCurrentSearchMessageItem(item));
        dispatch(
          setConversationInformation({ conversation_id, conversation: data })
        );

        if (activeConversation !== conversation_id) {
          dispatch(setCurrentConversationId(conversation_id));
        }

        onClearSearch();
      }
    } catch (error) {}
  };
  const onItemClicked = (item) => {
    const { conversation_id, id } = item;

    dispatch(setCurrentSearchMessageItem(null));

    const conversation = getConversationByConversationID(
      chatStore,
      conversation_id
    );

    if (isMobile) {
      dispatch(
        bulkUpdate({
          isConversationView: true,
          isChatPreview: false,
        })
      );
    }

    //check if conversation already exists
    if (conversation) {
      // if message already exists
      const { messages } = conversation || {};

      const _message = messages?.find((message) => message.id === id) || null;
      if (!_message) {
        //call API
        doFetchConversationMessages(item);
        return;
      }
      /**
       * if id_gt then return all messages from latest to the id_gt
       * update the pagination based on total messages and messages per page
       * for example if per page limit is 20 messages and total messages are 100
       * with id_gt it returns  80 messages
       * it should say that current page = 1 next_page = 2
       */
      if (activeConversation !== conversation_id) {
        dispatch(setCurrentConversationId(conversation_id));
      }
      dispatch(setCurrentSearchMessageItem(item)); // added inside state
      // set conversation Id if current and this not equal
      // scroll to that specific message
      onClearSearch();
      return;
    }
    // otherwise have to call API. Strees starts here 🤧
    doFetchConversationMessages(item);
    onClearSearch();
  };

  const isDropdownOpen =
    (!isLoading || page > 1) &&
    searchResults &&
    (searchResults?.messages?.length > 0 || searchedConversations?.length > 0);

  return (
    <div className={cx("m-search-container")}>
      <ClickAwayListener onClickAway={() => onClearSearch()}>
        <div className={cx("m-search-container__form")}>
          <MuiTextbox
            onChange={(e) => setSearchQuery(e.target.value)}
            name={"search"}
            value={searchQuery}
            placeholder={"Search messages"}
            className={"a-chat-search-input"}
          />
          {searchQuery && (
            <div className={cx("m-search-container__form__close-icon-wrapper")}>
              <CloseIcon onClick={() => onClearSearch()} />
            </div>
          )}
          {isLoading && searchQuery?.length > 0 && (
            <div className={cx("loading-container")}>
              {Array.from(Array(2).keys()).map((_, index) => (
                <ShimmerCategoryItem key={index} />
              ))}
            </div>
          )}
          {!isLoading &&
            loaded &&
            searchResults?.messages?.length === 0 &&
            searchResults?.conversations?.length === 0 && (
              <div
                className={cx([
                  "dropdown-container",
                  "nempty-message-container",
                ])}
              >
                <div className={cx("dropdown-container__content")}>
                  Oops! No Results Found
                </div>
              </div>
            )}
          {isDropdownOpen && (
            <div className={cx("dropdown-container")}>
              <div
                className={cx([
                  "dropdown-container__content",
                  "result-search-message-div",
                ])}
              >
                {searchedConversations?.length > 0 && (
                  <div className={cx("content-title-container")}>People</div>
                )}
                {searchedConversations?.length > 0 ? (
                  searchedConversations?.map((item) => (
                    <div
                      className={cx(["content-container", "people-container"])}
                      onClick={() => onItemClicked(item)}
                      key={item?.id}
                    >
                      <div className={cx("chat-user-list-item")}>
                        <CircularAvatar
                          src={item.user_image}
                          size={"30"}
                          name={item.title}
                          round={true}
                          wrapperClass={cx(
                            "chat-user-list-item__avatar-wrapper"
                          )}
                        />
                        <h4 className={cx("title")}>
                          <span>{item.title}</span>
                        </h4>
                      </div>
                    </div>
                  ))
                ) : (
                  <></>
                )}
                {searchResults?.messages?.length > 0 && (
                  <div className={cx("content-title-container")}>Messages</div>
                )}
                {searchResults?.messages?.map((item) => (
                  <div
                    className={cx("content-container")}
                    onClick={() => onItemClicked(item)}
                    key={item?.id}
                  >
                    <div className={cx("content-container__message")}>
                      <span>
                        {item.message.length > maxSearchTextLength
                          ? item.message.slice(0, maxSearchTextLength) + "..."
                          : item.message}
                      </span>
                    </div>
                    <div className={cx("content-container__title")}>
                      <span>
                        {item?.sender_name || ""}{" "}
                        <span className={cx("divider")}>|</span>
                        {cache[item.send_at] || _getDeliveryTime(item.send_at)}
                      </span>
                    </div>
                  </div>
                ))}
                {searchResults?.next_page && (
                  <div
                    id={"search-load-more"}
                    className={cx("load-more-search-messages")}
                    onClick={() => onClickLoadMore(searchResults.next_page)}
                  >
                    <span>Load more</span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </ClickAwayListener>
    </div>
  );
};
export default memo(ChatSearch);
