import React, { memo } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import FacebookIcon from "../../../assets/Icons/FacebookIcon.js";
import SocialIcon from "../SocialIcon/SocialIcon";
import { FACEBOOK_CLIENT_ID } from "../../../config/config";

const AuthFacebook = ({ callback = () => {} }) => {
  return (
    <FacebookLogin
      appId={FACEBOOK_CLIENT_ID}
      autoLoad={false}
      fields="id,email,picture,first_name,last_name"
      callback={callback}
      render={(renderProps) => (
        <SocialIcon text={"Facebook"} icon={<FacebookIcon />} onClick={renderProps.onClick} />
      )}
    />
  );
};
export default memo(AuthFacebook);
