import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchConversation } from "../api/chat";
import { updateConversationInformation } from "../redux/reducers/chat/reducers";

export const usePaginateConversation = (conversationId, options) => {
  const dispatch = useDispatch();
  const [loading, setIsLoading] = useState(false);
  const [conversation, setConversation] = useState(null);
  const loadConversations = async () => {
    setIsLoading(true);
    try {
      const response = await fetchConversation(conversationId, options);
      if (response) {
        const { data } = response;
        const { messages, next_page, prev_page, total_pages, current_page } =
          data || {};
        if (data && messages) {
          dispatch(
            updateConversationInformation({
              conversation_id: conversationId,
              messages,
              pagination: {
                next_page,
                prev_page,
                total_pages,
                current_page,
              },
            })
          );
          setConversation(response.data);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (loading) return;
    if (!options || Object.keys(options).length === 0) return;
    loadConversations();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, conversationId]);

  return {
    pagination: loading,
    conversation,
  };
};
