import React, { memo, useState } from "react";

import { Col } from "../../helpers/Grid/Col";
import { Row } from "../../helpers/Grid/Row";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./PrivacyPolicy.module.scss";
import Categories from "../../atoms/Categories/Categories";
import { PRIVACY_POLICY } from "../../../utils/constants";
import PrivacyPolicyDetails from "../../molecules/PrivacyPolicyDetails/PrivacyPolicyDetails";
// import ContactUsCard from "../../molecules/ContactUsCard/ContactUsCard";

import FilterIcon from "../../../assets/Icons/FilterIcon";

const cx = createModuleStyleExtractor(styles);

const PrivacyPolicy = () => {
  const [selectedCategory, setSelectedCategory] = useState(PRIVACY_POLICY[0]);
  const [openFilter, toggleFilter] = useState(false);

  return (
    <div className={cx("o-privacy-policy")}>
      <Row className={"two-section-row-with-spacing"}>
        <Col xs={1} sm={1} md={1} lg={1} />
        <Col xs={3} sm={3} md={3} lg={3}>
          <div
            className={`collapsible-section ${
              openFilter ? "collapsible-section--open" : ""
            }`}
          >
            <div
              className={cx("o-privacy-policy__header")}
              id={"collapsible-section-header"}
            >
              <h2>Privacy Policy</h2>
              <a
                href="/#"
                onClick={(e) => {
                  e?.preventDefault && e.preventDefault();
                  toggleFilter(!openFilter);
                }}
                id="collapsible-section-header-icon"
              >
                <FilterIcon fill={openFilter} />
              </a>
            </div>
            <div id="collapsible-section-search-filter">
              <Categories
                data={PRIVACY_POLICY}
                selectedCategoryId={selectedCategory.id}
                onCategorySelect={setSelectedCategory}
                toggleFilter={toggleFilter}
              />
            </div>
          </div>
        </Col>
        <Col xs={7} sm={7} md={7} lg={7}>
          <PrivacyPolicyDetails selectedCategory={selectedCategory} />
        </Col>
        <Col xs={1} sm={1} md={1} lg={1} />
      </Row>
    </div>
  );
};

export default memo(PrivacyPolicy);
