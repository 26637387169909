import React, { memo } from "react";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./ListingEffect.module.scss";

import { ShimmerCategoryItem } from "react-shimmer-effects";

import { Col } from "../../helpers/Grid/Col";
import Card, { CardModifier } from "../Card/Card";
import { Row } from "../../helpers/Grid/Row";

const cx = createModuleStyleExtractor(styles);

const ListingEffect = ({
  count = 3,
  styles = {},
  hasImage = true,
  imageWidth = 90,
  imageHeight = 90,
  cta = false,
  sideSpace = true,
  modifier,
}) => {
  const cssStyles = {
    backgroundColor: "#fff",
    marginBottom: "10px",
    borderRadius: "8px",
    padding: "20px",
    ...styles,
  };

  const middleSpace = sideSpace ? 10 : 12;

  return (
    <Row>
      {sideSpace && (
        <Col
          xs={1}
          sm={1}
          md={1}
          lg={1}
          // className={cx("a-card", modifier ? { [modifier]: true } : {})}
        />
      )}

      <Col xs={middleSpace} sm={middleSpace} md={middleSpace} lg={middleSpace}>
        <div className={cx("o-notifications-listing-wrapper")}>
          {Array.from(Array(count).keys()).map((_, index) => (
            <Card key={index} modifier={CardModifier.LISTING_EFFECT}>
              <div style={cssStyles}>
                <ShimmerCategoryItem
                  hasImage={hasImage}
                  imageType="thumbnail"
                  imageWidth={imageWidth}
                  imageHeight={imageHeight}
                  text
                  cta={cta}
                />
              </div>
            </Card>
          ))}
        </div>
      </Col>

      {sideSpace && <Col xs={1} sm={1} md={1} lg={1} />}
    </Row>
  );
};

export default memo(ListingEffect);
