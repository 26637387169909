import React from "react";

import Card from "../../../atoms/Card/Card";
import { Button } from "../../../atoms/Button";
import { CircularAvatar } from "../../../atoms/CircularAvatar/CircularAvatar";

const PendingRequest = ({ list = [], acceptRejectRequest = () => {} }) => {
  const renderRequest = (item, index) => {
    return (
      <div className="request">
        <CircularAvatar
          src={item.image}
          name={item.name}
          alt={`${item.name}'s picture`}
        />
        <div className="request-header">
          <div className="user-title">{item.name}</div>
          <div className="panel-sub-title">{`${item.major} | ${item.graduation_year}`}</div>
        </div>
        <div className="request-actions">
          <Button
            onClick={() =>
              acceptRejectRequest({
                request_state: "decline",
                topic_id: item.topic_id,
                topic_follower_id: item.id,
              })
            }
          >
            Decline
          </Button>
          <Button
            onClick={() =>
              acceptRejectRequest({
                request_state: "accept",
                topic_id: item.topic_id,
                topic_follower_id: item.id,
              })
            }
          >
            Accept
          </Button>
        </div>
      </div>
    );
  };

  return (
    list?.length > 0 && (
      <Card className="request-box">
        {list.map((item, index) => renderRequest(item, index))}
      </Card>
    )
  );
};

export default PendingRequest;
