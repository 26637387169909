import React, { useState, useEffect } from "react";
import { debounce } from "lodash";
import { ClickAwayListener } from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { ShimmerCategoryItem } from "react-shimmer-effects";

import toastify from "../../../../utils/toast";
import { Button } from "../../../atoms/Button";

import { capitalizeFirstLetterOnType } from "../../../../utils/common";
import MuiTextbox from "../../../atoms/MuiTextbox/MuiTextbox";
import RecommendationsDetails from "../Utility/RecommendationsDetails";
import {
  getDiscoverTopicsByNameAPI,
  followUnfollowTopicAPI,
  getUserByIdAPI,
  getRecommendedTopicsAPI,
} from "../../../../api/community";
import Card from "../../../atoms/Card/Card";
import { createModuleStyleExtractor } from "../../../../utils/css";
import { CircularAvatar } from "../../../atoms/CircularAvatar/CircularAvatar";
import styles from "./DiscoverTopic.module.scss";
import EmptyListWithImage from "../../../atoms/EmptyListWithImage/EmptyListWithImage";

import EmptyImage from "../../../../assets/svg/discover-community-no-data.svg";
import NoUserImage from "../../../../assets/svg/no-user.svg";
import FaceliftSearchIcon from "../../../../assets/Icons/FaceliftSearchIcon";

const cx = createModuleStyleExtractor(styles);

const DiscoverTopic = () => {
  const { viewTopic } = useSelector((store) => store.communityState);
  const { selectedSchool = {} } = useSelector((state) => state.userState);
  const selectedSchoolId = selectedSchool?.id || null;

  const [discoverTopics, setDiscoverTopics] = useState([]);
  const [topicTypeOptions, setTopicTypeOptions] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [topicType, setTopicType] = useState("");
  const [activeUser, setActiveUser] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [showUserPopup, setShowUserPopup] = useState({
    open: false,
    index: -1,
  });
  const [
    loadingGetDiscoverTopicsByNameAPI,
    setLoadingGetDiscoverTopicsByNameAPI,
  ] = useState(false);
  const [loadingGetRecommendedTopicsAPI, setLoadingGetRecommendedTopicsAPI] =
    useState(false);
  const [loadingFollowUnfollowTopicAPI, setLoadingFollowUnfollowTopicAPI] =
    useState(false);
  const [activeListItemIndex, setActiveListPanelIndex] = useState(-1);

  const callGetDiscoverTopicsByNameAPI = async ({
    search_keyword,
    topic_type,
  }) => {
    setLoadingGetDiscoverTopicsByNameAPI(true);
    const response = await getDiscoverTopicsByNameAPI(
      search_keyword,
      topic_type,
      null,
      selectedSchoolId
    );
    setLoadingGetDiscoverTopicsByNameAPI(false);
    if (response.success && response.data) {
      setDiscoverTopics(response?.data?.recommended_topics);
      setTopicTypeOptions([...response?.data?.topic_type]);
    }
  };

  const callGetRecommendedTopicsAPI = async () => {
    setLoadingGetRecommendedTopicsAPI(true);
    const response = await getRecommendedTopicsAPI(selectedSchoolId);
    setLoadingGetRecommendedTopicsAPI(false);
    if (response.success && response.data) {
      setDiscoverTopics(response?.data.recommended_topics);
    }
  };

  const callFollowUnfollowTopicAPI = async (topic_id = null) => {
    setLoadingFollowUnfollowTopicAPI(true);
    const response = await followUnfollowTopicAPI(topic_id, selectedSchoolId);
    setLoadingFollowUnfollowTopicAPI(false);
    if (response.success) {
      toastify(response?.message);
      fetchTopics();
      setSelectedTopic(null);
    } else {
      toastify(response?.message, "error");
      setSelectedTopic(null);
    }
  };

  const fetchTopics = ({
    sort = "name",
    search_keyword = searchKeyword,
    topic_type = topicType,
  } = {}) => {
    if (sort === "name") {
      callGetDiscoverTopicsByNameAPI({ search_keyword, topic_type });
    } else {
      callGetRecommendedTopicsAPI();
    }
  };

  const followTopic = (topicId = null) => {
    setSelectedTopic(topicId);
    callFollowUnfollowTopicAPI(topicId);
  };

  const handleSearchChange = debounce((value) => {
    fetchTopics({ search_keyword: value });
    setSearchKeyword(value);
  }, 500);

  const onContactInfoUserClick = async (userId = null, index = -1) => {
    const response = await getUserByIdAPI(userId);
    if (response.success) {
      setActiveUser(response.data.user_info);
      setShowUserPopup({
        open: true,
        index,
      });
    }
  };

  const handleClose = (e) => {
    if (e?.stopPropagation) e.stopPropagation();
    setShowUserPopup({
      open: false,
      index: -1,
    });
  };

  const clearFilters = () => {
    setSearchKeyword("");
    setTopicType("");
    setTimeout(() => {
      fetchTopics({ search_keyword: "", topic_type: "" });
    }, 1000);
  };

  useEffect(() => {
    if (viewTopic?.topic_name?.length > 0) {
      setSearchKeyword(viewTopic?.topic_name);
      fetchTopics({ search_keyword: viewTopic?.topic_name });
    } else {
      fetchTopics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={cx("discover-page")}>
      <div className={cx("discover-page__search")}>
        <div className={cx("discover-page__search__box")}>
          <MuiTextbox
            name="search_keyword"
            type="text"
            placeholder="Search keyword"
            label=""
            value={searchKeyword}
            onChange={(obj) => setSearchKeyword(obj.target.value)}
            onEnterKey={() => handleSearchChange(searchKeyword)}
          />
          <a
            className={cx("discover-page__search__box__button")}
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              handleSearchChange(searchKeyword);
            }}
          >
            <FaceliftSearchIcon />
          </a>
        </div>
        {topicTypeOptions?.length > 0 && (
          <div className={cx("discover-page__search__filter")}>
            <label>Filter</label>
            <div className={cx("discover-page__search__filter__content")}>
              {topicTypeOptions.map((item, index) => (
                <a
                  key={index}
                  href="/#"
                  onClick={(e) => {
                    e.preventDefault();
                    if (topicType === item) {
                      setTopicType("");
                      fetchTopics({ topic_type: "" });
                    } else {
                      setTopicType(item);
                      fetchTopics({ topic_type: item });
                    }
                  }}
                  className={cx([
                    "discover-page__search__filter__content__item",
                    topicType === item
                      ? "discover-page__search__filter__content__item--active"
                      : "",
                  ])}
                >
                  {item}
                </a>
              ))}
            </div>
            {(topicType || searchKeyword) && (
              <div className={cx("discover-page__search__filter__clear")}>
                <Link
                  to="/#"
                  onClick={(e) => {
                    e.preventDefault();
                    clearFilters();
                  }}
                >
                  Clear all
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={cx("discover-page__listing")}>
        {loadingGetDiscoverTopicsByNameAPI || loadingGetRecommendedTopicsAPI ? (
          <>
            {Array.from(Array(10).keys()).map((_, index) => (
              <Card key={index}>
                <ShimmerCategoryItem
                  hasImage={true}
                  imageType="thumbnail"
                  imageWidth={90}
                  imageHeight={90}
                  text
                  cta={false}
                />
              </Card>
            ))}
          </>
        ) : discoverTopics?.length > 0 ? (
          <div className={cx("discover-page__listing__topics")}>
            {discoverTopics?.map((topic, index) => (
              <div
                className={cx([
                  "discover-page__listing__topics__item",
                  activeListItemIndex === index
                    ? "discover-page__listing__topics__item--active"
                    : "",
                ])}
                key={index}
                onClick={() => setActiveListPanelIndex(index)}
              >
                <Card>
                  <div
                    className={cx(
                      "discover-page__listing__topics__item__content"
                    )}
                  >
                    <CircularAvatar
                      alt={"user"}
                      round={true}
                      size={90}
                      src={topic?.topic_icon}
                      name={topic?.topic_name}
                    />
                    <div
                      className={cx(
                        "discover-page__listing__topics__item__content__middle"
                      )}
                    >
                      <p
                        className={cx(
                          "discover-page__listing__topics__item__content__middle__title"
                        )}
                      >
                        {topic?.topic_name}
                      </p>
                      <p
                        className={cx(
                          "discover-page__listing__topics__item__content__middle__description"
                        )}
                      >
                        {topic?.topic_description}
                      </p>
                      <div
                        className={cx(
                          "discover-page__listing__topics__item__content__middle__contact"
                        )}
                      >
                        {topic?.total_participants > 0 && (
                          <div
                            className={cx(
                              "discover-page__listing__topics__item__content__middle__contact__member"
                            )}
                          >
                            {`${topic.total_participants} ${
                              topic.total_participants > 1
                                ? "members"
                                : "member"
                            }`}
                          </div>
                        )}
                        <div
                          className={cx(
                            "discover-page__listing__topics__item__content__middle__type"
                          )}
                        >
                          <span>
                            {capitalizeFirstLetterOnType(
                              topic?.topic_type || ""
                            )}
                          </span>
                        </div>
                        <div
                          className={cx(
                            "discover-page__listing__topics__item__content__middle__author"
                          )}
                        >
                          <span>Created by</span>
                          <a
                            href="/#"
                            onClick={(e) => {
                              e.preventDefault();
                              onContactInfoUserClick(
                                topic.created_by_id,
                                index
                              );
                            }}
                          >
                            <CircularAvatar
                              src={topic.created_by_image || NoUserImage}
                              alt="user"
                              round={true}
                              size={25}
                            />
                            <span>{topic.created_by_name}</span>
                          </a>
                          {showUserPopup?.open &&
                            showUserPopup?.index === index && (
                              <ClickAwayListener
                                onClickAway={() => handleClose()}
                              >
                                <div
                                  className={cx(
                                    "discover-page__listing__topics__item__content__middle__author__popup"
                                  )}
                                >
                                  <RecommendationsDetails
                                    details={activeUser && activeUser}
                                    handleClose={handleClose}
                                  />
                                </div>
                              </ClickAwayListener>
                            )}
                        </div>
                      </div>
                    </div>
                    <div
                      className={cx([
                        "discover-page__listing__topics__item__content__action",
                        activeListItemIndex === index
                          ? "discover-page__listing__topics__item__content__action--active"
                          : "",
                      ])}
                    >
                      <Button
                        onClick={() => {
                          followTopic(topic.id);
                        }}
                        disabled={
                          topic && topic.following_status === "requested"
                            ? true
                            : false
                        }
                        aria-label={`${capitalizeFirstLetterOnType(
                          topic.following_status
                        )} ${topic.topic_name}`}
                        isSubmitting={
                          selectedTopic === topic.id &&
                          loadingFollowUnfollowTopicAPI
                        }
                      >
                        {capitalizeFirstLetterOnType(topic.following_status)}
                      </Button>
                    </div>
                  </div>
                </Card>
              </div>
            ))}
          </div>
        ) : (
          <EmptyListWithImage
            Image={EmptyImage}
            title="Looks like there is no Community with this search."
            subTitle="Please try another search or start the Community you’re looking for and invite others to join!"
          />
        )}
      </div>
    </div>
  );
};

export default DiscoverTopic;
