import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./NotificationsList.module.scss";
import { Button, ButtonModifier } from "../../atoms/Button";
import Notification from "../../atoms/Notification/Notification";
import { readNotification } from "../../../api/notification";
import { setSelectedSchool } from "../../../redux/reducers/userReducer";
import { isNull } from "../../../utils/validations";

import noNotificationImage from "../../../assets/images/f-NoNotifications.svg";

const cx = createModuleStyleExtractor(styles);

export const NotificationsModifier = {
  LARGE: "m-notifications-list--large",
  SMALL: "m-notifications-list--small",
};

export const NotificationsList = ({
  modifier = NotificationsModifier.LARGE,
  title = "",
  notifications = [],
  actionButton,
  onActionButtonClick = () => {},
  emptyMessage,
  showDelete = true,
  onDeleteClick = () => {},
  onViewClick = () => {},
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userState = useSelector((state) => state.userState);
  const selectedSchool = userState.selectedSchool;
  const schools = userState.schools;

  const readNotificationAndRedirect = async (
    notification,
    redirectURL,
    extraPayload = false
  ) => {
    const { id, school_id, type_id } = notification;
    const response = await readNotification(id);

    const notificationRedirectURL = `${redirectURL}${
      extraPayload && type_id ? `?notification_id=${type_id}` : ``
    }`;

    if (response.success) {
      if (!school_id || isNull(school_id)) {
        navigate(notificationRedirectURL);
        onViewClick(response?.data?.notification?.id);
      } else {
        if (selectedSchool && selectedSchool.id !== school_id) {
          const notificationSchool = schools.find(
            (school) => school.id === school_id
          );

          if (notificationSchool)
            dispatch(setSelectedSchool(notificationSchool));
        }

        navigate(notificationRedirectURL);
        onViewClick(response?.data?.notification?.id);
      }
    }
  };

  return (
    <div
      className={cx(
        "m-notifications-list",
        modifier
          ? {
              [modifier]: true,
            }
          : {}
      )}
    >
      {
        <div className={cx("m-notifications-list--content")}>
          <div className={cx("m-notifications-list--content--title")}>
            <h2>{title}</h2>
            {actionButton && notifications.length !== 0 && (
              <Button
                modifier={ButtonModifier.NOTIFICATIONS_CLEAR}
                onClick={onActionButtonClick}
              >
                {actionButton}
              </Button>
            )}
          </div>
          {notifications.length === 0 ? (
            <>
              {emptyMessage ? (
                <div className={cx("m-notifications-list__empty_message")}>
                  <h3>{emptyMessage}</h3>
                </div>
              ) : (
                <div className={cx("m-notifications-list__empty")}>
                  <img src={noNotificationImage} alt="Empty" />
                  <h3>You’re all caught up!</h3>
                </div>
              )}
            </>
          ) : (
            <div className={cx("")}>
              {notifications.map((notification, index) => (
                <Notification
                  key={index}
                  notification={notification}
                  onDeleteClick={onDeleteClick}
                  onViewClick={readNotificationAndRedirect}
                  showDelete={showDelete}
                  variant={
                    modifier === NotificationsModifier.SMALL ? "small" : "large"
                  }
                />
              ))}
            </div>
          )}
        </div>
      }
    </div>
  );
};
