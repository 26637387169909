import React, { memo } from "react";
import { createModuleStyleExtractor } from "../../../utils/css";
import { noop } from "../../../utils/noop";
import styles from "./Checkbox.module.scss";
const cx = createModuleStyleExtractor(styles);

const Checkbox = ({
  id,
  label,
  type = "text", // by default its text type
  value,
  name,
  disabled = false,
  onChange = noop,
  className = "",
  readonly = false,
  disableDefaultStyles = false, // in order to disable the default styling
  styles = {},
  error,
  ...extraProps
}) => {
  return (
    <div>
      <div className="field-group checkbox-group">
        <div>
          <input
            autoComplete={"off"}
            readOnly={readonly}
            id={id}
            disabled={disabled}
            className={cx([
              className,
              ...(disableDefaultStyles ? [] : ["a-checkbox__default"]),
              ...(disabled ? ["a-checkbox--disabled"] : []),
            ])}
            type="checkbox"
            value={value}
            name={name}
            style={styles}
            onChange={
              disabled
                ? noop
                : (ev) => {
                    onChange(ev);
                  }
            }
            {...extraProps}
          />
          <span className="checkmark"></span>
        </div>
        {label && <label>{label}</label>}
      </div>
      {error && <span className="p-error">{error}</span>}
    </div>
  );
};
export default memo(Checkbox);
