import NotificationSettingCard from "../../../atoms/NotificationSettingCard/NotificationSettingCard";

export default function ResourcesSettings({ onToggle }) {
  return (
    <NotificationSettingCard
      title={"New resources"}
      subTitle={"Receive alerts whenever a new resource is posted."}
      pushValue={"resource_push"}
      emailValue={"resource_email"}
      updateValue="resources"
      onToggle={onToggle}
    />
  );
}
