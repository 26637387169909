import React, { memo } from "react";
import Slider from "react-slick";
import { useSelector } from "react-redux";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./DashboardPromoteSlider.module.scss";
import Card from "../../atoms/Card/Card";
import DiscoverNetworkSlide from "../DiscoverNetworkSlide/DiscoverNetworkSlide";
// import MasterclassCourseSlide from "../MasterclassCourseSlide/MasterclassCourseSlide";

const cx = createModuleStyleExtractor(styles);

const DashboardPromoteSlider = () => {
  const {
    isProspect,
    permissions = {},
    selectedSchool = {},
  } = useSelector((state) => state.userState);
  const selectedSchoolName = selectedSchool?.name;
  const selectedSchoolApplyLink = selectedSchool?.apply_link;

  let settings = {
    accessibility: true,
    dots: true,
    arrows: false,
    infinite: true,
    speed: 2000,
    pauseOnHover: true,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const SLIDES = [
    // {
    //   customUI: <MasterclassCourseSlide />,
    // },
    {
      customUI: (
        <DiscoverNetworkSlide
          isProspect={isProspect}
          selectedSchoolName={selectedSchoolName}
          selectedSchoolApplyLink={selectedSchoolApplyLink}
        />
      ),
      permission: Boolean(selectedSchoolApplyLink || permissions["chat"]),
    },
  ];

  return (
    <div className={cx("m-dashboard-promote-slider")}>
      <div className="dashboard-promote-wrapper">
        <Slider {...settings}>
          {SLIDES.map((slide) => (
            <>
              {slide.permission === false ? null : (
                <div className={cx("m-dashboard-promote-slider__slide")}>
                  <Card>{slide.customUI}</Card>
                </div>
              )}
            </>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default memo(DashboardPromoteSlider);
