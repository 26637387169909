import NotificationSettingCard from "../../../atoms/NotificationSettingCard/NotificationSettingCard";

export default function StudentServicesSettings({ onToggle }) {
  return (
    <NotificationSettingCard
      title={"New services"}
      subTitle={"Receive alerts whenever a new service is posted."}
      pushValue={"service_push"}
      emailValue={"service_email"}
      updateValue="services"
      onToggle={onToggle}
    />
  );
}
