import React, { memo } from "react";

function DiscoverListIcon({ selected = false }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="17"
      viewBox="0 0 24 17"
      fill="none"
    >
      <path
        stroke={selected ? "#FFFFFF" : "var(--primary-font-color)"}
        stroke-width="1.5"
        stroke-linecap="round"
        d="M6.75 1.75h16.5m-16.5 7h16.5m-16.5 7h16.5"
      />
      <circle
        cx="1.5"
        cy="1.5"
        r="1.5"
        fill={selected ? "#FFFFFF" : "var(--primary-font-color)"}
      />
      <circle
        cx="1.5"
        cy="8.5"
        r="1.5"
        fill={selected ? "#FFFFFF" : "var(--primary-font-color)"}
      />
      <circle
        cx="1.5"
        cy="15.5"
        r="1.5"
        fill={selected ? "#FFFFFF" : "var(--primary-font-color)"}
      />
    </svg>
  );
}

export default memo(DiscoverListIcon);
