import React, { memo } from "react";

function PerspectiveCheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-152 -199)">
          <g transform="translate(152 199)">
            <rect
              width="20"
              height="20"
              x="0"
              y="0"
              fill="#FFF"
              fillOpacity="0.2"
              rx="10"
            ></rect>
            <path
              stroke="#FFF"
              strokeWidth="1.786"
              d="M6.42857143 10.3584149L8.81760924 12.1428571 13.5714286 7.14285714"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default memo(PerspectiveCheckIcon);
