import React, { useEffect } from "react";
import { ColorRing } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

import { createModuleStyleExtractor } from "../../../utils/css";
import { verifyEmail } from "../../../api/profile";
import toastify from "../../../utils/toast";
import styles from "./ConfirmEmail.module.scss";
import { ROUTES } from "../../../routes";
import { INTERSTRIDE_LOGO_URL } from "../../../utils/constants";

const cx = createModuleStyleExtractor(styles);

export default function ConfirmEmail() {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const data = searchParams.get("data") ?? "";
  const email = decodeURIComponent(searchParams.get("email")) ?? "";
  const navigationDelay = 4000; // milliseconds

  // On DOM load
  useEffect(() => {
    // Call API to verify email
    (async () => {
      try {
        const response = await verifyEmail(data);
        if (response.success) {
          // Navigate delay to improve UX
          setTimeout(() => {
            navigate(
              `${ROUTES.LOGIN}?verify-email=${encodeURIComponent(email)}`
            );
          }, navigationDelay);
          toastify("Email verified");
        } else {
          // Navigate delay to improve UX
          setTimeout(() => {
            navigate(ROUTES.LOGIN);
          }, navigationDelay);
          toastify(response.message);
        }
      } catch (error) {
        // Navigate delay to improve UX
        setTimeout(() => {
          navigate(ROUTES.LOGIN);
        }, navigationDelay);
        toastify("Something went wrong");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={cx("o-confirm-email-wrapper")}>
      <div className={cx("o-confirm-email-content-wrapper")}>
        <div className={cx("o-confirm-email-logo")}>
          <img src={INTERSTRIDE_LOGO_URL} alt="interstrideLogo" />
        </div>
        <ColorRing
          visible={true}
          height="60"
          width="60"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
        />
      </div>
    </div>
  );
}
