import React from "react";

import { Modal } from "../../atoms/Modal/Modal";
import { ModalBody } from "../../atoms/Modal/ModalBody/ModalBody";
import { ModelHeader } from "../../atoms/Modal/ModelHeader/ModelHeader";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./ResourceModal.module.scss";
import ReactPlayer from "react-player";

const cx = createModuleStyleExtractor(styles);

export default function ResourceModal({ state = {}, onClose = () => {} }) {
  return (
    <Modal show={state.showModel} centerAlign marginTop={10} onClose={onClose}>
      <ModelHeader
        title={
          state.title.length > 25
            ? state.title.slice(0, 25) + "..."
            : state.title
        }
      />
      <ModalBody>
        <div className={cx("m-resource-modal")}>
          {state.link_type === "PDF" ? (
            <div className={cx("m-resource-modal__pdf")}>
              {state.image !== "" ? (
                <iframe
                  // height="530"
                  aria-label="PDF"
                  src={state.image}
                  title="none"
                ></iframe>
              ) : (
                <h3>Oops, PDF is not available</h3>
              )}
            </div>
          ) : null}

          {state.link_type === "YouTube Video" && (
            <div className={cx("m-resource-modal__player")}>
              {state.link ? (
                <ReactPlayer
                  className={cx("m-resource-modal__player__react-player")}
                  url={state.link && state.link}
                  controls={true}
                  width="100%"
                  height="100%"
                />
              ) : (
                <h3>{`Oops, ${state.link_type} is not available`}</h3>
              )}
            </div>
          )}

          {state.link_type === "Interstride Video" && (
            <div className={cx("m-resource-modal__interstride-video")}>
              {state.image ? (
                <video controls autoPlay={true} muted controlsList="nodownload">
                  <source src={state.image && state.image} />
                </video>
              ) : (
                <h3>{`Oops, ${state.link_type} is not available`}</h3>
              )}
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
}
