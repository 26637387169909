import React from "react";
import ProspectSignInForm from "../ProspectSignInForm/ProspectSignInForm";
import BackIcon from "../../../assets/images/f-btn-arrow-gray.svg";
import styles from "./WidgetSignInForm.module.scss";
import { createModuleStyleExtractor } from "../../../utils/css";
import { noop } from "../../../utils/noop";

const cx = createModuleStyleExtractor(styles);

const WidgetSignInForm = ({
  onSubmit = noop,
  schoolData = {},
  goBackToLogin = noop,
  email = "",
  allowEmailEdit = false,
}) => {
  return (
    <div className={cx("widget_signin_form_container")}>
      <div className={cx("widget_signin_form_container__back-btn")}>
        <a
          href="/login"
          onClick={(e) => {
            e?.preventDefault && e.preventDefault();
            goBackToLogin();
          }}
        >
          <img src={BackIcon} id="BackArrow" width="18px" alt="Back arrow" />
          <span>Back</span>
        </a>
      </div>
      <ProspectSignInForm
        allowEmailEdit={allowEmailEdit}
        onSubmit={onSubmit}
        schoolData={schoolData}
        email={email}
        widgetOnboarding
      />
    </div>
  );
};

export default WidgetSignInForm;
