import NotificationSettingCard from "../../../atoms/NotificationSettingCard/NotificationSettingCard";

export default function WebinarsSettings({ onToggle }) {
  return (
    <NotificationSettingCard
      title={"New webinars"}
      subTitle={"Receive alerts whenever a new webinar is posted."}
      pushValue={"webinar_push"}
      emailValue={"webinar_email"}
      updateValue="webinars"
      onToggle={onToggle}
    />
  );
}
