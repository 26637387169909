import React, { memo } from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./DashboardSavedPostCard.module.scss";
import Card from "../../atoms/Card/Card";
import MenuDotsHorizontal from "../../../assets/Icons/MenuDotsHorizontal";
import linkArrow from "../../../assets/images/f-arrow.svg";
import savedImage from "../../../assets/images/f-saved.svg";

const cx = createModuleStyleExtractor(styles);

const DashboardSavedPostCard = ({ count, setPage = () => {} }) => {
  return (
    <div className={cx("m-dashboard-saved-post-card")}>
      {!count && count === 0 ? (
        <div className={cx("m-dashboard-saved-post-card__empty")}>
          <Card>
            <div className={cx("m-dashboard-saved-post-card__empty__title")}>
              No Saved Posts yet!
            </div>
            <div
              className={cx("m-dashboard-saved-post-card__empty__illustration")}
            >
              Click on the More icon to save a post so you can visit it later.
              <div
                className={cx(
                  "m-dashboard-saved-post-card__empty__illustration__right-arrow"
                )}
              >
                <img src={linkArrow} width="18px" alt="linkArrow" />
              </div>
              <div
                className={cx(
                  "m-dashboard-saved-post-card__empty__illustration__menu-icon"
                )}
              >
                <MenuDotsHorizontal />
              </div>
            </div>
          </Card>
        </div>
      ) : (
        <div
          className={cx("m-dashboard-saved-post-card__count")}
          onClick={(e) => {
            e?.preventDefault && e.preventDefault();
            setPage();
          }}
        >
          <Card>
            <div className={cx("m-dashboard-saved-post-card__count__title")}>
              <img src={savedImage} width="32px" alt="savedImage" />
              <span>Your Saved Posts ({count})</span>
            </div>
            <div className={cx("m-dashboard-saved-post-card__count__link")}>
              <a href="/saved-posts">
                <img src={linkArrow} width="18px" alt="linkArrow" />
              </a>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};
export default memo(DashboardSavedPostCard);
