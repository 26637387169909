import { createSlice } from "@reduxjs/toolkit";

import { communitiesTabOptions } from "../../utils/constants";

const initialState = {
  tab: communitiesTabOptions[0],
  activeTopicId: null,
  activeTopic: {},
  followedTopics: [],
  suggestedTopics: [],
  topicPosts: {},
  loadingTopicPosts: false,
  topicPendingRequest: [],
  viewTopic: null,
  newTopicDetail: {
    topicName: "",
    description: "",
    file: "",
    topicProfile: "",
    department: { value: "all", label: "All Departments" },
    topicType: true, // true - public , false - private
    selectedTagsForTopic: [],
  },
};

export const communityReducer = createSlice({
  name: "community",
  initialState,
  reducers: {
    resetStateTopics: () => initialState,
    changeTab: (state, action) => {
      state.tab = action.payload;
      if (action.payload === communitiesTabOptions[1]) {
        state.followedTopics = [];
        state.suggestedTopics = [];
        state.topicPosts = {};
      }
    },
    setActiveTopicId: (state, action) => {
      state.activeTopicId = action.payload;
      state.topicPosts = {}; // Resets topic posts for new data
    },
    setActiveTopic: (state, action) => {
      state.activeTopic = action.payload || {};
    },
    setTopicPosts: (state, action) => {
      let { topicPosts = {}, type = null } = action.payload;
      state.loadingTopicPosts = false;
      if (type === "feed" || type === "all-saved") {
        state.activeTopic = {};
        state.activeTopicId = null;
      }
      state.topicPosts = {
        ...topicPosts,
        type,
        posts:
          topicPosts?.current_page === 1
            ? topicPosts?.posts
            : [
                ...(state?.topicPosts?.posts || {}),
                ...(topicPosts?.posts || {}),
              ],
      };
    },
    setLoadingTopicPosts: (state, action) => {
      state.loadingTopicPosts = action.payload;
    },
    setFollowingAndSuggestedTopics: (state, action) => {
      state.followedTopics = action.payload.followedTopics;
      state.suggestedTopics = action.payload.suggestedTopics;
    },
    updateTopicFavouriteStatus: (state, action) => {
      // Find topic index
      const topic_index = state.followedTopics.findIndex(
        (topic) => topic.id === action.payload
      );

      if (topic_index !== -1) {
        let _followedTopics = [...state?.followedTopics];
        _followedTopics[topic_index].favourite =
          !_followedTopics[topic_index].favourite;

        state.followedTopics = _followedTopics;
      }
    },
    setTopicPendingRequest: (state, action) => {
      state.topicPendingRequest = action.payload.topic_followers || [];
    },
    managePendingRequest: (state, action) => {
      const topic_follower_obj = action.payload.topic_follower || null;
      if (topic_follower_obj?.id) {
        state.topicPendingRequest = state.topicPendingRequest.filter(
          (topic) => topic.id !== topic_follower_obj.id
        );
      }
    },
    // Inserts reactions data into topic posts object
    updateReactionsData: (state, action) => {
      let _topicPosts = state.topicPosts;

      action.payload.forEach((reaction) => {
        const indexOfPost = _topicPosts.posts.findIndex(
          (post) => post.id === reaction.id
        );
        if (indexOfPost !== -1) {
          _topicPosts.posts[indexOfPost].details = reaction;
        }
      });
      state.topicPosts = _topicPosts;
    },
    incrementCommentCount: (state, action) => {
      let _topicPosts = state.topicPosts;

      const index = _topicPosts.posts.findIndex(
        (topic) => topic.id === action.payload
      );
      if (index !== -1) _topicPosts.posts[index].details.comment_counts += 1;

      state.topicPosts = _topicPosts;
    },
    decrementCommentCount: (state, action) => {
      let _topicPosts = state.topicPosts;

      const index = _topicPosts.posts.findIndex(
        (topic) => topic.id === action.payload
      );
      if (index !== -1) _topicPosts.posts[index].details.comment_counts -= 1;

      state.topicPosts = _topicPosts;
    },
    updateTopicPostReactions: (state, action) => {
      let _topicPosts = state.topicPosts;
      const reactionData = action.payload;

      const index = _topicPosts.posts.findIndex(
        (topic) => topic.id === reactionData?.topic_post_id
      );

      if (index !== -1) {
        if (reactionData?.total_count === 0) {
          delete _topicPosts.posts[index].details.emojies[
            reactionData?.emoji_unicode
          ];
        } else {
          _topicPosts.posts[index].details.emojies[
            reactionData?.emoji_unicode
          ] = {
            count: reactionData?.total_count,
            emoji: reactionData?.emoji,
            emoji_symbol: reactionData?.emoji_symbol,
          };
        }
      }

      state.topicPosts = _topicPosts;
    },
    updateSavedPostStatus: (state, action) => {
      let _topicPosts = state.topicPosts;

      const index = _topicPosts.posts.findIndex(
        (topic) => topic.id === action.payload
      );

      if (index !== -1) {
        if (_topicPosts.posts[index].saved) {
          _topicPosts.saved_posts -= 1;
        } else {
          _topicPosts.saved_posts += 1;
        }
        _topicPosts.posts[index].saved = !_topicPosts.posts[index].saved;
      }

      state.topicPosts = _topicPosts;
    },
    toggleActiveTopicMuteStatus: (state, action) => {
      const _followedTopics = [...state?.followedTopics];

      const index = _followedTopics.findIndex(
        (topic) => topic.id === state.activeTopic.id
      );
      if (index !== -1) {
        _followedTopics[index].mute_topic = !_followedTopics[index].mute_topic;
      }

      state.activeTopic = {
        ...state?.activeTopic,
        mute_topic: !state?.activeTopic?.mute_topic,
      };
      state.followedTopics = _followedTopics;
    },
    setPreApiCallSavedTopic: (state, action) => {
      const type = action.payload || null;
      state.loadingTopicPosts = true;
      state.topicPosts = { ...(type ? { type } : {}) };
    },
    navigateSavedToFeedHandler: (state, action) => {
      state.loadingTopicPosts = true;
      state.topicPosts = {
        type: "feed",
      };
    },
    navigateSavedToFollowing: (state, action) => {
      state.loadingTopicPosts = true;
      state.topicPosts = {
        type: "topics",
      };
    },
    setNewTopicDetail: (state, action) => {
      state.newTopicDetail = {
        ...state.newTopicDetail,
        ...(action.payload || {}),
      };
    },
    handleCommunityHeaderClick: (state, action) => {
      // Reset state to show topic feed
      state.activeTopic = {};
      state.activeTopicId = null;
      state.viewTopic = null;
      state.tab = communitiesTabOptions[0];
      if (state.tab === communitiesTabOptions[1]) {
        state.topicPosts = {};
      }
    },
    handlePostTopicCloseUnfollow: (state, action) => {
      // Reset state to show topic feed
      // Remove closed or unfollowed topic from following Communities list
      state.activeTopic = {};
      state.activeTopicId = null;
      state.tab = communitiesTabOptions[0];
      state.viewTopic = null;
      state.followedTopics = state.followedTopics?.filter(
        (topic) => topic.id !== action.payload
      );
    },
    postViewSuggestedTopic: (state, action) => {
      state.tab = communitiesTabOptions[1];
      state.viewTopic = action.payload || {};
    },
  },
});

export const {
  resetStateTopics,
  changeTab,
  setActiveTopicId,
  setActiveTopic,
  setTopicPosts,
  setLoadingTopicPosts,
  updateTopicFavouriteStatus,
  setTopicPendingRequest,
  managePendingRequest,
  updateReactionsData,
  incrementCommentCount,
  decrementCommentCount,
  updateTopicPostReactions,
  updateSavedPostStatus,
  toggleActiveTopicMuteStatus,
  setPreApiCallSavedTopic,
  navigateSavedToFeedHandler,
  navigateSavedToFollowing,
  setNewTopicDetail,
  handleCommunityHeaderClick,
  handlePostTopicCloseUnfollow,
  postViewSuggestedTopic,
  setFollowingAndSuggestedTopics,
} = communityReducer.actions;

export default communityReducer.reducer;
