import React, { memo, useState, useEffect } from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";

import {
  getServiceCategories,
  getStudentServicesList,
} from "../../../api/widget";
import { isNull } from "../../../utils/validations";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./WidgetServices.module.scss";
import Card from "../../atoms/Card/Card";
import { Button, ButtonModifier } from "../../atoms/Button/Button";
import ListingEffect from "../../atoms/ListingEffect/ListingEffect";
import Categories from "../../atoms/Categories/Categories";

import EmptyStudentServicesIcon from "../../../assets/Icons/EmptyStudentServicesIcon";
import FilterIcon from "../../../assets/Icons/FilterIcon";

const cx = createModuleStyleExtractor(styles);

const WidgetServices = ({ schoolData = {}, onSignupClick = () => {} }) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(false);
  const [categoriesLoader, setCategoriesLoader] = useState(false);
  const [services, setServices] = useState([]);
  const [serviceLoader, setServiceLoader] = useState(true);
  const [openFilter, toggleFilter] = useState(false);

  const VISIBLE_RECORDS_COUNT = 5;

  useEffect(() => {
    const fetchServiceCategories = async () => {
      setCategoriesLoader(true);
      const response = await getServiceCategories(schoolData?.token);
      if (response.success) {
        const { categories = [] } = response.data;
        setCategories(categories);
        if (categories.length > 0) setSelectedCategory(categories[0]);
      } else {
        setCategories([]);
        setSelectedCategory(null);
      }
      setCategoriesLoader(false);
    };

    fetchServiceCategories();
  }, [schoolData?.token]);

  useEffect(() => {
    const fetchServices = async () => {
      setServiceLoader(true);
      const response = await getStudentServicesList(
        selectedCategory?.id,
        schoolData?.token
      );
      if (response.success) {
        setServices(response.data?.deals);
      } else {
        setServices([]);
      }
      setServiceLoader(false);
    };
    if (selectedCategory && !isNull(selectedCategory)) fetchServices();
  }, [selectedCategory, schoolData?.token]);

  const handleSignupClick = () => {
    onSignupClick("Get full access to all our services and support");
  };

  return (
    <div className={cx("m-widget-services")}>
      <div className={cx("m-widget-services__content")}>
        <div className={cx("m-widget-services__content__left")}>
          {categoriesLoader ? (
            <>
              {Array.from(Array(5).keys()).map((_, index) => (
                <ShimmerThumbnail
                  key={index}
                  height={60}
                  width={"100%"}
                  rounded
                />
              ))}
            </>
          ) : (
            <div
              className={`collapsible-section ${
                openFilter ? "collapsible-section--open" : ""
              }`}
            >
              <div id={"collapsible-section-header"}>
                <h2 className={cx("m-widget-services__content__left__title")}>
                  Categories
                </h2>
                <a
                  href="/#"
                  onClick={(e) => {
                    e?.preventDefault && e.preventDefault();
                    toggleFilter(!openFilter);
                  }}
                  id="collapsible-section-header-icon"
                >
                  <FilterIcon fill={openFilter} />
                </a>
              </div>
              <div
                id="collapsible-section-search-filter"
                className={cx("m-widget-services__content__left__categories")}
              >
                <Categories
                  type="studentServices"
                  data={categories}
                  selectedCategoryId={
                    selectedCategory && !isNull(selectedCategory)
                      ? selectedCategory.id
                      : null
                  }
                  onCategorySelect={setSelectedCategory}
                />
              </div>
            </div>
          )}
        </div>
        <div className={cx("m-widget-services__content__right")}>
          <div className={cx("m-widget-services__content__right__content")}>
            {serviceLoader ? (
              <ListingEffect count={5} sideSpace={false} />
            ) : (
              <>
                {services.length > 0 ? (
                  <>
                    {services?.map((studentService, index) => (
                      <div className={cx("m-widget-services-list-wrapper")}>
                        <Card key={index}>
                          {index >= VISIBLE_RECORDS_COUNT && (
                            <div
                              className={cx(
                                "m-widget-services-list-wrapper__overlay"
                              )}
                            />
                          )}
                          <div
                            className={cx(
                              "m-widget-services-list-item-wrapper"
                            )}
                          >
                            <div
                              className={cx(
                                "m-widget-services-list-item-img-left"
                              )}
                            >
                              <img
                                src={studentService.logo_url}
                                alt="studentService"
                              />
                            </div>
                            <div
                              className={cx(
                                "m-widget-services-list-item-content-wrapper"
                              )}
                            >
                              <h3>{studentService.name}</h3>
                              <p>{studentService.description}</p>
                            </div>
                            <Button
                              modifier={ButtonModifier.STUDENT_SERVICES}
                              onClick={handleSignupClick}
                            >
                              Sign up
                            </Button>
                          </div>
                        </Card>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className={cx("m-widget-services-empty")}>
                    <h2>
                      Woops! <br /> We don't have any services listed right now.
                    </h2>
                    <EmptyStudentServicesIcon />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(WidgetServices);
