import React, { memo } from "react";
import { useGoogleLogin } from '@react-oauth/google';

import GoogleIcon from "../../../assets/Icons/GoogleIcon.js";
import SocialIcon from "../SocialIcon/SocialIcon";

const AuthGoogle = ({ callback = () => {} }) => {
  const url = new URL(window.location.href);
  console.log(url.origin)
  const login = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: callback,
    onError: errorResponse => console.log(errorResponse),
    scope: "openid profile email",
    redirect_uri: url.origin,
  });
  
  return (
    <SocialIcon text={"Google"} icon={<GoogleIcon />} onClick={() => login()}>
      Google
    </SocialIcon>
  );
};
export default memo(AuthGoogle);
