import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { Grid } from "@mui/material";

import StudentServicesList from "../../molecules/StudentServicesList/StudentServicesList";
import SavedStudentServices from "../../molecules/SavedStudentServices/SavedStudentServices";
import StudentServicesCategories from "../../molecules/StudentServicesCategories/StudentServicesCategories";
import EmptyServices from "../../atoms/EmptyServices/EmptyServices";
import MuiTextbox from "../../atoms/MuiTextbox/MuiTextbox";
import {
  getStudentServicesCategories,
  getStudentServicesByCategory,
  getSavedServicesList,
} from "../../../api/studentServices";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./StudentServices.module.scss";

import savePinIcon from "../../../assets/svg/save-pin.svg";
import arrowRight from "../../../assets/svg/arrow-right.svg";
import backArrowV2 from "../../../assets/images/f-btn-arrow-gray.svg";
import FaceliftSearchIcon from "../../../assets/Icons/FaceliftSearchIcon";

const cx = createModuleStyleExtractor(styles);

const StudentServices = () => {
  const [tab, setTab] = useState("listing"); // Possible Options 'listing' and 'saved'
  const [searchText, setSearchText] = useState("");
  const [categories, setCategories] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [savedServiceList, setSavedServiceList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showPaginationLoader, setShowPaginationLoader] = useState(false);
  const [categoriesLoader, setCategoriesLoader] = useState(false);
  const [savedServicesLoader, setSavedServicesLoader] = useState(false);

  const { selectedSchool = {} } = useSelector((state) => state.userState);

  useEffect(() => {
    fetchStudentServicesCategories();
    fetchSavedServices();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSchool]);

  const fetchStudentServicesCategories = async (searchText = "") => {
    try {
      setCategoriesLoader(true);
      const response = await getStudentServicesCategories(
        selectedSchool.id,
        searchText
      );
      if (response.success && response.data) {
        const categories = response.data.categories;

        if (categories && categories.length > 0) {
          setCategories(categories);
          getServiceData(categories[0]);
        } else getServiceData([]);
      } else {
        getServiceData([]);
      }
      setCategoriesLoader(false);
    } catch (error) {
      setCategoriesLoader(false);
    }
  };

  const fetchSavedServices = async () => {
    try {
      setSavedServicesLoader(true);
      const response = await getSavedServicesList(selectedSchool.id);
      if (response.success && response.data)
        setSavedServiceList(response.data.favorite_deals);
      else setSavedServiceList([]);
      setSavedServicesLoader(false);
    } catch (error) {
      setSavedServicesLoader(false);
    }
  };

  const fetchStudentServices = async (id, page = 1) => {
    const response = await getStudentServicesByCategory(
      selectedSchool.id,
      id,
      page
    );
    if (response.data) {
      setServiceList([
        ...(page > 1 ? serviceList : []),
        ...response.data.deals,
      ]);
      setTotalPages(response.data.total_pages);
    }
    setShowLoader(false);
    setShowPaginationLoader(false);
  };

  const getServiceData = (data) => {
    if (data.length === 0) {
      setSelectedCategory([]);
      setCategories([]);
      setServiceList([]);
    } else {
      setSelectedCategory(data);
      setShowLoader(true);
      fetchStudentServices(data.id, 1);
      setCurrentPage(1);
    }
  };

  const getPaginationData = () => {
    setShowPaginationLoader(true);
    fetchStudentServices(selectedCategory.id, currentPage + 1);
    setCurrentPage(currentPage + 1);
  };

  const getSearchServicesData = debounce(() => {
    fetchStudentServicesCategories(searchText);
  }, 500);

  const handleClearSearch = () => {
    setSearchText("");
    fetchStudentServicesCategories("");
  };

  return (
    <div className={cx("o-student-services")}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          {tab === "saved" && (
            <div className={cx("o-student-services__listing-services")}>
              <a
                href
                className={cx("o-student-services__listing-services__button")}
                onClick={(e) => {
                  e?.preventDefault && e.preventDefault();
                  setTab("listing");
                }}
              >
                <img src={backArrowV2} alt="back-arrow" />
                <span>Back to Services</span>
              </a>
            </div>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <div className={cx("o-student-services__title")}>
            <h2>
              {tab === "listing"
                ? "Explore our Services"
                : "Your Saved Services"}
            </h2>
          </div>
        </Grid>
        {tab === "listing" && (
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <div className={cx("o-student-services__saved-services")}>
              <div
                className={cx("o-student-services__saved-services__button")}
                onClick={() => setTab("saved")}
              >
                <img src={savePinIcon} alt="save-pin" />
                <span>
                  Your saved services{" "}
                  {savedServiceList.length > 0
                    ? "(" + savedServiceList.length + ")"
                    : ""}
                </span>
                <img src={arrowRight} alt="arrow-right" />
              </div>
            </div>
          </Grid>
        )}
      </Grid>
      {tab === "listing" && (
        <>
          <div className={cx("o-student-services__title-search-spacer")} />
          <Grid container spacing={2}>
            <Grid item xs={0} sm={3} md={3} lg={3} xl={3} />
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <div className={cx("o-student-services__search")}>
                <MuiTextbox
                  value={searchText}
                  name="search"
                  placeholder="Search keyword"
                  lableaignment="left"
                  onChange={(e) => setSearchText(e.target.value.trim())}
                  onEnterKey={() => getSearchServicesData()}
                />
                <a
                  href
                  onClick={(e) => {
                    e?.preventDefault && e.preventDefault();
                    getSearchServicesData();
                  }}
                  className={cx("o-student-services__search__button")}
                >
                  <FaceliftSearchIcon />
                </a>
                {searchText.length > 0 && (
                  <a
                    href
                    onClick={(e) => {
                      e?.preventDefault && e.preventDefault();
                      handleClearSearch();
                    }}
                    className={cx("o-student-services__search__clear")}
                  >
                    <span>Clear all</span>
                  </a>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3} xl={3} />
          </Grid>
        </>
      )}
      <div className={cx("o-student-services__search-deals-spacer")} />
      {tab === "listing" ? (
        <Grid container spacing={4}>
          {categoriesLoader ? (
            <>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <div className={cx("o-student-services__categories--loader")}>
                  {[...Array(8)].map((_, index) => (
                    <ShimmerThumbnail height={50} key={index} />
                  ))}
                </div>
              </Grid>
              <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                <div className={cx("o-student-services__listing--loader")}>
                  {[...Array(9)].map((_, index) => (
                    <ShimmerThumbnail width={267} height={345} key={index} />
                  ))}
                </div>
              </Grid>
            </>
          ) : categories.length > 0 ? (
            <>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <StudentServicesCategories
                  categories={categories}
                  selectedCategory={selectedCategory}
                  getServiceData={getServiceData}
                />
              </Grid>
              <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                <StudentServicesList
                  serviceList={serviceList}
                  refreshSavedServices={fetchSavedServices}
                  savedServiceList={savedServiceList}
                  showLoader={showLoader || categoriesLoader}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  fetchMoreData={getPaginationData}
                  showPaginationLoader={showPaginationLoader}
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <EmptyServices />
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SavedStudentServices
              savedServiceList={savedServiceList}
              refreshSavedServices={fetchSavedServices}
              showLoader={savedServicesLoader}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default memo(StudentServices);
