import React, { useState, useEffect } from "react";
import { Tooltip, ClickAwayListener, TextareaAutosize } from "@mui/material";
import moment from "moment";
import Picker from "emoji-picker-react";
import { CircleSpinner } from "react-spinners-kit";
import { useSelector, useDispatch } from "react-redux";

import Card from "../../../atoms/Card/Card";
import Comments from "./Comments";
import { CircularAvatar } from "../../../atoms/CircularAvatar/CircularAvatar";
import {
  favouriteTopicAPI,
  getTopicPostCommentsAPI,
  updateTopicPostReactionAPI,
  saveUnsaveTopicPostAPI,
  reportTopicPostAPI,
  deleteTopicPostAPI,
  deleteTopicPostComment,
  getUserByIdAPI,
  sendCommentAPI,
} from "../../../../api/community";
import { Button } from "../../../atoms/Button";
import ReadMoreReadLess from "../../../atoms/ReadMoreReadLess/ReadMoreReadLess";
import toastify from "../../../../utils/toast";
import CreatePost from "../CreatePost/CreatePost";
import { Modal } from "../../../atoms/Modal/Modal";
import { ModelHeader } from "../../../atoms/Modal/ModelHeader/ModelHeader";
import CreateComment from "./CreateComment";
import LinkContent from "../../../atoms/LinkContent/LinkContent";
import {
  updateTopicFavouriteStatus,
  incrementCommentCount,
  decrementCommentCount,
  updateTopicPostReactions,
  updateSavedPostStatus,
  setPreApiCallSavedTopic,
} from "../../../../redux/reducers/communityReducer";
import { getUrlFromDesc } from "../../../../utils/common";

import StarIcon from "../../../atoms/StarIcon/StarIcon";
import messageTopicIcon from "../../../../assets/svg/community-message.svg";
import emojiPlusIcon from "../../../../assets/svg/community-emoji-plus.svg";
import topicMenuOpenIcon from "../../../../assets/svg/community-menu-open.svg";
import topicMenuCloseIcon from "../../../../assets/svg/community-menu-close.svg";
import pinTopicIcon from "../../../../assets/svg/community-pin.svg";
import NoUserIcon from "../../../../assets/Icons/f-user-avatar.svg";

// Constants
const MAX_LINES_TOPIC_POST = 13;
const MAX_LENGTH_TOPIC_CREATE_COMMENT = 500;
const INITIAL_REPORT_MODAL_STATE = {
  open: false,
  text: "",
  apiLoading: false,
};
const INITIAL_EDIT_MODAL_STATE = {
  open: false,
  edit_topic_post_id: null,
};
const INITIAL_DELETE_MODAL_STATE = {
  open: false,
  delete_topic_post_id: null,
  apiLoading: false,
};
const INITIAL_COMMENT_MENU_STATE = {
  menuOpen: false,
  comment: {},
  editModalOpen: false,
  deleteModalOpen: false,
};

export default function TopicPost({
  topic,
  setActiveUser,
  setShowUserPopup,
  callGetTopicDetailsAPI = () => {},
}) {
  const dispatch = useDispatch();
  const { followedTopics, activeTopicId, activeTopic, topicPosts } =
    useSelector((state) => state.communityState);
  const { selectedSchool = {}, user = {} } = useSelector(
    (state) => state.userState
  );
  const userId = user?.id;
  const selectedSchoolId = selectedSchool?.id || null;

  const [topicOfPost, setTopicOfPost] = useState(null);
  const [commentText, setCommentText] = useState("");
  const [emojiContainer, setEmojiContainer] = useState(false);
  const [showReplies, setShowReplies] = useState(false);
  const [loadingReplies, setLoadingReplies] = useState(false);
  const [replies, setReplies] = useState({});
  const [reactionsEmojiContainer, setReactionsEmojiContainer] = useState(false);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [loadingSendCommentAPI, setLoadingSendCommentAPI] = useState(false);
  const [loadingDeleteTopicPostComment, setLoadingDeleteTopicPostComment] =
    useState(false);
  const [reportModalState, setReportModalState] = useState(
    INITIAL_REPORT_MODAL_STATE
  );
  const [editPostModalState, setEditPostModalState] = useState(
    INITIAL_EDIT_MODAL_STATE
  );
  const [deletePostModalState, setDeletePostModalState] = useState(
    INITIAL_DELETE_MODAL_STATE
  );
  const [commentMenuState, setCommentMenuState] = useState(
    INITIAL_COMMENT_MENU_STATE
  );

  const callSendCommentAPI = async (payload = {}) => {
    setLoadingSendCommentAPI(true);
    const response = await sendCommentAPI(payload);
    setLoadingSendCommentAPI(false);

    if (response?.success && response?.data) {
      if (emojiContainer) setEmojiContainer(false);

      const responseData = response.data;
      toastify(response.message);

      if (commentMenuState.editModalOpen) {
        // Editing existing comment
        let _comments = [...(replies?.comments || [])];
        const index = _comments?.findIndex(
          (comment) => comment?.id === commentMenuState.comment?.id
        );
        if (index !== -1) {
          _comments[index].comment = responseData?.topic?.comment;
        }
        setReplies((current) => ({
          ...current,
          comments: _comments,
        }));
        setCommentMenuState(INITIAL_COMMENT_MENU_STATE);
      } else {
        // Creating new comment
        setCommentText("");
        dispatch(incrementCommentCount(responseData?.topic?.topic_post_id)); // Update the comment count in state
        showReplies &&
          setReplies((current) => ({
            ...current,
            comments: [
              {
                // Standardize comment object before insertion
                id: responseData?.topic?.id,
                topic_id: responseData?.topic?.topic_id,
                topic_post_id: responseData?.topic?.topic_post_id,
                user_id: responseData?.topic?.commneted_by_id,
                comment: responseData?.topic?.comment,
                created_at: responseData?.topic?.commented_at,
                commented_by: {
                  id: responseData?.topic?.commneted_by_id,
                  first_name:
                    responseData?.topic?.commneted_by_name?.split(" ")[0],
                  last_name:
                    responseData?.topic?.commneted_by_name?.split(" ")[1],
                  image: responseData?.topic?.commneted_by_image,
                  school_id: null,
                  school_name: null,
                },
                user_type: responseData?.topic?.user_type,
              },
              ...(current?.comments?.length > 0 ? current.comments : []),
            ],
          }));
      }
    }
  };

  const callDeleteTopicPostComment = async (post_comment_id = null) => {
    setLoadingDeleteTopicPostComment(true);
    const response = await deleteTopicPostComment(
      post_comment_id,
      selectedSchoolId
    );
    setLoadingDeleteTopicPostComment(false);
    if (response?.success && response?.data) {
      toastify(response?.message);

      // Update the state to remove the deleted comment
      let _comments = [...(replies?.comments || [])];
      const index = _comments?.findIndex(
        (comment) => comment?.id === commentMenuState.comment?.id
      );
      if (index !== -1) {
        _comments.splice(index, 1);
      }
      setReplies((current) => ({
        ...current,
        comments: _comments,
      }));
      dispatch(decrementCommentCount(topic?.id)); // Update the comment count in state

      setCommentMenuState(INITIAL_COMMENT_MENU_STATE);
    }
  };

  const fetchReplies = async (page = 1, per_page = 5) => {
    setLoadingReplies(true);
    const response = await getTopicPostCommentsAPI(
      topic?.id,
      page,
      per_page,
      selectedSchoolId
    );
    setLoadingReplies(false);
    if (response.success) {
      setReplies((current) => ({
        ...response?.data,
        comments:
          page === 1
            ? response.data.comments
            : [...current.comments, ...response.data.comments],
      }));
    }
  };

  const onContactInfoUserClick = async (user_id = null) => {
    if (!user_id) return;
    const response = await getUserByIdAPI(user_id);
    if (response?.success && response?.data?.user_info) {
      setActiveUser(response.data.user_info);
      setShowUserPopup(true);
    }
  };

  const handleSubmitComment = () => {
    const payload = {
      topic_post_comment: {
        comment: commentMenuState.editModalOpen
          ? commentMenuState?.comment?.comment?.trim()
          : commentText.trim(),
        topic_id: topic?.topic_id,
        topic_post_id: topic?.id,
        ...(commentMenuState.editModalOpen
          ? { post_comment_id: commentMenuState?.comment?.id }
          : {}),
        school_id: selectedSchoolId,
      },
    };
    callSendCommentAPI(payload);
  };

  const onReactionsEmojiClick = async (emojiObject = {}) => {
    setReactionsEmojiContainer(false);
    const response = await updateTopicPostReactionAPI(topic?.id, {
      emoji: emojiObject?.emoji,
      emoji_unicode: emojiObject?.unified,
      emoji_symbol: emojiObject?.names[0],
    });
    if (response?.success && response?.data) {
      dispatch(updateTopicPostReactions(response?.data));
    } else {
      toastify(response?.message, "error");
    }
  };

  const removeEmojiReaction = async (emojiData = {}, index = null) => {
    const emojiUnicode = Object.keys(topic?.details?.emojies)[index];
    if (emojiUnicode) {
      const response = await updateTopicPostReactionAPI(topic?.id, {
        emoji: emojiData?.emoji,
        emoji_unicode: emojiUnicode,
        emoji_symbol: emojiData?.emoji_symbol,
      });
      if (response?.success && response?.data) {
        dispatch(updateTopicPostReactions(response?.data));
      } else {
        toastify(response?.message, "error");
      }
    }
  };

  const handleFavorite = async () => {
    if (topicOfPost?.id) {
      const response = await favouriteTopicAPI(
        topicOfPost.id,
        selectedSchoolId
      );
      if (response?.success && response?.data) {
        toastify(response?.message);
        // Update local app state
        dispatch(updateTopicFavouriteStatus(topicOfPost.id));
      }
    } else {
      toastify("", "error");
    }
  };

  const handleEditTopicPost = () => {
    setOptionsOpen(false);
    setEditPostModalState({
      open: true,
      edit_topic_post_id: topic?.id,
    });
  };

  const handleSavePost = async () => {
    setOptionsOpen(false);
    const response = await saveUnsaveTopicPostAPI(topic?.id, selectedSchoolId);
    if (response.data) {
      toastify(response?.message);
      dispatch(updateSavedPostStatus(topic?.id));
    } else {
      toastify(response?.message, "error");
    }
  };

  const handleSubmitReportPost = async () => {
    setReportModalState((current) => ({
      ...current,
      apiLoading: true,
    }));
    const reportTextTrimmed = reportModalState.text?.trim();
    if (reportTextTrimmed?.length > 0) {
      const response = await reportTopicPostAPI(topic?.id, {
        report_text: reportTextTrimmed,
        school_id: selectedSchoolId,
      });
      if (response?.success) {
        toastify(response?.message);
        setReportModalState(INITIAL_REPORT_MODAL_STATE);
      } else {
        toastify(response?.message, "error");
        setReportModalState((current) => ({
          ...current,
          apiLoading: false,
        }));
      }
    }
  };

  const handleCloseTopicEditModal = () =>
    setEditPostModalState(INITIAL_EDIT_MODAL_STATE);

  const handleCloseDeletePostModal = () =>
    setDeletePostModalState(INITIAL_DELETE_MODAL_STATE);

  const handleDeletePost = async () => {
    setOptionsOpen(false);
    setDeletePostModalState((current) => ({ ...current, apiLoading: true }));
    const response = await deleteTopicPostAPI(topic?.id, selectedSchoolId);
    if (response.success) {
      dispatch(setPreApiCallSavedTopic("topic"));
      callGetTopicDetailsAPI(activeTopicId);
      toastify(response?.message);
      handleCloseDeletePostModal();
    } else {
      toastify(response?.message, "error");
      setDeletePostModalState((current) => ({ ...current, apiLoading: false }));
    }
  };

  const handleDeleteTopicComment = () => {
    callDeleteTopicPostComment(commentMenuState.comment.id);
  };

  // Extract topic of current post
  useEffect(() => {
    if (followedTopics?.length > 0 && topic?.topic_id) {
      setTopicOfPost(
        followedTopics.filter((item) => item.id === topic.topic_id)[0]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [followedTopics]);

  useEffect(() => {
    // If comment box opened, call API to get comments
    if (showReplies) {
      fetchReplies();
    } else {
      // Clear data if box is closed
      setReplies({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showReplies]);

  return (
    <Card>
      <div className="topic-post">
        <div className="topic-post__title">
          <CircularAvatar
            round={true}
            size={50}
            style={{
              cursor: topic?.anonymously ? "initial" : "pointer",
            }}
            onClick={() =>
              !topic?.anonymously &&
              topic?.posted_by?.id &&
              onContactInfoUserClick(topic?.posted_by?.id)
            }
            src={topic?.anonymously ? NoUserIcon : topic?.posted_by?.image}
            alt={
              topic?.anonymously
                ? "Private post"
                : `${
                    topic?.posted_by?.first_name +
                    " " +
                    topic?.posted_by?.last_name
                  }'s profile picture`
            }
            name={
              topic?.posted_by?.first_name + " " + topic?.posted_by?.last_name
            }
          />
          <div className="topic-post__title__name-wrapper">
            <div
              className="topic-post__title__name"
              onClick={() =>
                !topic?.anonymously &&
                topic?.posted_by?.id &&
                onContactInfoUserClick(topic?.posted_by?.id)
              }
              style={{
                cursor: topic?.anonymously ? "initial" : "pointer",
              }}
            >
              <h4>
                {topic?.anonymously
                  ? "Anonymous User"
                  : topic?.posted_by?.first_name +
                    " " +
                    topic?.posted_by?.last_name}
              </h4>
            </div>
            {topic?.posted_by?.id === activeTopic?.created_by_id &&
              !topic?.anonymously && (
                <div className="topic-post__title__tag topic-post__title__tag--community-creator">
                  Community Creator
                </div>
              )}
            {topic?.external && !topic?.anonymously && (
              <div className="topic-post__title__tag topic-post__title__tag--external">
                External
              </div>
            )}
            {!topic?.anonymously &&
              topic?.user_types?.map((type, index) => (
                <div
                  className={`topic-post__title__tag ${
                    type === "Mentor"
                      ? "topic-post__title__tag--mentor"
                      : type === "Alumni"
                      ? "topic-post__title__tag--alumni"
                      : type === "Student"
                      ? "topic-post__title__tag--student"
                      : type === "Staff"
                      ? "topic-post__title__tag--staff"
                      : type === "Ambassador"
                      ? "topic-post__title__tag--ambassador"
                      : ""
                  }`}
                  key={index}
                >
                  {type}
                </div>
              ))}
          </div>
          <h4 className="topic-post__title__time">
            {moment(topic?.posted_at).calendar(null, {
              sameDay: "[Today at] hh:mm A",
              lastDay: "[Yesterday at] hh:mm A",
              sameElse: "DD/MM/YYYY",
            })}
          </h4>
          {topicPosts?.type === "topic" && (
            <div className="topic-post__title__options">
              {topic?.pinned && (
                <Tooltip title="This post has been pinned by Admin">
                  <img src={pinTopicIcon} alt="pin" />
                </Tooltip>
              )}
              <img
                className="topic-post__title__options__menu-icon"
                alt="menu-icon"
                src={optionsOpen ? topicMenuCloseIcon : topicMenuOpenIcon}
                onClick={() => {
                  setOptionsOpen((current) => !current);
                }}
              />
              {optionsOpen && (
                <ClickAwayListener onClickAway={() => setOptionsOpen(false)}>
                  <div className="topic-post__title__options__menu">
                    {topic?.posted_by?.id === userId && (
                      <span onClick={handleEditTopicPost}>Edit</span>
                    )}
                    {topic?.posted_by?.id === userId && (
                      <span
                        onClick={() => {
                          setOptionsOpen(false);
                          setDeletePostModalState((current) => ({
                            ...current,
                            open: true,
                          }));
                        }}
                      >
                        Delete
                      </span>
                    )}
                    <span onClick={handleSavePost}>
                      {topic?.saved === true ? "Unsave post" : "Save post"}
                    </span>
                    <span
                      onClick={() => {
                        setOptionsOpen(false);
                        setReportModalState({
                          ...INITIAL_REPORT_MODAL_STATE,
                          open: true,
                        });
                      }}
                    >
                      Report
                    </span>
                  </div>
                </ClickAwayListener>
              )}
            </div>
          )}
        </div>
        {!activeTopicId && topicOfPost?.topic_name && (
          <Tooltip title={topicOfPost.topic_name}>
            <div className="topic-post__topic">
              <span className="topic-post__topic__name">
                {topicOfPost.topic_name?.length > 30
                  ? topicOfPost.topic_name?.slice(0, 60) + "..."
                  : topicOfPost.topic_name}
              </span>
              <StarIcon
                className="topic-post__topic__star"
                variant={topicOfPost?.favourite ? "filled" : "un-filled"}
                onClick={handleFavorite}
              />
            </div>
          </Tooltip>
        )}
        <div className="header-primary">
          {topic?.content?.length > 0 && (
            <div className="header-primary__content">
              <h3>
                <LinkContent>
                  <ReadMoreReadLess
                    maxLines={MAX_LINES_TOPIC_POST}
                    moreComp={
                      <div className="header-primary__content__read-more-less">
                        Read more
                      </div>
                    }
                    lessComp={
                      <div className="header-primary__content__read-more-less">
                        Read less
                      </div>
                    }
                  >
                    {topic?.content}
                  </ReadMoreReadLess>
                </LinkContent>
              </h3>
              {topic?.image_url?.length > 0 && !topic?.url_title && (
                <div className="header-primary__content__attachment">
                  <img src={topic?.image_url} alt="topic post" />
                </div>
              )}
            </div>
          )}
          {topic?.url_title && (
            <div
              className="header-primary__url"
              onClick={() =>
                getUrlFromDesc(topic?.content)?.length > 0
                  ? window
                      .open(getUrlFromDesc(topic?.content), "_blank")
                      .focus()
                  : null
              }
            >
              {topic?.image_url?.length > 0 && (
                <div className="header-primary__url__image">
                  <img src={topic?.image_url} alt="topic post" />
                </div>
              )}
              {topic?.url_title?.length > 0 && (
                <h4 className="header-primary__url__title">
                  {topic?.url_title}
                </h4>
              )}
              {topic?.url_description?.length > 0 &&
                topic?.url_description !== "null" && (
                  <span className="header-primary__url__description">
                    {topic?.url_description}
                  </span>
                )}
            </div>
          )}
        </div>
        <div className="actions">
          {topic?.details?.comment_counts >= 0 && (
            <div
              className="actions__replies"
              onClick={() => setShowReplies((current) => !current)}
            >
              <img src={messageTopicIcon} alt="message" />
              {showReplies ? (
                <span>Hide replies</span>
              ) : (
                <span>
                  {topic?.details?.comment_counts === 0
                    ? "Reply"
                    : `${topic?.details?.comment_counts} ${
                        topic?.details?.comment_counts === 1
                          ? "Reply"
                          : "Replies"
                      }`}
                </span>
              )}
            </div>
          )}
          {Object.values(topic?.details?.emojies || {})?.map((emoji, index) => (
            <div
              className="actions__reaction"
              key={index}
              onClick={() => removeEmojiReaction(emoji, index)}
            >
              {emoji?.emoji && emoji?.count && (
                <span>
                  {emoji?.emoji} {emoji?.count}
                </span>
              )}
            </div>
          ))}
          <div className="actions__emoji">
            <img
              src={emojiPlusIcon}
              alt="emoji-plus"
              onClick={() => setReactionsEmojiContainer((current) => !current)}
            />
            {reactionsEmojiContainer && (
              <ClickAwayListener
                onClickAway={() => setReactionsEmojiContainer(false)}
              >
                <div className="actions__emoji__picker">
                  <Picker
                    onEmojiClick={onReactionsEmojiClick}
                    style={{ width: "100%" }}
                    skinTonesDisabled
                    reactionsDefaultOpen
                  />
                </div>
              </ClickAwayListener>
            )}
          </div>
        </div>
        {showReplies && (
          <>
            <CreateComment
              commentText={commentText}
              emojiContainer={emojiContainer}
              maxCommentLength={MAX_LENGTH_TOPIC_CREATE_COMMENT}
              apiFetching={loadingSendCommentAPI}
              setCommentText={setCommentText}
              handleSubmitComment={handleSubmitComment}
              setEmojiContainer={setEmojiContainer}
            />
            <div className="comment-section">
              <Comments
                data={replies}
                fetchReplies={fetchReplies}
                loadingReplies={loadingReplies}
                onContactInfoUserClick={onContactInfoUserClick}
                topic={topic}
                commentMenuState={commentMenuState}
                setCommentMenuState={setCommentMenuState}
                INITIAL_COMMENT_MENU_STATE={INITIAL_COMMENT_MENU_STATE}
              />
            </div>
          </>
        )}
        {loadingReplies && (
          <div className="comment-loader">
            <CircleSpinner
              size={20}
              color={
                getComputedStyle(
                  document?.getElementById("root")
                )?.getPropertyValue("--primary-color") || "#000c3d"
              }
            />
          </div>
        )}
        <div className="report-topic-modal">
          <Modal
            show={reportModalState.open}
            onClose={() => setReportModalState(INITIAL_REPORT_MODAL_STATE)}
          >
            <ModelHeader
              title={"Please let us know the reason of the report."}
            />
            <div className="report-modal">
              <div className="report-modal__textbox">
                <TextareaAutosize
                  draggable={false}
                  name="report-text"
                  placeholder="Write your report"
                  value={reportModalState.text}
                  onChange={(e) =>
                    setReportModalState((current) => ({
                      ...current,
                      text: e?.target?.value,
                    }))
                  }
                  minRows={2}
                  maxRows={4}
                />
              </div>
              <div className="report-modal__button">
                <Button
                  isSubmitting={reportModalState.apiLoading}
                  disabled={reportModalState?.text?.trim() === ""}
                  onClick={handleSubmitReportPost}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Modal>
        </div>
        <div className="edit-topic-modal">
          <Modal
            show={editPostModalState.open}
            onClose={handleCloseTopicEditModal}
          >
            <ModelHeader title={"Edit Community post"} />
            <div className="edit-topic-modal__body">
              <CreatePost
                editTopicPostId={editPostModalState.edit_topic_post_id}
                handleCloseTopicEditModal={handleCloseTopicEditModal}
                callGetTopicDetailsAPI={callGetTopicDetailsAPI}
              />
            </div>
          </Modal>
        </div>
        <div className="delete-topic-modal">
          <Modal
            show={deletePostModalState.open}
            onClose={handleCloseDeletePostModal}
          >
            <ModelHeader title={"Are you sure you want to delete this post?"} />
            <div className="delete-topic-modal__body">
              <Button
                isSubmitting={deletePostModalState.apiLoading}
                onClick={handleDeletePost}
              >
                Submit
              </Button>
            </div>
          </Modal>
        </div>
        <div className="edit-topic-modal">
          <Modal
            show={commentMenuState.editModalOpen}
            onClose={() => setCommentMenuState(INITIAL_COMMENT_MENU_STATE)}
          >
            <ModelHeader title={"Edit comment"} />
            <div className="edit-topic-modal__body">
              <CreateComment
                commentText={commentText}
                emojiContainer={emojiContainer}
                maxCommentLength={MAX_LENGTH_TOPIC_CREATE_COMMENT}
                apiFetching={loadingSendCommentAPI}
                edit={commentMenuState.editModalOpen}
                commentMenuState={commentMenuState}
                setCommentText={setCommentText}
                handleSubmitComment={handleSubmitComment}
                setEmojiContainer={setEmojiContainer}
                setCommentMenuState={setCommentMenuState}
              />
            </div>
          </Modal>
        </div>
        <div className="delete-topic-modal">
          <Modal
            show={commentMenuState.deleteModalOpen}
            onClose={() => setCommentMenuState(INITIAL_COMMENT_MENU_STATE)}
          >
            <ModelHeader
              title={"Are you sure you want to delete your comment?"}
            />
            <div className="delete-topic-modal__body">
              <div className="delete-topic-modal__body__button">
                <Button
                  isSubmitting={loadingDeleteTopicPostComment}
                  onClick={handleDeleteTopicComment}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </Card>
  );
}
