import React, { useEffect } from "react";
import { useFormik } from "formik";

import { Modal } from "../../../atoms/Modal/Modal";
import { ModelHeader } from "../../../atoms/Modal/ModelHeader/ModelHeader";
import { ModalBody } from "../../../atoms/Modal/ModalBody/ModalBody";
import { createModuleStyleExtractor } from "../../../../utils/css";
import MuiTextbox from "../../../atoms/MuiTextbox/MuiTextbox";
import { Button, ButtonModifier } from "../../../atoms/Button/Button";
import { validateString, validateEmail } from "../../../../utils/validations";
import { editUserEmail } from "../../../../api/profile";
import toastify from "../../../../utils/toast";
import styles from "./EditEmailModal.module.scss";

const cx = createModuleStyleExtractor(styles);

export default function EditEmailModal({
  showEditEmailModal,
  setShowEditEmailModal,
  handleOnSignout,
}) {
  const handleSave = async (values) => {
    // Call API
    try {
      const response = await editUserEmail(values.email);
      if (response.success) {
        setShowEditEmailModal(false); // Close modal
        toastify(response.message); // Display message
        handleOnSignout(); // Signout user
      } else {
        toastify(response.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Validation function for profile fields
  const validate = (values) => {
    const errors = {};

    if (validateString(values.email)) {
      errors.email = "Email is required";
    } else if (validateEmail(values.email)) {
      errors.email = "Email is not valid";
    }

    return errors;
  };

  // Formik Hook
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate,
    onSubmit: handleSave,
  });

  // Reset form fields upon modal close
  useEffect(() => {
    if (!showEditEmailModal) {
      formik.resetForm({ values: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEditEmailModal]);

  return (
    <form key={"email-form"}>
      <Modal
        show={showEditEmailModal}
        onClose={() => setShowEditEmailModal(false)}
      >
        <ModelHeader title={"Change email address"} />
        <ModalBody>
          <div className={cx("o-profile-edit-email-modal-body")}>
            <div className={cx("o-profile-edit-email-modal-body-input")}>
              <MuiTextbox
                type="email"
                value={formik.values.email}
                onChange={(e) => formik.setFieldValue("email", e.target.value)}
                name={"email"}
                label="Your new email*"
                placeholder={"Enter your email"}
                error={formik.errors.email}
              />
            </div>
            <div className={cx("o-profile-edit-email-modal-body-note")}>
              <p>
                Updating the email will sign you out from all active sessions
              </p>
              <p>
                You will receive a confirmation email for updated email address
              </p>
            </div>
            <Button
              modifier={ButtonModifier.PROFILE_SUBMIT}
              type="button"
              onClick={formik.handleSubmit}
            >
              Apply changes
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </form>
  );
}
