import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./TopicCard.module.scss";
import { CircularAvatar } from "../CircularAvatar/CircularAvatar";
import { ThreeDots } from "react-loader-spinner";
import { followUnfollowTopic } from "../../../api/topics";

import toastify from "../../../utils/toast";

const cx = createModuleStyleExtractor(styles);

export const TopicCard = ({ topic, fetchDiscoverTopics }) => {
  const [isCallingTopicsFollow, setIsCallingTopicFollow] = useState(false);

  const { selectedSchool = {} } = useSelector((state) => state.userState);
  const selectedSchoolID = selectedSchool?.id || null;

  const handleTopicFollow = async () => {
    // Call API to follow / unfollow topic
    setIsCallingTopicFollow(true);
    const response = await followUnfollowTopic(topic.id, selectedSchoolID);
    setIsCallingTopicFollow(false);
    if (response.success) {
      // Recall / refresh topic api
      fetchDiscoverTopics();
      toastify(response.message);
    } else {
      toastify(response.message);
    }
  };

  return (
    <div className={cx("a-topic-card")}>
      <div className={cx("a-topic-card__profile")}>
        <div className={cx("a-topic-card__profile__image")}>
          <CircularAvatar
            alt="topic"
            src={topic.topic_icon}
            name={topic.topic_name}
            size={73}
          />
        </div>
      </div>
      <div className={cx("a-topic-card__description")}>
        <Tooltip
          title={topic?.topic_name}
          PopperProps={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [10, -13],
                },
              },
            ],
          }}
        >
          <h2>{topic?.topic_name}</h2>
        </Tooltip>
        <span className={cx("a-topic-card__description--tag")}>
          {topic.topic_type === "private" ? "Private" : "Public"}
        </span>
        <div className={cx("a-topic-card__description__body_wrapper")}>
          <Tooltip
            title={topic.topic_description}
            placement="bottom-start"
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -42],
                    },
                  },
                ],
              },
            }}
            componentsProps={{
              tooltip: {
                sx: {
                  width: "300px",
                },
              },
            }}
          >
            <p>{topic.topic_description}</p>
          </Tooltip>
        </div>
      </div>
      <div className={cx("a-topic-card__btns-wrapper")}>
        <button
          className={cx("a-topic-card__btns-wrapper__btn-secondary")}
          onClick={handleTopicFollow}
          disabled={
            isCallingTopicsFollow || topic.following_status === "requested"
          }
        >
          {isCallingTopicsFollow ? (
            <ThreeDots
              color={getComputedStyle(
                document.getElementById("root")
              ).getPropertyValue("--primary-color")}
              height={18}
              width={50}
            />
          ) : (
            <>
              {topic.following_status === "requested" ? "Requested" : "Follow"}
            </>
          )}
        </button>
      </div>
    </div>
  );
};
