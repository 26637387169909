import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip, Chip } from "@mui/material";

import { updateTopicFavouriteStatus } from "../../../../redux/reducers/communityReducer";
import { favouriteTopicAPI } from "../../../../api/community";
import styles from "./MyTopic.module.scss";
import { createModuleStyleExtractor } from "../../../../utils/css";
import { setActiveTopicId } from "../../../../redux/reducers/communityReducer";
import { CircularAvatar } from "../../../atoms/CircularAvatar/CircularAvatar";
import toastify from "../../../../utils/toast";

import StarIcon from "../../../atoms/StarIcon/StarIcon";
import FilterIcon from "../../../../assets/Icons/FilterIcon";

const cx = createModuleStyleExtractor(styles);
const TOPIC_LINE_ONE_LENGTH = 16;
const TOPIC_LINE_TWO_LENGTH = 47;

export default function MyTopic() {
  const { followedTopics, activeTopicId } = useSelector(
    (state) => state.communityState
  );
  const { topicSpecificNotificationCount = {} } = useSelector(
    (state) => state.notificationState
  );
  const { selectedSchool = {} } = useSelector((state) => state.userState);
  const selectedSchoolId = selectedSchool?.id || null;
  const dispatch = useDispatch();

  const [openFilter, toggleFilter] = useState(false);

  // This function ensures Community name is truncated enough to be displayed within UI
  const renderTopicName = (topicName = "", isAdmissionTag = false) => {
    if (isAdmissionTag) {
      return topicName?.length > TOPIC_LINE_ONE_LENGTH
        ? topicName?.slice(0, TOPIC_LINE_ONE_LENGTH) + "..."
        : topicName;
    } else {
      return topicName?.length > TOPIC_LINE_TWO_LENGTH
        ? topicName?.slice(0, TOPIC_LINE_TWO_LENGTH) + "..."
        : topicName;
    }
  };

  const findTopicCount = (id = null) => {
    let count = 0;
    if (
      id &&
      topicSpecificNotificationCount &&
      topicSpecificNotificationCount[id]
    ) {
      count = parseInt(topicSpecificNotificationCount[id]);
    }
    return count;
  };

  const handleFavorite = async (item = {}) => {
    if (item.id) {
      const response = await favouriteTopicAPI(item.id, selectedSchoolId);
      if (response?.success && response?.data) {
        toastify(response?.message);
        // Update local app state
        dispatch(updateTopicFavouriteStatus(item.id));
      } else {
        toastify("", "error");
      }
    } else {
      toastify("", "error");
    }
  };

  useEffect(() => {
    if (activeTopicId !== null) toggleFilter(false);
  }, [activeTopicId]);

  return (
    <div className={cx("mytopic")}>
      <div
        className={`collapsible-section ${
          openFilter ? "collapsible-section--open" : ""
        }`}
      >
        <div
          className={cx("mytopic__header")}
          id={"collapsible-section-header"}
        >
          <h2 className={cx("mytopic__header__title")}>My Communities</h2>
          <a
            href="/#"
            onClick={(e) => {
              e?.preventDefault && e.preventDefault();
              toggleFilter(!openFilter);
            }}
            id="collapsible-section-header-icon"
          >
            <FilterIcon fill={openFilter} />
          </a>
        </div>
        <div
          className={cx("mytopic__categories")}
          id="collapsible-section-search-filter"
        >
          <div className={cx("mytopic__categories__topic")}>
            {followedTopics?.length > 0 ? (
              followedTopics.map((item, index) => (
                <div
                  className={cx([
                    "mytopic__categories__topic__card",
                    item.id === activeTopicId
                      ? "mytopic__categories__topic__card--active"
                      : "",
                  ])}
                  key={index}
                  onClick={() =>
                    dispatch(
                      setActiveTopicId(
                        activeTopicId === item?.id ? null : item?.id
                      )
                    )
                  }
                >
                  <div
                    className={cx("mytopic__categories__topic__card__avatar")}
                  >
                    <CircularAvatar
                      src={item.topic_icon}
                      size={"45"}
                      name={item.topic_name}
                      round={true}
                    />
                  </div>
                  <div
                    className={cx("mytopic__categories__topic__card__title")}
                  >
                    <div
                      className={cx(
                        "mytopic__categories__topic__card__title__container"
                      )}
                    >
                      <Tooltip title={item?.topic_name}>
                        <span
                          className={cx([
                            "mytopic__categories__topic__card__title__container__topic-name",
                            item.id === activeTopicId &&
                              "mytopic__categories__topic__card__title__container__topic-name--active",
                          ])}
                        >
                          {renderTopicName(
                            item?.topic_name,
                            item?.topic_portal_type
                          )}
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                  {findTopicCount(item?.id) > 0 && (
                    <div
                      className={cx(
                        "mytopic__categories__topic__card__notification"
                      )}
                    >
                      <Chip label={findTopicCount(item.id)} />
                    </div>
                  )}
                  <StarIcon
                    className={cx("mytopic__categories__topic__card__favorite")}
                    variant={item?.favourite ? "filled" : "un-filled"}
                    onClick={() => handleFavorite(item)}
                  />
                </div>
              ))
            ) : (
              <div className={cx("mytopic__categories__topic__empty")}>
                <h3>
                  Looks like you haven't created or followed any Community.
                </h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
