import React, { useEffect } from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";

import VerticalBarChart from "../../atoms/VerticalBarChart/VerticalBarChart";
import MuiSelect from "../../atoms/MuiSelect/MuiSelect";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./AlumniDegreesGraph.module.scss";
import Card from "../../atoms/Card/Card";
import { getGraphData } from "../../../api/employmentDataHub";

import NoDataImage from "../../../assets/images/chart_placeholder.png";
import FilterIcon from "../../../assets/Icons/FilterIcon";

const cx = createModuleStyleExtractor(styles);

export default function AlumniDegreesGraph({
  filters = {},
  state,
  setState,
  openFilter,
  toggleFilter,
  schoolId,
  processGraphClick,
}) {
  // Calls API upon component and/or selected school change
  useEffect(() => {
    (async () => {
      const response = await getGraphData(
        schoolId,
        "degree",
        state?.selectedFilters?.major?.value,
        state?.selectedFilters?.year?.value,
        state?.selectedFilters?.nationality?.value,
        null
      );
      if (response?.success && response?.data?.length > 0) {
        // Process data
        let _labels = [];
        let _data = [];
        response?.data?.slice(0, 10)?.forEach((item) => {
          // Only display and process top 10 records
          _labels.push(item?.degree);
          _data?.push(item?.count);
        });
        setState((current) => ({
          ...current,
          loader: false,
          data_set: {
            labels: _labels,
            data: _data,
          },
        }));
        return;
      }
      setState((current) => ({
        ...current,
        loader: false,
        data_set: {
          labels: null,
          data: null,
        },
      }));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolId, state?.selectedFilters]);

  return (
    <div className={cx("m-alum-deg")}>
      <div
        className={`collapsible-section ${
          openFilter ? "collapsible-section--open" : ""
        }`}
      >
        <div
          className={cx("m-alum-deg__header")}
          id={"collapsible-section-header"}
        >
          <h2 className={cx("m-alum-deg__header__title")}>
            Degrees of international graduates with employment
          </h2>
          <a
            href="/#"
            onClick={(e) => {
              e?.preventDefault && e.preventDefault();
              toggleFilter(!openFilter);
            }}
            id="collapsible-section-header-icon"
          >
            <FilterIcon fill={openFilter} />
          </a>
          <div
            className={cx("m-alum-deg__header__filters")}
            id="collapsible-section-search-filter"
          >
            <MuiSelect
              placeholder="Filter by major"
              justifyContent
              isClearable={true}
              isDisabled={state.loader}
              options={filters?.major}
              value={state?.selectedFilters?.major}
              onChange={(option) =>
                setState((current) => ({
                  ...current,
                  selectedFilters: {
                    ...current.selectedFilters,
                    major: option,
                  },
                }))
              }
            />
            <MuiSelect
              placeholder="Filter by nationality"
              justifyContent
              isClearable={true}
              isDisabled={state.loader}
              options={filters?.nationality}
              value={state?.selectedFilters?.nationality}
              onChange={(option) =>
                setState((current) => ({
                  ...current,
                  selectedFilters: {
                    ...current.selectedFilters,
                    nationality: option,
                  },
                }))
              }
            />
            <MuiSelect
              placeholder="Filter by year"
              justifyContent
              isClearable={true}
              isDisabled={state.loader}
              options={filters?.year}
              value={state?.selectedFilters?.year}
              onChange={(option) =>
                setState((current) => ({
                  ...current,
                  selectedFilters: {
                    ...current.selectedFilters,
                    year: option,
                  },
                }))
              }
            />
          </div>
        </div>
      </div>
      <div className={cx("m-alum-deg__body")}>
        <Card>
          <div className={cx("m-alum-deg__body__card")}>
            {state.loader ? (
              <div className={cx("m-alum-deg__body__card__loader")}>
                <ShimmerThumbnail rounded />
              </div>
            ) : !state.loader &&
              (state.data_set.labels === null ||
                state.data_set.data === null) ? (
              <div className={cx("m-alum-deg__body__card__no-data")}>
                <img src={NoDataImage} alt="No Data Available" />
                <h3>No data available</h3>
              </div>
            ) : !state.loader &&
              state.data_set.labels?.length > 0 &&
              state.data_set.data?.length > 0 ? (
              <div className={cx("m-alum-deg__body__card__graph")}>
                <VerticalBarChart
                  labels={state.data_set.labels}
                  data={state.data_set.data}
                  enableCursorPointer
                  onClickHandler={(coordinates) =>
                    processGraphClick(coordinates, "AlumniDegrees")
                  }
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
}
