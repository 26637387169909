import React from "react";

function SelectDownArrow() {
  return (
    <svg
      id="Action_Dropdown_Arrow"
      data-name="Action/Dropdown/Arrow"
      xmlns="http://www.w3.org/2000/svg"
      width="12.248"
      height="6"
      viewBox="0 0 12.248 6"
    >
      <path
        id="Triangle"
        d="M5.641-.4a.6.6,0,0,0,.821,0l4.858-4.76A.5.5,0,0,0,10.91-6H1.193a.5.5,0,0,0-.41.838Z"
        transform="translate(0.072 6)"
        fill="#607790"
      />
    </svg>
  );
}

export default React.memo(SelectDownArrow);
