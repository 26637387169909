import React from "react";
import { Grid } from "@mui/material";
import {
  ShimmerThumbnail,
  ShimmerSocialPost,
  ShimmerText,
} from "react-shimmer-effects";

export default function CommunityLoader() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={3} md={3} lg={3}>
        {Array.from(Array(10).keys()).map((_, index) => (
          <ShimmerThumbnail height={50} key={index} />
        ))}
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <ShimmerText line={4} />
        {Array.from(Array(3).keys()).map((item, index) => (
          <Grid xs={12} item key={index}>
            <ShimmerSocialPost type="image" key={index} />
            <br />
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} sm={3} md={3} lg={3}>
        <ShimmerText line={20} />
      </Grid>
    </Grid>
  );
}
