import React, { memo, useState, useEffect } from "react";
import moment from "moment";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./SingleTopicPost.module.scss";
import Card from "../../atoms/Card/Card";
import PostComment from "../PostComment/PostComment";
import { CircularAvatar } from "../../atoms/CircularAvatar/CircularAvatar";
import { isEmpty, isNull, validateString } from "../../../utils/validations";

import BinDelete from "../../../assets/Icons/bin-delete.svg";
import LinkContent from "../../atoms/LinkContent/LinkContent";

const cx = createModuleStyleExtractor(styles);

const SingleTopicPost = ({
  index,
  post = {},
  setTopicDetails,
  setDeletePostModal,
  setTopicPostDeleteId,
  setTopicPostCommentDeleteId,
  setDeletePostCommentModal,
}) => {
  const { topic_post: postDetails = {} } = post;
  const [previewUrl, setPreviewUrl] = useState(null);

  const { id: userId } = useSelector((store) => store.userState.user);

  useEffect(() => {
    if (
      postDetails?.topic_post_content &&
      postDetails?.topic_post_content.length
    ) {
      const urldata =
        postDetails.topic_post_content.match(/\bhttps?:\/\/\S+/gi);
      if (urldata && urldata.length) {
        setPreviewUrl(urldata[0]);
      } else {
        setPreviewUrl(null);
      }
    }
  }, [postDetails?.topic_post_content]);

  const getUrlFromDesc = (desc) => {
    const urldata = desc.match(/\bhttps?:\/\/\S+/gi);
    if (urldata && urldata.length) return urldata[0];
  };

  const hasPostDetailsURLDescription =
    !validateString(postDetails.url_description) &&
    postDetails.url_description !== "null";
  const hasURLTitle = postDetails.url_title;
  const isImageTypeVideo = postDetails.image_type === "video";
  const hasImageURL = postDetails.image_url && !isEmpty(postDetails.image_url);
  const hasTopicPostImage = postDetails.topic_post_image !== "";

  return (
    <Card key={index}>
      <div className={cx("m-my-topic-middle-post-detail")}>
        {postDetails.posted_by_id === userId && (
          <div className={cx("m-my-topic-middle-post-detail__delete-post")}>
            <img
              src={BinDelete}
              alt="del"
              onClick={() => {
                setTopicPostDeleteId(postDetails.id);
                setDeletePostModal(true);
              }}
            />
          </div>
        )}
        <div className={cx("m-my-topic-middle-post-detail-header")}>
          <div
            className={cx("m-my-topic-middle-post-detail-header-posted-by-img")}
          >
            <CircularAvatar
              src={postDetails.posted_by_image}
              alt="posted by"
              round
              size={40}
              name={postDetails.posted_by_name}
            />
          </div>
          <div
            className={cx("m-my-topic-middle-post-detail-header-text-wrapper")}
          >
            <span>{postDetails.posted_by_name}</span>
            <span>at {moment(postDetails.posted_at).calendar()}</span>
          </div>
        </div>
        <div className={cx("m-my-topic-middle-post-detail-subheader")}>
          <LinkContent>{postDetails.topic_post_content}</LinkContent>
          {!hasPostDetailsURLDescription &&
          !hasURLTitle &&
          !isImageTypeVideo &&
          !hasImageURL &&
          hasTopicPostImage ? (
            <div
              className={`${cx(
                "m-my-topic-middle-post-detail-default-image"
              )} mt-1`}
            >
              <img src={postDetails.topic_post_image} alt="" />
            </div>
          ) : (
            (hasPostDetailsURLDescription ||
              hasURLTitle ||
              isImageTypeVideo ||
              hasImageURL ||
              hasTopicPostImage) && (
              <div className={cx("topic-middle-post-detail-container")}>
                {!hasTopicPostImage && (
                  <>
                    {previewUrl && !isNull(previewUrl) && <></>}
                    {isImageTypeVideo ? (
                      <>
                        <div
                          className={cx(
                            "m-my-topic-middle-post-detail-preview-video"
                          )}
                        >
                          <ReactPlayer
                            playing={false}
                            url={postDetails.image_url}
                            controls={true}
                          />
                        </div>
                      </>
                    ) : hasImageURL ? (
                      <div
                        className={cx(
                          "m-my-topic-middle-post-detail-preview-image"
                        )}
                      >
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={getUrlFromDesc(postDetails.topic_post_content)}
                        >
                          <img src={postDetails.image_url} alt="" />
                        </a>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
                {hasTopicPostImage && (
                  <div
                    className={cx(
                      "m-my-topic-middle-post-detail-default-image"
                    )}
                  >
                    <img src={postDetails.topic_post_image} alt="" />
                  </div>
                )}
                {(hasURLTitle || hasPostDetailsURLDescription) && (
                  <div className={cx("text-detail-wrapper")}>
                    {hasURLTitle && (
                      <h3>
                        {!isNull(previewUrl) ? (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={getUrlFromDesc(
                              postDetails.topic_post_content
                            )}
                          >
                            {postDetails.url_title}
                          </a>
                        ) : (
                          postDetails.url_title
                        )}
                      </h3>
                    )}
                    {hasPostDetailsURLDescription && (
                      <h4>{postDetails.url_description}</h4>
                    )}
                  </div>
                )}
              </div>
            )
          )}
        </div>

        <PostComment
          post={post}
          setTopicDetails={setTopicDetails}
          setTopicPostCommentDeleteId={setTopicPostCommentDeleteId}
          setDeletePostCommentModal={setDeletePostCommentModal}
        />
      </div>
    </Card>
  );
};

export default memo(SingleTopicPost);
