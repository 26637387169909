import React from "react";
import { TextareaAutosize } from "@mui/material";

import { Modal } from "../../../atoms/Modal/Modal";
import { ModelHeader } from "../../../atoms/Modal/ModelHeader/ModelHeader";
import { Button } from "../../../atoms/Button";

export default function MenuDialog({
  handleClose,
  callAPI,
  data,
  loader = false,
  onTextChange = () => {},
  reportText = "",
}) {
  const { isOpen, modelId, title, subTitle, btnText, bottomText, showTextBox } =
    data;
  return (
    <div className={`topic-menu-dialog ${data.className}`}>
      <Modal show={isOpen} onClose={handleClose}>
        <ModelHeader title={title} />
        <div className="menu-dialog-content">
          {subTitle && <div className="subheader">{subTitle}</div>}
          <div className="content-box">
            {showTextBox && (
              <TextareaAutosize
                placeholder="Please share any complaints or inappropriate behavior."
                onChange={(e) => onTextChange(e.target.value)}
                minRows={2}
                maxRows={2}
              />
            )}
          </div>
          <div className="btn">
            {showTextBox ? (
              <Button
                isSubmitting={loader}
                disabled={loader || reportText?.trim()?.length === 0}
                onClick={() => callAPI(modelId)}
              >
                {btnText}
              </Button>
            ) : (
              <Button
                isSubmitting={loader}
                disabled={loader}
                onClick={() => callAPI(modelId)}
              >
                {btnText}
              </Button>
            )}
          </div>
          <div className="text-center">
            <a
              href="/#"
              className="ngray-link"
              onClick={(e) => {
                e.preventDefault();
                handleClose(e);
              }}
              aria-label={bottomText}
            >
              {bottomText && bottomText}
            </a>
          </div>
        </div>
      </Modal>
    </div>
  );
}
