import React, { useState, memo } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";

import { createModuleStyleExtractor } from "../../../utils/css";
import { Button } from "../../atoms/Button/Button";
import styles from "./ForgotPassword.module.scss";
import { sendForgotPasswordEmail } from "../../../api/onboarding";
import { ROUTES } from "../../../routes";
import toastify from "../../../utils/toast";
import BackIcon from "../../../assets/images/f-btn-arrow-gray.svg";
import MuiTextbox from "../../atoms/MuiTextbox/MuiTextbox";
import { validateString, validateEmail } from "../../../utils/validations";
import { INTERSTRIDE_LOGO_URL } from "../../../utils/constants";

const cx = createModuleStyleExtractor(styles);

const ForgotPassword = ({ defaultEmail }) => {
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const sendEmail = async (email) => {
    setIsSubmitting(true);
    const response = await sendForgotPasswordEmail(email);

    if (response.success && response.data) {
      if (response?.message) toastify(response.message);
      setIsSubmitting(false);
    } else {
      setIsSubmitting(false);
      toastify(
        "Your email address is not registered with us. Please complete the process with Sign Up"
      );
    }
  };

  return (
    <div className={cx("m-forgot-password-container")}>
      <div className={cx("m-forgot-password-container__back-btn")}>
        <a
          href="/login"
          onClick={(e) => {
            e?.preventDefault && e.preventDefault();
            navigate(`${ROUTES.LOGIN}?email=${defaultEmail}`);
          }}
        >
          <img src={BackIcon} id="BackArrow" width="18px" alt="Back arrow" />
          <span>Back</span>
        </a>
      </div>
      <div className={cx("m-forgot-password-container__content")}>
        <div className={cx("m-forgot-password-container__content__unlogo")}>
          <img src={INTERSTRIDE_LOGO_URL} alt="interstride logo" />
        </div>
        <h2>
          Woops!
          <br /> Forgot your password?
        </h2>

        <h5>
          Enter your email below and we’ll send you a link to reset your
          password.
        </h5>
        <Formik
          initialValues={{
            email: defaultEmail ? defaultEmail : "",
          }}
          initialTouched={{
            email: true,
          }}
          validateOnChange={true}
          validateOnBlur={true}
          validate={(values) => {
            let errors = {};

            if (validateString(values.email)) {
              errors.email = "Email is required";
            } else if (validateEmail(values.email)) {
              errors.email = "Email is not valid";
            }

            return errors;
          }}
          onSubmit={(values) => {
            sendEmail(values.email);
          }}
        >
          {({ isValid, values }) => (
            <Form>
              <div
                className={cx(
                  "m-forgot-password-container__content__formfield"
                )}
              >
                <Field name="email">
                  {({ field, meta }) => (
                    <div>
                      <MuiTextbox
                        {...field}
                        value={values.email}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                        name={"email"}
                        label="Enter your email"
                        placeholder={"Enter your email"}
                        error={meta.touched && meta.error}
                      />
                    </div>
                  )}
                </Field>

                <Button
                  type="submit"
                  isSubmitting={isSubmitting}
                  disabled={!isValid}
                >
                  Reset Password
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <h6>
        <p className="mt-0">
          If you don’t see the email, please check your spam folder.
        </p>
        <p className="m-0">Still having problems?</p>
        Email us at{" "}
        <a href="mailto:contact@interstride.com">contact@interstride.com.</a>
      </h6>
    </div>
  );
};
export default memo(ForgotPassword);
