import React, { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./Notifications.module.scss";
import { Button, ButtonModifier } from "../../atoms/Button";
import {
  getAllNotifications,
  removeNotification,
  removeAllPastNotifications,
  readAllNotifications,
} from "../../../api/notification";
import {
  setAllNotifications,
  updateNewNotifications,
  updatePastNotifications,
  resetAllNotificationCounts,
} from "../../../redux/reducers/notificationReducer";
import {
  NotificationsList,
  NotificationsModifier,
} from "../../molecules/NotificationsList/NotificationsList";
import toastify from "../../../utils/toast";
import ListingEffect from "../../atoms/ListingEffect/ListingEffect";
import { Mixpanel } from "../../../MixPanel/mixpanel";
import { ROUTES } from "../../../routes";

const cx = createModuleStyleExtractor(styles);

const Notifications = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isFetching, setIsFetching] = useState(false);

  const notificationState = useSelector((state) => state.notificationState);
  const newNotifications = notificationState.newNotifications;
  const pastNotifications = notificationState.pastNotifications;

  const getNotifications = async () => {
    setIsFetching(true);
    const response = await getAllNotifications();

    if (response.success && response.data) {
      dispatch(setAllNotifications(response.data));
      markAllNotificationsRead();
    } else {
      setIsFetching(false);
    }
  };

  const markAllNotificationsRead = async () => {
    const response = await readAllNotifications();

    if (response.success) {
      dispatch(resetAllNotificationCounts());
    }
    setIsFetching(false);
  };

  useEffect(() => {
    Mixpanel.track(`Notifications visited`);
    getNotifications();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteNotification = async (id, type) => {
    const response = await removeNotification(id);

    if (response.success) {
      toastify(response.message);
      if (type === "new") {
        const updatedNotifications = newNotifications.filter(
          (rec) => rec.id !== id
        );
        dispatch(updateNewNotifications(updatedNotifications));
      } else {
        const updatedNotifications = pastNotifications.filter(
          (rec) => rec.id !== id
        );
        dispatch(updatePastNotifications(updatedNotifications));
      }
    }
  };

  const clearAllPastNotifications = async () => {
    const response = await removeAllPastNotifications();

    if (response.success) {
      toastify(response.message);
      dispatch(updatePastNotifications([]));
    }
  };

  const navigateToSettings = () => {
    navigate(ROUTES.NOTIFICATIONS_SETTINGS, { replace: false });
  };

  return (
    <div className={cx("o-notifications-container")}>
      {isFetching ? (
        <ListingEffect count={5} />
      ) : (
        <>
          <div className={cx("o-notifications-title")}>
            <Button
              modifier={ButtonModifier.NOTIFICATIONS_MANAGE}
              onClick={navigateToSettings}
            >
              Manage Settings
            </Button>
          </div>
          <div className={cx("o-notifications-listing-wrapper")}>
            <NotificationsList
              modifier={NotificationsModifier.LARGE}
              title={"New Notifications"}
              notifications={newNotifications}
              emptyMessage="Looks like you have no new notifications!"
              onDeleteClick={(id) => deleteNotification(id, "new")}
            />
            <NotificationsList
              modifier={NotificationsModifier.LARGE}
              title={"Past Notifications"}
              notifications={pastNotifications}
              actionButton="Clear all"
              onActionButtonClick={clearAllPastNotifications}
              emptyMessage="Looks like you have no past notifications!"
              onDeleteClick={(id) => deleteNotification(id, "past")}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default memo(Notifications);
