import { useEffect } from "react";
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from "firebase/messaging";
import { useDispatch } from "react-redux";

import { setFirebaseToken } from "../redux/reducers/userReducer";

export default function useFirebase() {
  const dispatch = useDispatch();

  const firebaseConfig = {
    apiKey: "AIzaSyCal6I_mnG7mg9yWA4gWRwYogj-OEbWoXs",
    authDomain: "interstride-177910.firebaseapp.com",
    databaseURL: "https://interstride-177910.firebaseio.com",
    projectId: "interstride-177910",
    storageBucket: "interstride-177910.appspot.com",
    messagingSenderId: "662715351295",
    appId: "1:662715351295:web:18882ea3fc0ca0d2550cca",
    measurementId: "G-HX316H7X0B",
  };

  const app = initializeApp(firebaseConfig);

  const messaging = (async () => {
    try {
      const isSupportedBrowser = await isSupported();
      if (isSupportedBrowser) {
        return getMessaging(app);
      }
      console.log(
        "This browser doesn't support the API's required to use the Firebase SDK."
      );
      return null;
    } catch (err) {
      console.log(err);
      return null;
    }
  })();

  onMessage(messaging, (payload) => {
    console.log("Message received. ", payload);
    // ...
  });

  const checkNotificationPromise = () => {
    try {
      Notification.requestPermission().then();
    } catch (e) {
      return false;
    }

    return true;
  };

  const setToken = () => {
    getToken(messaging, {
      vapidKey:
        "BIp3aRuRavBXeDqWb2jFcRbSPQQj4EhBHP2Q_n-Mqxg8CcWqCeKDSx2sF1Q9Fz_rgE44vBLfKoNnxqvSgk4-SEA",
    })
      .then((currentToken) => {
        if (currentToken) {
          dispatch(setFirebaseToken(currentToken));
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });
  };

  useEffect(() => {
    if (messaging === null) return;

    const isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof safari !== "undefined" && window["safari"].pushNotification)
      );

    // Check if the browser supports notifications
    if (!("Notification" in window) || isSafari) {
      console.log("This browser does not support desktop notification");
    }
    // Check whether notification permissions have already been granted;
    else if (Notification.permission === "granted") {
      setToken();
    }
    // Reuqest for the permission to show notifications
    else if (Notification.permission !== "denied") {
      // Check is browser supports promise based permissions
      if (checkNotificationPromise()) {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") setToken();
        });
      }
      // Use older callback version
      else {
        if (typeof Notification?.requestPermission === "function")
          Notification.requestPermission((permission) => {
            if (permission === "granted") setToken();
          });
      }
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {};
}
