import React from "react";

function EmptyPostIcon() {
  return (
    <svg
      width="430px"
      height="267px"
      viewBox="0 0 430 267"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <polygon
          id="path-1"
          points="0 266.750587 429.972444 266.750587 429.972444 0 0 0"
        ></polygon>
      </defs>
      <g
        id="Extra-Assets"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Discover-Topics_Empty"
          transform="translate(-506.000000, -340.000000)"
        >
          <g id="Group-75" transform="translate(506.000000, 340.000000)">
            <path
              d="M265.978583,265.911535 L160.639797,265.911535 C155.790282,265.911535 151.859082,261.988074 151.859082,257.148499 L151.859082,143.262607 L274.759618,143.262607 L274.759618,257.148499 C274.759618,261.988074 270.828419,265.911535 265.978583,265.911535"
              id="Fill-1"
              fill="#FBEEC4"
            ></path>
            <path
              d="M265.978583,265.911535 L160.639797,265.911535 C155.790282,265.911535 151.859082,261.988074 151.859082,257.148499 L151.859082,143.262607 L274.759618,143.262607 L274.759618,257.148499 C274.759618,261.988074 270.828419,265.911535 265.978583,265.911535 Z"
              id="Stroke-3"
              stroke="#FBEEC4"
              strokeWidth="1.67936"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <mask id="mask-2" fill="white">
              <use href="#path-1"></use>
            </mask>
            <g id="Clip-6"></g>
            <polygon
              id="Fill-5"
              fill="#F4B090"
              mask="url(#mask-2)"
              points="151.85921 156.559527 274.759747 156.559527 274.759747 143.262607 151.85921 143.262607"
            ></polygon>
            <polygon
              id="Stroke-7"
              stroke="#000485"
              strokeWidth="1.67936"
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#mask-2)"
              points="151.85921 156.559527 274.759747 156.559527 274.759747 143.262607 151.85921 143.262607"
            ></polygon>
            <path
              d="M231.018686,251.008331 L195.600079,251.008331 C192.089587,251.008331 189.24332,248.168218 189.24332,244.664922 C189.24332,241.161307 192.089587,238.321194 195.600079,238.321194 L231.018686,238.321194 C234.529177,238.321194 237.375124,241.161307 237.375124,244.664922 C237.375124,248.168218 234.529177,251.008331 231.018686,251.008331"
              id="Fill-8"
              fill="#0030B5"
              mask="url(#mask-2)"
            ></path>
            <polygon
              id="Fill-9"
              fill="#FFFFFF"
              mask="url(#mask-2)"
              points="165.684896 208.919666 260.933741 208.919666 260.933741 166.830234 165.684896 166.830234"
            ></polygon>
            <polyline
              id="Fill-10"
              fill="#FFE3E2"
              mask="url(#mask-2)"
              points="213.816603 190.787968 231.067549 181.762728 260.933645 196.03076 260.933645 208.919666 242.679472 208.919666"
            ></polyline>
            <polyline
              id="Fill-11"
              fill="#FF7570"
              mask="url(#mask-2)"
              points="165.684864 208.919538 245.19353 208.919538 222.592256 186.196719 213.816667 190.78784 189.243224 176.587916 165.684864 188.203855"
            ></polyline>
            <polygon
              id="Stroke-12"
              stroke="#000485"
              strokeWidth="1.67936"
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#mask-2)"
              points="165.684896 208.919666 260.933741 208.919666 260.933741 166.830234 165.684896 166.830234"
            ></polygon>
            <path
              d="M305.312945,117.41074 L318.774316,117.41074"
              id="Stroke-13"
              stroke="#000485"
              strokeWidth="1.92"
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M114.96066,265.911535 L9.62187481,265.911535 C4.77203875,265.911535 0.840839046,261.988074 0.840839046,257.148499 L0.840839046,143.262607 L123.741696,143.262607 L123.741696,257.148499 C123.741696,261.988074 119.810176,265.911535 114.96066,265.911535"
              id="Fill-14"
              fill="#EBF1F7"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M114.96066,265.911535 L9.62187481,265.911535 C4.77203875,265.911535 0.840839046,261.988074 0.840839046,257.148499 L0.840839046,143.262607 L123.741696,143.262607 L123.741696,257.148499 C123.741696,261.988074 119.810176,265.911535 114.96066,265.911535 Z"
              id="Stroke-15"
              stroke="#EBF1F7"
              strokeWidth="1.67936"
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#mask-2)"
            ></path>
            <polygon
              id="Fill-16"
              fill="#94BDFF"
              mask="url(#mask-2)"
              points="0.84109538 156.559527 123.741952 156.559527 123.741952 143.262607 0.84109538 143.262607"
            ></polygon>
            <polygon
              id="Stroke-17"
              stroke="#000485"
              strokeWidth="1.67936"
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#mask-2)"
              points="0.84109538 156.559527 123.741952 156.559527 123.741952 143.262607 0.84109538 143.262607"
            ></polygon>
            <path
              d="M80.0004426,251.008331 L44.5821565,251.008331 C41.0713443,251.008331 38.2253979,248.168218 38.2253979,244.664922 C38.2253979,241.161307 41.0713443,238.321194 44.5821565,238.321194 L80.0004426,238.321194 C83.5112548,238.321194 86.3572012,241.161307 86.3572012,244.664922 C86.3572012,248.168218 83.5112548,251.008331 80.0004426,251.008331"
              id="Fill-18"
              fill="#000485"
              mask="url(#mask-2)"
            ></path>
            <polygon
              id="Fill-19"
              fill="#FFFFFF"
              mask="url(#mask-2)"
              points="14.6667809 208.919666 109.915626 208.919666 109.915626 166.830234 14.6667809 166.830234"
            ></polygon>
            <polyline
              id="Fill-20"
              fill="#94BDFF"
              mask="url(#mask-2)"
              points="62.7986803 190.787968 80.0496267 181.762728 109.915722 196.03076 109.915722 208.919666 91.6612288 208.919666"
            ></polyline>
            <polyline
              id="Fill-21"
              fill="#0030B5"
              mask="url(#mask-2)"
              points="14.6669411 208.919538 94.1756073 208.919538 71.5740127 186.196719 62.7987444 190.78784 38.2253018 176.587916 14.6669411 188.203855"
            ></polyline>
            <polygon
              id="Fill-22"
              fill="#FFE3E2"
              mask="url(#mask-2)"
              points="14.6669411 196.030888 38.2253018 196.030888 38.2253018 201.669864 54.421114 201.669864 62.7987444 204.587039 62.7987444 196.030888 67.9417623 196.030888 71.5740127 200.308964 71.5740127 208.919474 14.6669411 208.919474"
            ></polygon>
            <polygon
              id="Stroke-23"
              stroke="#000485"
              strokeWidth="1.67936"
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#mask-2)"
              points="14.6667809 208.919666 109.915626 208.919666 109.915626 166.830234 14.6667809 166.830234"
            ></polygon>
            <path
              d="M114.96066,123.488044 L9.62187481,123.488044 C4.77203875,123.488044 0.840839046,119.564583 0.840839046,114.725008 L0.840839046,0.839115449 L123.741696,0.839115449 L123.741696,114.725008 C123.741696,119.564583 119.810176,123.488044 114.96066,123.488044"
              id="Fill-24"
              fill="#FBEEC4"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M114.96066,123.488044 L9.62187481,123.488044 C4.77203875,123.488044 0.840839046,119.564583 0.840839046,114.725008 L0.840839046,0.839115449 L123.741696,0.839115449 L123.741696,114.725008 C123.741696,119.564583 119.810176,123.488044 114.96066,123.488044 Z"
              id="Stroke-25"
              stroke="#FBEEC4"
              strokeWidth="1.67936"
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#mask-2)"
            ></path>
            <polygon
              id="Fill-26"
              fill="#F4B090"
              mask="url(#mask-2)"
              points="0.84109538 14.136291 123.741952 14.136291 123.741952 0.839371257 0.84109538 0.839371257"
            ></polygon>
            <polygon
              id="Stroke-27"
              stroke="#000485"
              strokeWidth="1.67936"
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#mask-2)"
              points="0.84109538 14.136291 123.741952 14.136291 123.741952 0.839371257 0.84109538 0.839371257"
            ></polygon>
            <path
              d="M80.0004426,108.584999 L44.5821565,108.584999 C41.0713443,108.584999 38.2253979,105.744886 38.2253979,102.241271 C38.2253979,98.737975 41.0713443,95.8978624 44.5821565,95.8978624 L80.0004426,95.8978624 C83.5112548,95.8978624 86.3572012,98.737975 86.3572012,102.241271 C86.3572012,105.744886 83.5112548,108.584999 80.0004426,108.584999"
              id="Fill-28"
              fill="#FF7570"
              mask="url(#mask-2)"
            ></path>
            <polygon
              id="Fill-29"
              fill="#FFFFFF"
              mask="url(#mask-2)"
              points="14.6667809 66.4961102 109.915626 66.4961102 109.915626 24.4066778 14.6667809 24.4066778"
            ></polygon>
            <polyline
              id="Fill-30"
              fill="#FFE3E2"
              mask="url(#mask-2)"
              points="62.7986803 48.3644759 80.0496267 39.3392364 109.915722 53.6075885 109.915722 66.4961741 91.6612288 66.4961741"
            ></polyline>
            <polyline
              id="Fill-31"
              fill="#FF7570"
              mask="url(#mask-2)"
              points="14.6669411 66.4962061 94.1756073 66.4962061 71.5740127 43.7733869 62.7987444 48.3645079 38.2253018 34.1645846 14.6669411 45.7802037"
            ></polyline>
            <polygon
              id="Stroke-32"
              stroke="#000485"
              strokeWidth="1.67936"
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#mask-2)"
              points="14.6667809 66.4961102 109.915626 66.4961102 109.915626 24.4066778 14.6667809 24.4066778"
            ></polygon>
            <path
              d="M265.978583,123.488044 L160.639797,123.488044 C155.790282,123.488044 151.859082,119.564583 151.859082,114.725008 L151.859082,0.839115449 L274.759618,0.839115449 L274.759618,114.725008 C274.759618,119.564583 270.828419,123.488044 265.978583,123.488044"
              id="Fill-33"
              fill="#EBF1F7"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M265.978583,123.488044 L160.639797,123.488044 C155.790282,123.488044 151.859082,119.564583 151.859082,114.725008 L151.859082,0.839115449 L274.759618,0.839115449 L274.759618,114.725008 C274.759618,119.564583 270.828419,123.488044 265.978583,123.488044 Z"
              id="Stroke-34"
              stroke="#EBF1F7"
              strokeWidth="1.67936"
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#mask-2)"
            ></path>
            <polygon
              id="Fill-35"
              fill="#94BDFF"
              mask="url(#mask-2)"
              points="151.85921 14.136291 274.759747 14.136291 274.759747 0.839371257 151.85921 0.839371257"
            ></polygon>
            <polygon
              id="Stroke-36"
              stroke="#000485"
              strokeWidth="1.67936"
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#mask-2)"
              points="151.85921 14.136291 274.759747 14.136291 274.759747 0.839371257 151.85921 0.839371257"
            ></polygon>
            <path
              d="M231.018686,108.584999 L195.600079,108.584999 C192.089587,108.584999 189.24332,105.744886 189.24332,102.241271 C189.24332,98.737975 192.089587,95.8978624 195.600079,95.8978624 L231.018686,95.8978624 C234.529177,95.8978624 237.375124,98.737975 237.375124,102.241271 C237.375124,105.744886 234.529177,108.584999 231.018686,108.584999"
              id="Fill-37"
              fill="#000485"
              mask="url(#mask-2)"
            ></path>
            <polygon
              id="Fill-38"
              fill="#FFFFFF"
              mask="url(#mask-2)"
              points="165.684896 66.4961102 260.933741 66.4961102 260.933741 24.4066778 165.684896 24.4066778"
            ></polygon>
            <polyline
              id="Fill-39"
              fill="#94BDFF"
              mask="url(#mask-2)"
              points="213.816603 48.3644759 231.067549 39.3392364 260.933645 53.6075885 260.933645 66.4961741 242.679472 66.4961741"
            ></polyline>
            <polyline
              id="Fill-40"
              fill="#0030B5"
              mask="url(#mask-2)"
              points="165.684864 66.4962061 245.19353 66.4962061 222.592256 43.7733869 213.816667 48.3645079 189.243224 34.1645846 165.684864 45.7802037"
            ></polyline>
            <polygon
              id="Fill-41"
              fill="#FFE3E2"
              mask="url(#mask-2)"
              points="165.684864 53.6075885 189.243224 53.6075885 189.243224 59.2462448 205.439037 59.2462448 213.816667 62.1637394 213.816667 53.6075885 218.959685 53.6075885 222.592256 57.885664 222.592256 66.4961741 165.684864 66.4961741"
            ></polygon>
            <polygon
              id="Stroke-42"
              stroke="#000485"
              strokeWidth="1.67936"
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#mask-2)"
              points="165.684896 66.4961102 260.933741 66.4961102 260.933741 24.4066778 165.684896 24.4066778"
            ></polygon>
            <path
              d="M419.74575,265.911535 L314.406964,265.911535 C309.557128,265.911535 305.625928,261.988074 305.625928,257.148499 L305.625928,143.262607 L428.526465,143.262607 L428.526465,257.148499 C428.526465,261.988074 424.595265,265.911535 419.74575,265.911535"
              id="Fill-43"
              fill="#EBF1F7"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M419.74575,265.911535 L314.406964,265.911535 C309.557128,265.911535 305.625928,261.988074 305.625928,257.148499 L305.625928,143.262607 L428.526465,143.262607 L428.526465,257.148499 C428.526465,261.988074 424.595265,265.911535 419.74575,265.911535 Z"
              id="Stroke-44"
              stroke="#EBF1F7"
              strokeWidth="1.67936"
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#mask-2)"
            ></path>
            <polygon
              id="Fill-45"
              fill="#94BDFF"
              mask="url(#mask-2)"
              points="305.625544 156.559527 428.526401 156.559527 428.526401 143.262607 305.625544 143.262607"
            ></polygon>
            <polygon
              id="Stroke-46"
              stroke="#000485"
              strokeWidth="1.67936"
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#mask-2)"
              points="305.625544 156.559527 428.526401 156.559527 428.526401 143.262607 305.625544 143.262607"
            ></polygon>
            <path
              d="M384.785532,251.008331 L349.366925,251.008331 C345.856434,251.008331 343.010487,248.168218 343.010487,244.664922 C343.010487,241.161307 345.856434,238.321194 349.366925,238.321194 L384.785532,238.321194 C388.296024,238.321194 391.142291,241.161307 391.142291,244.664922 C391.142291,248.168218 388.296024,251.008331 384.785532,251.008331"
              id="Fill-47"
              fill="#FF7570"
              mask="url(#mask-2)"
            ></path>
            <polygon
              id="Fill-48"
              fill="#FFFFFF"
              mask="url(#mask-2)"
              points="319.45155 208.919666 414.700715 208.919666 414.700715 166.830234 319.45155 166.830234"
            ></polygon>
            <polyline
              id="Fill-49"
              fill="#94BDFF"
              mask="url(#mask-2)"
              points="367.583449 190.787968 384.834716 181.762728 414.700811 196.03076 414.700811 208.919666 396.446318 208.919666"
            ></polyline>
            <polyline
              id="Fill-50"
              fill="#0030B5"
              mask="url(#mask-2)"
              points="319.451678 208.919538 398.960665 208.919538 376.35907 186.196719 367.583481 190.78784 343.010359 176.587916 319.451678 188.203855"
            ></polyline>
            <polygon
              id="Fill-51"
              fill="#FFE3E2"
              mask="url(#mask-2)"
              points="319.451678 196.030888 343.010359 196.030888 343.010359 201.669864 359.205851 201.669864 367.583481 204.587039 367.583481 196.030888 372.72682 196.030888 376.35907 200.308964 376.35907 208.919474 319.451678 208.919474"
            ></polygon>
            <polygon
              id="Stroke-52"
              stroke="#000485"
              strokeWidth="1.67936"
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#mask-2)"
              points="319.45155 208.919666 414.700715 208.919666 414.700715 166.830234 319.45155 166.830234"
            ></polygon>
            <path
              d="M419.74575,123.488044 L314.406964,123.488044 C309.557128,123.488044 305.625928,119.564583 305.625928,114.725008 L305.625928,0.839115449 L428.526465,0.839115449 L428.526465,114.725008 C428.526465,119.564583 424.595265,123.488044 419.74575,123.488044"
              id="Fill-53"
              fill="#FF7570"
              mask="url(#mask-2)"
            ></path>
            <polygon
              id="Fill-54"
              fill="#FFE3E2"
              mask="url(#mask-2)"
              points="305.625544 14.136291 428.526401 14.136291 428.526401 0.839371257 305.625544 0.839371257"
            ></polygon>
            <polygon
              id="Stroke-55"
              stroke="#000485"
              strokeWidth="1.67936"
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#mask-2)"
              points="305.625544 14.136291 428.526401 14.136291 428.526401 0.839371257 305.625544 0.839371257"
            ></polygon>
            <path
              d="M384.785532,108.584999 L349.366925,108.584999 C345.856434,108.584999 343.010487,105.744886 343.010487,102.241271 C343.010487,98.737975 345.856434,95.8978624 349.366925,95.8978624 L384.785532,95.8978624 C388.296024,95.8978624 391.142291,98.737975 391.142291,102.241271 C391.142291,105.744886 388.296024,108.584999 384.785532,108.584999"
              id="Fill-56"
              fill="#0030B5"
              mask="url(#mask-2)"
            ></path>
            <polygon
              id="Fill-57"
              fill="#FFFFFF"
              mask="url(#mask-2)"
              points="319.45155 66.4961102 414.700715 66.4961102 414.700715 24.4066778 319.45155 24.4066778"
            ></polygon>
            <polyline
              id="Fill-58"
              fill="#0030B5"
              mask="url(#mask-2)"
              points="367.583449 48.3644759 384.834716 39.3392364 414.700811 53.6075885 414.700811 66.4961741 396.446318 66.4961741"
            ></polyline>
            <polyline
              id="Fill-59"
              fill="#0030B5"
              mask="url(#mask-2)"
              points="319.451678 66.4962061 398.960665 66.4962061 376.35907 43.7733869 367.583481 48.3645079 343.010359 34.1645846 319.451678 45.7802037"
            ></polyline>
            <polygon
              id="Stroke-60"
              stroke="#000485"
              strokeWidth="1.67936"
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#mask-2)"
              points="319.45155 66.4961102 414.700715 66.4961102 414.700715 24.4066778 319.45155 24.4066778"
            ></polygon>
            <g id="Group-63" mask="url(#mask-2)">
              <g transform="translate(174.307004, 61.784120)">
                <polyline
                  id="Fill-61"
                  stroke="none"
                  fill="#000485"
                  fillRule="evenodd"
                  points="0.0316251863 130.139541 254.704092 156.834105 254.512482 110.547497 44.2520946 0.0104561677"
                ></polyline>
                <polyline
                  id="Stroke-62"
                  stroke="#000485"
                  strokeWidth="1.92"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  points="0.0316251863 130.139541 254.704092 156.834105 254.512482 110.547497 44.2520946 0.0104561677"
                ></polyline>
              </g>
            </g>
            <path
              d="M253.221361,122.282547 C253.221361,161.030482 221.745489,192.442153 182.917642,192.442153 C144.089796,192.442153 112.613924,161.030482 112.613924,122.282547 C112.613924,83.5342915 144.089796,52.1226208 182.917642,52.1226208 C221.745489,52.1226208 253.221361,83.5342915 253.221361,122.282547"
              id="Fill-64"
              fill="#D1DDE5"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M253.221361,122.282547 C253.221361,161.030482 221.745489,192.442153 182.917642,192.442153 C144.089796,192.442153 112.613924,161.030482 112.613924,122.282547 C112.613924,83.5342915 144.089796,52.1226208 182.917642,52.1226208 C221.745489,52.1226208 253.221361,83.5342915 253.221361,122.282547 Z"
              id="Stroke-65"
              stroke="#000485"
              strokeWidth="1.92"
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M242.65073,122.282547 C242.65073,155.204446 215.907422,181.893254 182.917578,181.893254 C149.927735,181.893254 123.184426,155.204446 123.184426,122.282547 C123.184426,89.3603274 149.927735,62.6718388 182.917578,62.6718388 C215.907422,62.6718388 242.65073,89.3603274 242.65073,122.282547"
              id="Fill-66"
              fill="#FFFFFF"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M242.65073,122.282547 C242.65073,155.204446 215.907422,181.893254 182.917578,181.893254 C149.927735,181.893254 123.184426,155.204446 123.184426,122.282547 C123.184426,89.3603274 149.927735,62.6718388 182.917578,62.6718388 C215.907422,62.6718388 242.65073,89.3603274 242.65073,122.282547 Z"
              id="Stroke-67"
              stroke="#000485"
              strokeWidth="1.92"
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M242.65073,122.282547 C242.65073,122.282547 215.907422,154.435742 182.917578,154.435742 C149.927735,154.435742 123.184426,122.282547 123.184426,122.282547 C123.184426,122.282547 149.927735,90.1293514 182.917578,90.1293514 C215.907422,90.1293514 242.65073,122.282547 242.65073,122.282547"
              id="Fill-68"
              fill="#FFFFFF"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M242.65073,122.282547 C242.65073,122.282547 215.907422,154.435742 182.917578,154.435742 C149.927735,154.435742 123.184426,122.282547 123.184426,122.282547 C123.184426,122.282547 149.927735,90.1293514 182.917578,90.1293514 C215.907422,90.1293514 242.65073,122.282547 242.65073,122.282547 Z"
              id="Stroke-69"
              stroke="#000485"
              strokeWidth="1.92"
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M208.731548,122.282547 C208.731548,136.509969 197.174417,148.04341 182.91777,148.04341 C168.660803,148.04341 157.103672,136.509969 157.103672,122.282547 C157.103672,108.055124 168.660803,96.5216831 182.91777,96.5216831 C197.174417,96.5216831 208.731548,108.055124 208.731548,122.282547"
              id="Fill-70"
              fill="#566D82"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M208.731548,122.282547 C208.731548,136.509969 197.174417,148.04341 182.91777,148.04341 C168.660803,148.04341 157.103672,136.509969 157.103672,122.282547 C157.103672,108.055124 168.660803,96.5216831 182.91777,96.5216831 C197.174417,96.5216831 208.731548,108.055124 208.731548,122.282547 Z"
              id="Stroke-71"
              stroke="#000485"
              strokeWidth="1.92"
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M192.635578,123.789706 C192.635578,129.145694 188.284632,133.487721 182.917642,133.487721 C177.550653,133.487721 173.199706,129.145694 173.199706,123.789706 C173.199706,118.433718 177.550653,114.09169 182.917642,114.09169 C188.284632,114.09169 192.635578,118.433718 192.635578,123.789706"
              id="Fill-72"
              fill="#000C3D"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M184.056598,112.584499 C184.056598,117.940487 179.705651,122.282515 174.338662,122.282515 C168.971672,122.282515 164.620726,117.940487 164.620726,112.584499 C164.620726,107.228511 168.971672,102.886483 174.338662,102.886483 C179.705651,102.886483 184.056598,107.228511 184.056598,112.584499"
              id="Fill-73"
              fill="#FFFFFF"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M184.056598,112.584499 C184.056598,117.940487 179.705651,122.282515 174.338662,122.282515 C168.971672,122.282515 164.620726,117.940487 164.620726,112.584499 C164.620726,107.228511 168.971672,102.886483 174.338662,102.886483 C179.705651,102.886483 184.056598,107.228511 184.056598,112.584499 Z"
              id="Stroke-74"
              stroke="#000485"
              strokeWidth="1.92"
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#mask-2)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default React.memo(EmptyPostIcon);
