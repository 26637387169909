import React from "react";
import { generateColumnClasses } from "./const";

export const Col = ({ xs, sm, md, lg, children, styles }) => {
  return (
    <div
      className={generateColumnClasses({
        ...(sm && { sm }),
        ...(lg && { lg }),
        ...(md && { md }),
        ...(xs && { xs }),
      })}
      style={styles}
    >
      {children}
    </div>
  );
};
