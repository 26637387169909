import React from "react";
import PerspectiveCheckIcon from "../../../assets/Icons/PerspectiveCheckIcon";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./PerceptiveItem.module.scss";
const cx = createModuleStyleExtractor(styles);
export const PerceptiveItem = ({ text }) => {
  return (
    <ul className={cx("m-perspective-item")}>
      <li>
        <div className={cx("m-perspective-item__icon")}>
          <PerspectiveCheckIcon />
        </div>
        <div className={cx("m-perspective-item__text")}>{text}</div>
      </li>
    </ul>
  );
};
