import { ApiWrapper, REQUEST } from ".";

// Save profile
export const updateUser = async (userId, payload) => {
  return await ApiWrapper({
    url: `/users/${userId}`,
    method: REQUEST.PUT,
    data: payload,
  });
};

// Call on profile page to edit user email
export const editUserEmail = async (email) => {
  return await ApiWrapper({
    url: "/user_email_confirmation",
    method: REQUEST.PUT,
    data: { email },
  });
};

// Change password
export const updateUserPassword = async (password, repeatPassword) => {
  return await ApiWrapper({
    url: "/update_user_password",
    method: REQUEST.PATCH,
    data: {
      user: {
        password: password,
        password_confirmation: repeatPassword,
      },
    },
  });
};

// Verify Email
export const verifyEmail = async (email_token) => {
  return await ApiWrapper({
    url: "/update_user_email",
    method: REQUEST.PATCH,
    data: { email_token },
  });
};

// Update or remove user profile
export const updateProfileImage = async (payload) => {
  return await ApiWrapper({
    url: "/user_information/update_profile_image",
    method: REQUEST.PATCH,
    data: payload,
  });
};

// Close account
export const closeAccount = async () => {
  return await ApiWrapper({
    url: "/users/delete_account",
    method: REQUEST.DELETE,
  });
};

export const getUserProfileDetails = async () => {
  return await ApiWrapper({
    url: "/user_information",
    method: REQUEST.GET,
  });
};

// Invite a friend
export const inviteFriend = async (payload) => {
  return await ApiWrapper({
    url: "/users/invite_friend",
    method: REQUEST.POST,
    data: payload,
  });
};
