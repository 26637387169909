import React, { memo, useState } from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import { Button } from "../../atoms/Button";
import SingleSelectCard from "../../atoms/SingleSelectCard/SingleSelectCard";
import styles from "./WidgetSignUpOverlay.module.scss";
import {
  UNIVERSITY_WIDGET_SOURCE,
  getUniversityVisitURL,
} from "../../../utils/common";

import CloseIcon from "../../../assets/Icons/CloseIcon";

const cx = createModuleStyleExtractor(styles);

const WidgetSignUpOverlay = ({
  token,
  title = "",
  closeOption = true,
  route_type,
  route_id,
  route_view,
  closeOverlay = () => {},
}) => {
  const [role, setRole] = useState();

  return (
    <div className={cx("m-widget-sign-up-overlay")}>
      <div className={cx("m-widget-sign-up-overlay__signup-view")}>
        {closeOption && (
          <span className={cx("m-widget-sign-up-overlay__signup-view__close")}>
            <CloseIcon onClick={closeOverlay} />
          </span>
        )}

        <h2>{title}</h2>
        <h5>
          Already have an account?
          <a
            href={getUniversityVisitURL(token, UNIVERSITY_WIDGET_SOURCE, {
              route_type,
              ...(route_id ? { route_id } : {}),
              ...(route_view ? { route_view } : {}),
            })}
            target="_blank"
            rel="noreferrer"
          >
            Log In
          </a>
        </h5>
        <div className={cx("m-widget-sign-up-overlay__signup-view__select")}>
          <SingleSelectCard
            header={"Before creating an account, tell us who you are."}
            options={[
              {
                value: "prospect",
                title: "I'm a student",
              },
              {
                value: "parent",
                title: "I'm a parent",
              },
            ]}
            value={role}
            onClick={(value) => setRole(value)}
          />
        </div>
        <Button
          type="submit"
          disabled={!role}
          onClick={() =>
            window.open(
              getUniversityVisitURL(token, UNIVERSITY_WIDGET_SOURCE, {
                role,
                route_type,
                ...(route_id ? { route_id } : {}),
                ...(route_view ? { route_view } : {}),
              })
            )
          }
        >
          Get started
        </Button>
      </div>
    </div>
  );
};
export default memo(WidgetSignUpOverlay);
