import React, { useState, memo } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import Bugsnag from "@bugsnag/js";

import { createModuleStyleExtractor } from "../../../utils/css";
import { Button } from "../../atoms/Button/Button";
import { setLoginData } from "../../../redux/reducers/userReducer";
import styles from "./AuthInviteSignUp.module.scss";
import { invitedProspectParentSignUp } from "../../../api/onboarding";
import toastify from "../../../utils/toast";
import MuiTextbox from "../../atoms/MuiTextbox/MuiTextbox";
import Checkbox from "../../atoms/Checkbox/Checkbox";
import { validatePassword, validateString } from "../../../utils/validations";
import { Mixpanel } from "../../../MixPanel/mixpanel";
import { setMSClarityTagsOnSignUp } from "../../../utils/common";
import { INTERSTRIDE_LOGO_URL } from "../../../utils/constants";

const cx = createModuleStyleExtractor(styles);

const AuthInviteSignUp = ({ schoolData, email, token }) => {
  const dispatch = useDispatch();
  const validToken = schoolData && schoolData !== null;

  const [password, setPassword] = useState("");
  const [tnc, setTnc] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleLoginResponse = (response) => {
    if (response.success) {
      const { users = {}, schools = [] } = response.data;

      sessionStorage.setItem("isLogin", "1");

      // Set user details for bugsnag
      Bugsnag.setUser(users.id, users.email, users.name);
      Mixpanel.identify(users.id);
      Mixpanel.track("Invite sign up");
      setMSClarityTagsOnSignUp(users);

      dispatch(
        setLoginData({
          user: { ...users },
          schools,
        })
      );
      // Redirection to route after login is handled from App.js effects
      setIsSubmitting(false);
    } else {
      toastify(response.message);
      setIsSubmitting(false);
    }
  };

  const handleSignUpClick = async () => {
    setIsSubmitting(true);
    const params = {
      user: {
        email,
        password,
        token,
      },
    };

    if (validToken) {
      params.school_id = schoolData.id;
      params.school_token = token;
    }

    const response = await invitedProspectParentSignUp(params);
    handleLoginResponse(response);
  };

  return (
    <div className={cx("m-auth-invite-sign-up")}>
      <div className={cx("m-auth-invite-sign-up__content")}>
        {validToken ? (
          <>
            <div className={cx("m-auth-invite-sign-up__content__unlogo")}>
              <img src={schoolData.logo_url} alt="University logo" />
            </div>
            <div className={cx("m-auth-invite-sign-up__content__sitelogo")}>
              <h3>Powered by</h3>
              <img src={INTERSTRIDE_LOGO_URL} alt="interstride logo" />
            </div>
          </>
        ) : (
          <>
            <div className={cx("m-auth-invite-sign-up__content__unlogo")}>
              <img src={INTERSTRIDE_LOGO_URL} alt="interstride logo" />
            </div>
          </>
        )}

        <h2>Welcome to the Admissions Portal</h2>

        <h5>Create an account</h5>
        <Formik
          initialValues={{
            password: "",
            tnc: "",
          }}
          validateOnChange={true}
          validateOnBlur={true}
          validate={(values) => {
            let errors = {};

            if (validateString(values.password)) {
              errors.password = "Password is required";
            } else if (values.password?.length < 8) {
              errors.password = "Password must have at least 8 characters";
            } else if (validatePassword(values.password)) {
              errors.password =
                "Password should have both upper and lowercase characters";
            }

            return errors;
          }}
          onSubmit={() => {
            handleSignUpClick();
          }}
        >
          {({ isValid }) => (
            <Form>
              <div className={cx("m-auth-invite-sign-up__content__formfield")}>
                <MuiTextbox
                  disabled={true}
                  value={email}
                  name={"email"}
                  label="Enter your email"
                  placeholder={"Enter your email"}
                />

                <Field name="password">
                  {({ field, meta }) => (
                    <div>
                      <MuiTextbox
                        {...field}
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          field.onChange(e);
                        }}
                        name={"password"}
                        label="Choose a password"
                        placeholder={"Choose a password"}
                        type="password"
                        error={meta.touched && meta.error}
                      />
                    </div>
                  )}
                </Field>

                <div
                  className={cx(
                    "m-auth-invite-sign-up__content__formfield__keepMeSignin--T-C"
                  )}
                >
                  <Checkbox
                    value={tnc}
                    onChange={(e) => {
                      setTnc(e.target.checked);
                    }}
                  />
                  <span>
                    I agree to the Interstride{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={"https://www.interstride.com/terms-and-conditions/"}
                      className="link-focus"
                    >
                      Terms & Conditions
                    </a>{" "}
                    and{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={"https://www.interstride.com/privacy-policy/"}
                      className="link-focus"
                    >
                      Privacy Policy
                    </a>
                  </span>
                </div>

                <Button
                  type="submit"
                  isSubmitting={isSubmitting}
                  disabled={!tnc || !isValid}
                >
                  Create account
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
export default memo(AuthInviteSignUp);
