import React, { memo, useState, useEffect } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Col } from "../../helpers/Grid/Col";
import { Row } from "../../helpers/Grid/Row";
import AuthLogin from "../../molecules/AuthLogin/AuthLogin";
import AuthSignUp from "../../molecules/AuthSignUp/AuthSignUp";
import VerifyEmail from "../../molecules/VerifyEmail/VerifyEmail";
import styles from "./Login.module.scss";
import Perspective from "../../molecules/Perspective/Perspective";
import { createModuleStyleExtractor } from "../../../utils/css";
import { validateUniversityToken } from "../../../api/onboarding";
import { DEFAULT_PRIMARY_COLOR } from "../../../config/config";
import { setThemeColor } from "../../../redux/reducers/preservedReducer";
import InvalidProspectToken from "../../molecules/InvalidProspectToken/InvalidProspectToken";
import InterstridePageLoader from "../../atoms/InterstridePageLoader/InterstridePageLoader";
import { Mixpanel } from "../../../MixPanel/mixpanel";
import AuthRoleSelection from "../../molecules/AuthRoleSelection/AuthRoleSelection";
import WidgetSignInForm from "../../molecules/WidgetSignInForm/WidgetSignInForm";

const cx = createModuleStyleExtractor(styles);

const Login = () => {
  const dispatch = useDispatch();

  const routeParams = useParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token") ?? routeParams.token ?? false;
  const ssoToken = searchParams.get("sso") ? searchParams.get("sso") : false;
  const email = searchParams.get("email")
    ? decodeURIComponent(searchParams.get("email"))
    : false;
  const verifyEmail = searchParams.get("verify-email")
    ? decodeURIComponent(searchParams.get("verify-email"))
    : "";
  const roleParam = searchParams.get("role") ? searchParams.get("role") : false;

  const [step, setStep] = useState(1);
  const [schoolData, setSchoolData] = useState(null);
  const [showLoader, setShowLoader] = useState(token ? true : false);
  const [role, setRole] = useState(roleParam ? roleParam : false);
  const [enteredEmail, setEnteredEmail] = useState("");
  const [allowEmailEdit, setAllowEmailEdit] = useState(false);

  useEffect(() => {
    const checkUniversityToken = async () => {
      const response = await validateUniversityToken(token);

      if (response.success && response.data) {
        const {
          data: { school = {} },
        } = response;

        Mixpanel.track(`Visited with school token`, {
          token,
          school_id: school?.id,
        });

        const themeColor =
          school && school.school_setting
            ? school.school_setting.theme_color
            : DEFAULT_PRIMARY_COLOR;
        dispatch(setThemeColor(themeColor));
        setSchoolData(school);
        // Set signup step if role is already then in URL else role selection step
        setStep(roleParam ? 1 : 2);
        setShowLoader(false);
      } else {
        // Show invalid token page
        setStep(6);
        setShowLoader(false);
      }
    };
    if (token) {
      checkUniversityToken();
    }
  }, [token, dispatch, roleParam]);

  const goBackToLogin = () => {
    setAllowEmailEdit(false);
    setStep(1);
  };

  const handleProspectFormSubmit = () => {
    setStep(4);
  };
  const goToProspectForm = (form_email) => {
    setStep(5);
    setEnteredEmail(form_email);
  };
  return (
    <>
      {showLoader ? (
        <InterstridePageLoader />
      ) : (
        <Row className={cx("o-login-container")}>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Perspective schoolData={schoolData} />
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            {step === 1 ? (
              <AuthLogin
                goToProspectForm={goToProspectForm}
                goToSignUp={(email) => {
                  if (token && role === "prospect") {
                    setAllowEmailEdit(true);
                    goToProspectForm(email);
                  } else {
                    setStep(2);
                  }
                }}
                schoolData={schoolData}
                ssoToken={ssoToken}
                verifyEmail={verifyEmail}
                defaultEmail={email}
                token={token}
                role={role}
              />
            ) : step === 2 ? (
              <AuthRoleSelection
                goBackToLogin={goBackToLogin}
                goToSignUp={() => {
                  if (token && role === "prospect") {
                    setAllowEmailEdit(true);
                    setStep(5);
                  } else {
                    setStep(3);
                  }
                }}
                schoolData={schoolData}
                role={role}
                setRole={setRole}
              />
            ) : step === 3 ? (
              <AuthSignUp
                goBackToLogin={goBackToLogin}
                goBackToRoleSelection={() => setStep(2)}
                goToVerifyEmail={() => setStep(4)}
                schoolData={schoolData}
                role={role}
                token={token}
              />
            ) : step === 4 ? (
              <VerifyEmail
                goBackToLogin={goBackToLogin}
                schoolData={schoolData}
              />
            ) : step === 5 ? (
              <WidgetSignInForm
                allowEmailEdit={allowEmailEdit}
                onSubmit={handleProspectFormSubmit}
                schoolData={schoolData}
                goBackToLogin={goBackToLogin}
                email={enteredEmail}
                role={role}
                token={token}
              />
            ) : (
              <InvalidProspectToken goBackToLogin={goBackToLogin} />
            )}
          </Col>
        </Row>
      )}
    </>
  );
};
export default memo(Login);
