import React, { memo } from "react";
import { createModuleStyleExtractor } from "../../../utils/css";
import { PerceptiveItem } from "../PerceptiveItem/PerceptiveItem";
import styles from "./Perspective.module.scss";

const cx = createModuleStyleExtractor(styles);

const Perspective = ({ schoolData = {} }) => {
  const validToken = schoolData && schoolData !== null;

  const instructions = [
    "Network with students who are living the experience and discover what it’s really like",
    "Filter out ambassadors who come from your country or have the same study interests",
    "Get access to checklists and tasks related to your admissions",
    "Learn everything you need to know about studying abroad",
  ];
  return (
    <div className={cx("m-perspective-container")}>
      <div>
        <h3 className={cx("m-perspective-container__heading")}>
          Get insider perspective on studying and living{" "}
          {validToken ? `at ${schoolData.name}` : "in United States."}
        </h3>
        <div className={cx("m-perspective-container__bullets")}>
          {instructions.map((item, index) => (
            <PerceptiveItem key={index} text={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(Perspective);
