import React from "react";

function LinkedinIcon({ onClick }) {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      fill="none"
      viewBox="0 0 34 34"
    >
      <circle cx="17" cy="17" r="17" fill="#0A66C2"></circle>
      <path
        fill="#fff"
        d="M11.75 9.957c0 1.095-.825 1.956-1.875 1.956S8 11.053 8 9.957 8.825 8 9.875 8s1.875.86 1.875 1.957zm0 3.521H8V26h3.75V13.478zm6 0H14V26h3.75v-6.574c0-3.678 4.5-3.991 4.5 0V26H26v-7.904c0-6.183-6.675-5.948-8.25-2.896v-1.722z"
      ></path>
    </svg>
  );
}

export default React.memo(LinkedinIcon);
