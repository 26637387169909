import React from "react";

function DownArrow() {
  return (
    <svg
      width="13px"
      height="9px"
      viewBox="0 0 13 9"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Action/Dropdown/Card/Arrow" stroke="#607790" strokeWidth="1.5">
          <polyline id="Path-3" points="1 1 6.5 7 12 1"></polyline>
        </g>
      </g>
    </svg>
  );
}

export default React.memo(DownArrow);
