import React from "react";

import { createModuleStyleExtractor } from "../../../../utils/css";
import styles from "./InfoWindow.module.scss";

import GoogleMapWebIcon from "../../../../assets/svg/GoogleMapWebIcon";
import GoogleMapDirectionIcon from "../../../../assets/svg/GoogleMapDirectionIcon";

const cx = createModuleStyleExtractor(styles);

export default function InfoWindow({ poi = {}, handleClick = () => {} }) {
  const handleClickDirections = () => {
    if (poi?.google_map_url) {
      window.open(poi?.google_map_url);
    } else if (poi?.address) {
      window.open("http://maps.google.com/?q=" + poi?.address);
    }
  };

  return (
    <div
      className={cx("a-info-window")}
      onClick={() =>
        handleClick(
          null,
          poi?.id,
          {
            lat: parseFloat(poi?.latitude),
            lng: parseFloat(poi?.longitude),
          },
          true
        )
      }
    >
      <div className={cx("a-info-window__body")}>
        <h3 className={cx("a-info-window__body__title")}>{poi?.name}</h3>
        {poi?.description && (
          <p className={cx("a-info-window__body__description")}>
            {poi?.description}
          </p>
        )}
        <div className={cx("a-info-window__body__actions")}>
          {poi?.website && (
            <GoogleMapWebIcon
              onClick={() => poi?.website && window.open(poi.website)}
            />
          )}
          {(poi?.google_map_url || poi?.address) && (
            <GoogleMapDirectionIcon onClick={handleClickDirections} />
          )}
        </div>
      </div>
      {poi?.image_url && (
        <div className={cx("a-info-window__image")}>
          <img src={poi?.image_url} alt="poi" />
        </div>
      )}
    </div>
  );
}
