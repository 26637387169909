import React, { memo } from "react";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./Switch.module.scss";

const cx = createModuleStyleExtractor(styles);

const Switch = ({
  leftTitle = "",
  rightTitle = "",
  activeSide = "left",
  setActiveSide = () => {},
}) => {
  return (
    <div className={cx("a-switch")}>
      <div
        onClick={() => {
          if (activeSide !== "left") setActiveSide("left");
        }}
        className={
          activeSide === "left"
            ? cx(["a-switch-item", "a-switch-selected"])
            : cx("a-switch-item")
        }
      >
        {leftTitle}
      </div>
      <div
        onClick={() => {
          if (activeSide !== "right") setActiveSide("right");
        }}
        className={
          activeSide === "right"
            ? cx(["a-switch-item", "a-switch-selected"])
            : cx("a-switch-item")
        }
      >
        {rightTitle}
      </div>
    </div>
  );
};

export default memo(Switch);
