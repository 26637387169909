import React, { useState, useEffect, memo } from "react";
import { ShimmerPostItem } from "react-shimmer-effects";
import Masonry from "@mui/lab/Masonry";

import { getCommunityList } from "../../../api/widget";
import { ROUTE_TYPES } from "../../../utils/common";
import { createModuleStyleExtractor } from "../../../utils/css";
import { Col } from "../../helpers/Grid/Col";
import { Row } from "../../helpers/Grid/Row";
import styles from "./WidgetCommunityTab.module.scss";
import EmptyDiscoverTopicsIcon from "../../../assets/Icons/EmptyDiscoverTopicsIcon";
import WidgetTopicCard from "../../atoms/WidgetTopicCard/WidgetTopicCard";
import WidgetSignUpOverlay from "../WidgetSignUpOverlay/WidgetSignUpOverlay";
import WidgetSignUpBar from "../../atoms/WidgetSignUpBar/WidgetSignUpBar";

const cx = createModuleStyleExtractor(styles);

const WidgetCommunityTab = ({ schoolData = {} }) => {
  const [communities, setCommunities] = useState([]);
  const [communityLoader, setCommunityLoader] = useState(true);
  const [showSignupOverlay, setShowSignupOverlay] = useState(false);
  const [overlaySubtitle, setOverlaySubtitle] = useState("");

  useEffect(() => {
    const fetchCommunities = async () => {
      try {
        setCommunityLoader(true);
        const response = await getCommunityList(schoolData?.token);
        if (response.success) {
          setCommunityLoader(false);
          setCommunities(response.data.communities);
        } else {
          setCommunityLoader(false);
        }
      } catch (error) {
        setCommunityLoader(false);
      }
    };

    fetchCommunities();
  }, [schoolData?.token]);

  const showOverlay = () => {
    setShowSignupOverlay(true);
    setOverlaySubtitle("Get full access to our student communities");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const closeOverlay = () => setShowSignupOverlay(false);

  return (
    <>
      {showSignupOverlay && (
        <WidgetSignUpOverlay
          token={schoolData?.token}
          title={"Sign up to join a community"}
          subtitle={overlaySubtitle}
          route_type={ROUTE_TYPES.COMMUNITY}
          closeOverlay={closeOverlay}
        />
      )}
      <WidgetSignUpBar text="Join a community" onSignupClick={showOverlay} />
      <div className={cx("m-widget-community-tab")}>
        <h2 className={cx("m-widget-community-tab__title")}>
          Join one of our communities
        </h2>

        <div className={cx("m-widget-community-tab__listing")}>
          {communityLoader ? (
            <Row>
              {[...Array(12)].map((_, index) => (
                <Col
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  key={`topic_${index}`}
                  styles={{ marginBottom: "10px" }}
                >
                  <ShimmerPostItem
                    card
                    title
                    cta
                    imageType="circular"
                    imageWidth={70}
                    imageHeight={70}
                    contentCenter
                  />
                </Col>
              ))}
            </Row>
          ) : communities.length > 0 ? (
            <div className={cx("m-widget-community-tab__listing__topic-cards")}>
              <Masonry columns={3}>
                {communities.map((topic, index) => (
                  <div
                    key={index}
                    className={cx(
                      "m-widget-community-tab__listing__topic-cards__card"
                    )}
                  >
                    <WidgetTopicCard
                      key={topic.id}
                      topic={topic}
                      onJoinTopic={showOverlay}
                    />
                  </div>
                ))}
              </Masonry>
            </div>
          ) : (
            <div className={cx("m-widget-community-tab__listing__empty")}>
              <h2>
                <br />
                Looks like there are no Communities.
              </h2>
              <EmptyDiscoverTopicsIcon />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default memo(WidgetCommunityTab);
