import React from "react";
import { useDispatch } from "react-redux";

import { handleCommunityHeaderClick } from "../../../../redux/reducers/communityReducer";
import { Button } from "../../../atoms/Button";
import DiscoverTopic from "./DiscoverTopic";

import backIcon from "../../../../assets/svg/back-arrow-v2.svg";

export default function DiscoverCommunity() {
  const dispatch = useDispatch();
  return (
    <>
      <div className="discover-header">
        <h2 className="discover-header__title">Discover Communities</h2>
        <div className="discover-header__back">
          <Button onClick={() => dispatch(handleCommunityHeaderClick())}>
            <div className="content">
              <img src={backIcon} alt="back" />
              <span>Back to Community Feed</span>
            </div>
          </Button>
        </div>
      </div>
      <DiscoverTopic />
    </>
  );
}
