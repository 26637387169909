import { ApiWrapper, REQUEST } from ".";

export const getMyTopics = async (school_id) => {
  return await ApiWrapper({
    url: `topics?school_id=${school_id}`,
    method: REQUEST.GET,
  });
};

export const getDiscoverTopics = async (school_id) => {
  return await ApiWrapper({
    url: `topics/discover_dashboard?school_id=${school_id}`,
    method: REQUEST.GET,
  });
};

export const followUnfollowTopic = async (
  topic_id = null,
  school_id = null
) => {
  return await ApiWrapper({
    url: "topics/follow_unfollow_topic",
    method: REQUEST.POST,
    data: {
      topic_id,
      school_id,
    },
  });
};

export const getTopicDetails = async (topicId, selectedSchoolId = null) => {
  return await ApiWrapper({
    url: `topics/${topicId}?school_id=${selectedSchoolId}`,
    method: REQUEST.GET,
  });
};

export const createOrUpdatePost = async (data) => {
  return await ApiWrapper({
    url: "topics/create_or_update_post",
    method: REQUEST.POST,
    data,
  });
};

export const createOrUpdatePostComments = async (
  topic_id,
  comment,
  topic_post_id,
  school_id
) => {
  return await ApiWrapper({
    url: "topics/create_or_update_post_comment",
    method: REQUEST.POST,
    data: {
      topic_post_comment: {
        topic_id,
        comment,
        topic_post_id,
      },
      school_id,
    },
  });
};

export const topicMuteOrUnmute = async (topic_follower_id) => {
  return await ApiWrapper({
    url: `topics/mute_or_unmute_topic?topic_follower_id=${topic_follower_id}`,
    method: REQUEST.GET,
  });
};

export const getTopicPostPreview = async (topic_url) => {
  return await ApiWrapper({
    url: `topics/topic_thumbnailer_url?topic_url=${topic_url}`,
    method: REQUEST.GET,
  });
};

export const readTopicNotification = async (topic_id) => {
  return await ApiWrapper({
    url: `notifications/read_topic_notifications`,
    method: REQUEST.POST,
    data: {
      topic_id,
    },
  });
};

export const reportTopic = async (report_text, topic_id) => {
  return await ApiWrapper({
    url: `topics/report_topic`,
    method: REQUEST.POST,
    data: {
      report_text,
      topic_id,
    },
  });
};

export const deleteTopicPost = async (topic_post_id) => {
  return await ApiWrapper({
    url: `/topics/delete_post?topic_post_id=${topic_post_id}`,
    method: REQUEST.DELETE,
  });
};

export const deleteTopicPostComment = async (post_comment_id) => {
  return await ApiWrapper({
    url: `/topics/delete_post_comment?post_comment_id=${post_comment_id}`,
    method: REQUEST.DELETE,
  });
};
