import React, { memo } from "react";

function WidgetStudentsIcon() {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke={"var(--primary-font-color)"}
        strokeWidth="1.319"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M28.254 8.934c0 1.588-1.198 2.875-2.677 2.875-1.478 0-2.677-1.287-2.677-2.875s1.2-2.876 2.677-2.876c1.48 0 2.677 1.288 2.677 2.876" />
        <path d="M18.584 15.73c-5.051-3.876-2.963-8.677-2.206-11.216s2.579-1.435 2.294.048c-1.391 6.432 3.159 9.617 9.473 10.53s6.133 7.613 6.133 7.613H21.867M9.024 20.83c0 1.588 1.198 2.876 2.677 2.876 1.478 0 2.677-1.288 2.677-2.876s-1.199-2.875-2.677-2.875c-1.479 0-2.677 1.287-2.677 2.875" />
        <path d="M3 34.601s-.18-6.7 6.134-7.613 10.865-4.099 9.473-10.53c-.285-1.483 1.536-2.587 2.294-.048s2.846 7.34-2.206 11.215c-2.525 1.938-3.27 4.844-3.382 7.266z" />
      </g>
    </svg>
  );
}

export default memo(WidgetStudentsIcon);
