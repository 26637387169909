import React from "react";

function SliderNextArrow({ className, style, onClick = () => {} }) {
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.1375 25.8379C10.9 25.8379 10.6625 25.7504 10.475 25.5629C10.1125 25.2004 10.1125 24.6004 10.475 24.2379L18.625 16.0879C19.225 15.4879 19.225 14.5129 18.625 13.9129L10.475 5.76289C10.1125 5.40039 10.1125 4.80039 10.475 4.43789C10.8375 4.07539 11.4375 4.07539 11.8 4.43789L19.95 12.5879C20.5875 13.2254 20.95 14.0879 20.95 15.0004C20.95 15.9129 20.6 16.7754 19.95 17.4129L11.8 25.5629C11.6125 25.7379 11.375 25.8379 11.1375 25.8379Z"
          fill="var(--primary-color)"
        />
      </svg>
    </div>
  );
}

export default React.memo(SliderNextArrow);
