import React from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./CalculatorSummary.module.scss";
import Card, { CardModifier } from "../../atoms/Card/Card";
import { getUSDAmount } from "../../../utils/common";

const cx = createModuleStyleExtractor(styles);

export default function CalculatorSummary({
  totalCostOfAttendance = 0,
  totalSourceOfFunds = 0,
  amountNeeded = 0,
}) {
  return (
    <div className={cx("m-calculator-summary")}>
      <Card modifier={CardModifier.FULL_WIDTH}>
        <div className={cx("m-calculator-summary__title")}>
          Cost of attendance
        </div>
        <div className={cx("m-calculator-summary__value")}>
          {totalCostOfAttendance && totalCostOfAttendance > 0
            ? getUSDAmount(totalCostOfAttendance)
            : "None"}
        </div>
        <div
          className={cx([
            "m-calculator-summary__title",
            "m-calculator-summary__title--second",
          ])}
        >
          Source of funds
        </div>
        <div className={cx("m-calculator-summary__value")}>
          {totalSourceOfFunds && totalSourceOfFunds > 0
            ? getUSDAmount(totalSourceOfFunds)
            : "None"}
        </div>
      </Card>
      <Card modifier={CardModifier.FULL_WIDTH}>
        <div className={cx("m-calculator-summary__title")}>Amount needed</div>
        <div className={cx("m-calculator-summary__value")}>
          {amountNeeded && amountNeeded !== 0
            ? getUSDAmount(amountNeeded)
            : "None"}
        </div>
      </Card>
    </div>
  );
}
