import React from "react";
import { Button } from "../Button";
import styles from "./WidgetFadedBanner.module.scss";
import { createModuleStyleExtractor } from "../../../utils/css";
import { noop } from "../../../utils/noop";

const cx = createModuleStyleExtractor(styles);

const WidgetFadedBanner = ({ actionText = "", onClick = noop }) => {
  return (
    <div className={cx("m-widget-faded-banner")}>
      <Button onClick={onClick}>{actionText}</Button>
    </div>
  );
};

export default WidgetFadedBanner;
