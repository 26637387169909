import React, { memo } from "react";

function FaceliftSearchIcon({ onClick = () => {} }) {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g fill="none" fill-rule="evenodd">
        <rect fill="var(--primary-color)" width="50" height="50" rx="25" />
        <path
          d="M31.521 23.913a6.522 6.522 0 1 1-13.043 0 6.522 6.522 0 0 1 13.043 0Zm-1.941 4.891 3.028 3.804"
          stroke="#FFF"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </svg>
  );
}

export default memo(FaceliftSearchIcon);
