import React from "react";

function SelectUpArrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10.832" height="5.76" viewBox="0 0 10.832 5.76">
      <path
        id="Triangle"
        d="M5.641-5.838a.6.6,0,0,1,.821,0l4.858,4.76a.5.5,0,0,1-.41.838H1.193a.5.5,0,0,1-.41-.838Z"
        transform="translate(-0.636 6)"
        fill="#607790"
      />
    </svg>
  );
}

export default React.memo(SelectUpArrow);
