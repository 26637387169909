import React, { memo, useState, useEffect } from "react";

import styles from "./HelpCenterList.module.scss";
import { createModuleStyleExtractor } from "../../../utils/css";
import Accordion from "../../atoms/Accordian/Accordian";

const cx = createModuleStyleExtractor(styles);

const HelpCenterList = ({ selectedCategory = {} }) => {
  const { QA: questions = [], category } = selectedCategory;
  const [openQuestion, setOpenQuestion] = useState(false);

  useEffect(() => {
    setOpenQuestion(false)
  }, [category])

  return (
    <div className={cx("m-help-center-list")}>
      <h2>{category} FAQ</h2>
      <div>
        {questions.map((question) => (
          <Accordion
            openState={openQuestion === question.id}
            openStateChange={(state) => {
              setOpenQuestion(state === true ? question.id : false);
            }}
            title={question.question}
          >
            <p
              className="text-justify text-color-gray"
              dangerouslySetInnerHTML={{ __html: question.answer }}
            />
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default memo(HelpCenterList);
