import { createSlice } from "@reduxjs/toolkit";

import { DEFAULT_PRIMARY_COLOR } from "../../config/config";

const initialState = {
  themeColor: DEFAULT_PRIMARY_COLOR,
  lastPerDayAppLoad: localStorage.getItem("per_day_app_load") ?? null,
  chatInfoStatus: !!localStorage.getItem("chat_info_status") ?? false,
  savedRoute: {},
};

export const preservedReducer = createSlice({
  name: "preserved",
  initialState,
  reducers: {
    setThemeColor: (state, action) => {
      state.themeColor = action.payload;
    },
    setLastPerDayAppLoad: (state, action) => {
      state.lastPerDayAppLoad = action.payload;
      localStorage.setItem("per_day_app_load", action.payload);
    },
    setChatInfoStatus: (state, action) => {
      state.chatInfoStatus = action.payload;
      localStorage.setItem("chat_info_status", action.payload);
    },
    setSavedRoute: (state, action) => {
      // localStorage.setItem("saved_route", action.payload);
      state.savedRoute = action.payload;
    },
    wipeOutSavedRoute: (state) => {
      state.savedRoute = {};
    },
  },
});

export const {
  setThemeColor,
  setLastPerDayAppLoad,
  setChatInfoStatus,
  setSavedRoute,
  wipeOutSavedRoute,
} = preservedReducer.actions;

export default preservedReducer.reducer;
