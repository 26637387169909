import React, { memo, useEffect } from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import { Button } from "../../atoms/Button/Button";
import styles from "./AuthRoleSelection.module.scss";

import BackIcon from "../../../assets/images/f-btn-arrow-gray.svg";
import SingleSelectCard from "../../atoms/SingleSelectCard/SingleSelectCard";
import { INTERSTRIDE_LOGO_URL } from "../../../utils/constants";

const cx = createModuleStyleExtractor(styles);

const AuthRoleSelection = ({
  role,
  setRole,
  goBackToLogin,
  goToSignUp,
  schoolData,
}) => {
  useEffect(() => {
    // Prospect should be default selected option if no role is not defined
    if (!role) setRole("prospect");
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={cx("m-auth-role-selection")}>
      <div className={cx("m-auth-role-selection__back-btn")}>
        <a
          href="/login"
          onClick={(e) => {
            e?.preventDefault && e.preventDefault();
            goBackToLogin();
          }}
        >
          <img src={BackIcon} id="BackArrow" width="18px" alt="Back arrow" />
          <span>Back</span>
        </a>
      </div>
      <div className={cx("m-auth-role-selection__content")}>
        <div className={cx("m-auth-role-selection__content__unlogo")}>
          <img src={schoolData.logo_url} alt="University logo" />
        </div>
        <div className={cx("m-auth-role-selection__content__sitelogo")}>
          <h3>Powered by</h3>
          <img src={INTERSTRIDE_LOGO_URL} alt="interstride logo" />
        </div>
        <h2>Welcome to the Admissions Portal</h2>

        <h5>
          Already have an account?
          <a
            href="/login"
            onClick={(e) => {
              e?.preventDefault && e.preventDefault();
              goBackToLogin();
            }}
          >
            Log In
          </a>
        </h5>

        <div className={cx("m-auth-role-selection__content__select")}>
          <SingleSelectCard
            header={"Before creating an account, tell us who you are."}
            options={[
              {
                value: "prospect",
                title: "I'm a student",
              },
              {
                value: "parent",
                title: "I'm a parent",
              },
            ]}
            value={role}
            onClick={(value) => setRole(value)}
          />
        </div>

        <Button type="submit" disabled={!role} onClick={goToSignUp}>
          Get started
        </Button>
      </div>
    </div>
  );
};
export default memo(AuthRoleSelection);
