import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { ROUTES } from "../../../routes";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./MenuItem.module.scss";

const cx = createModuleStyleExtractor(styles);

export const MenuItemModifier = {
  ACTIVE: "a-menu-item--active",
  DISABLED: "a-menu-item--disabled",
};

export const MenuItem = ({ menu = {}, onClick = () => {}, modifier }) => {
  // Compute menuLink based on permissions with fallback
  let menuLink = null;
  if (menu?.subMenus?.length > 0) {
    for (let index = 0; index < menu.subMenus.length; index++) {
      if (menu.subMenus[index].permission) {
        menuLink = menu.subMenus[index]?.route || ROUTES.DASHBOARD;
        break;
      }
    }
  } else {
    menuLink = menu?.routes[0] || ROUTES.DASHBOARD;
  }

  return (
    <li
      className={cx(
        "a-menu-item",
        modifier
          ? {
              [modifier]: true,
            }
          : {}
      )}
    >
      <Tooltip
        title={menu.tooltip || ""}
        placement="bottom"
        enterTouchDelay={0}
      >
        <Link
          className={cx("a-menu-item__link")}
          to={menuLink}
          onClick={onClick}
        >
          {menu.icon && (
            <div className={cx("a-menu-item__icon")}>{menu.icon}</div>
          )}
          <span> {menu.title}</span>
          {menu.notificationIcon && (
            <span className={cx("a-menu-item__notification_badge")}></span>
          )}
        </Link>
      </Tooltip>
    </li>
  );
};
