import { createSlice } from "@reduxjs/toolkit";
import moment from "moment/moment";

const initialState = {
  isLogin: false,
  token: null,
  tokenExpiry: null,
  keepSignedIn: false,
  user: {},
  userId: null,
  schools: [],
  selectedSchool: {},
  isSuperAdmin: false,
  isSchoolAdmin: false,
  isProspect: false,
  isParent: false,
  isStudentAmbassador: false,
  isAlumniAmbassador: false,
  firebaseToken: null,
  lastActiveSession: null,
  permissions: {},
  permissionsExpiry: null,
};

export const userReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoginData: (state, action) => {
      state.isLogin = true;
      state.user = action.payload.user;
      state.userId = action.payload.user.id;
      state.token = action.payload.user.authentication_token;
      state.tokenExpiry = action.payload.user.auth_token_expiration;

      state.keepSignedIn = action.payload.keepSignedIn ?? state.keepSignedIn;

      state.isSuperAdmin =
        action.payload.user.user_type === "super_admin" ? true : false;
      state.isSchoolAdmin =
        action.payload.user.user_type === "school" ||
        action.payload.user.user_type === "school_admin"
          ? true
          : false;
      state.isProspect =
        action.payload.user.user_type === "prospect" &&
        action.payload.user.account_type === "prospect_user"
          ? true
          : false;
      state.isParent =
        action.payload.user.user_type === "prospect" &&
        action.payload.user.account_type === "parent_user"
          ? true
          : false;
      state.isStudentAmbassador =
        action.payload.user.admission_user_type === "student_ambassador"
          ? true
          : false;
      state.isAlumniAmbassador =
        action.payload.user.admission_user_type === "alumni_ambassador"
          ? true
          : false;

      if (action.payload.schools && action.payload.schools.length > 0) {
        state.selectedSchool = action.payload.schools[0];
        state.schools = action.payload.schools;
      } else {
        // Need to set school manually for school admin & ambassador
        const selectedSchool = {
          id: action.payload.user.school_id,
          name: action.payload.user.school_name,
          token: action.payload.user.school_token,
          logo_url: action.payload.user.school_logo,
          theme_color: action.payload.user.theme_color,
        };
        state.selectedSchool = selectedSchool;
        state.schools = [selectedSchool];
      }
    },
    resetLoginData: () => initialState,
    setSelectedSchool: (state, action) => {
      state.selectedSchool = action.payload;
    },
    setSignedInStatus: (state, action) => {
      state.keepSignedIn = action.payload;
    },
    setUserData: (state, action) => {
      state.user = action.payload;
    },
    resetUserData: (state) => {
      state.user = {};
    },
    updateUserData: (state, action) => {
      state.user += action.payload;
    },
    setTokenData: (state, action) => {
      state.token = action.payload.authentication_token;
      state.tokenExpiry = action.payload.auth_token_expiration;
    },
    removeUserProfileImage: (state) => {
      state.user.profile_image_url = "";
    },
    setFirebaseToken: (state, action) => {
      state.firebaseToken = action.payload;
    },
    setLastActiveSession: (state, action) => {
      state.lastActiveSession = action.payload;
    },
    updateUserPermissions: (state, action) => {
      // Change permissions only if anything changed
      if (JSON.stringify(state.permissions) !== JSON.stringify(action.payload))
        state.permissions = action.payload;
      state.permissionsExpiry = moment()
        .add(24, "hours")
        .format("YYYY-MM-DD HH:mm");
    },
  },
});

export const {
  setLoginData,
  resetLoginData,
  setUserData,
  resetUserData,
  updateUserData,
  setSignedInStatus,
  setSelectedSchool,
  setTokenData,
  removeUserProfileImage,
  setFirebaseToken,
  setLastActiveSession,
  updateUserPermissions,
} = userReducer.actions;

export default userReducer.reducer;
