import React from "react";

import { generateFirstName } from "../../../utils/common";
import { createModuleStyleExtractor } from "../../../utils/css";
import { validateAttribute } from "../../../utils/validations";
import { Button, ButtonModifier } from "../Button";
import { CircularAvatar } from "../CircularAvatar/CircularAvatar";
import styles from "./WidgetChatPopupCard.module.scss";

import { INTERSTRIDE_LOGO_URL } from "../../../utils/constants";

const cx = createModuleStyleExtractor(styles);

export default function WidgetChatPopupCard({
  user = {},
  onChatClick = () => {},
}) {
  const {
    country_image,
    country_of_origin,
    user_profile,
    intro,
    user_type,
    major,
    graduation_year,
    degree,
    school_name,
    last_active_time,
  } = user;
  // Full Name override based on user_type
  let { full_name } = user;
  if (user_type === "Student") {
    full_name = generateFirstName(full_name);
  }

  const introText = validateAttribute(intro)
    ? intro
    : (user_type === "Student" || user_type === "Alumni") &&
      validateAttribute(major) &&
      validateAttribute(degree) &&
      validateAttribute(school_name)
    ? `I ${
        user_type === "Alumni" ? "have completed" : "am currently persuing my"
      } ${degree} in ${major} at ${school_name}`
    : user_type === "Admin"
    ? `University Admin at ${school_name}`
    : "";

  return (
    <div className={cx("a-widget-chatpopup-card")}>
      <div className={cx("a-widget-chatpopup-card__profile")}>
        <CircularAvatar
          src={user_profile}
          name={full_name}
          round={true}
          size={73}
          wrapperClass={cx("a-widget-chatpopup-card__profile__pic")}
        />
        <div className={cx("a-widget-chatpopup-card__profile__info")}>
          <div
            className={cx("a-widget-chatpopup-card__profile__info__line_one")}
          >
            <span>{full_name}</span>
            <span>{user_type}</span>
          </div>
          <div
            className={cx("a-widget-chatpopup-card__profile__info__line_two")}
          >
            {user_type === "Admin" ? (
              <span>Admissions Team</span>
            ) : major && graduation_year ? (
              <span>{major + ", " + graduation_year}</span>
            ) : (
              <span></span>
            )}
          </div>
          {country_image && country_of_origin && (
            <div
              className={cx(
                "a-widget-chatpopup-card__profile__info__line_three"
              )}
            >
              <img src={country_image} alt="flag" />
              <span>From {country_of_origin}</span>
            </div>
          )}
        </div>
      </div>
      <div className={cx("a-widget-chatpopup-card__body")}>
        <p className={cx("a-widget-chatpopup-card__body__intro")}>
          {introText}
        </p>
        <div className={cx("a-widget-chatpopup-card__body__bottom")}>
          {last_active_time && (
            <div
              className={cx(
                "a-widget-chatpopup-card__body__bottom__active-text"
              )}
            >
              <div
                className={cx(
                  "a-widget-chatpopup-card__body__bottom__active-text__bullet"
                )}
              />
              {last_active_time}
            </div>
          )}
          <div className={cx("a-widget-chatpopup-card__body__bottom__buttons")}>
            <Button
              modifier={ButtonModifier.WIDGET_CHATLIST_CARD_BUTTON}
              onClick={() => onChatClick(user)}
            >
              Chat with {full_name}
            </Button>
          </div>
          <div className={cx("a-widget-chatpopup-card__body__bottom__logo")}>
            <h3>Powered by</h3>
            <img src={INTERSTRIDE_LOGO_URL} alt="interstride logo"></img>
          </div>
        </div>
      </div>
    </div>
  );
}
