import React, { memo } from "react";

import Star from "../../assets/svg/f-saved-star.svg";
import StarBorder from "../../assets/svg/f-unclicked-normal-start.svg";

const StarIconFacelift = ({ variant = "", ...props }) => {
  return (
    <>
      {variant === "filled" ? (
        <div className={`filled-star-facelift ${props.className}`}>
          <a
            aria-label={
              props["aria-label"]
                ? props["aria-label"]
                : `Remove from favourite`
            }
            href="/#"
            onClick={(e) => {
              e?.preventDefault && e.preventDefault();
              props.onClick && props.onClick(e);
            }}
          >
            <img src={Star} alt="Favourite Star" />
          </a>
        </div>
      ) : (
        <div className={`border-star-facelift ${props.className}`}>
          <a
            aria-label={
              props["aria-label"] ? props["aria-label"] : `Add to favourite`
            }
            href="/#"
            onClick={(e) => {
              e?.preventDefault && e.preventDefault();
              props.onClick && props.onClick(e);
            }}
          >
            <img src={StarBorder} alt="Non-favourite star" s />
          </a>
        </div>
      )}
    </>
  );
};

export default memo(StarIconFacelift);
