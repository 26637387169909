import React, { memo } from "react";
import moment from "moment";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./Notification.module.scss";
import Card, { CardModifier } from "../Card/Card";
import { Button, ButtonModifier } from "../Button";
import { ROUTES } from "../../../routes";

import NotificationMessage from "../../../assets/svg/notification/Networking.svg";
import NotificationPost from "../../../assets/svg/notification/Post.svg";
import NotificationResources from "../../../assets/svg/notification/Resources.svg";
import NotificationServices from "../../../assets/svg/notification/Services.svg";
import NotificationTopics from "../../../assets/svg/notification/Topics.svg";
import NotificationWebinar from "../../../assets/svg/notification/Webinar.svg";
import NotificationDefault from "../../../assets/svg/notification/Other.svg";
import deleteImage from "../../../assets/images/f-delete-icon.svg";

const cx = createModuleStyleExtractor(styles);

const getNotificationInfo = ({ type }) => {
  switch (type) {
    case "network_messages":
    case "group_messages":
    case "missed_message":
      return {
        buttonLabel: "View Message",
        redirectURL: ROUTES.MESSAGES,
        icon: NotificationMessage,
      };
    case "new_topics":
      return {
        buttonLabel: "View Community",
        redirectURL: ROUTES.COMMUNITY.TOPICS + "?tab=explore",
        icon: NotificationTopics,
      };
    case "created_topics":
    case "followed_topics":
    case "follow_requests":
      return {
        buttonLabel: "View Community",
        redirectURL: ROUTES.COMMUNITY.TOPICS,
        icon: NotificationTopics,
      };
    case "user_post":
      return {
        buttonLabel: "View Post",
        redirectURL: ROUTES.DASHBOARD,
        extraPayload: true,
        icon: NotificationPost,
      };
    case "webinar":
      return {
        buttonLabel: "View Webinar",
        redirectURL: ROUTES.DISCOVER.WEBINARS,
        icon: NotificationWebinar,
      };
    case "deal":
      return {
        buttonLabel: `View Service`,
        redirectURL: ROUTES.DISCOVER.STUDENT_SERVICES,
        icon: NotificationServices,
      };
    case "video":
      return {
        buttonLabel: `View Resource`,
        redirectURL: ROUTES.DISCOVER.RESOURCES,
        icon: NotificationResources,
      };
    default:
      return {
        buttonLabel: `View ${type}`,
        redirectURL: "/",
        icon: NotificationDefault,
      };
  }
};

const Notification = ({
  notification = {},
  showDelete = true,
  onDeleteClick,
  onViewClick,
  variant = "large",
}) => {
  const {
    buttonLabel,
    redirectURL,
    icon,
    extraPayload = false,
  } = getNotificationInfo(notification);

  const timeLabel = moment(notification.created_at).fromNow(true);

  return (
    <Card modifier={CardModifier.NOTIFICATION}>
      <div className={cx("a-notification")}>
        <div className={cx("a-notification__text-info")}>
          <div className={cx("a-notification__text-info__icon")}>
            <img src={icon} alt="notification" />
          </div>
          <div className={cx("a-notification__text-info__text")}>
            <h3>
              {notification.message
                ? notification.message
                : notification.title
                ? notification.title
                : ""}
            </h3>
            <span>
              {notification?.school_name
                ? notification.school_name + " | "
                : ""}
              {timeLabel} ago
            </span>
          </div>
        </div>
        <div className={cx("a-notification__action-btn")}>
          <Button
            className={cx("a-notification__primary-btn")}
            modifier={
              variant === "small"
                ? ButtonModifier.NOTIFICATIONS_ACTION_SMALL
                : ButtonModifier.NOTIFICATIONS_ACTION
            }
            onClick={() => {
              onViewClick(notification, redirectURL, extraPayload);
            }}
          >
            {variant === "small" ? "View" : buttonLabel}
          </Button>
          {showDelete && (
            <Button
              className={cx("a-secondary-btn")}
              modifier={ButtonModifier.NOTIFICATIONS_DELETE}
              onClick={() => onDeleteClick(notification.id)}
            >
              <img src={deleteImage} alt="delete" />
            </Button>
          )}
        </div>
      </div>
    </Card>
  );
};

export default memo(Notification);
