import React, { memo, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Col } from "../../helpers/Grid/Col";
import { Row } from "../../helpers/Grid/Row";
import styles from "./AmbassadorSignUp.module.scss";
import AmbassadorPerspective from "../../molecules/AmbassadorPerspective/AmbassadorPerspective";
import { createModuleStyleExtractor } from "../../../utils/css";
import { validateAmbassadorUniversityToken } from "../../../api/onboarding";
import { DEFAULT_PRIMARY_COLOR } from "../../../config/config";
import { setThemeColor } from "../../../redux/reducers/preservedReducer";
import { ROUTES } from "../../../routes";
import AuthSignUpAmbassador from "../../molecules/AuthSignUpAmbassador/AuthSignUpAmbassador";
import toastify from "../../../utils/toast";
import InterstridePageLoader from "../../atoms/InterstridePageLoader/InterstridePageLoader";

const cx = createModuleStyleExtractor(styles);

const AmbassadorSignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const confirmation_token = searchParams.getAll("confirmation_token")
    ? searchParams.get("confirmation_token")
    : false;
  const email = searchParams.getAll("email")
    ? decodeURIComponent(searchParams.get("email"))
    : false;
  const redirect = searchParams.getAll("redirect")
    ? searchParams.get("redirect")
    : false;

  const [schoolData, setSchoolData] = useState(null);
  const [showLoader, setShowLoader] = useState(
    confirmation_token ? true : false
  );

  useEffect(() => {
    const checkUniversityToken = async () => {
      const response = await validateAmbassadorUniversityToken(
        confirmation_token,
        email
      );

      if (response.success && response.data) {
        const {
          data: { school = {} },
        } = response;

        const themeColor =
          school && school.school_setting
            ? school.school_setting.theme_color
            : DEFAULT_PRIMARY_COLOR;
        dispatch(setThemeColor(themeColor));
        setSchoolData(school);
        setShowLoader(false);
      } else {
        toastify(response.message);
        setShowLoader(false);
        navigate(ROUTES.LOGIN_WITH_PARAMS, { replace: true });
      }
    };
    if (confirmation_token && email) {
      checkUniversityToken();
    } else if (redirect) {
    } else {
      navigate(ROUTES.LOGIN_WITH_PARAMS, { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {showLoader ? (
        <InterstridePageLoader />
      ) : (
        <Row className={cx("o-ambassador-signup-container")}>
          <Col xs={12} sm={6} md={6} lg={6}>
            <AmbassadorPerspective schoolData={schoolData} />
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <AuthSignUpAmbassador
              schoolData={schoolData}
              defaultEmail={email}
            />
          </Col>
        </Row>
      )}
    </>
  );
};
export default memo(AmbassadorSignUp);
