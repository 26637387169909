import React, { memo, useEffect, useState } from "react";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./ScrollToTopIcon.module.scss";
import ScrollIcon from "../../../assets/images/f-scroll-to-top.svg";

const cx = createModuleStyleExtractor(styles);

const ScrollToTopIcon = () => {
  const [isBottom, setIsBottom] = useState(false);

  useEffect(() => {
    const handleNavigation = () => {
      const position = window.pageYOffset;
      setIsBottom(position > 100 ? true : false);
    };
    window.addEventListener("scroll", handleNavigation);
    return () => {
      // return a cleanup function to unregister our function since its gonna run multiple times
      window.removeEventListener("scroll", handleNavigation);
    };
  }, []);

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <button
        className={cx("a-scroll-to-top-icon")}
        title="Go to top"
        style={{
          display: isBottom ? "block" : "none",
        }}
        onClick={() => handleScrollTop()}
      >
        <img src={ScrollIcon} alt="scroll to top" />
      </button>
    </>
  );
};
export default memo(ScrollToTopIcon);
