import React, { useRef } from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import useClickOutsideV2 from "../../../hooks/useClickOutsideV2";
import styles from "./Modal.module.scss";

import close from "../../../assets/images/closeicon.svg";
import { useEffect } from "react";
import { disableBodyScroll } from "../../../utils/common";

const cx = createModuleStyleExtractor(styles);

export const ModalModifier = {
  DASHBOARD_POST: "dashboard-post", // Using only modifier substring because we have to apply it on multiple elements
  RESOURCES_LIST: "resources-list",
  FEEDBACK_CONFIRMATION: "feedback-confirmation",
};

export const Modal = ({
  children,
  onClose = () => {},
  show,
  hideClose = false,
  centerAlign = true,
  marginTop,
  modifier,
  maxWidth = 480,
  preserveBodyScroll = false,
}) => {
  useEffect(() => {
    if (preserveBodyScroll) return;

    disableBodyScroll(show);

    return () => {
      disableBodyScroll(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const getStyles = () => {
    if (show) {
      return centerAlign
        ? { display: "flex", alignItems: "center" }
        : { display: "block" };
    }
    return { display: "none" };
  };

  const modalRef = useRef(null);
  useClickOutsideV2(modalRef, () => {
    if (show) onClose();
  });

  return (
    <div
      className={cx(["a-modal", ...(centerAlign ? ["a-modal__center"] : [])])}
      tabIndex="-1"
      role="dialog"
      style={getStyles()}
    >
      <div
        ref={modalRef}
        className={cx(
          "a-modal__dialog",
          modifier
            ? {
                [`a-modal__dialog--${modifier}`]: true,
              }
            : {}
        )}
        style={{ marginTop, maxWidth }}
        role="document"
        id="modal-dialog"
      >
        <div
          className={cx(
            "a-modal__dialog__content",
            modifier
              ? {
                  [`a-modal__dialog__content--${modifier}`]: true,
                }
              : {}
          )}
        >
          {!hideClose && (
            <button
              type="button"
              className={cx(
                "a-modal__dialog__content__close",
                modifier
                  ? {
                      [`a-modal__dialog__content__close--${modifier}`]: true,
                    }
                  : {}
              )}
              data-dismiss="modal"
              aria-label="Close"
            >
              <img src={close} alt="close" onClick={onClose} />
            </button>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};
