import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
} from "@mui/material";
import { ShimmerThumbnail } from "react-shimmer-effects";

import Card from "../Card/Card";
import MuiPagination from "../MuiPagination/MuiPagination";
import toastify from "../../../utils/toast";

/**
 * Material UI Table component wrapper
 * @param {Array} tableHeaders Sample data structure: ["Header One", "Header Two"]
 * @param {Array} tableData Sample data structure: [["Record One", "Record Two"], ["Record Three", "Record Four"]]
 * @param {Boolean} pagination Display enable/disable of pagination component at table footer
 * @param {Function} handlePaginationChange Callback when pagination component changes state via click
 * @param {Number} page Current Page
 * @param {Number} total_pages Total pages
 * @param {Boolean} tableLoader Loading state of table
 * @param {String} noDataTextHeader Heading text of card if no data is present
 * @param {String} noDataTextBody body text of card if no data is present
 * @param {Funtion} changeSortingFn callback for sort click
 * @returns {Component} Table
 */
export default function MuiTable({
  tableHeaders = [],
  tableData = [],
  pagination = false,
  handlePaginationChange = () => {},
  page = 1,
  total_pages = 1,
  tableLoader = false,
  noDataTextHeader = "No data available",
  noDataTextBody = "Try searching again",
  changeSortingFn = null,
}) {
  const [orderBy, setOrderBy] = useState(""); // Options: Column header name
  const [orderDirection, setOrderDirection] = useState("asc"); // Options "asc", "desc"

  const handleRequestSort = (propertyName) => {
    if (changeSortingFn && propertyName && orderDirection) {
      const isAsc = orderBy === propertyName && orderDirection === "asc";

      changeSortingFn(propertyName, isAsc ? "desc" : "asc");

      // Update Table states
      setOrderDirection(isAsc ? "desc" : "asc");
      setOrderBy(propertyName);
    } else {
      console.error("Please supply a changeSortingFn, propertyName, or order");
      toastify("Sorting not available");
    }
  };

  return (
    <div className="mui-table-wrapper">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="OPT Data table">
          <TableHead>
            <TableRow>
              {tableHeaders?.length > 0 ? (
                tableHeaders?.map((header, index) => (
                  <TableCell
                    key={index}
                    sortDirection={
                      orderBy === header?.name ? orderDirection : false
                    }
                  >
                    <TableSortLabel
                      active={orderBy === header?.name}
                      direction={
                        orderBy === header?.name ? orderDirection : "asc"
                      }
                      hideSortIcon={!header.isSort}
                      onClick={() => handleRequestSort(header.name)}
                    >
                      {header?.name || "Header " + index}
                    </TableSortLabel>
                  </TableCell>
                ))
              ) : (
                <TableCell>Headers not available</TableCell>
              )}
            </TableRow>
          </TableHead>
          {tableData?.length > 0 && !tableLoader && (
            <TableBody>
              {tableData?.map((row, index) => (
                <TableRow key={index}>
                  {row?.map((record, index) => (
                    <TableCell key={index}>{record}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {tableLoader ? (
        <div>
          <ShimmerThumbnail rounded height={500} />
        </div>
      ) : tableData?.length === 0 || tableData === null ? (
        <div className="mui-table-wrapper__no-data">
          <Card>
            <p className="mui-table-wrapper__no-data__header">
              {noDataTextHeader}
            </p>
            <p className="mui-table-wrapper__no-data__body">{noDataTextBody}</p>
          </Card>
        </div>
      ) : pagination && tableData?.length > 0 ? (
        <MuiPagination
          page={page}
          total_pages={total_pages}
          changePaginationFn={handlePaginationChange}
        />
      ) : null}
    </div>
  );
}
