import React, { memo, useState } from "react";

import PhoneNumberSelect from "../PhoneNumberSelect/PhoneNumberSelect";

const FormattedPhoneNumber = ({ number = "" }) => {
  const [formattedPhoneNo, setFormattedPhoneNo] = useState(number);

  return (
    <>
      {formattedPhoneNo}
      <div style={{ display: "none" }}>
        <PhoneNumberSelect
          name="phone_number"
          onMount={(formattedValue) => {
            setFormattedPhoneNo(formattedValue);
          }}
          value={number}
        />
      </div>
    </>
  );
};
export default memo(FormattedPhoneNumber);
