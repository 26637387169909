import React, { memo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { createModuleStyleExtractor } from "../../../utils/css";
import { validateWidgetToken, getChatPopupContacts } from "../../../api/widget";
import { DEFAULT_PRIMARY_COLOR } from "../../../config/config";
import {
  ROUTE_TYPES,
  UNIVERSITY_CHAT_POPUP_SOURCE,
  getUniversityVisitURL,
} from "../../../utils/common";
import { setThemeColor } from "../../../redux/reducers/preservedReducer";
import { setBodyBackground, disableBodyScroll } from "../../../utils/common";
import WidgetChatPopupCard from "../../atoms/WidgetChatPopupCard/WidgetChatPopupCard";
import styles from "./UniversityWidgetChatPopup.module.scss";

import nostudentsImg from "../../../assets/images/discover_no_contacts.svg";
import CloseIcon from "../../../assets/Icons/CloseIcon";
import { INTERSTRIDE_LOGO_URL } from "../../../utils/constants";

const cx = createModuleStyleExtractor(styles);
const CHAT_POPUP_TIME_DELAY = 30000; // in ms

const UniversityWidgetChatPopup = () => {
  const dispatch = useDispatch();

  const params = useParams();

  const universityToken = params.university_token ?? false;
  const userId = params.user_id ?? false;

  const [showTokenLoader, setShowTokenLoader] = useState(
    universityToken ? true : false
  );
  const [studentsLoader, setStudentsLoader] = useState(false);
  const [showInvalidToken, setShowInvalidToken] = useState(false);
  const [students, setStudents] = useState(null);
  const [showWidget, setShowWidget] = useState(false);

  useEffect(() => {
    setBodyBackground("transparent");
    disableBodyScroll(true);
  }, []);

  useEffect(() => {
    // Check localstorage if popup close itme is greater than time delay
    const localStoragePopupTime = localStorage.getItem(
      `${universityToken}_chat_popup_close_time`
    );
    if (
      localStoragePopupTime &&
      new Date().getTime() - parseInt(localStoragePopupTime) <
        CHAT_POPUP_TIME_DELAY
    ) {
      return;
    } else {
      setShowWidget(true);
    }

    // Check token and call APIs
    const checkUniversityToken = async () => {
      const response = await validateWidgetToken(universityToken);

      if (response.success && response.data) {
        const {
          data: { school = {} },
        } = response;

        const themeColor =
          school && school.theme_color
            ? school.theme_color
            : DEFAULT_PRIMARY_COLOR;
        dispatch(setThemeColor(themeColor));
        setShowTokenLoader(false);
        // Call API for student list
        setStudentsLoader(true);
        const responseStudentList = await getChatPopupContacts(
          {},
          universityToken
        );
        if (responseStudentList.success && responseStudentList.data) {
          if (userId) {
            // Need to find and show URL param based student
            const selectedUser = (responseStudentList?.data?.users ?? []).find(
              (student) => student.id === parseInt(userId)
            );
            // Set only selected user to show static profile on every load
            setStudents(
              selectedUser
                ? [selectedUser]
                : responseStudentList?.data?.users?.splice(0, 5)
            );
          } else {
            setStudents(responseStudentList?.data?.users?.splice(0, 5));
          }
          setStudentsLoader(false);
        } else {
          setStudents([]);
          setStudentsLoader(false);
        }
      } else {
        // Show invalid token page
        setShowInvalidToken(true);
        setShowTokenLoader(false);
      }
    };
    if (universityToken) {
      checkUniversityToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [universityToken, dispatch]);

  const onChatClick = ({ id = null }) => {
    window.open(
      getUniversityVisitURL(universityToken, UNIVERSITY_CHAT_POPUP_SOURCE, {
        route_type: ROUTE_TYPES.CHAT,
        route_id: id,
      })
    );
  };

  const handleOnClose = () => {
    setShowWidget(false);
    localStorage.setItem(
      `${universityToken}_chat_popup_close_time`,
      new Date().getTime()
    );
    // Stop clarity recording on chat popup close
    if (window?.clarity) {
      window.clarity("stop");
    }
  };

  return (
    <div className={cx("chat-widget")}>
      {/* Need to show popup only when content is completely loaded */}
      {!showWidget || showTokenLoader || studentsLoader ? (
        <></>
      ) : (
        <>
          {showInvalidToken ? (
            <div className={cx("chat-widget__card")}>
              <div className={cx("chat-widget__invalid-token")}>
                <div className={cx("chat-widget__invalid-token__content")}>
                  <div
                    className={cx(
                      "chat-widget__invalid-token__content__sitelogo"
                    )}
                  >
                    <img src={INTERSTRIDE_LOGO_URL} alt="interstride logo" />
                  </div>
                  <h2>
                    Woops!
                    <br /> We couldn't find the resource you are looking for!
                  </h2>
                </div>
                <h6>
                  If you have any questions, please reach out to us at{" "}
                  <a href="mailto:contact@interstride.com">
                    contact@interstride.com.
                  </a>
                </h6>
              </div>
            </div>
          ) : (
            <div className={cx("chat-widget__content")}>
              {!studentsLoader && students?.length === 0 ? (
                <div className={cx("chat-widget__card")}>
                  <div className={cx("chat-widget__content__no-chats")}>
                    <h2>There are no students available at this moment.</h2>
                    <h2>Please try again later.</h2>
                    <img src={nostudentsImg} alt="No students" />
                  </div>
                </div>
              ) : (
                <div className={cx("chat-widget__content__student")}>
                  <div className={cx("chat-widget__content__student__close")}>
                    <CloseIcon onClick={handleOnClose} />
                  </div>
                  <WidgetChatPopupCard
                    user={students[Math.floor(Math.random() * students.length)]}
                    onChatClick={onChatClick}
                  />
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default memo(UniversityWidgetChatPopup);
