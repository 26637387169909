import React, { memo, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { acceptAmbassadorInvite } from "../../../api/onboarding";
import toastify from "../../../utils/toast";
import { ROUTES } from "../../../routes";
import InterstridePageLoader from "../../atoms/InterstridePageLoader/InterstridePageLoader";

const AmbassadorAcceptInvite = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const ambassador_accept_token = searchParams.getAll("ambassador_accept_token")
    ? searchParams.get("ambassador_accept_token")
    : false;

  useEffect(() => {
    const acceptInvite = async () => {
      const response = await acceptAmbassadorInvite(ambassador_accept_token);

      if (response.success) {
        toastify("Thanks for accepting the invitation. You may log in now. ");
        navigate(ROUTES.LOGIN, { replace: true });
      } else {
        toastify(
          "Unable to accept invitation. Please contact us at contact@interstride.com"
        );
        navigate(ROUTES.LOGIN, { replace: true });
      }
    };
    if (ambassador_accept_token) {
      acceptInvite();
    } else {
      navigate(ROUTES.LOGIN, { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  return <InterstridePageLoader />;
};
export default memo(AmbassadorAcceptInvite);
