import React from "react";
import { Chip } from "@mui/material";

import { Modal } from "../../../atoms/Modal/Modal";
import { ModalBody } from "../../../atoms/Modal/ModalBody/ModalBody";
import { createModuleStyleExtractor } from "../../../../utils/css";
import { CircularAvatar } from "../../../atoms/CircularAvatar/CircularAvatar";
import styles from "./PreviewProfileModal.module.scss";

import HomeIcon from "../../../../assets/Icons/HomeIcon";

const cx = createModuleStyleExtractor(styles);

export default function PreviewProfileModal({
  data,
  showPreviewProffile,
  setShowPreviewProfile,
  permissions = {},
}) {
  const onboardingSettings = permissions?.custom_onboarding_field;
  const profilePictureSetting = onboardingSettings?.profile_pitcure;
  const nationalitySetting = onboardingSettings?.country_of_origin;
  const degreeSetting = onboardingSettings?.degree;
  const majorSetting = onboardingSettings?.major;
  const enrollmentYearSetting = onboardingSettings?.graduation_year;

  const isProspect = data?.accountType?.value === "prospect_user";

  const renderEductionLabels = () => {
    if (isProspect) {
      return (
        <>
          {data?.degree?.value && degreeSetting?.is_visible && (
            <span>
              {data.degree.value}
              <br />
            </span>
          )}
          <span>
            {majorSetting?.is_visible && data?.major?.value}
            {majorSetting?.is_visible &&
              data?.major?.value &&
              enrollmentYearSetting?.is_visible &&
              data?.graduationYear?.value &&
              ", "}
            {enrollmentYearSetting?.is_visible && data?.graduationYear?.value}
          </span>
        </>
      );
    }
    return (
      <>
        {data?.degree?.value && (
          <span>
            {data.degree.value}
            <br />
          </span>
        )}
        <span>
          {data?.major?.value}
          {data?.major?.value && data?.graduationYear?.value && ", "}
          {data?.graduationYear?.value}
        </span>
      </>
    );
  };

  return (
    <div className={cx("o-preview-profile-modal-wrapper")}>
      <Modal
        show={showPreviewProffile}
        onClose={() => setShowPreviewProfile(false)}
        maxWidth={400}
      >
        <ModalBody>
          <div className={cx("o-preview-profile-body")}>
            <div className={cx("o-preview-profile-body__image")}>
              {isProspect && !profilePictureSetting?.is_visible ? (
                <></>
              ) : (
                <CircularAvatar
                  size={70}
                  name={
                    data?.firstName && data?.lastName
                      ? data?.firstName + " " + data?.lastName
                      : "*"
                  }
                  src={
                    typeof data?.profileImage === "string"
                      ? data?.profileImage
                      : URL.createObjectURL(data?.profileImage)
                  }
                  round
                />
              )}
            </div>
            <div className={cx("o-preview-profile-body__name")}>
              <h2>
                {data?.firstName && data?.lastName
                  ? data?.firstName + " " + data?.lastName
                  : ""}
              </h2>
            </div>
            <div className={cx("o-preview-profile-body__account_type")}>
              {data && data?.accountType?.value === "prospect_user" && (
                <Chip label="Prospect" />
              )}
              {data && data?.accountType?.value === "student" && (
                <Chip label="Student" />
              )}
              {data && data?.accountType?.value === "alumni" && (
                <Chip label="Alumni" />
              )}
            </div>
            <div className={cx("o-preview-profile-body__education")}>
              {renderEductionLabels()}
            </div>
            {isProspect && !nationalitySetting?.is_visible ? (
              <></>
            ) : data?.nationality?.value ? (
              <div className={cx("o-preview-profile-body__location")}>
                <HomeIcon />
                <span>{data.nationality.value}</span>
              </div>
            ) : (
              <></>
            )}
            {data?.intro && (
              <div className={cx("o-preview-profile-body__introduction")}>
                <p>{data?.intro}</p>
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
