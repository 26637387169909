import axiosHttp from "../utils/axios";

export const REQUEST = {
  GET: "GET",
  POST: "POST",
  PATCH: "PATCH",
  PUT: "PUT",
  DELETE: "DELETE",
  PURGE: "PURGE",
  LINK: "LINK",
  UNLINK: "UNLINK",
  OPTIONS: "OPTIONS",
};

export const RESPONSE_TYPE = {
  JSON: "json",
  ARRAY_BUFFER: "arraybuffer",
  BLOB: "blog",
  DOCUMENT: "document",
  TEXT: "text",
  STREAM: "stream",
};

/***
 * Top wrapper for API call
 */
export const ApiWrapper = async ({
  method = REQUEST.GET,
  url,
  responseType = RESPONSE_TYPE.JSON,
  data,
  config = {},
}) => {
  try {
    let response;
    switch (method) {
      case REQUEST.GET:
        response = await axiosHttp.get(url, config, { responseType });
        break;

      case REQUEST.POST:
        response = await axiosHttp.post(url, data, { responseType });
        break;

      case REQUEST.PUT:
        response = await axiosHttp.put(url, data, { responseType });
        break;

      case REQUEST.PATCH:
        response = await axiosHttp.patch(url, data, { responseType });
        break;

      case REQUEST.DELETE:
        response = await axiosHttp.delete(url, config, { responseType });
        break;

      default:
      // just ignore at the moment
    }

    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      // toastify("Something went wrong!");
      // dispatch error
      console.error("Error: ", error);
      return { success: false };
    }
  }
};
