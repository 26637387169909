import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Modal } from "../../atoms/Modal/Modal";
import { ModelHeader } from "../../atoms/Modal/ModelHeader/ModelHeader";
import { ModalBody } from "../../atoms/Modal/ModalBody/ModalBody";
import { Button } from "../../atoms/Button/Button";
import styles from "./ReportChat.module.scss";
import { createModuleStyleExtractor } from "../../../utils/css";
import { reportChat } from "../../../api/chat";
import MuiTextbox from "../../atoms/MuiTextbox/MuiTextbox";
import toastify from "../../../utils/toast";

const cx = createModuleStyleExtractor(styles);

const ReportChat = ({ show, onClose, onSuccess }) => {
  const [text, setText] = useState("");
  const [isReported, setIsReported] = useState(false);
  const [loading, setLoading] = useState(false);

  const { activeConversation } = useSelector((store) => store.chatStore);

  const handleOnClick = async () => {
    if (!isReported) {
      try {
        setLoading(true);
        const response = await reportChat({
          report_text: text,
          conversation_id: activeConversation,
        });
        setLoading(false);
        if (response?.success) {
          setIsReported(true);
          return;
        } else {
          toastify("Oops! something went wrong. Please try again");
        }
      } catch (error) {
        console.error(error);
        toastify("Oops! something went wrong. Please try again");
      }
    }

    onSuccess();
    setIsReported(false);
  };

  useEffect(() => {
    if (!show) {
      setText("");
      setIsReported(false);
      setLoading(false);
    }
  }, [show]);

  return (
    <Modal show={show} onClose={onClose}>
      <ModelHeader
        title={
          !isReported
            ? "What would you like to report?"
            : "Thanks for sharing your feedback!"
        }
      />
      <ModalBody>
        <p className={cx("report-heading")}>
          {!isReported
            ? ""
            : "We will review your report and take the necessary action."}
        </p>
        {!isReported && (
          <div className={cx("m-report-chat-textbox-wrapper")}>
            <MuiTextbox
              placeholder="Please tell us a reason for your report"
              isMultiline
              rows={2}
              value={text}
              onChange={(e) => setText(e.target.value)}
              maxLength={255}
            />
          </div>
        )}
        <div className={cx("footer")}>
          <div className={cx("button-wrapper")}>
            <Button onClick={handleOnClick} isSubmitting={loading}>
              {!isReported ? "Share Report" : "Got It!"}
            </Button>
          </div>
          {!isReported && (
            <span className={cx("cancel-label")} onClick={onClose}>
              No thanks
            </span>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ReportChat;
