import React, { memo } from "react";
import { Link } from "react-router-dom";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./HelpCenterIcon.module.scss";
import { ROUTES } from "../../../routes";
import { Mixpanel } from "../../../MixPanel/mixpanel";

import FAQ from "../../../assets/images/FAQ.svg";

const cx = createModuleStyleExtractor(styles);

const HelpCenterIcon = ({ active = false }) => (
  <div
    className={cx([
      "a-header-help-center-icon",
      active ? "a-header-help-center-icon--active" : "",
    ])}
  >
    <Link
      to={ROUTES.HELP_CENTER}
      onClick={() => {
        Mixpanel.track("Help center visited");
      }}
    >
      <img src={FAQ} alt="Message" />
    </Link>
  </div>
);

export default memo(HelpCenterIcon);
