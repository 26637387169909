import React from "react";

function UploadIcon({style}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="15"
      viewBox="0 0 16 15"
      style={style}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g stroke="#FFF" strokeWidth="1.2" transform="translate(-177 -589)">
          <g transform="translate(88 541)">
            <g>
              <g transform="translate(69 36)">
                <g transform="translate(21 13)">
                  <path strokeLinecap="square" d="M.5 12.5h13"></path>
                  <path
                    d="M4 0L7 3 10 0"
                    transform="matrix(1 0 0 -1 0 3)"
                  ></path>
                  <path
                    strokeLinecap="square"
                    d="M7 9V1.5"
                    transform="matrix(1 0 0 -1 0 10)"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default React.memo(UploadIcon);
