import React, { memo, useEffect, useState } from "react";

import { getSelectOptions } from "../../../utils/noop";
import { createModuleStyleExtractor } from "../../../utils/css";
import MuiSelect from "../../atoms/MuiSelect/MuiSelect";
import { Button } from "../../atoms/Button";
import styles from "./DiscoverNetworkFilters.module.scss";

import DiscoverListIcon from "../../../assets/Icons/DiscoverListIcon";
import DiscoverMapIcon from "../../../assets/Icons/DiscoverMapIcon";

const cx = createModuleStyleExtractor(styles);

const DiscoverNetworkFilters = ({
  tab = "left",
  networkFilterOptions = {},
  selectedNetworkFilters = {},
  onChangeFilter = () => {},
  onClearFilter = () => {},
  setTab = () => {},
  showDropdownFilters = true,
  variant = "chat", // possible values => [chat, widget]
}) => {
  const [userTypeOptions, setUserTypeOptions] = useState([]);

  const countryOptions = getSelectOptions(networkFilterOptions.countries);
  const degreeOptions = getSelectOptions(networkFilterOptions.degree);
  const majorOptions = getSelectOptions(networkFilterOptions.major);

  useEffect(() => {
    let tabSpecificUserTypes = [...(networkFilterOptions?.user_types || [])];
    setUserTypeOptions(getSelectOptions(tabSpecificUserTypes));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, networkFilterOptions]);

  return (
    <div className={cx("m-filters-container")}>
      {variant === "chat" && (
        <h2 className={cx("m-filters-container__heading")}>Chat</h2>
      )}
      <div
        className={cx([
          "m-filters-container__container",
          variant === "widget" ? "m-filters-container__container--widget" : "",
        ])}
      >
        {showDropdownFilters ? (
          <div className={cx("m-filters-container__container__left")}>
            <div
              className={cx("m-filters-container__container__left__filters")}
            >
              <span
                className={cx(
                  "m-filters-container__container__left__filters__title"
                )}
              >
                Filter
              </span>
              {countryOptions?.length > 0 && (
                <MuiSelect
                  label=""
                  placeholder="Filter by country"
                  value={
                    countryOptions
                      ? countryOptions.find(
                          (option) =>
                            option.value === selectedNetworkFilters?.country
                        )
                      : ""
                  }
                  options={countryOptions}
                  justifyContent
                  onChange={(item) =>
                    onChangeFilter("country", item ? item.value : null)
                  }
                  isClearable={true}
                />
              )}
              {degreeOptions?.length > 0 && (
                <MuiSelect
                  label=""
                  placeholder="Filter by degree"
                  value={
                    degreeOptions
                      ? degreeOptions.find(
                          (option) =>
                            option.value === selectedNetworkFilters?.degree
                        )
                      : ""
                  }
                  options={degreeOptions}
                  onChange={(item) =>
                    onChangeFilter("degree", item ? item.value : null)
                  }
                  justifyContent
                  isClearable={true}
                />
              )}
              {majorOptions?.length > 0 && (
                <MuiSelect
                  label=""
                  placeholder="Filter by major"
                  value={
                    majorOptions
                      ? majorOptions.find(
                          (option) =>
                            option.value === selectedNetworkFilters?.major
                        )
                      : ""
                  }
                  options={majorOptions}
                  onChange={(item) =>
                    onChangeFilter("major", item ? item.value : null)
                  }
                  justifyContent
                  isClearable={true}
                />
              )}
              {variant !== "widget" && userTypeOptions?.length > 0 && (
                <MuiSelect
                  label=""
                  placeholder="Filter by user"
                  value={
                    userTypeOptions
                      ? userTypeOptions.find(
                          (option) =>
                            option.value === selectedNetworkFilters?.user_type
                        )
                      : ""
                  }
                  options={userTypeOptions}
                  onChange={(item) =>
                    onChangeFilter("user_type", item ? item.value : null)
                  }
                  justifyContent
                  isClearable={true}
                />
              )}
              {Object.keys(selectedNetworkFilters).length > 0 && (
                <span
                  className={cx(
                    "m-filters-container__container__left__filters__clear"
                  )}
                  onClick={onClearFilter}
                >
                  Clear all
                </span>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className={cx("m-filters-container__container__right")}>
          <div
            className={cx([
              "m-filters-container__container__right__button",
              tab === "left"
                ? "m-filters-container__container__right__button--active"
                : "",
            ])}
          >
            <Button onClick={() => setTab("left")}>
              <div
                className={cx([
                  "m-filters-container__container__right__button__body",
                  tab === "left"
                    ? "m-filters-container__container__right__button__body--active"
                    : "",
                ])}
              >
                <DiscoverMapIcon selected={tab === "left"} />
                <span>Map view</span>
              </div>
            </Button>
          </div>
          <div
            className={cx([
              "m-filters-container__container__right__button",
              tab === "right"
                ? "m-filters-container__container__right__button--active"
                : "",
            ])}
          >
            <Button onClick={() => setTab("right")}>
              <div
                className={cx([
                  "m-filters-container__container__right__button__body",
                  tab === "right"
                    ? "m-filters-container__container__right__button__body--active"
                    : "",
                ])}
              >
                <DiscoverListIcon selected={tab === "right"} />
                <span>List view</span>
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(DiscoverNetworkFilters);
